import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'

import flareReducer from 'modules/flares/reducer'
import alertsReducer from './alerts/reducer'
import WMHReducer from './watchMyHome/reducer'
import requestsStatusesReducer from './requestsStatuses/reducer'
import userReducer from './user/reducer'
import usersReducer from './users/reducer'
import generalSettings from './generalSettings/reducer'
import geofenceReducer from './geofence/reducer'
import groupResucer from './group/reducer'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
  timeout: 120000,
  whitelist: [],
}

const rootReducer = combineReducers({
  flares: flareReducer,
  alerts: alertsReducer,
  wmh: WMHReducer,
  requestsStatuses: requestsStatusesReducer,
  user: userReducer,
  users: usersReducer,
  generalSettings,
  station: geofenceReducer,
  router: connectRouter(history),
  citizens: groupResucer,
})

export default persistReducer(persistConfig, rootReducer)
