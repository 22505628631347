/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'reactn'
import { styled, makeStyles, withStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CssBaseline,
  Typography,
  Container,
  CircularProgress,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx'

import { connect } from 'react-redux'

import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { decodeJWTToken } from '../utils/decodeJWTToken'
import Licenses from './Licenses'
import { USER_LOG_OUT } from '../modules/user/actions'
import {
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_SUBMIT,
  AUTH_FORGOT_USERNAME,
} from '../modules/auth/actions'
import {
  isSuccessSelector,
  reasonFailedSelector,
  isLoadingSelector,
  isFailedSelector,
} from '../modules/requestsStatuses/selectors'
import {
  CssPasswordInput,
  Logo,
  CssTextField,
} from '../ui/auth/components/common'

import ForgotDialog from '../ui/auth/components/dialog'

import { DIALOG_TYPE } from '../ui/auth/constants'
import { getStationId } from '../components/StationDetect'

import * as sharedStyles from '../ui/shared/styles'
import { getAuthToken } from '../api/utils/getAuthToken'
import { parseError } from '../utils/parseError'
import { Regex } from 'utils/regex'

const StyledCircularProgress = withStyles({
  root: {
    marginLeft: '20px',
  },
  colorPrimary: {
    color: 'primary',
  },
})(CircularProgress)

const CssContainer = styled(Container)({
  marginTop: '3.5625rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  resourceContainer: {
    marginTop: '1rem',
  },
  resourceCard: {
    width: '97%',
  },
  heading: {
    fontSize: '1rem',
  },
  secondaryHeading: {
    color: '#989898',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '100%',
  },
  helper: {
    borderLeft: '2px solid #989898',
    padding: '2rem',
  },
  link: {
    color: '#0052a5',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
let thisUser = ''
let thisPass = ''
let session = ''

class Login extends Component {
  state = {
    username: '',
    password: '',
    currentStation: getStationId().toLowerCase(),
    new_password: '',
    confirm_password: '',
    authcode: '',
    dialogOpen: false,
    resetPass: false,
    openLicenseDialog: false,
    dialogType: undefined,
    isSignIn: false,
    errors: {},
  }

  componentDidMount() {
    const { history } = this.props
    getAuthToken().then((token) => {
      if (token) {
        history.push('/dashboard')
      }
    })
  }

  componentDidUpdate(prevProps) {
    const {
      forgotPasswordFailedMessage,
      forgotUsernameFailedMessage,
      forgotUsernameSuccess,
      forgotUsernameFailed,
    } = this.props
    if (
      forgotPasswordFailedMessage &&
      forgotPasswordFailedMessage !== prevProps.forgotPasswordFailedMessage
    ) {
      this.showError(forgotPasswordFailedMessage)
    }
    if (
      forgotUsernameSuccess &&
      forgotUsernameSuccess !== prevProps.forgotUsernameSuccess
    ) {
      this.showSuccess('We will send an email with the username ')
      this.handleDialogClose()
    } else if (
      forgotUsernameFailed &&
      forgotUsernameFailed !== prevProps.forgotUsernameFailed
    ) {
      this.showError(forgotUsernameFailedMessage)
    }
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  signIn = () => {
    const {
      history,
      location: { state },
    } = this.props
    console.log('SIGNIN')
    this.setState({ isSignIn: true })
    Auth.signIn(this.state.username, this.state.password) // Check login info against Cognito user pool
      .then((user) => {
        console.log('user', user)
        this.setGlobal({ userID: user })
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          thisUser = this.state.username
          thisPass = this.state.password
          session = user.Session
          history.push('/changepassword', {
            username: this.state.username,
            session: user.Session,
          })
        } else
          Auth.currentUserInfo()
            .then((data) => {
              const token = user.signInUserSession.idToken.jwtToken
              const decodedJWT = decodeJWTToken(token)

              const userStation = decodedJWT.station
              console.log('userStation', userStation)
              if (
                userStation === this.state.currentStation ||
                userStation === 'All'
              ) {
                if (state && state.pathname) {
                  history.push(state.pathname)
                } else {
                  history.push('/dashboard') // Forward user to main page
                }
              } else {
                this.showError()
                this.props.logOut()
              }
            })
            .catch((err) => console.log('error: ', err))
      })
      .catch((err) => {
        switch (err.code) {
          case 'UserNotConfirmedException': {
            thisUser = this.state.username
            thisPass = this.state.password
            history.push('/verifyusername', {
              username: this.state.username,
            })
            break
          }
          case 'PasswordResetRequiredException': {
            Auth.forgotPassword(this.state.username)
            this.props.history.push('/forgotpassword', {
              username: this.state.username,
              title: 'Password Update Required',
            })
            break
          }
          default: {
            console.log('error signing in...: ', err)
            this.showError(err.message) // Display login error to user
            break
          }
        }
      })
      .finally(() => {
        this.setState({ isSignIn: false })
      })
  }

  showError = (message) => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.error(message || 'User not exist', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    }
  }

  showSuccess = (message = 'Success') => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    }
  }

  handleDialogClose = () => {
    this.setState({ dialogType: undefined })
  }

  handleOpenForgotUsername = () => {
    this.setState({ dialogType: DIALOG_TYPE.FORGOT_USERNAME })
  }

  handleOpenForgotPassword = () => {
    this.setState({ dialogType: DIALOG_TYPE.FORGOT_PASSWORD })
  }

  forgotPasswordStart = async (value) => {
    const { dialogType } = this.state
    if (dialogType === DIALOG_TYPE.FORGOT_USERNAME) {
      this.props.forgotUserName(value)
    } else {
      this.props.forgotPassword({ username: value })
    }
  }

  // Open Send Alert window
  handleClickOpenLicenses = (booleanValue) => () => {
    this.setState({
      openLicenseDialog: booleanValue,
    })
  }

  validateField = (typeField, text, regex, textIfError) => {
    this.setState((prevState) => {
      const errors = { ...prevState.errors }
      if (!text || regex.test(text)) {
        delete errors[typeField]
      } else {
        errors[typeField] = textIfError
      }
      return { errors }
    })
  }

  validateUserField = (typeField, text) => {
    this.validateField(
      typeField,
      text,
      Regex.noWhitespace,
      'The field has white spaces',
    )
  }

  render() {
    const { dialogType } = this.state
    const { isLoading } = this.props
    console.log('isLoading', isLoading)
    return (
      <div>
        <CssContainer component="main">
          <CssBaseline />
          <div className="paper" style={sharedStyles.form}>
            <Logo />
            <Typography
              style={{ marginTop: '1rem' }}
              component="h1"
              variant="h4"
            >
              Sign in
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              noValidate
            >
              <CssTextField
                style={{ marginTop: '1.43rem' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email or Username"
                name="username"
                autoComplete="username"
                onChange={(evt) => {
                  this.validateUserField('username', evt.target.value)
                  this.onChange('username', evt.target.value)
                }}
                autoFocus
                error={this.state.errors.username}
              />
              {this.state.errors.username ? (
              <p style={styles.errorText}>
                {this.state.errors.username}
              </p>
            ) : null}

              <CssPasswordInput
                style={{ marginTop: '1rem' }}
                id="password"
                label="Password"
                name="password"
                onChange={this.onChange}
              />
              <Button
                style={styles.button}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={this.state.isSignIn || this.state.errors.username}
                onClick={this.signIn}
              >
                Sign In
                {this.state.isSignIn && <StyledCircularProgress size={20} />}
              </Button>
            </form>
          </div>
          <div style={styles.buttons_container}>
            <Button
              style={styles.secondary_button}
              color="primary"
              onClick={this.handleOpenForgotUsername}
            >
              Forgot Username?
            </Button>
            <Button
              style={styles.secondary_button}
              color="primary"
              onClick={this.handleOpenForgotPassword}
            >
              Forgot Password?
            </Button>
          </div>
        </CssContainer>

        {/* Forgot Password Dialog */}
        <ForgotDialog
          isLoading={isLoading}
          dialogType={dialogType}
          handleCloseDialog={this.handleDialogClose}
          onSendHandle={this.forgotPasswordStart}
        />
        {/* End Forgot Password Dialog */}
        <Button
          style={{
            marginTop: '1rem',
            position: 'absolute',
            bottom: '.2rem',
            right: '.2rem',
            fontSize: '.5rem',
            color: '#989898',
          }}
          onClick={this.handleClickOpenLicenses(true)}
        >
          Licenses
        </Button>
        <ShowLicenses
          open={this.state.openLicenseDialog}
          handleClickOpen={this.handleClickOpenLicenses}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    forgotPasswordFailedMessage: reasonFailedSelector(
      state,
      AUTH_FORGOT_PASSWORD,
    ),
    isLoading:
      isLoadingSelector(state, AUTH_FORGOT_PASSWORD) ||
      isLoadingSelector(state, AUTH_FORGOT_USERNAME),
    forgotUsernameSuccess: isSuccessSelector(state, AUTH_FORGOT_USERNAME),
    forgotUsernameFailed: isFailedSelector(state, AUTH_FORGOT_USERNAME),
    forgotUsernameFailedMessage: reasonFailedSelector(
      state,
      AUTH_FORGOT_USERNAME,
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      forgotPassword: AUTH_FORGOT_PASSWORD.START.create,
      forgotPasswordSubmit: AUTH_FORGOT_PASSWORD_SUBMIT.START.create,
      logOut: USER_LOG_OUT.START.create,
      forgotUserName: AUTH_FORGOT_USERNAME.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))

function ShowLicenses(props) {
  function extractNameFromGithubUrl(url) {
    if (!url) {
      return null
    }

    const reg =
      /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i
    const components = reg.exec(url)

    if (components && components.length > 5) {
      return components[5]
    }
    return null
  }

  function sortDataByKey(data, key) {
    // eslint-disable-next-line no-nested-ternary
    data.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0))
    return data
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const licenses = Object.keys(Licenses).map((key) => {
    // eslint-disable-next-line no-shadow
    const { licenses, ...license } = Licenses[key]
    const [name, version] = key.split('@')

    const reg =
      /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i
    let username =
      extractNameFromGithubUrl(license.repository) ||
      extractNameFromGithubUrl(license.licenseUrl)

    let userUrl
    let image
    if (username) {
      username = capitalizeFirstLetter(username)
      image = `http://github.com/${username}.png`
      userUrl = `http://github.com/${username}`
    }

    return {
      key,
      name,
      image,
      userUrl,
      username,
      licenses: licenses.slice(0, 405),
      version,
      ...license,
    }
  })

  sortDataByKey(licenses, 'key')

  const classes = useStyles()

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title" fullScreen>
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        Licenses
      </DialogTitle>
      <DialogContent>
        {licenses.map(
          ({
            key,
            name,
            licenseUrl,
            // eslint-disable-next-line no-shadow
            licenses,
            parents,
            repository,
            userUrl,
            username,
            version,
          }) => (
            <Accordion key={key} className={classes.resourceCard}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>{key}</Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}>
                    {licenses}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={clsx(classes.column, classes.helper)}>
                  <Typography variant="caption">
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Name:{' '}
                      </strong>
                      {name}
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Key:{' '}
                      </strong>
                      {key}
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        License URL:{' '}
                      </strong>
                      <a
                        href={licenseUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {licenseUrl}
                      </a>
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Licenses:{' '}
                      </strong>
                      {licenses}
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Parents:{' '}
                      </strong>
                      {parents}
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Repository:{' '}
                      </strong>
                      <a
                        href={repository}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {repository}
                      </a>
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        User URL:{' '}
                      </strong>
                      <a
                        href={userUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {userUrl}
                      </a>
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Username:{' '}
                      </strong>
                      {username}
                    </p>
                    <p>
                      <strong
                        style={{ color: '#0052a5', textTransform: 'uppercase' }}
                      >
                        Version:{' '}
                      </strong>
                      {version}
                    </p>
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          ),
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClickOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  buttons_container: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  secondary_button: {
    color: 'black',
    marginTop: '1rem',
    fontSize: '1rem',
    textDecoration: 'underline',
  },
  button: {
    heigth: '3.5rem',
    fontSize: '1.5625rem',
    marginTop: '1.5rem',
  },
  errorText: {
    color: 'rgb(213, 0, 0)',
    textAlign: 'left',
  },
}
