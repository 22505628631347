import LinearProgress from '@material-ui/core/LinearProgress'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React from 'reactn'

const styles = {
  progress: {
    width: '100%',
    height: '4px',
  },
}

export const ModalDialog = ({
  isSubmitButtonDisabled,
  isLoading,
  open,
  close,
  title,
  maxWidth,
  buttonText,
  onSubmit,
  children,
}) => (
  <Dialog open={open} maxWidth={maxWidth} fullWidth>
    {isLoading && (
      <LinearProgress variant="indeterminate" style={styles.progress} />
    )}
    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
      {title}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={close} color="primary">
        Close
      </Button>
      <Button
        onClick={onSubmit}
        color="primary"
        disabled={isSubmitButtonDisabled}
      >
        {buttonText || 'Submit'}
      </Button>
    </DialogActions>
  </Dialog>
)
