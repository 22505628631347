import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (flareId, flareData) =>
  new Promise(async (resolve) => {
    const path = `${FLARE_PATH.latest}/${flareId}`
    const result = {
      error: undefined,
      response: undefined,
    }

    const token = await getAuthToken()
    try {
      const response = await API.patch(FLARE_API_NAME, path, {
        body: flareData,
        headers: {
          Authorization: token,
        },
      })

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: FLARE_API_NAME + path,
        payload: {
          flareId,
          flareData,
        },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
