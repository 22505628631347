import InputMask from 'react-input-mask'
import React, { forwardRef, useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Popper, TextField } from '@material-ui/core'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import DateFnsAdapter from '@material-ui/lab/AdapterDateFns'
import { StaticDateRangePicker } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { useOnClickOutside } from '../../../../hooks/useClickOutsideToClose'

const PopperContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
})(Paper)

const DefaultDateFiltersContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    maxWidth: 200,
  },
})(Container)

const DefaultDateFilterButton = withStyles({
  root: {
    width: 140,
  },
})(Button)

export const FlareFiltersItem = withStyles({
  root: {
    display: 'flex',
    gap: '35px',
    padding: '10px 0',
  },
})(Container)

const DateMaskInput = forwardRef((props) => (
  <InputMask
    {...props}
    ref={props.inputRef}
    value={props.value}
    onChange={props.onChange}
    mask="99/99/9999"
    maskChar={null}
  />
))

export const CustomDateRangePicker = ({ date: [start, end], onDateChange }) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [dateErrors, setDateErrors] = useState({
    start: false,
    end: false,
  })
  const datePickerRef = useOnClickOutside(() => setIsPopperOpen(false))

  useEffect(() => {
    setStartDate(formatDate(start))
    setEndDate(formatDate(end))
  }, [start, end])

  const handleInputFocus = (event) => {
    if (isPopperOpen) return
    setAnchorEl(event.target.parentNode.parentNode.parentNode)
    setIsPopperOpen(true)
  }

  const handleInputBlur = () => {
    // setIsPopperOpen(false)
  }

  const checkIsDateValid = (date) => moment(date, 'MM/DD/YYYY', true).isValid()

  const handleStartChange = ({ target }) => {
    setStartDate(target.value)
    const isDateValid = checkIsDateValid(target.value)
    if (isDateValid) {
      setDateErrors((prev) => ({
        ...prev,
        start: false,
      }))

      return onDateChange([new Date(target.value), end])
    }
    setDateErrors((prev) => ({
      ...prev,
      start: true,
    }))
  }

  const handleEndChange = ({ target }) => {
    setEndDate(target.value)
    const isDateValid = checkIsDateValid(target.value)
    if (isDateValid) {
      setDateErrors((prev) => ({
        ...prev,
        end: false,
      }))
      return onDateChange([start, new Date(target.value)])
    }
    setDateErrors((prev) => ({
      ...prev,
      end: true,
    }))
  }

  const formatDate = (date) => {
    const formattedDate = moment(date).format('MM/DD/YYYY')
    return formattedDate === 'Invalid date' ? '' : formattedDate
  }

  const handleDateRangeError = ([startValue, endValue]) => {
    setDateErrors({ start: startValue !== null, end: endValue !== null })
  }

  return (
    <div ref={datePickerRef}>
      <FlareFiltersItem>
        <TextField
          value={startDate}
          label="Start date"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          error={dateErrors.start}
          InputProps={{
            inputComponent: DateMaskInput,
            onChange: handleStartChange,
            value: startDate,
          }}
          placeholder="mm/dd/yyyy"
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="standard"
        />
        <TextField
          label="End date"
          error={dateErrors.end}
          InputProps={{
            inputComponent: DateMaskInput,
            onChange: handleEndChange,
            value: endDate,
          }}
          placeholder="mm/dd/yyyy"
          InputLabelProps={{ shrink: true }}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          fullWidth
          variant="standard"
        />
      </FlareFiltersItem>
      <Popper
        open={isPopperOpen}
        disablePortal
        anchorEl={anchorEl}
        placement="bottom"
      >
        {() => (
          <PopperContainer>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                allowSameDateSelection
                disableCloseOnSelect
                value={[start, end]}
                onError={handleDateRangeError}
                onChange={onDateChange}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
            <DefaultDateFiltersContainer>
              {defaultFilters().map(({ name, dateRange }) => (
                <DefaultDateFilterButton
                  key={name}
                  variant="contained"
                  color="primary"
                  onClick={() => onDateChange(dateRange)}
                >
                  {name}
                </DefaultDateFilterButton>
              ))}
            </DefaultDateFiltersContainer>
          </PopperContainer>
        )}
      </Popper>
    </div>
  )
}

const defaultFilters = () => {
  const today = moment().toDate()
  const lastDay = moment().subtract(1, 'day').toDate()
  const last3Days = moment().subtract(2, 'day').toDate()
  const lastWeek = moment().subtract(1, 'week').toDate()
  const last2Weeks = moment().subtract(2, 'week').toDate()
  const lastMonth = moment().subtract(1, 'month').toDate()
  const last3Month = moment().subtract(3, 'month').toDate()
  return [
    {
      name: 'reset',
      dateRange: ['', ''],
    },
    {
      name: 'last day',
      dateRange: [lastDay, lastDay],
    },
    {
      name: 'last 3 days',
      dateRange: [last3Days, today],
    },
    {
      name: 'last week',
      dateRange: [lastWeek, today],
    },
    {
      name: 'last 2 weeks',
      dateRange: [last2Weeks, today],
    },
    {
      name: 'last month',
      dateRange: [lastMonth, today],
    },
    {
      name: 'last 3 month',
      dateRange: [last3Month, today],
    },
  ]
}
