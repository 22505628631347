import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Geocode from 'react-geocode'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import getParsedStatus from 'utils/getParsedStatus'
import Button from 'components/Button/Button'
import { getFlaresSelected } from 'modules/flares/selectors'
import { LinkItUrl } from 'react-linkify-it'
import { FlareAttachments } from './FlareAttachments'
import FormDialog from '../FormDialog/FormDialog'
import Logs from '../Logs'
import ProgressBar from '../ProgressBar/ProgressBar'
import { EmbedMapView } from '../../MapFunctions'
import { isFloorsOptionAvailable } from '../../../modules/geofence/selectors'
import { ProgressStage } from '../../../constants'
import { flareActions } from '../../../utils/getFormDialogInfo'

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    '&:not(:first-of-type)': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-root:before': {
      height: 0,
    },
  },
  accordionSummaryRoot: {
    padding: 0,
    minHeight: 50,
    margin: '0 24px 0 30px',
    '&.Mui-expanded': { minHeight: 50 },
  },
  accordionSummaryContent: {
    margin: '16px 0',
    '&.Mui-expanded': { margin: '16px 0' },
  },
  accordionDetailsRoot: {
    margin: '4px 30px 30px',
    padding: 0,
  },
  accordionStepperRoot: {
    padding: 0,
    marginRight: 15,
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    overflow: 'hidden',
  },
  summaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  scrollableContainer: {
    height: '200px',
    overflowY: 'auto',
  },
  contentColumn: {
    overflow: 'hidden',
  },
  logContainer: {
    width: '100%',
  },
  infoText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#555555',
    '-moz-hyphens': 'auto',
    '-webkit-hyphens': 'auto',
    '-o-hyphens': 'auto',
    hyphens: 'auto',
    overflow: 'auto',
    maxHeight: '3.6em', // show max 3 lines
  },
  coordination: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#555555',
  },
  fakeIcon: {
    height: 24,
    width: 24,
  },
  buttonRoot: {
    width: 91,
  },
  noteButton: {
    color: theme.palette.blue,
    backgroundColor: 'rgb(1, 42, 68, 0.1)',
  },
  finishButton: {
    color: theme.palette.finish,
    backgroundColor: 'rgba(244, 67, 54, 0.13)',
  },
  makeActiveButton: {
    backgroundColor: 'rgb(9, 33, 20, 0.1)',
    '&:hover': {
      backgroundColor: 'rgb(23, 83, 50, 0.05)',
    },
  },
  stepperPosition: {
    '&>div:first-of-type': {
      marginLeft: '-12%',
    },
    '&>div:last-of-type': {
      marginRight: '-12%',
    },
  },
}))

function FlareItem({
  currentUser,
  isSingleFlare,
  reportMode,
  historyMode,
  handleUpdateDataFlare,
  handleAddNoteFlare,
  getFlareLogs,
  getMoreFlareLogs,
  flare,
  addFlareToSelection,
  removeFlareFromSelection,
  isLoadingLogs,
}) {
  const classes = useStyles()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalAction, setModalAction] = useState()
  const [panelExpanded, setPanelExpanded] = useState(isSingleFlare || false)
  const [newStatus, setNewStatus] = useState('')
  const [address, setAddress] = useState('')
  const isFloorOptionAvailable = useSelector(isFloorsOptionAvailable)
  const [isFlareSelected, setIsFlareSelected] = useState(false)
  const flaresSelected = useSelector(getFlaresSelected)

  useEffect(() => {
    if (panelExpanded) {
      getFlareLogs({ flareId: flare.id })
    }
  }, [panelExpanded, flare.id, getFlareLogs])

  // Get address from latitude & longitude.
  useEffect(() => {
    if (flare.address) {
      setAddress(flare.address)
    } else if (flare && flare.latitude && flare.longitude) {
      Geocode.fromLatLng(flare.latitude, flare.longitude).then(
        (response) => {
          const addressResponse = response.results[0].formatted_address
          setAddress(addressResponse)
        },
        (error) => {
          console.error(error)
        },
      )
    } else {
      setAddress('******')
    }
  }, [flare.latitude, flare.longitude, flare.address])

  useEffect(() => {
    if (isSingleFlare && flare.status === ProgressStage.NEW) {
      handleUpdate()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setIsFlareSelected(flare.isSelected)
  }, [flaresSelected])

  const fetchPrevFlareLogs = useCallback(async () => {
    if (!flare.logs) return
    const lastId = flare.logs[flare.logs.length - 1].id
    getMoreFlareLogs({ flareId: flare.id, lastId })
  }, [flare, getMoreFlareLogs])

  const handlePanelChange = () => {
    if (isSingleFlare || reportMode) {
      return
    }
    setPanelExpanded(!panelExpanded)
    // Change status to received when panel is first opened
    if (flare.status === ProgressStage.NEW) {
      handleUpdate()
    }
  }

  const { type, color } = getParsedStatus(flare.status, flare.isClosed)

  const fontWeight = useMemo(
    () => (flare.status === ProgressStage.NEW ? 'bolder' : 'normal'),
    [flare.status],
  )

  const expandIcon = useMemo(
    () =>
      !reportMode && !isSingleFlare ? (
        <ExpandMoreIcon />
      ) : (
        <div className={classes.fakeIcon} />
      ),
    [reportMode, isSingleFlare, classes.fakeIcon],
  )

  const finished = useMemo(
    () => flare.isClosed || flare.status === ProgressStage.COMPLETED,
    [flare.isClosed, flare.status],
  )

  const handleUpdate = useCallback(
    (message) => {
      switch (modalAction) {
        case flareActions.SEND_MESSAGE:
        case flareActions.ADD_NOTE:
          handleAddNoteFlare({ flareId: flare.id, message })
          break
        case flareActions.MOVE_TO_HISTORY:
        case flareActions.CHANGE_STATUS:
        case flareActions.MAKE_ACTIVE:
          handleUpdateDataFlare({
            flareId: flare.id,
            flareData: {
              flareAction: modalAction,
              status: newStatus || flare.status,
              logs: [
                {
                  message: message || 'No message provided',
                },
              ],
            },
          })
          break
        default:
          handleUpdateDataFlare({
            flareId: flare.id,
            flareData: {
              status: ProgressStage.RECEIVED,
              logs: [
                {
                  message: 'Flare received',
                },
              ],
            },
          })
          break
      }
    },
    [
      flare.id,
      handleAddNoteFlare,
      modalAction,
      currentUser.formattedFullname,
      flare.status,
      newStatus,
      handleUpdateDataFlare,
    ],
  )

  const openModal = (action) => {
    setModalAction(action)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalAction(undefined)
    setModalOpen(false)
    setNewStatus('')
  }

  const handleSendMessage = () => openModal(flareActions.SEND_MESSAGE)
  const handleMoveToHistory = () => {
    setNewStatus(ProgressStage.CLOSED)
    openModal(flareActions.MOVE_TO_HISTORY)
  }
  const handleAddNote = () => openModal(flareActions.ADD_NOTE)
  const handleMakeActive = () => {
    setNewStatus(
      flare.status === ProgressStage.COMPLETED
        ? ProgressStage.IN_PROGRESS
        : flare.status,
    )
    openModal(flareActions.MAKE_ACTIVE)
  }
  const handleChangeStatus = (status) => {
    setNewStatus(status)
    openModal(flareActions.CHANGE_STATUS)
  }

  const flareLogs = useMemo(() => flare.logs, [flare])
  const flareLogsTotal = useMemo(() => flare.logsTotal, [flare])

  return (
    <>
      <Accordion
        expanded={panelExpanded}
        onChange={handlePanelChange}
        TransitionProps={{ unmountOnExit: true }}
        classes={{ root: classes.accordionRoot }}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
          }}
          expandIcon={expandIcon}
        >
          <Grid container alignItems="center" style={{ height: '19px' }}>
            {!(reportMode || historyMode || isSingleFlare) && (
              <Grid item xs={1}>
                <input
                  id="flareSelected"
                  type="checkbox"
                  checked={!!isFlareSelected}
                  onChange={(e) => {
                    if (!panelExpanded) {
                      setPanelExpanded(false)
                    } else {
                      setPanelExpanded(true)
                    }
                    const { checked } = e.target
                    setIsFlareSelected(checked)
                    if (checked) {
                      addFlareToSelection(flare)
                    } else {
                      removeFlareFromSelection(flare)
                    }
                  }}
                />
              </Grid>
            )}
            {!reportMode && (
              <Grid item xs={2}>
                <Tooltip
                  title={flare.citizen?.name}
                  placement="bottom-start"
                  enterDelay={500}
                  style={{ fontWeight }}
                >
                  <Typography classes={{ root: classes.summaryText }}>
                    {flare.citizen?.name}
                  </Typography>
                </Tooltip>
              </Grid>
            )}

            <Grid item xs={2}>
              <Tooltip
                title={flare.type?.name || flare.type}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight }}
                >
                  {flare.type?.name ? flare.type?.name || flare.type : ''}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={2}>
              <Tooltip title={type} placement="bottom-start" enterDelay={500}>
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight, color }}
                >
                  {type}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={reportMode ? 4 : 3}>
              <Tooltip
                title={address}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight }}
                >
                  {address.split(',')[0]}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={reportMode ? 3 : 2}>
              <Tooltip
                title={flare.createdAt?.toLocaleString('en-US', {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight }}
                >
                  {flare.createdAt?.toLocaleString('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                  })}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} classes={{ root: classes.contentColumn }}>
              <Grid container direction="column" spacing={1.25}>
                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Name
                  </Typography>

                  <Typography classes={{ root: classes.infoText }}>
                    {flare.citizen?.name}
                  </Typography>
                </Grid>

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Location
                  </Typography>

                  <Typography classes={{ root: classes.infoText }}>
                    {address}
                  </Typography>
                  <Typography
                    classes={{ root: classes.coordination }}
                    variant="body2"
                  >
                    {flare.latitude}, {flare.longitude}
                  </Typography>
                </Grid>

                {typeof flare.floor === 'number' || isFloorOptionAvailable ? (
                  <Grid item width="100%">
                    <Typography classes={{ root: classes.summaryText }}>
                      Floor
                    </Typography>

                    <Typography classes={{ root: classes.infoText }}>
                      {typeof flare.floor === 'number'
                        ? flare.floor
                        : 'Not Submitted'}
                    </Typography>
                  </Grid>
                ) : null}

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Phone
                  </Typography>

                  <Typography classes={{ root: classes.infoText }}>
                    {flare.citizen?.phoneNumber}
                  </Typography>
                </Grid>

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Details
                  </Typography>
                  <LinkItUrl>
                    <Typography classes={{ root: classes.infoText }}>
                      {flare.message}
                    </Typography>
                  </LinkItUrl>
                </Grid>

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Logs
                  </Typography>
                  <Grid
                    container
                    classes={{ root: classes.scrollableContainer }}
                  >
                    <Grid item xs={12}>
                      {/* We can either add a scrollable view or a view more button here */}
                      <Grid container>
                        <Grid item classes={{ root: classes.logContainer }}>
                          <Logs
                            flareLogs={flareLogs}
                            flareLogsTotal={flareLogsTotal}
                            fetchPrevFlareLogs={fetchPrevFlareLogs}
                            isLoadingLogs={isLoadingLogs}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} classes={{ root: classes.contentColumn }}>
              <FlareAttachments image={flare.files} />
            </Grid>

            <Grid item xs={12} sm={4} classes={{ root: classes.contentColumn }}>
              <EmbedMapView
                zoom={17}
                center={{ lat: flare.latitude, lng: flare.longitude }}
                height="404"
              />
            </Grid>
          </Grid>
        </AccordionDetails>

        <AccordionDetails classes={{ root: classes.accordionStepperRoot }}>
          <Box flex={1} sx={{ m: 2.25 }}>
            <ProgressBar
              status={flare.status}
              onClick={handleChangeStatus}
              isClosed={flare.isClosed}
              disabled={finished}
              className={classes.stepperPosition}
            />
          </Box>
          <Button
            onClick={finished ? handleAddNote : handleSendMessage}
            color="primary"
            classes={{ root: clsx(classes.buttonRoot, classes.noteButton) }}
          >
            {finished ? 'Add Note' : 'Send Message'}
          </Button>
          {finished ? (
            <Button
              onClick={handleMakeActive}
              color="primary"
              style={{ color: '#175332' }}
              classes={{
                root: clsx(classes.buttonRoot, classes.makeActiveButton),
              }}
            >
              Make Active
            </Button>
          ) : (
            <Button
              onClick={handleMoveToHistory}
              color="secondary"
              classes={{ root: clsx(classes.buttonRoot, classes.finishButton) }}
            >
              Move To History
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
      {isModalOpen && (
        <FormDialog
          onSend={handleUpdate}
          modalAction={modalAction}
          onClose={closeModal}
          newStatus={newStatus}
        />
      )}
    </>
  )
}

export default FlareItem
