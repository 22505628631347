/* eslint-disable no-param-reassign */
import produce from 'immer'

import { GET_GENERAL_SETTINGS, GET_USER_GENERAL_SETTINGS } from './actions'

const INITIAL_STATE = {
  general: [
    { option: 'REGISTRATION', enabled: false },
    { option: 'ALERT', enabled: true },
  ],
  user: [
    { option: 'MAP', enabled: true },
    { option: 'FLARE', enabled: true },
    { option: 'CREATE_USER', enabled: true },
  ],
}

function generalSettingsReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_GENERAL_SETTINGS.SUCCESS.type:
        draft.general = action.payload || INITIAL_STATE.general
        break

      case GET_USER_GENERAL_SETTINGS.SUCCESS.type:
        draft.user = action.payload || INITIAL_STATE.user
        break

      default:
        break
    }
  })
}

export default generalSettingsReducer
