import produce from 'immer'
import { USER_GET_INFO, USER_LOG_OUT, USER_EDIT } from './actions'
import { setUserHelper } from './helpers'

const INITIAL_STATE = {
  id: null,
  username: '',
  givenName: '',
  familyName: '',
  email: '',
  formattedFullname: '',
  role: '',
  emailVerified: true,
  phoneNumber: '',
  phoneNumberVerified: false,
  notificationSettings: {
    email: false,
    sms: false,
  },
  stationRoleAccess: '',
}

function userReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case USER_GET_INFO.SUCCESS.type:
        return setUserHelper(draft, action.payload)

      case USER_EDIT.SUCCESS.type:
        setUserHelper(draft, action.payload)
        return

      case USER_LOG_OUT.SUCCESS.type:
        return INITIAL_STATE

      default:
        return draft
    }
  })
}

export default userReducer
