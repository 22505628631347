import { API } from 'aws-amplify'
import { getAuthToken } from 'api/utils/getAuthToken'
import { ALERT_API_NAME, ALERT_PATH } from 'api/alerts'
import { getStationId } from 'components/StationDetect'

import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (typeIds) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const stationId = getStationId()

    const path = `${ALERT_PATH.latest}/types/check/active/${stationId}`

    try {
      const token = await getAuthToken()
      const request = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          typeIds,
        },
      }
      result.response = await API.get(ALERT_API_NAME, path, request)
    } catch (err) {
      const parsedError = parseError(err)

      reportAPIError({
        endpoint: ALERT_API_NAME + path,
        payload: { stationId },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
