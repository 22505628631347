import { put } from 'redux-saga/effects'

import api from 'api'

import {
  UPDATE_CLOSED_GROUP,
  ACTIVE_FLARES_GET_GROUPED,
  GET_CLOSED_FLARE_LOGS,
} from 'modules/flares/actions'

import { flareActions } from 'utils/getFormDialogInfo'

export default function* updateClosedGroupSaga({ payload }) {
  const { flareData, groupId, currentFlareId, flareAction } = payload

  const { response, error } = yield api.flare.updateDataGroup(
    flareData,
    groupId,
  )

  if (error) {
    console.log('updateDataGroupSaga error', error)

    yield put(UPDATE_CLOSED_GROUP.FAILED.create(error.message))
  } else {
    console.log('updateDataGroupSaga response', response)
    yield put(UPDATE_CLOSED_GROUP.SUCCESS.create(payload))

    if (flareAction === flareActions.MAKE_ACTIVE) {
      yield put(ACTIVE_FLARES_GET_GROUPED.START.create({ page: 1 }))
    } else if (currentFlareId) {
      yield put(
        GET_CLOSED_FLARE_LOGS.START.create({
          flareId: currentFlareId,
          groupId,
        }),
      )
    }
  }
}
