import React, { Component } from 'react'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import { Link } from 'react-router-dom'

import { Button, CssBaseline, Typography } from '@material-ui/core'
import { CssContainer, CssTextField, Logo } from '../components/common'

import * as styles from './styles'

class Verify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: props?.routerState?.username || '',
      code: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.resetSignUpVerifyCodeErrorMessage !==
        this.props.resetSignUpVerifyCodeErrorMessage &&
      this.props.resetSignUpVerifyCodeErrorMessage
    ) {
      this.showError(this.props.resetSignUpVerifyCodeErrorMessage)
    }
    if (
      prevProps.signUpVirifyCodeErrorMessage !==
        this.props.signUpVirifyCodeErrorMessage &&
      this.props.signUpVirifyCodeErrorMessage
    ) {
      this.showError(this.props.signUpVirifyCodeErrorMessage)
    }
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  showError = (error) => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    }
  }

  resendCode = () => {
    const { username } = this.state
    this.props.resendSignUpVerifyCode({ username })
  }

  verifySignUp = () => {
    const { username, code } = this.state
    this.props.signUpVerify({ username, code })
  }

  render() {
    const { username, code } = this.state
    return (
      <>
        <ToastContainer transition={Zoom} />
        <CssContainer component="main">
          <CssBaseline />
          <div style={styles.form}>
            <Logo />
            <Typography component="h1" variant="h5">
              Verify Account
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              noValidate
            >
              <CssTextField
                value={username}
                style={styles.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                type="text"
                onChange={(evt) => this.onChange('username', evt.target.value)}
                disabled
              />
              <CssTextField
                value={code}
                style={styles.second_input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="verification_code"
                label="Verification Code"
                name="verification_code"
                type="test"
                onChange={(evt) => this.onChange('code', evt.target.value)}
                autoFocus
              />
              <Button
                style={styles.button}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                onClick={this.verifySignUp}
              >
                Verify account
              </Button>
            </form>
            <div>
              <Typography
                style={styles.reset_code_button}
                onClick={this.resendCode}
              >
                Reset Code?
              </Typography>
            </div>
            <div style={styles.buttons_container}>
              <Link to="/login" style={styles.navigationLink}>
                <Button style={styles.secondary_button} color="primary">
                  Return to login
                </Button>
              </Link>
            </div>
          </div>
        </CssContainer>
      </>
    )
  }
}

export default Verify
