import { ProgressStage } from '../constants'

const { COMPLETE, CLOSED } = ProgressStage

export const filterByActiveStatus = (item, customStatus) => {
  const itemStatuses = [item.currentstatus || item.status].map((s) =>
    s?.toLowerCase(),
  )

  const targetStatuses = customStatus
    ? [].concat(customStatus)
    : [COMPLETE, CLOSED]

  return !targetStatuses
    .map((s) => s?.toLowerCase())
    .some((targetStatus) => itemStatuses?.includes(targetStatus))
}
