import { API } from 'aws-amplify'
import { GROUP_API_NAME } from 'api/group/index'
import { getAuthToken } from 'api/utils/getAuthToken'
import { getStationId } from 'components/StationDetect'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default ({ groupId, data }) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const stationId = getStationId()
    const path = `/groups/from/station/${stationId}/${groupId}`
    try {
      const token = await getAuthToken()
      const params = {
        headers: {
          Authorization: token,
        },
        body: data,
      }
      result.response = await API.put(GROUP_API_NAME, path, params)
    } catch (error) {
      const parsedError = parseError(error)
      reportAPIError({
        endpoint: GROUP_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }
    resolve(result)
  })
