import { call, put } from 'redux-saga/effects'
import api from 'api'

import { STATION_USER_UPDATE } from 'modules/users/actions'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'

export default function* editCurrentUserSaga({ payload: { userData } }) {
  try {
    const { response, error } = yield call(api.agents.editUser, userData)
    if (error) {
      yield put(STATION_USER_UPDATE.FAILED.create(error.message))
      yield showToast(TOAST_TYPES.ERROR, error.message)
    } else {
      yield put(STATION_USER_UPDATE.SUCCESS.create(response))
      yield showToast(TOAST_TYPES.SUCCESS, 'User has been updated successfully')
    }
  } catch (err) {
    yield put(STATION_USER_UPDATE.FAILED.create(err))
  }
}
