import { put, select } from 'redux-saga/effects'

import api from 'api'

import {
  ADD_ACTIVE_FLARE_NOTE,
  GET_ACTIVE_FLARE_LOGS,
} from 'modules/flares/actions'
import { rootUserSelector } from '../../../user/selectors'
import { formattingUrlInText } from '../../../../utils'

export default function* addActiveFlareNoteSaga(action) {
  const {
    flareId,
    message,
    groupId = null,
    isSingleGroup = false,
  } = action.payload

  const user = yield select((state) => rootUserSelector(state))

  const { response, error } = yield api.logs.createFlareLog(
    flareId,
    formattingUrlInText(message),
    user.formattedFullname,
  )

  if (error) {
    console.log('addActiveFlareNoteSaga error', error)

    yield put(ADD_ACTIVE_FLARE_NOTE.FAILED.create(error.message))
  } else {
    console.log('addActiveFlareNoteSaga response', response)
    yield put(ADD_ACTIVE_FLARE_NOTE.SUCCESS.create(flareId))
    yield put(
      GET_ACTIVE_FLARE_LOGS.START.create({ flareId, groupId, isSingleGroup }),
    )
  }
}
