import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'
import { getStationId } from '../../components/StationDetect'

export default (groupId) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const token = await getAuthToken()
    const stationId = getStationId().toLowerCase()
    const path = `${FLARE_PATH.latest}/from/station/${stationId}/grouped/${groupId}`
    try {
      const response = await API.get(FLARE_API_NAME, path, {
        headers: {
          Authorization: token,
        },
      })

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: FLARE_API_NAME + path,
        payload: {
          groupId,
        },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
