/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'

import { isAlertOptionAvailableSelector } from 'modules/geofence/selectors'
import { getAlertAvailableSelector } from 'modules/generalSettings/selectors'

import AlertListItem from '../AlertListItem'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 44,
    paddingRight: 44,
    overflowY: 'auto',
    overflow: 'hidden',
    marginTop: 44,
  },
  listWrapper: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.light}`,
    backgroundColor: '#fff',
    'box-shadow': '0px 8px 50px -35px rgb(0 0 0)',
  },
}))

function AlertsList({
  alerts,
  getActiveAlerts,
  total,
  historyMode,
  getActiveAlertsNextPage,
  isLoading,
  getClosedAlerts,
  getClosedAlertsNextPage,
}) {
  const [page, setPage] = useState(1)
  const classes = useStyles()
  const isAlertOptionAvailable = useSelector(
    (state) =>
      isAlertOptionAvailableSelector(state) && getAlertAvailableSelector(state),
  )
  useEffect(() => {
    if (isAlertOptionAvailable) {
      if (historyMode) {
        getClosedAlerts()
      } else {
        getActiveAlerts({})
      }
    }
  }, [])

  const fetchMore = () => {
    if (isAlertOptionAvailable) {
      setPage(page + 1)
      if (historyMode) {
        getClosedAlertsNextPage({ page: page + 1 })
      } else {
        getActiveAlertsNextPage({ page: page + 1 })
      }
    }
  }

  return (
    <div className={classes.listWrapper}>
      {alerts.length ? (
        <InfiniteScroll
          next={fetchMore}
          hasMore={total > alerts.length}
          dataLength={alerts.length}
          loader={<h4>Loading...</h4>}
        >
          {alerts.map((alert) => (
            <AlertListItem
              key={`alert-list-Item-${alert.id}`}
              alert={alert}
              historyMode={historyMode}
            />
          ))}
        </InfiniteScroll>
      ) : isLoading ? (
        <div>Loading...</div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography fontSize="2rem">No alerts</Typography>
        </div>
      )}
    </div>
  )
}

export default React.memo(AlertsList)
