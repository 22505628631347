// eslint-disable-next-line import/no-cycle
import { API } from 'aws-amplify'
import { STATION_API_NAME, STATION_PATH } from 'api/station'
import { reportAPIError } from '../../utils/errorReports'
import { getAuthToken } from '../utils/getAuthToken'
import { parseError } from '../../utils/parseError'

const updateStationSettings = async (stationId, stationData) => {
  const path = `${STATION_PATH.latest}/${stationId}`
  const result = {
    error: undefined,
    response: undefined,
  }
  try {
    const token = await getAuthToken()

    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        station: stationData,
      },
    }
    const response = await API.patch(STATION_API_NAME, path, request)
    result.response = response.station
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: STATION_API_NAME + path,
      payload: { stationData },
      error: parsedError,
    })

    result.error = parsedError
  }
  return result
}

export default updateStationSettings
