import React, { useEffect, useReducer, useState } from 'react'
import { withStyles } from '@material-ui/styles'
import {
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  FormControlLabel,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Container from '@material-ui/core/Container'
import { Autocomplete } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import { FlareStatusText, initialFlareFilterState } from '../../../../constants'
import {
  GET_FILTERED_FLARES,
  GET_FLARE_OPERATORS,
  GET_FLARE_TYPES,
  GET_FLARE_USERS,
} from '../../../../modules/flares/actions'
import {
  flareOperatorsSelector,
  flareTypesSelector,
  flareUsersSelector,
} from '../../../../modules/flares/selectors'
import { isLoadingSelector } from '../../../../modules/requestsStatuses/selectors'
import { CustomDateRangePicker } from './dateRangePicker'

const styles = {
  loadingContainer: {
    textAlign: 'center',
  },
  buttonsContainer: {
    padding: '8px 24px',
  },
}

const FlareFiltersContainer = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
})(Container)

const FlareFiltersItem = withStyles({
  root: {
    display: 'flex',
    gap: '35px',
    padding: '10px 0',
  },
})(Container)

const FlareFiltersFooter = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: '10px 0',
    '@media print': {
      display: 'none',
    },
  },
})(Container)

const FlareFiltersDate = withStyles({
  root: {
    padding: '10px 0',
    zIndex: 2,
  },
})(Container)

const StyledAutocomplete = withStyles({
  root: {
    width: '100%',
  },
})(Autocomplete)

const StyledButton = withStyles({
  root: {
    marginLeft: '10px',
  },
})(Button)

const StyledCircularProgress = withStyles({
  root: {
    marginLeft: '8px',
  },
  colorPrimary: {
    color: '#ffffff',
  },
})(CircularProgress)

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function reducer(state, { type, payload }) {
  switch (type) {
    case 'types':
    case 'status':
    case 'operator':
    case 'user':
    case 'date':
      return { ...state, [type]: payload }
    default:
      return initialFlareFilterState
  }
}

export const FlareFilters = ({ onApplyFilters, toogleShowDetail }) => {
  const [filterState, dispatchFilterState] = useReducer(
    reducer,
    initialFlareFilterState,
  )
  const { types, status, operator, user, date } = filterState
  const dispatch = useDispatch()
  const flareTypes = useSelector(flareTypesSelector)
  const isFilteredFlaresLoading = useSelector((state) =>
    isLoadingSelector(state, GET_FILTERED_FLARES),
  )
  const flareUsers = useSelector(flareUsersSelector)
  const flareOperators = useSelector(flareOperatorsSelector)
  const [isOperatorsDropdownOpen, setIsOperatorDropdownOpen] = useState(false)
  const [isUsersDropdownOpen, setIsUsersDropdownOpen] = useState(false)

  const initFilters = () => {
    dispatch(GET_FLARE_TYPES.START.create())
    dispatch(GET_FLARE_USERS.START.create())
    dispatch(GET_FLARE_OPERATORS.START.create())
  }

  useEffect(() => {
    initFilters()
  }, [])

  const applyFilters = () => {
    dispatch(GET_FILTERED_FLARES.START.create({ filters: filterState }))
    onApplyFilters(filterState)
  }

  const clearFilters = () => {
    dispatchFilterState(initialFlareFilterState)
    dispatch(
      GET_FILTERED_FLARES.START.create({
        page: 1,
        filters: initialFlareFilterState,
      }),
    )
    onApplyFilters(initialFlareFilterState)
  }

  const handleFlareTypesChange = (event, flareTypesValue) => {
    dispatchFilterState({ type: 'types', payload: flareTypesValue })
  }
  const handleStatusChange = (event, flareStatus) => {
    dispatchFilterState({ type: 'status', payload: flareStatus })
  }

  const handleOperatorsChange = (event, flareOperatorsValue) => {
    dispatchFilterState({ type: 'operator', payload: flareOperatorsValue })
  }

  const handleUsersChange = (event, flareUsersValue) => {
    dispatchFilterState({ type: 'user', payload: flareUsersValue })
  }

  const handleDateChange = (flareDate) => {
    dispatchFilterState({ type: 'date', payload: flareDate })
  }

  const handleOperatorsInputChange = (e, value) => {
    if (!value) return setIsOperatorDropdownOpen(false)
    setIsOperatorDropdownOpen(true)
  }

  const handleUsersInputChange = (e, value) => {
    if (!value) return setIsUsersDropdownOpen(false)
    setIsUsersDropdownOpen(true)
  }

  return (
    <FlareFiltersContainer>
      <Typography color="#383838">Report filters:</Typography>
      {flareTypes && (
        <FlareFiltersItem>
          <StyledAutocomplete
            multiple
            disableCloseOnSelect
            options={flareTypes}
            limitTags={2}
            onChange={handleFlareTypesChange}
            value={types}
            renderOption={(props, option, { selected }) => (
              <div {...props}>
                <Checkbox
                  key={`flareType-checkbox-${option.id}`}
                  icon={icon}
                  color="primary"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </div>
            )}
            getOptionLabel={(option) => option.name}
            loading={!!flareTypes.length}
            renderInput={(params) => (
              <TextField
                {...params}
                maxRows={2}
                fullWidth
                variant="standard"
                label={types.length ? 'Flare Types' : 'Flare Types: All'}
              />
            )}
          />
          <StyledAutocomplete
            multiple
            disableCloseOnSelect
            options={FlareStatusText}
            limitTags={2}
            value={status}
            onChange={handleStatusChange}
            renderOption={(props, option, { selected }) => (
              <div {...props}>
                <Checkbox
                  key={`status-checkbox-${option.id}`}
                  icon={icon}
                  color="primary"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </div>
            )}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={status.length ? 'Status' : 'Status: All'}
                variant="standard"
              />
            )}
          />
        </FlareFiltersItem>
      )}
      <FlareFiltersItem>
        {flareOperators && (
          <StyledAutocomplete
            multiple
            disableCloseOnSelect
            options={flareOperators}
            onInputChange={handleOperatorsInputChange}
            open={isOperatorsDropdownOpen}
            popupIcon={null}
            limitTags={2}
            value={operator}
            onChange={handleOperatorsChange}
            renderOption={(props, option, { selected }) => (
              <div {...props}>
                <Checkbox
                  key={`operator-checkbox-${option.id}`}
                  icon={icon}
                  color="primary"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </div>
            )}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={operator.length ? 'Operators' : 'Operators: All'}
                variant="standard"
              />
            )}
          />
        )}
        {flareUsers && (
          <StyledAutocomplete
            multiple
            disableCloseOnSelect
            options={flareUsers}
            popupIcon={null}
            onChange={handleUsersChange}
            onInputChange={handleUsersInputChange}
            open={isUsersDropdownOpen}
            limitTags={2}
            value={user}
            renderOption={(props, option, { selected }) => (
              <div {...props}>
                <Checkbox
                  key={`creator-checkbox-${option.id}`}
                  icon={icon}
                  color="primary"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </div>
            )}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={user.length ? 'Creators' : 'Creators: All'}
                variant="standard"
              />
            )}
          />
        )}
      </FlareFiltersItem>
      <FlareFiltersDate>
        <CustomDateRangePicker date={date} onDateChange={handleDateChange} />
      </FlareFiltersDate>
      <FormControlLabel
        disableTypography
        control={
          <Checkbox
            disabled={isFilteredFlaresLoading}
            onChange={() => toogleShowDetail()}
            name="showFlareDetail"
            color="primary"
          />
        }
        label="show flare details"
      />
      <FlareFiltersFooter>
        <StyledButton
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          Clear filters
        </StyledButton>
        <StyledButton
          onClick={applyFilters}
          disabled={isFilteredFlaresLoading}
          variant="contained"
          color="primary"
        >
          apply
          {isFilteredFlaresLoading && (
            <StyledCircularProgress size={15} style={styles.loading} />
          )}
        </StyledButton>
      </FlareFiltersFooter>
    </FlareFiltersContainer>
  )
}

export default FlareFilters
