import { put } from 'redux-saga/effects'

import api from 'api'

import { toast } from 'react-toastify'

import {
  GET_STATION_ALERT_TYPES,
  UPDATE_ALERT_TYPE,
} from 'modules/alerts/actions'

export default function* updateAlertTypeSaga(action) {
  const { typeId, typeName, description, mandatoryNotified } = action.payload
  const { response, error } = yield api.alerts.updateAlertType(
    typeId,
    typeName,
    description,
    mandatoryNotified,
  )

  if (error) {
    console.log('updateAlertTypeSaga error', error)

    yield put(UPDATE_ALERT_TYPE.FAILED.create(error.message))
    toast.error(error.message || 'Can`t update type', {
      position: toast.POSITION.TOP_CENTER,
    })
  } else {
    console.log('updateAlertTypeSaga response', response)

    toast.success('Type has been update successfully', {
      position: toast.POSITION.TOP_CENTER,
    })
    yield put(GET_STATION_ALERT_TYPES.START.create())
    yield put(UPDATE_ALERT_TYPE.SUCCESS.create(response))
  }
}
