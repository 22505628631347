/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { createSelector } from 'reselect'
import moment from 'moment'

import { flareWMHKeyFunc } from 'components/QueueOutput/queueItems'
import clusterMarker from 'assets/icons/clusterMarker.svg'
import flareMarker from 'assets/icons/flareMarker.svg'

import getStatusData from '../../utils/getStatusData'
import { ProgressStage, defaultType } from '../../constants'

export const rootGroupsSelector = (state) => state.flares.groups
export const rootFlaresSelector = (state) => state.flares.flares
export const getFlareTotalItemsSelector = (state) => state.flares.totalItems

export const getActiveFlares = (state) => state.flares.active.flares
export const getActiveFlare = (state, id) =>
  rootFlaresSelector(state).find((flare) => flare.id === id)
export const getClosedFlares = (state) => state.flares.closed.flares

export const totalActiveFlaresSelector = (state) =>
  state.flares.active.totalItems

export const activeFlaresPageSelector = (state) => state.flares.active.page ?? 1

export const totalClosedFlaresSelector = (state) =>
  state.flares.closed.totalItems

export const closedFlaresPageSelector = (state) => state.flares.closed.page

export const getFlareSelector = (state, id) =>
  rootFlaresSelector(state).find((flare) => flare.id === id)

export const getGroupSelector = (state, id) =>
  rootGroupsSelector(state).find((item) => String(item.group.id) === id)

export const getFlareStatusSelector = (state, id) =>
  getFlareSelector(state, id).status.toUpperCase()

export const getFlareTypeSelector = (state, id) =>
  getFlareSelector(state, id).type

export const getFlaresIdsSelector = createSelector(
  rootFlaresSelector,
  (flares) =>
    Object.keys(flares).sort(
      (a, b) =>
        moment(flares[b].createdAt).toDate().getTime() -
        moment(flares[a].createdAt).toDate().getTime(),
    ),
)

export const getFlaresFromIds = (state, ids) =>
  ids.map((id) => rootFlaresSelector(state, id))

export const getActiveFlaresIdsWithGroupedFormatSelector = (state) =>
  getActiveFlaresIdsSelector(state).map((flare) => ({ flare }))

export const getActiveFlaresIdsSelector = createSelector(
  rootFlaresSelector,
  (flares) =>
    flares.filter(
      (flare) =>
        flare.status.toUpperCase() !== ProgressStage.COMPLETED &&
        !flare.isClosed,
    ),
)

export const getClosedFlaresIdsSelector = createSelector(
  rootFlaresSelector,
  (flares) =>
    flares.filter(
      (flare) =>
        flare.status.toUpperCase() === ProgressStage.COMPLETED ||
        flare.isClosed,
    ),
)

export const getMostRecetFlareSelector = createSelector(
  [getActiveFlaresIdsSelector, getClosedFlaresIdsSelector, rootFlaresSelector],
  (activeIds, closedIds, flares) => {
    const recentActiveFlare = flares[activeIds[0]]
    const recentClosedFlare = flares[closedIds[0]]

    if (recentClosedFlare && recentActiveFlare) {
      const result = recentActiveFlare.createdAt.localeCompare(
        recentClosedFlare.createdAt,
      )
      if (result === -1) {
        return recentClosedFlare.id
      }
      return recentActiveFlare.id
    }
    if (recentClosedFlare) {
      return recentClosedFlare.id
    }
    if (recentActiveFlare) {
      return recentActiveFlare.id
    }

    return null
  },
)

export const getFlareMarkerSelector = (state, id) => {
  const flare = getFlareSelector(state, id)
  const isMultipleLocation =
    getActiveFlaresIdsSelector(state).findIndex((flareId) => {
      if (flareId !== flare.id) {
        const item = getFlareSelector(state, flareId)
        if (
          item.latitude === flare.latitude &&
          item.longitude === flare.longitude
        ) {
          return true
        }
      }
      return false
    }) > 0
  const status = getStatusData(getFlareStatusSelector(state, id))

  const image = isMultipleLocation ? clusterMarker : flareMarker

  return {
    name: 'Flare',
    url: image,
    position: {
      lat: flare.latitude,
      lng: flare.longitude,
    },
    label: {
      userRealName: flare.citizen?.name,
      alertType: flare.type,
      creationTime: flare.createdAt,
      status,
    },
    key: flareWMHKeyFunc(flare),
    isMultipleLocation,
    id: flare.id,
  }
}

export const getFilteredFlareByTypeSelector = (state, flaresIds, filterType) =>
  !filterType || filterType === 'none'
    ? flaresIds
    : flaresIds.filter(
        (flareId) => getFlareTypeSelector(state, flareId) === filterType,
      )

export const getUnreadFlaresCount = (state) => {
  const items = getActiveFlares(state)
  return items.filter((item) => {
    const status = item.flare?.status || item.group?.status || null
    if (status != null) {
      return status.toUpperCase() === ProgressStage.NEW.toUpperCase()
    }
    return false
  }).length
}

export const getLatestFlareInGroup = (flaredGroup) => {
  const { flares } = flaredGroup
  const flaresArrayForSort = [...flares]
  // create_at descending
  const sortedFlares = flaresArrayForSort.sort(
    (a, b) =>
      convertFromStringToTimestamp(a.createdAt) -
      convertFromStringToTimestamp(b.createdAt),
  )
  return sortedFlares[0]
}

const convertFromStringToTimestamp = (dateString) => {
  const datum = Date.parse(dateString)
  return datum / 1000
}

export const getFlaresIdsByLocation = (state, flareId) => {
  const targetFlare = getFlareSelector(state, flareId)
  const { latitude, longitude } = targetFlare
  return getActiveFlaresIdsSelector(state)
    .filter((flareId) => {
      const flare = getFlareSelector(state, flareId)
      return flare.latitude === latitude && flare.longitude === longitude
    })
    .map((flareId) => getFlareMarkerSelector(state, flareId))
}

export const getFlareLogsByFlareId = (state, flareId) => {
  const flare = getFlareSelector(state, flareId)
  if (flare && flare.logs) {
    const { total: foo, ...logs } = flare.logs
    return logs
  }
  return undefined
}

export const getLasterFlareLog = (logs) =>
  logs &&
  logs.length > 0 &&
  Object.values(logs)
    .filter((item) => typeof item !== 'number')
    .sort(
      (a, b) =>
        moment(a.createdAt).toDate().getTime() -
        moment(b.createdAt).toDate().getTime(),
    )[Object.values(logs).length - 2].id

export const getPrevFlareLog = (logs) =>
  logs &&
  Object.values(logs)
    .filter((item) => typeof item !== 'number')
    .sort(
      (a, b) =>
        moment(a.createdAt).toDate().getTime() -
        moment(b.createdAt).toDate().getTime(),
    )[0].id

export const isLoadMoreLogsAvaliable = (state, flareId) => {
  const flare = getFlareSelector(state, flareId)
  return (
    flare && flare.logs && Object.keys(flare.logs).length - 1 < flare.logs.total
  )
}

export const flareTypesSelector = (state) => state.flares.flareTypes
export const flareUsersSelector = (state) => state.flares.flareUsers
export const flareOperatorsSelector = (state) => state.flares.flareOperators
export const flareReportSummarySelector = (state) =>
  state.flares.flareReportSummary

export const activeFlareTypesFilterSelector = (state) =>
  state.flares.active.filters.types

export const closedFlareTypesFilterSelector = (state) =>
  state.flares.closed.filters.types

export const activeFilterSelector = (state) => state.flares.active.filters

export const closedFilterSelector = (state) => state.flares.closed.filters

export const getFlaresSelected = (state) => state.flares?.flaresSelected

export const getFlaresGroupsSelected = (state) =>
  state.flares?.flaresGroupsSelected

export const getTypesFlaresSelected = (state) => {
  const flaresType = []

  flaresType.push(defaultType)
  if (state.flares?.flaresSelected?.length > 0) {
    state.flares.flaresSelected.forEach((flare) => {
      flaresType.push(flare.type)
    })
  }

  if (state.flares?.flaresGroupsSelected?.length > 0) {
    state.flares.flaresGroupsSelected.forEach((group) => {
      group.flares.forEach((flare) => {
        flaresType.push(flare.type)
      })
    })
  }

  // Removed duplicate types
  return flaresType?.filter(
    (value, index, self) =>
      index === self.findIndex((type) => type.id === value.id),
  )
}
