/* eslint-disable no-restricted-syntax */
import { put, select } from 'redux-saga/effects'

import api from 'api'

import { RECENT_FLARES_GET } from 'modules/flares/actions'
import { getMostRecetFlareSelector } from '../selectors'

export default function* getRecentFlaresSaga() {
  const lastId = yield select((state) => getMostRecetFlareSelector(state))
  const { response, error } = yield api.flare.getRecentFlares({
    lastId,
  })
  if (error) {
    console.log('getRecentFlaresSaga error', error)

    yield put(RECENT_FLARES_GET.FAILED.create(error.message))
  } else {
    console.log('getRecentFlaresSaga response', response)

    const flaresById = {}
    const flares = response.items || response.payload
    for (const flare of flares) {
      flaresById[flare.id] = flare
    }

    yield put(RECENT_FLARES_GET.SUCCESS.create(flaresById))
  }
}
