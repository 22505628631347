import { API } from 'aws-amplify'
import { BILLING_PATH, ENDPOINT } from 'api/billings'
import { getAuthToken } from '../utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default async ({ subdomain = '' }) => {
  const path = `${BILLING_PATH.latest}/${subdomain}`
  try {
    const token = await getAuthToken()
    const request = {
      headers: {
        Authorization: token,
      },
    }
    const response = await API.get(ENDPOINT, path, request)
    return response
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: ENDPOINT + path,
      response: parsedError,
    })
    throw parsedError
  }
}
