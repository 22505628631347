import React, { useCallback } from 'react'
import FlaresList from 'components/FlareList/FlaresList'
import { useDispatch, useSelector } from 'react-redux'
import {
  closedFlaresPageSelector,
  closedFlareTypesFilterSelector,
  getClosedFlares,
  totalClosedFlaresSelector,
} from '../../../../../modules/flares/selectors'
import {
  ADD_CLOSED_FLARE_NOTE,
  CLOSED_FLARE_UPDATE,
  CLOSED_FLARES_GET,
  GET_CLOSED_FLARE_LOGS,
  UPDATE_CLOSED_FLARE_ADDITIONAL_FILTER,
  UPDATE_CLOSED_GROUP,
} from '../../../../../modules/flares/actions'
import {
  isLoadingSelector,
  isSuccessSelector,
} from '../../../../../modules/requestsStatuses/selectors'

const PastFlares = () => {
  const flares = useSelector(getClosedFlares)
  const totalFlares = useSelector(totalClosedFlaresSelector)
  const selectedFilterFlareTypes = useSelector(closedFlareTypesFilterSelector)
  const closedFlaresPage = useSelector(closedFlaresPageSelector)
  const closedFlareLoading = useSelector((state) =>
    isLoadingSelector(state, CLOSED_FLARES_GET),
  )
  const isLoadingLogs = useSelector((state) =>
    isLoadingSelector(state, GET_CLOSED_FLARE_LOGS),
  )

  const isSuccessUpdateGroup = useSelector((state) =>
    isSuccessSelector(state, UPDATE_CLOSED_GROUP),
  )

  const dispatch = useDispatch()
  const getMoreClosedFlares = useCallback(
    (payload) => {
      dispatch(CLOSED_FLARES_GET.START.create(payload))
    },
    [dispatch],
  )
  const getFlareLogs = useCallback(
    (payload) => {
      dispatch(GET_CLOSED_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )
  const getMoreFlareLogs = useCallback(
    (payload) => {
      dispatch(GET_CLOSED_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )
  const handleUpdateDataFlareClosed = useCallback(
    (payload) => {
      const { flareId } = payload
      dispatch(CLOSED_FLARE_UPDATE(flareId).START.create(payload))
    },
    [dispatch],
  )

  const handleAddNoteFlareClosed = useCallback(
    (payload) => {
      dispatch(ADD_CLOSED_FLARE_NOTE.START.create(payload))
    },
    [dispatch],
  )

  const handleFiltersUpdate = useCallback(
    (payload) => {
      dispatch(
        UPDATE_CLOSED_FLARE_ADDITIONAL_FILTER.START.create({
          flareTypes: payload,
        }),
      )
      dispatch(CLOSED_FLARES_GET.START.create({}))
    },
    [dispatch],
  )

  const handleUpdateDataGroup = useCallback((payload) => {
    dispatch(UPDATE_CLOSED_GROUP.START.create(payload))
  })

  return (
    <FlaresList
      flaresAndGroup={flares}
      historyMode
      getMoreFlares={getMoreClosedFlares}
      getFlareLogs={getFlareLogs}
      getMoreFlareLogs={getMoreFlareLogs}
      totalFlares={totalFlares}
      handleUpdateDataFlare={handleUpdateDataFlareClosed}
      handleAddNoteFlare={handleAddNoteFlareClosed}
      selectedFilterFlareTypes={selectedFilterFlareTypes}
      onFiltersUpdate={handleFiltersUpdate}
      loading={closedFlareLoading}
      flaresPage={closedFlaresPage}
      isLoadingLogs={isLoadingLogs}
      handleUpdateDataGroup={handleUpdateDataGroup}
      isSuccessUpdateGroup={isSuccessUpdateGroup}
    />
  )
}
export default PastFlares
