/*  eslint-disable import/no-cycle */
import { all, takeLatest } from 'redux-saga/effects'

import {
  USERS_DELETE,
  USERS_GET,
  USERS_CREATE,
  USERS_ACTIVATE,
  USERS_DEACTIVATE,
  RESET_PASSWORD,
  STATION_USER_UPDATE,
} from '../actions'

import getUsersSaga from './getUsersSaga'
import deleteUserSaga from './deleteUserSaga'
import createUserSaga from './createUserSaga'
import activateUserSaga from './activateUserSaga'
import deactivateUserSaga from './deactivateUserSaga'
import resetPasswordSaga from './resetPasswordSaga'
import editCurrentUserSaga from './editStationUserSaga'

function* rootUsersSaga() {
  yield all([
    takeLatest(USERS_GET.START.type, getUsersSaga),
    takeLatest(USERS_CREATE.START.type, createUserSaga),
    takeLatest(USERS_DELETE(null).START.type, deleteUserSaga),
    takeLatest(USERS_ACTIVATE.START.type, activateUserSaga),
    takeLatest(USERS_DEACTIVATE.START.type, deactivateUserSaga),
    takeLatest(RESET_PASSWORD.START.type, resetPasswordSaga),
    takeLatest(STATION_USER_UPDATE.START.type, editCurrentUserSaga),
  ])
}

export default rootUsersSaga
