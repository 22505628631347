/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import { StaticGoogleMap, Marker } from 'react-static-google-map'
import Geocode from 'react-geocode'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import { Button, Fade } from '@material-ui/core'

import MapComponent from 'components/Map'

import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'

const RecenterButton = withStyles({
  root: {
    padding: 0,
    backgroundColor: '#fff',
    height: '2.5rem',
    maxWidth: '2.5rem',
    marginTop: '.125rem',
    position: 'absolute',
    top: '4.5rem',
    right: '.6rem',
    borderRadius: '3px',
    border: 'none',
    boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.15)',
  },
})(Button)

/// ///////////////////////////////////////////

// Broadstone Coords:
// lat: 36.1496578 | lng: -86.7800074

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY)

export default class MapView extends Component {
  state = {
    center: {
      lat: 0,
      lng: 0,
    },
    address: '',
    activeSearch: false,
    dragged: false,
    zoom: this.props.reportPage
      ? this.props.station.mapZoom - 1
      : this.props.station.mapZoom,
  }

  componentDidMount() {
    this.recenter()
  }

  recenter = () => {
    this.setState({
      center: {
        lat: this.props.station.location.latitude,
        lng: this.props.station.location.longitude,
      },
      activeSearch: false,
      dragged: false,
    })
  }

  onPlaceSelected = (place) => {
    // eslint-disable-next-line no-prototype-builtins
    const isValid = !place.hasOwnProperty('name')

    if (isValid === true) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        changed: true,
        address: place.formatted_address,
      })
      const latValue = place.geometry.location.lat()
      const lngValue = place.geometry.location.lng()

      // Set these values in the state.
      this.setState({
        center: {
          lat: latValue,
          lng: lngValue,
        },
        activeSearch: true,
      })
    } else {
      this.toastId = toast.error('Invalid Address', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    }
  }

  inputAddress = (event) => {
    this.setState({ address: event })
  }

  clearAddress = () => {
    this.setState({ address: '', activeSearch: false })
  }

  updateCenter = (coord) => {
    this.setState({
      center: {
        lat: coord.lat,
        lng: coord.lng,
      },
    })
  }

  dragEvent = () => {
    this.setState({ activeSearch: true, dragged: true })
  }

  render() {
    return (
      <div>
        <ToastContainer transition={Zoom} />
        <MapComponent
          center={this.state.center}
          zoom={this.state.zoom}
          geofence={this.props.station.geofence}
          onPlaceSelected={this.onPlaceSelected}
          address={this.state.address}
          setAddressState={this.inputAddress}
          clearAddress={this.clearAddress}
          dragEvent={this.dragEvent}
          // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAcTxoDWl0SUtImYUP9eX-d8XnhRKYMTB0&libraries=places"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: this.props.height }} />}
          mapElement={<div style={{ height: '100%' }} />}
          updateCenter={this.updateCenter}
          activeSearch={this.state.activeSearch}
          reportPage={this.props.reportPage ?? false}
        />
        <Fade in={this.state.activeSearch}>
          <div>
            {this.state.dragged ? (
              <NavLink
                to="/dashboard"
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                  '&:hover': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <RecenterButton variant="contained" onClick={this.recenter}>
                  <img
                    src={`${process.env.REACT_APP_S3}/mylocation.svg`}
                    alt=""
                  />
                </RecenterButton>
              </NavLink>
            ) : (
              <RecenterButton variant="contained" onClick={this.recenter}>
                <img
                  src={`${process.env.REACT_APP_S3}/mylocation.svg`}
                  alt=""
                />
              </RecenterButton>
            )}
          </div>
        </Fade>
      </div>
    )
  }
}

export class EmbedMapView extends Component {
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.center.lat === nextProps.center.lat) {
      return false
    }
    return true
  }

  render() {
    const marketCenter = `${this.props.center.lat},${this.props.center.lng}`
    const size = `${this.props.height}x${this.props.height}`
    const style = { maxWidth: '100%' }
    const AsyncMap = () => (
      <StaticGoogleMap
        size={size}
        zoom={this.props.zoom}
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        as={(props) => <img {...props} style={{ ...style }} />}
      >
        <Marker
          location={marketCenter}
          iconURL={`${process.env.REACT_APP_S3}/blueMarker.svg`}
        />
      </StaticGoogleMap>
    )
    let map
    if (this.props.center.lat !== undefined) {
      map = (
        <div style={{ height: '100%' }}>
          <ToastContainer transition={Zoom} />
          <AsyncMap
            // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAcTxoDWl0SUtImYUP9eX-d8XnhRKYMTB0&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      )
    } else {
      map = <div style={{ height: this.props.height }} />
    }
    return map
  }
}

export class EmbedMapViewGroup extends Component {
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.center?.length === nextProps.center?.length) {
      return false
    }
    return true
  }

  render() {
    const markets = this.props.center.map(
      (point) => `${point.lat},${point.lng}`,
    )
    const size = `${this.props.height}x${this.props.height}`
    const style = { maxWidth: '100%' }
    const AsyncMap = () => (
      <StaticGoogleMap
        size={size}
        zoom={this.props.zoom}
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        as={(props) => <img {...props} style={{ ...style }} />}
      >
        {markets.map((marketCenter) => (
          <Marker
            key={`market-${marketCenter}`}
            location={marketCenter}
            iconURL={`${process.env.REACT_APP_S3}/blueMarker.svg`}
          />
        ))}
      </StaticGoogleMap>
    )
    let map
    if (this.props.center !== undefined) {
      map = (
        <div style={{ height: '100%' }}>
          <ToastContainer transition={Zoom} />
          <AsyncMap
            // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAcTxoDWl0SUtImYUP9eX-d8XnhRKYMTB0&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      )
    } else {
      map = <div style={{ height: this.props.height }} />
    }
    return map
  }
}
