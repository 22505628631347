import React from 'react'
import './Nav.css'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  isFlareReportAvailableSelector,
  isAlertOptionAvailableSelector,
  isFlareOptionAvailableSelector,
} from 'modules/geofence/selectors'

import {
  getMapAvailableSelector,
  getFlareAvailableSelector,
  getAlertAvailableSelector,
} from '../../modules/generalSettings/selectors'
import { NoTab } from '../../front/Error404'
import Map from '../pages/tabs/Map'
import History from '../pages/tabs/history/History'
import Alert from '../pages/tabs/AlertsPage'
import AgentList from '../pages/admin/AgentList'
import PrivateRoute from '../../components/PrivateRoute'
import AdminRoute from '../../components/AdminRoute'
import { Profile } from '../pages/profile/Profile'
import SingleFlare from '../pages/tabs/activeFlares/SingleFlare'
import GroupFlare from '../pages/tabs/activeFlares/GroupFlare'
import { StationSettings } from '../pages/admin/StationSettings'
import FlareReport from '../pages/tabs/report/FlareReport'
import ActiveFlares from '../pages/tabs/activeFlares/ActiveFlares'
import Invite from '../pages/admin/Invite'

// function _renderNavigationRoute ({}) {

// }

function RoutePaths() {
  const isMapAvailable = useSelector(getMapAvailableSelector)
  const isFlareAvailable = useSelector(getFlareAvailableSelector)
  const isFlareReportAvailable = useSelector(isFlareReportAvailableSelector)
  const isFlareOptionAvailable = useSelector(isFlareOptionAvailableSelector)
  const isAlertOptionAvailable = useSelector(
    (state) =>
      isAlertOptionAvailableSelector(state) && getAlertAvailableSelector(state),
  )

  return (
    <Switch>
      {isMapAvailable ? (
        <Route
          path="/dashboard"
          exact
          render={() => <Redirect to="/dashboard/map" />}
        />
      ) : (
        <Route
          path="/dashboard/map"
          exact
          render={() => <Redirect to="/dashboard" />}
        />
      )}

      {isMapAvailable ? (
        <Route path="/dashboard/map" component={Map} />
      ) : (
        <Route path="/dashboard" exact render={() => null} />
      )}

      {isFlareAvailable && isFlareOptionAvailable ? (
        <Route path="/dashboard/flares" exact component={ActiveFlares} />
      ) : (
        <Route path="/dashboard/flares" exact component={NoTab} />
      )}

      {isFlareAvailable && isFlareOptionAvailable ? (
        <Route path="/dashboard/flares/:id" component={SingleFlare} />
      ) : (
        <Route path="/dashboard/flares/:id" exact component={NoTab} />
      )}

      {isFlareAvailable && isFlareOptionAvailable ? (
        <Route path="/dashboard/group/:id" component={GroupFlare} />
      ) : (
        <Route path="/dashboard/group/:id" exact component={NoTab} />
      )}

      {isFlareReportAvailable ? (
        <Route path="/dashboard/report" component={FlareReport} />
      ) : (
        <Route
          path="/dashboard/report"
          exact
          render={() => <Redirect to="/dashboard" />}
        />
      )}

      {/* <Route path="/dashboard/watchMyHome" component={WatchMyHomePage} />} /> */}
      {/* <Route
          path="/dashboard/helplineResources"
          component={HelplineResourcesPage}
        /> */}
      {isFlareAvailable &&
      (isFlareOptionAvailable || isAlertOptionAvailable) ? (
        <Route path="/dashboard/history" component={History} />
      ) : (
        <Route path="/dashboard/history" exact component={NoTab} />
      )}
      <AdminRoute path="/dashboard/agentList" component={AgentList} />
      <AdminRoute
        path="/dashboard/station-settings"
        component={StationSettings}
      />
      <AdminRoute path="/dashboard/mobileUsers" component={Invite} />
      <Route path="/dashboard/profile" component={Profile} />

      {isAlertOptionAvailable ? (
        <Route path="/dashboard/activeAlerts" component={Alert} />
      ) : (
        <Route path="/dashboard/activeAlerts" exact component={NoTab} />
      )}
      {/* <AdminRoute
          path="/dashboard/manageResources"
          component={ManageResources}
        /> */}
      {/* Catch Unmatched Routes */}
      <PrivateRoute component={NoTab} />
    </Switch>
  )
}

export default withRouter(RoutePaths)
