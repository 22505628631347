import { all, takeLatest } from 'redux-saga/effects'

import { ADD_LICENSE, GET_GEOFENCE, GET_LICENSES } from '../actions'
import getGeofenceSaga from './getGeofenceSaga'
import getLicensesSaga from './getLicensesSaga'
import addLicenseSaga from './addLicenseSaga'

function* rootGeofenceSaga() {
  yield all([
    takeLatest(GET_GEOFENCE.START.type, getGeofenceSaga),
    takeLatest(GET_LICENSES.START.type, getLicensesSaga),
    takeLatest(ADD_LICENSE.START.type, addLicenseSaga),
  ])
}

export default rootGeofenceSaga
