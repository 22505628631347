/* eslint-disable react/no-children-prop */
import React, { Component } from 'react'
import { Progress } from 'react-sweet-progress'
import Button from '@material-ui/core/Button'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import 'react-sweet-progress/lib/style.css'
import { withStyles } from '@material-ui/styles'

import { ProgressStage } from '../constants'
import './Flare.css'

const CssButton = withStyles({
  root: {
    padding: 0,
    borderRadius: 25,
    minWidth: '10px',
    boxShadow: 'none',
    backgroundColor: '#dfe3e4',
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
  },
})(Button)

function fillPercentage(stage) {
  return stage ? (100 / 3) * (stage - 1) : 0
}

/*
 * Properties
 * ----------
 * onStageChange?: (ProgressStage) => any - called when the progress bar moves to a new stage. The return value, if any,
 *     is ignored.
 */
export default class ProgressBar extends Component {
  setStage = (newStage) => {
    this.props.onStageChange(newStage)
  }

  render() {
    return (
      <div
        style={{
          width: '90%',
          marginLeft: '1.5rem',
          position: 'relative',
        }}
      >
        <Progress
          percent={fillPercentage(this.props.currentStage)}
          theme={{
            error: {
              trailColor: 'pink',
              color: 'red',
            },
            default: {
              trailColor: '#dfe3e4',
              color: '#0080ff',
            },
            active: {
              trailColor: '#e4e3df',
              color: '#0080ff',
            },
            success: {
              trailColor: '#c4dfc4',
              color: 'green',
            },
          }}
        />

        <div id="receivedButton">
          <CssButton
            variant="contained"
            style={{
              position: 'absolute',
              bottom: '-.435rem',
              left: '-.16rem',
            }}
            // eslint-disable-next-line react/no-children-prop
            children={
              this.props.currentStage >= ProgressStage.RECEIVED ? (
                <CircleCheckedFilled style={{ color: '#27AE60' }} />
              ) : (
                <CircleUnchecked style={{ color: '#989898' }} />
              )
            }
          />
          <p
            style={{
              position: 'absolute',
              bottom: '-2.6rem',
              left: '-1.11rem',
              fontSize: '.8rem',
              color:
                this.props.currentStage === ProgressStage.RECEIVED
                  ? '#27AE60'
                  : '#646464',
            }}
            children="Received"
          />
        </div>

        <div id="reviewingButton">
          <CssButton
            onClick={() => this.setStage(ProgressStage.IN_REVIEW)}
            variant="contained"
            style={{
              position: 'absolute',
              bottom: '-.435rem',
              left: '31.9%',
            }}
            children={
              this.props.currentStage >= ProgressStage.IN_REVIEW ? (
                <CircleCheckedFilled style={{ color: '#27AE60' }} />
              ) : (
                <CircleUnchecked style={{ color: '#989898' }} />
              )
            }
          />
          <p
            style={{
              position: 'absolute',
              bottom: '-2.6rem',
              left: '30.25%',
              fontSize: '.8rem',
              color:
                this.props.currentStage === ProgressStage.IN_REVIEW
                  ? '#27AE60'
                  : '#646464',
            }}
            children="Reviewing"
          />
        </div>

        <div id="inprogressButton">
          <CssButton
            onClick={() => this.setStage(ProgressStage.IN_PROGRESS)}
            variant="contained"
            style={{
              position: 'absolute',
              bottom: '-.435rem',
              left: '65.3%',
            }}
            children={
              this.props.currentStage >= ProgressStage.IN_PROGRESS ? (
                <CircleCheckedFilled style={{ color: '#27AE60' }} />
              ) : (
                <CircleUnchecked style={{ color: '#989898' }} />
              )
            }
          />
          <p
            style={{
              position: 'absolute',
              bottom: '-2.6rem',
              left: '63.1%',
              fontSize: '.8rem',
              color:
                this.props.currentStage === ProgressStage.IN_PROGRESS
                  ? '#27AE60'
                  : '#646464',
            }}
            children="In Progress"
          />
        </div>

        <div id="completeButton">
          <CssButton
            onClick={() => this.setStage(ProgressStage.COMPLETED)}
            variant="contained"
            style={{
              position: 'absolute',
              bottom: '-.435rem',
              left: '98.1%',
            }}
            children={
              this.props.currentStage === ProgressStage.COMPLETED ? (
                <CircleCheckedFilled style={{ color: '#27AE60' }} />
              ) : (
                <CircleUnchecked style={{ color: '#989898' }} />
              )
            }
          />
          <p
            style={{
              position: 'absolute',
              bottom: '-2.6rem',
              left: '96.5%',
              fontSize: '.8rem',
              color:
                this.props.currentStage === ProgressStage.COMPLETED
                  ? '#27AE60'
                  : '#646464',
            }}
            children="Complete"
          />
        </div>
      </div>
    )
  }
}
