import { Auth } from 'aws-amplify'

import { parseError } from '../../utils/parseError'

export default ({ user, attributeName }) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }

    try {
      result.response = await Auth.verifyUserAttribute(user, attributeName)
    } catch (error) {
      result.error = parseError(error)
    }

    resolve(result)
  })
