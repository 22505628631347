import React, { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { UPDATE_ALERT } from 'modules/alerts/actions'

import {
  isSuccessSelector,
  reasonFailedSelector,
  isLoadingSelector,
} from 'modules/requestsStatuses/selectors'

import { ButtonWithLoader } from 'ui/shared/common'

import { UrgencyLevels } from '../../../constants'

import { formattingUrlInText } from '../../../utils'

const toastId = 'UPDATE_ALERT_TOAST'

export const DIALOG_TYPE = {
  MOVE_TO_CLOSE: 'MOVE_TO_CLOSE',
  CHANGE_URGENCY: 'CHANGE_URGENCY',
}

export const UpdateAlertDialog = ({
  isOpen,
  handleClose,
  alertId,
  dialogType = DIALOG_TYPE.MOVE_TO_CLOSE,
  alertUrgency,
}) => {
  const dialogTitle =
    dialogType === DIALOG_TYPE.MOVE_TO_CLOSE
      ? 'Move alert to Complete'
      : 'Change priority'
  const dialogText =
    dialogType === DIALOG_TYPE.MOVE_TO_CLOSE
      ? 'Add message to close log'
      : 'Select priority'
  const isLoading = useSelector((state) =>
    isLoadingSelector(state, UPDATE_ALERT),
  )
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [urgency, setUrgency] = useState(alertUrgency || '')
  // eslint-disable-next-line no-unused-vars
  const [urgencyError, setUrgencyError] = useState(undefined)
  const [wasUpdate, setWasUpdate] = useState(false)

  const error = useSelector((state) =>
    reasonFailedSelector(state, UPDATE_ALERT),
  )
  const success = useSelector((state) => isSuccessSelector(state, UPDATE_ALERT))

  const showMessage = useCallback(
    (reason, isError) => {
      if (isError) {
        toast.error(reason, {
          toastId,
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
      } else {
        toast.success(reason, {
          toastId,
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
        handleClose()
      }
    },
    [handleClose],
  )

  useEffect(() => {
    setUrgency(alertUrgency)
    setMessage('')
  }, [isOpen])

  useEffect(() => {
    if (error && isOpen) {
      showMessage(error, true)
    }
  }, [error, isOpen, showMessage])

  useEffect(() => {
    if (success && wasUpdate) {
      setWasUpdate(false)
      showMessage('Alert successful updated!')
    }
  }, [success, isOpen, showMessage])

  const handleOnChange = (e) => {
    const formattedMessage = formattingUrlInText(e.target.value)
    setMessage(formattedMessage)
  }

  const updateAlert = () => {
    if (alertUrgency === urgency && dialogType !== DIALOG_TYPE.MOVE_TO_CLOSE) {
      showMessage('Priority level was not updated. Select a new priority', true)
      return
    }

    const body = {
      message,
    }
    if (dialogType === DIALOG_TYPE.MOVE_TO_CLOSE) {
      body.isClosed = true
    }
    if (dialogType === DIALOG_TYPE.CHANGE_URGENCY) {
      const urgencyValue = Object.keys(UrgencyLevels).find(
        (key) => UrgencyLevels[key] === urgency,
      )
      body.urgency = urgencyValue
    }
    setWasUpdate(true)
    dispatch(UPDATE_ALERT.START.create({ alertId, body }))
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        {dialogType === DIALOG_TYPE.CHANGE_URGENCY ? (
          <>
            <InputLabel>Priority</InputLabel>
            <Select
              fullWidth
              error={urgencyError}
              value={urgency}
              onChange={(e) => {
                setUrgency(e.target.value)
              }}
              id="urgency"
              name="urgency"
            >
              {Object.values(UrgencyLevels).map((value) => (
                <MenuItem key={`urgency-${value}`} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {urgencyError && (
              <FormHelperText error>{urgencyError}</FormHelperText>
            )}
          </>
        ) : null}

        <TextField
          autoFocus
          error={error}
          fullWidth
          helperText={error ? 'Message field is required' : ''}
          id="name"
          label="Message"
          margin="dense"
          variant="standard"
          multiline
          onChange={handleOnChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading} color="primary">
          Cancel
        </Button>
        <ButtonWithLoader
          isLoading={isLoading}
          text="Submit"
          onClick={updateAlert}
        />
      </DialogActions>
    </Dialog>
  )
}
