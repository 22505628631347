export const convertDurationToTime = (duration) => {
  const days = Math.floor(duration / (3600 * 24))
  const hours = Math.floor((duration % (3600 * 24)) / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  let value = minutes ? `${minutes}m` : ''
  if (hours) {
    value = `${hours}h ${minutes}m`
  }
  if (days) {
    value = `${days}d ${hours}h ${minutes}m`
  }
  return value || ''
}
