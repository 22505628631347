import { connect } from 'react-redux'

import {
  activeAlertsSelector,
  historyAlertsSelector,
  activeAlertsTotalSelector,
  historyAlertsTotalSelector,
} from 'modules/alerts/selectors'

import { isLoadingSelector } from 'modules/requestsStatuses/selectors'

import {
  GET_ACTIVE_ALERTS,
  GET_ACTIVE_ALERTS_NEXT_PAGE,
  GET_CLOSED_ALERTS,
  GET_CLOSED_ALERTS_NEXT_PAGE,
} from 'modules/alerts/actions'

import AlertsList from './AlertsList'

const mapStateToProps = (state, props) => ({
  alerts: props.historyMode
    ? historyAlertsSelector(state)
    : activeAlertsSelector(state),
  total: props.historyMode
    ? historyAlertsTotalSelector(state)
    : activeAlertsTotalSelector(state),
  isLoading: props.historyMode
    ? isLoadingSelector(state, GET_CLOSED_ALERTS)
    : isLoadingSelector(state, GET_ACTIVE_ALERTS),
  isPaginationLoading: props.historyMode
    ? isLoadingSelector(state, GET_CLOSED_ALERTS_NEXT_PAGE)
    : isLoadingSelector(state, GET_ACTIVE_ALERTS_NEXT_PAGE),
})

const mapDispatchToProps = {
  getActiveAlerts: GET_ACTIVE_ALERTS.START.create,
  getActiveAlertsNextPage: GET_ACTIVE_ALERTS_NEXT_PAGE.START.create,
  getClosedAlerts: GET_CLOSED_ALERTS.START.create,
  getClosedAlertsNextPage: GET_CLOSED_ALERTS_NEXT_PAGE.START.create,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsList)
