/* eslint-disable no-restricted-syntax */
import { put } from 'redux-saga/effects'

import api from 'api'

import { CLOSED_FLARES_GET } from 'modules/flares/actions'
import {
  FlareStatusClosed,
  PER_PAGE,
  initialFlareFilterState,
} from '../../../../constants'

export default function* getClosedFlaresSaga({ payload }) {
  const { page, isNextPage, filters = initialFlareFilterState } = payload
  const { response, error } = yield api.flare.getFlaresGrouped({
    perPage: PER_PAGE,
    page,
    filters: {
      ...filters,
      status: FlareStatusClosed,
    },
  })

  if (error) {
    console.log('getClosedFlaresSaga error', error)

    yield put(CLOSED_FLARES_GET.FAILED.create(error.message))
  } else {
    console.log('getClosedFlaresSaga response', response)

    yield put(
      CLOSED_FLARES_GET.SUCCESS.create({
        items: response.items,
        totalItems: +response.totalItems,
        isNextPage,
        page,
      }),
    )
  }
}
