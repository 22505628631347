/* eslint-disable import/no-cycle */
import { flareWMHKeyFunc } from 'components/QueueOutput/queueItems'
import getStatusData from '../../utils/getStatusData'

export const rootAlertsSelector = (state) => state.alerts

export const activeAlertsSelector = (state) =>
  rootAlertsSelector(state).active.items

export const activeAlertsCountSelector = (state) =>
  rootAlertsSelector(state).active.items.length

export const activeAlertsTotalSelector = (state) =>
  rootAlertsSelector(state).active.total

export const historyAlertsSelector = (state) =>
  rootAlertsSelector(state).history.items

export const historyAlertsTotalSelector = (state) =>
  rootAlertsSelector(state).active.total

export const activeAlertSelector = (state, id) =>
  activeAlertsSelector(state).find((alert) => alert.id === id)

export const historyAlertSelector = (state, id) =>
  historyAlertsSelector(state).find((alert) => alert.id === id)

export const getAlertMarkerSelector = (state, id) => {
  const alert = activeAlertSelector(state, id)

  const status = getStatusData(alert.status.toUpperCase())
  const creator = alert.logs[0]?.operator?.name || ''

  return {
    name: 'Alert',
    url: `${process.env.REACT_APP_S3}/flareMarker.svg`,
    position: alert.coordinates,
    label: {
      userRealName: creator,
      alertType: alert.urgency,
      creationTime: alert.creationTime,
      status,
    },
    key: flareWMHKeyFunc(alert),
  }
}

export const alertLogs = (state, alertId) =>
  rootAlertsSelector(state).logs[alertId]?.items
    ? Object.values(rootAlertsSelector(state).logs[alertId]?.items)
    : []

export const logsHasMore = (state, alertId) =>
  rootAlertsSelector(state).logs[alertId]?.hasMore

export const getLastAlertLogId = (state, alertId) =>
  alertLogs(state, alertId)?.pop()?.id

export const alertTypesSelector = (state) => rootAlertsSelector(state).types

export const alertStationTypesSelector = (state) =>
  rootAlertsSelector(state).stationTypes

export const attachmentsAlertSelector = (state, alertId) =>
  activeAlertSelector(state, alertId)?.files ||
  historyAlertSelector(state, alertId)?.files

export const getStationAlertTypesSelector = (state) =>
  rootAlertsSelector(state).stationTypes

export const getUsedAlertTypes = (state) =>
  rootAlertsSelector(state).usedAlertTypes
