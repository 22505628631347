/* eslint-disable import/no-cycle */
import getFlares from './getFlares'
import getFlaresGrouped from './getFlaresGrouped'
import getRecentFlares from './getRecentFlares'
import updateFlare from './updateFlare'
import getFlare from './getFlare'
import getFlareTypes from './getFlareTypes'
import getFlaresOperators from './getFlaresOperators'
import getFlaresUsers from './getFlaresUsers'
import getFlaresReportSummary from './getFlaresReportSummary'
import addFlareType from './addFlareType'
import updateStationFlareTypes from './updateStationFlareTypes'
import addFlaresInGroup from './addFlaresInGroup'
import createFlareGroup from './createFlareGroup'
import updateDataGroup from './updateDataGroup'
import getFlareGroup from './getFlareGroup'
import { Version } from '../utils'

export const FLARE_API_NAME = 'FlaresCRUD'
export const FLARE_LOGS_API_NAME = 'FlareLogs'

const RESOURCE_PATH = '/flare'

export const FLARE_PATH = {
  legacy: '/flares',
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  getFlares,
  getFlaresGrouped,
  getFlaresReportSummary,
  getFlaresOperators,
  getFlaresUsers,
  getRecentFlares,
  updateFlare,
  getFlare,
  getFlareTypes,
  addFlareType,
  updateStationFlareTypes,
  addFlaresInGroup,
  createFlareGroup,
  updateDataGroup,
  getFlareGroup,
}
