/*  eslint-disable import/no-cycle */
import { all, takeLatest } from 'redux-saga/effects'

import { CITIZEN_INVITE } from '../actions'

import inviteCitizenUserSaga from './inviteCitizenUserSaga'

function* rootInviteSaga() {
  yield all([takeLatest(CITIZEN_INVITE.START.type, inviteCitizenUserSaga)])
}

export default rootInviteSaga
