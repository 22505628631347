import React, { useMemo } from 'react'
import { useCountdownTimer } from '../hooks/useCountdown'

export const VerifyContext = React.createContext()

const VerifyProvider = (props) => {
  const [isCountdownActive, countdownTime, startCountdown, stopCountdown] =
    useCountdownTimer()
  const contextValue = useMemo(
    () => ({ isCountdownActive, countdownTime, startCountdown, stopCountdown }),
    [isCountdownActive, countdownTime, startCountdown, stopCountdown],
  )

  return (
    <VerifyContext.Provider value={contextValue}>
      {props.children}
    </VerifyContext.Provider>
  )
}

export default VerifyProvider
