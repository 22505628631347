export const styles = (theme) => ({
  snackbar: {
    bottom: 'auto',
    maxWidth: '100%',
  },
  snackbar2: {
    bottom: '10%',
    maxWidth: '100%',
  },
  alert: {
    width: '100%',
  },
  paper: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  gridColumn: {
    [theme.breakpoints.down('md')]: {
      height: '330px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '450px',
    },
  },
  gridList: {
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTileSpan: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
  inputLabel: {
    zIndex: 0,
  },
})
