import { put, select } from 'redux-saga/effects'

import api from 'api'

import { ALERTS_ADD_LOG } from 'modules/alerts/actions'

import { rootUserSelector } from 'modules/user/selectors'

export default function* addAlertLog(action) {
  const user = yield select(rootUserSelector)
  const { response, error } = yield api.logs.createAlertLog({
    ...action.payload,
    operatorName: user.formattedFullname,
  })

  if (error) {
    console.log('addAlertLog error', error)

    yield put(ALERTS_ADD_LOG.FAILED.create(error.message))
  } else {
    console.log('addAlertLog response', response)

    yield put(
      ALERTS_ADD_LOG.SUCCESS.create({
        log: response.log,
        alertId: action.payload.alertId,
      }),
    )
  }
}
