import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_ACTIVE_FLARE_LOGS } from '../../actions'

export default function* getActiveFlareLogsSaga(action) {
  const {
    flareId,
    lastId = null,
    groupId = null,
    isSingleGroup = false,
  } = action.payload
  const { response, error } = yield api.logs.getLogs({
    itemId: flareId,
    lastId,
  })

  if (error) {
    console.log('getActiveFlareLogsSaga error', error)

    yield put(GET_ACTIVE_FLARE_LOGS.FAILED.create(error.message))
  } else {
    console.log('getActiveFlareLogsSaga response', response)

    yield put(
      GET_ACTIVE_FLARE_LOGS.SUCCESS.create({
        ...response,
        flareId,
        isPagination: !!lastId,
        groupId,
        isSingleGroup,
      }),
    )
  }
}
