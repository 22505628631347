import produce from 'immer'
import { WMH_GET_DATA } from './actions'
import { setWMHHelper } from './helpers'

const INITIAL_STATE = {}

function WMHReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case WMH_GET_DATA.SUCCESS.type:
        return setWMHHelper(draft, action.payload)

      default:
        return draft
    }
  })
}

export default WMHReducer
