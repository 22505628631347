import React, { useContext, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { ModalDialog } from '../Dialog'
import { VerifyContext } from '../../context/VerifyProvider'

export const VerifyEmailDialogWrapper = ({
  handleVerificationCodeSubmit,
  isVerifyEmailDialogOpen,
  toggleVerifyEmailDialog,
  requestVerificationCode,
  requestVerificationCodeLoading,
  submitVerificationLoading,
  isEmailChanged,
}) => {
  const [code, setCode] = useState('')
  const { isCountdownActive, countdownTime, startCountdown, stopCountdown } =
    useContext(VerifyContext)

  useEffect(() => {
    if (!isCountdownActive && isEmailChanged) {
      startCountdown()
    }
  }, [])

  const sendCode = () => {
    if (!isCountdownActive) {
      requestVerificationCode({ attributeName: 'email' })
      startCountdown()
    }
  }

  const handleVerificationCodeChange = (value) => {
    setCode(value)
  }

  const onSubmit = () => {
    handleVerificationCodeSubmit(code)
    stopCountdown()
  }

  return (
    <ModalDialog
      title="Verify email"
      maxWidth="sm"
      buttonText="Submit"
      isLoading={requestVerificationCodeLoading || submitVerificationLoading}
      onSubmit={onSubmit}
      isSubmitButtonDisabled={code === ''}
      open={isVerifyEmailDialogOpen}
      close={toggleVerifyEmailDialog}
    >
      <VerifyEmail
        onVerificationCodeChange={handleVerificationCodeChange}
        value={code}
        isCountdownActive={isCountdownActive}
        countdownTime={countdownTime}
        onResend={sendCode}
      />
    </ModalDialog>
  )
}

const useClasses = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    marginTop: 10,
    maxWidth: 130,
  },
})

export const VerifyEmail = ({
  value,
  onVerificationCodeChange,
  onResend,
  isCountdownActive,
  countdownTime,
}) => {
  const classes = useClasses()

  // eslint-disable-next-line no-shadow
  const handleVerificationCodeChange = (value) => {
    onVerificationCodeChange(value)
  }

  return (
    <div className={classes.container}>
      <TextField
        autoFocus
        value={value}
        variant="standard"
        margin="dense"
        id="verification_code"
        label="Verification code"
        type="text"
        fullWidth
        required
        onChange={(evt) => handleVerificationCodeChange(evt.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={isCountdownActive}
        className={classes.submitButton}
        onClick={onResend}
      >
        RESEND {isCountdownActive && countdownTime}
      </Button>
    </div>
  )
}
