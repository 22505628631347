/* eslint-disable max-classes-per-file */
import React from 'react'
import { AppBar, Toolbar, Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  bar: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: '#fff',
    color: '#525252',
    width: '97vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 0 0 0',
    borderRadius: '.25rem',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.50);',
  },
  checkbox: {
    marginRight: '.5rem',
    colorPrimary: '#767676',
  },
  dropdown: {
    width: '20rem',
    display: 'flex',
  },
  outlineInput: {
    borderRadius: 0,
    [`& fieldset`]: {
      borderRadius: 0,
      border: 'none',
      borderRight: '1px solid #c4c4c4',
    },
  },
  clearButton: {
    height: '3.5rem',
    alignItems: 'right',
    backgroundColor: '#fffafa',
    borderRadius: '0',
    color: '#525252',
    textSize: '1.3rem',
    width: '10rem',
    transitionDuration: '.2s',
    '&:hover': {
      transitionDuration: '.2s',
      backgroundColor: '#FF938B',
    },
  },
  tabs: {
    flexGrow: 1,
    marginTop: '1rem',
    border: '1px solid #c4c4c4',
    borderRadius: '.25rem',
  },
  tab: {
    height: '3.5rem',
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      opacity: '.85',
      transitionDuration: '.5s',
    },
    '&.Mui-selected': {
      color: '#000',
      backgroundColor: '#c3d9ff',
      transitionDuration: '.2s',
      zIndex: 99,
    },
    '&.Mui-selected:hover': {
      color: '#000',
      opacity: '1',
    },
  },
  indicator: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  resourceContainer: {
    marginTop: '1rem',
  },
  resourceCard: {
    width: '97%',
  },
  heading: {
    fontSize: '1rem',
  },
  secondaryHeading: {
    color: '#989898',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
  },
  helper: {
    borderLeft: '2px solid #989898',
    padding: '2rem',
  },
  link: {
    color: '#0052a5',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}

class WatchMyHomeAppBar extends React.PureComponent {
  render() {
    const { classes, value, handleChange } = this.props
    return (
      <AppBar position="static" className={classes.bar}>
        <Toolbar variant="dense" disableGutters>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.indicator }}
          >
            <Tab label="Requests" className={classes.tab} value="requests" />
            <Divider />
            <Tab label="Active" className={classes.tab} value="active" />
            <Divider />
          </Tabs>
        </Toolbar>
      </AppBar>
    )
  }
}
export const WatchMyHomeBar = withStyles(styles)(WatchMyHomeAppBar)

class AlertAppBar extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <AppBar position="static" className={classes.bar}>
        <Toolbar variant="dense" disableGutters>
          <Tabs
            value={this.props.value}
            onChange={this.props.handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.indicator }}
          >
            <Tab label="Requests" className={classes.tab} value="requests" />
            <Divider />
            <Tab label="Active" className={classes.tab} value="active" />
            <Divider />
          </Tabs>
        </Toolbar>
      </AppBar>
    )
  }
}
export const AlertBar = withStyles(styles)(AlertAppBar)

function Divider() {
  return <div style={{ backgroundColor: '#d8d8d8', width: '1px' }} />
}
