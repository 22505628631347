/* eslint-disable import/no-cycle */
import { connect } from 'react-redux'

import { ACTIVE_FLARES_GET_GROUPED } from 'modules/flares/actions'

import { isFlareOptionAvailableSelector } from 'modules/geofence/selectors'
import {
  getActiveFlares,
  getActiveFlaresIdsWithGroupedFormatSelector,
} from 'modules/flares/selectors'

import { GoogleMap } from './GoogleMap'

// import { activeAlertsIdsSelector } from '../../modules/alerts/selectors'
import { getWMHByStatusIdsSelector } from '../../modules/watchMyHome/selectors'

const mapStateToProps = (state, { reportPage }) => ({
  flareMarkers: reportPage
    ? getActiveFlaresIdsWithGroupedFormatSelector(state)
    : getActiveFlares(state),
  // alertMarkers: activeAlertsIdsSelector(state),
  wmhMarkers: getWMHByStatusIdsSelector(state),
  isFlareOptionAvailable: isFlareOptionAvailableSelector(state),
})

const mapDispatchToProps = {
  getActiveFlares: (payload) => ACTIVE_FLARES_GET_GROUPED.START.create(payload),
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMap)
