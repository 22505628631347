import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const WMH_GET_DATA = createAction('WMH_GET_DATA', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const WMH_CHANGE_STATE = createAction(
  'WMH_CHANGE_STATE',
  {
    [ACTIONS_SUBTYPES.START]: (params) => params,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
  true,
)
