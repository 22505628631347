import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'

const StyledCircularProgress = withStyles({
  root: {
    marginLeft: '8px',
  },
  colorPrimary: {
    color: '#ffffff',
  },
})(CircularProgress)

const StyledButton = withStyles({
  root: {
    marginLeft: '10px',
  },
})(Button)

export const ButtonWithLoader = ({
  isDisabled,
  isLoading,
  onClick,
  color = 'primary',
  text = '',
  type,
}) => (
  <StyledButton
    onClick={onClick}
    disabled={isLoading || isDisabled}
    variant="contained"
    color={color}
    type={type}
  >
    {text}
    {isLoading && <StyledCircularProgress size={15} />}
  </StyledButton>
)
