export const ErrorCodes = Object.freeze({
  VALIDATION: 1000,
  BATCH_VALIDATION: 1001,
  UNAUTHORIZED: 1002,
  UNDEFINED: 1003,
  NOT_FOUND: 1004,
})

export const parseError = (errorData) => {
  if (!errorData || !errorData.response) return errorData
  const { data } = errorData.response
  if (data) {
    const { code } = data
    const error = data.error || data.errors
    /* eslint-disable */
    const message =
      code === ErrorCodes.BATCH_VALIDATION
        ? parseErrors(error)
        : data.message
        ? data.message
        : error
    /* eslint-enable */
    return { code, message }
  }
}

export const parseErrorLatest = (errorData) => {
  if (!errorData || !errorData.response) return errorData
  const { data } = errorData.response
  if (data) {
    const { statusCode, message, error } = data
    return { statusCode, message, error }
  }
}

const parseErrors = (arr) =>
  arr
    .map((item) => {
      const [key] = Object.keys(item)
      const [value] = Object.values(item)
      const formattedKey = formatKey(key)
      return `${key === '_error' ? '' : formattedKey} ${value}`
    })
    .join(', ')

const formatKey = (key) => {
  const isUnderscore = key.split('_').length > 1
  if (isUnderscore) {
    return capitalize(key.split('_').join(' '))
  }
  return capitalize(camelToSnakeCase(key))
}

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`)

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1)
