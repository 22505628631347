import getGeneralSettings from './getGeneralSettings'
import getUserGeneralSettings from './getGeneralUserSettings'

export const GENERAL_SETTINGS = 'Settings'

const RESOURCE_PATH = '/app-settings'

export const SETTINGS_PATH = {
  legacy: RESOURCE_PATH,
  latest: RESOURCE_PATH,
}

export default {
  getGeneralSettings,
  getUserGeneralSettings,
}
