import last from 'lodash/last'
// eslint-disable-next-line import/no-cycle
import { ID_DIVIDER } from '../modules/utils/getId'

export function toTimeStamp(date) {
  if (!date) return new Date().getTime()
  return new Date(date).getTime()
}

export function formatDate(date) {
  const time = new Date(date)
  return `${time.getMonth() + 1}/${time.getDate()}/${time
    .getFullYear()
    .toString()
    .substr(-2)} @ ${time
    .toLocaleTimeString()
    .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`
}

export function sortByDate(a, b) {
  const aDate = toTimeStamp(Number(last(a.split(ID_DIVIDER))))
  const bDate = toTimeStamp(Number(last(b.split(ID_DIVIDER))))
  return bDate - aDate
}
