import React, { memo, useEffect, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import { DataLoadWrapper } from '../../../components/LoadingOverlay'
import { AddUser, DisplayUserList } from '../../../components/ManageUsers'
import {
  isFailedSelector,
  isLoadingSelector,
  isSuccessSelector,
  reasonFailedSelector,
} from '../../../modules/requestsStatuses/selectors'
import { getCreateUserAvailableSelector } from '../../../modules/generalSettings/selectors'

import {
  USERS_CREATE,
  USERS_DELETE,
  USERS_GET,
} from '../../../modules/users/actions'
import { ADD_LICENSE, GET_LICENSES } from '../../../modules/geofence/actions'
import {
  getActiveUsersCountSelector,
  licensesSelector,
} from '../../../modules/geofence/selectors'
import { CreateUserBeyondLicensesPrompt } from '../../../components/createUserBeyondLicensesPrompt'
import {
  getUserCountSelector,
  getUsersIdsByStationSelector,
} from '../../../modules/users/selectors'
import AddLicenseModal from '../../../components/addLicenseModal'

export const ColorButton = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    color: theme.palette.getContrastText('#eaffea'),
    backgroundColor: '#eaffea',
    '&:hover': {
      backgroundColor: '#d8ead8',
    },
  },
}))(Button)

const ManageResources = memo(
  ({
    isLoading,
    isUserCreatedSuccess,
    isUserCreatedFailed,
    isUserCreatedFailedMessage,
    isCreateUserAvailable,
    isUserDeletedSuccess,
    isUserDeletedFailed,
    isUserDeletedFailedMessage,
    licenses,
    activeUsersCount,
    loadUsers,
    loadLicenses,
    addLicense,
    isAddLicenseSuccess,
    isAddLicenseLoading,
    isAddLicenseFailed,
    isAddLicenseFailedMessage,
    users,
  }) => {
    let toastId
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isConfirmationPromptOpen, setIsConfirmationPromptOpen] =
      useState(false)
    const [isAddLicenseModelVisible, setIsAddLicenseModelVisible] =
      useState(false)

    useEffect(() => {
      loadUsers()
      loadLicenses()
    }, [])

    useEffect(() => {
      if (isUserCreatedSuccess) {
        showSuccess('User created')
        toggleDialog()
        return
      }
      if (isUserCreatedFailed) {
        showError(isUserCreatedFailedMessage)
      }
    }, [isUserCreatedSuccess, isUserCreatedFailed, isUserCreatedFailedMessage])

    useEffect(() => {
      if (isAddLicenseSuccess) {
        showSuccess('Licenses has been updated successfully')
        toggleLicenseModal()
      }
    }, [isAddLicenseSuccess, isAddLicenseFailed, isAddLicenseFailedMessage])

    useEffect(() => {
      if (isUserDeletedSuccess) {
        showSuccess('User deleted')
        return
      }
      if (isUserDeletedFailed) {
        showError(isUserDeletedFailedMessage)
      }
    }, [isUserDeletedSuccess, isUserDeletedFailed, isUserDeletedFailedMessage])

    const openAddUserDialog = () => {
      if (licenses <= activeUsersCount) {
        toggleConfirmationPrompt()
        return
      }
      toggleDialog()
    }

    const toggleDialog = () => {
      setIsDialogOpen((state) => !state)
    } // Open/close edit dialog

    const toggleConfirmationPrompt = () => {
      setIsConfirmationPromptOpen((state) => !state)
    }
    const toggleLicenseModal = () => {
      setIsAddLicenseModelVisible((state) => !state)
    }

    const closeConfirmationPrompt = (isConfirmed) => {
      if (isConfirmed) {
        setIsDialogOpen(true)
      }
      toggleConfirmationPrompt()
    }

    const showSuccess = (message) => {
      if (!toast.isActive(toastId)) {
        toastId = toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
      }
    }

    const showError = (message) => {
      if (!toast.isActive(toastId)) {
        toastId = toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
      }
    }

    const handleAddLicense = (licensesCount) => {
      addLicense({ licenses: licensesCount })
    }

    return (
      <div>
        <DataLoadWrapper
          loading={isLoading && users.length === 0}
          path="/dashboard/agentList"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 15,
                width: '97%',
                marginTop: '1rem',
                marginLeft: 20,
                marginRight: 'auto',
              }}
            >
              {isCreateUserAvailable ? (
                <>
                  <ColorButton variant="contained" onClick={openAddUserDialog}>
                    <span style={{ fontSize: 20 }}>+</span>&nbsp; Add User
                  </ColorButton>
                  <ColorButton variant="contained" onClick={toggleLicenseModal}>
                    Update License Count
                  </ColorButton>
                </>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-end',
                marginRight: 15,
                width: 375,
              }}
            >
              <Typography style={{ paddingRight: 15 }} variant="body2">
                Active licenses:
                <span style={{ fontWeight: 'bold', paddingLeft: 5 }}>
                  {licenses}
                </span>
              </Typography>
              <Typography variant="body2">
                Active users:
                <span style={{ fontWeight: 'bold', paddingLeft: 5 }}>
                  {activeUsersCount}
                </span>
              </Typography>
            </div>
          </div>
          <DisplayUserList />
        </DataLoadWrapper>
        {isCreateUserAvailable ? (
          <AddUser open={isDialogOpen} close={toggleDialog} />
        ) : null}
        <CreateUserBeyondLicensesPrompt
          open={isConfirmationPromptOpen}
          close={closeConfirmationPrompt}
        />
        {isAddLicenseModelVisible && (
          <AddLicenseModal
            isVisible={isAddLicenseModelVisible}
            isLoading={isAddLicenseLoading}
            handleCloseDialog={toggleLicenseModal}
            onSubmitModal={handleAddLicense}
          />
        )}
      </div>
    )
  },
)

function mapStateToProps(state) {
  return {
    activeUsersCount: getActiveUsersCountSelector(state),
    isLoading: isLoadingSelector(state, USERS_GET),
    isUserCreatedSuccess: isSuccessSelector(state, USERS_CREATE),
    isUserCreatedFailed: isFailedSelector(state, USERS_CREATE),
    isUserCreatedFailedMessage: reasonFailedSelector(state, USERS_CREATE),
    isAddLicenseSuccess: isSuccessSelector(state, ADD_LICENSE),
    isAddLicenseLoading: isLoadingSelector(state, ADD_LICENSE),
    isAddLicenseFailed: isFailedSelector(state, ADD_LICENSE),
    isAddLicenseFailedMessage: reasonFailedSelector(state, ADD_LICENSE),
    isCreateUserAvailable: getCreateUserAvailableSelector(state),
    isUserDeletedSuccess: isSuccessSelector(state, USERS_DELETE),
    isUserDeletedFailed: isFailedSelector(state, USERS_DELETE),
    isUserDeletedFailedMessage: reasonFailedSelector(state, USERS_DELETE),
    licenses: licensesSelector(state),
    usersCount: getUserCountSelector(state),
    users: getUsersIdsByStationSelector(state),
  }
}

const mapDispatchToProps = {
  loadUsers: USERS_GET.START.create,
  loadLicenses: GET_LICENSES.START.create,
  addLicense: ADD_LICENSE.START.create,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageResources)
