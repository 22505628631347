import React from 'react'
import { put } from 'redux-saga/effects'

import api from 'api'

import { CHECK_ALERT_TYPE_IS_ACTIVE } from 'modules/alerts/actions'
import { toast } from 'react-toastify'
import { ToastMessage } from '../../../components/AlertTypeToastMessage'

export default function* checkAlertTypeIsActiveSaga({ payload }) {
  const { response, error } = yield api.alerts.checkAlertTypeIsActive(
    payload.typeIds,
  )

  if (error) {
    console.log('checkIsAlertTypeIsActiveSaga error', error)

    yield put(CHECK_ALERT_TYPE_IS_ACTIVE.FAILED.create(error.message))
  } else {
    console.log('checkIsAlertTypeIsActiveSaga response', response)
    if (!payload.isSubmitting) {
      yield put(CHECK_ALERT_TYPE_IS_ACTIVE.SUCCESS.create(response))
    }
    if (payload.isSubmitting && response.length) {
      toast.warn(<ToastMessage data={response} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      })
    }
  }
}
