import { API } from 'aws-amplify'
import { ALERT_API_NAME, ALERT_PATH } from 'api/alerts'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (body) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const bodyCustom = body
    bodyCustom.urgency =
      bodyCustom.urgency === undefined ? null : bodyCustom.urgency
    try {
      const token = await getAuthToken()
      const path = `${ALERT_PATH.latest}`

      const requestParams = {
        body: bodyCustom,
        headers: {
          Authorization: token,
        },
      }

      result.response = await API.post(ALERT_API_NAME, path, requestParams)
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: 'AWS create alert',
        payload: JSON.stringify(bodyCustom),
        error: parsedError,
      })

      result.error = parsedError
    }
    resolve(result)
  })
