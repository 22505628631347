import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getWMHSelector } from 'modules/watchMyHome/selectors'
import WatchMyHomeHistoryListItem from './WatchMyHomeHistoryListItem'
import { flareWMHKeyFunc } from '../QueueOutput/queueItems'
import { toTitleCase } from '../../utils'
import { WMH_CHANGE_STATE } from '../../modules/watchMyHome/actions'
import { rootUserSelector } from '../../modules/user/selectors'

const mapStateToProps = (state, props) => {
  const wmhData = getWMHSelector(state, props.id)
  return {
    name: wmhData.userRealName,
    address: {
      street: wmhData.address,
      city: wmhData.city,
      state: wmhData.state,
      zip: wmhData.zip,
    },
    duration: { start: wmhData.startDate, end: wmhData.endDate },
    contact: {
      phone: wmhData.phoneNumber,
      email: 'EMAIL PLACEHOLDER',
    },
    emergencyContact: {
      name: `${toTitleCase(wmhData.ecfirstName)} ${toTitleCase(
        wmhData.eclastName,
      )}`,
      relationship: wmhData.relationship,
      phone: wmhData.ecphone,
      // placeholder for optional email field
      email: undefined,
    },
    message: wmhData.message,
    key: flareWMHKeyFunc(wmhData),
    image: wmhData.picture,
    time: new Date(wmhData.creationTime),
    Operator2: wmhData.Operator2,
    Note2: wmhData.Note2,
    Date2: new Date(wmhData.Date2),

    Operator3: wmhData.Operator3,
    Note3: wmhData.Note3,
    Date3: new Date(wmhData.Date3),

    Operator4: wmhData.Operator4,
    Note4: wmhData.Note4,
    Date4: new Date(wmhData.Date4),

    currentUser: rootUserSelector(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { id } = ownProps
  return bindActionCreators(
    {
      wmhStateChange: WMH_CHANGE_STATE(id).START.create,
    },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WatchMyHomeHistoryListItem)
