import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { AGENT_API_NAME, AGENTS_PATH } from 'api/agents'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (userData) =>
  new Promise(async (resolve) => {
    const path = AGENTS_PATH.latest
    const result = {
      error: undefined,
      response: undefined,
    }

    const token = await getAuthToken()

    const params = {
      body: {
        ...userData,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await API.post(AGENT_API_NAME, path, params)

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: AGENT_API_NAME + path,
        payload: userData,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
