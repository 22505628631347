import { call, put } from 'redux-saga/effects'
import api from 'api'

import { USER_REQUEST_VERIFICATION_CODE } from 'modules/user/actions'
import { getCurrentAuthenticatedUser } from '../../../api/utils/getAuthenticatedUser'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'

export default function* changeCurrentUserPasswordSaga({
  payload: { attributeName },
}) {
  try {
    const user = yield call(getCurrentAuthenticatedUser)

    const { response, error } = yield call(api.agents.requestVerificationCode, {
      user,
      attributeName,
    })

    if (error) {
      yield put(USER_REQUEST_VERIFICATION_CODE.FAILED.create(error.message))
      yield showToast(TOAST_TYPES.ERROR, error.message)
    } else {
      yield showToast(TOAST_TYPES.SUCCESS, 'Verification code has been send')
      yield put(USER_REQUEST_VERIFICATION_CODE.SUCCESS.create(response))
    }
  } catch (err) {
    yield put(USER_REQUEST_VERIFICATION_CODE.FAILED.create(err))
    yield showToast(TOAST_TYPES.ERROR, err.message)
  }
}
