import { put } from 'redux-saga/effects'

import api from 'api'

import {
  CLOSED_FLARE_UPDATE,
  GET_CLOSED_FLARE_LOGS,
  ACTIVE_FLARES_GET_GROUPED,
} from 'modules/flares/actions'

import { ProgressStage } from '../../../../constants'

export default function* updateClosedFlareSaga(action) {
  const { flareId, flareData } = action.payload

  const { response, error } = yield api.flare.updateFlare(flareId, flareData)

  if (error) {
    console.log('updateClosedFlareSaga error', error)

    yield put(CLOSED_FLARE_UPDATE(action.id).FAILED.create(error.message))
  } else {
    console.log('updateClosedFlareSaga response', response)
    yield put(
      CLOSED_FLARE_UPDATE(action.id).SUCCESS.create({
        updatedFlare: response,
      }),
    )
    if (
      !response.flare.isClosed ||
      response.flare.status !== ProgressStage.COMPLETED
    ) {
      yield put(ACTIVE_FLARES_GET_GROUPED.START.create({ page: 1 }))
    } else {
      yield put(GET_CLOSED_FLARE_LOGS.START.create({ flareId }))
    }
  }
}
