/*  eslint-disable import/no-cycle */
import { put } from 'redux-saga/effects'
import api from 'api'

import { toast } from 'react-toastify'
import { GROUP_BYID } from '../actions'

export default function* getGroupByIdSaga({ payload }) {
  try {
    const { response, error } = yield api.group.getGroupById(payload)

    if (error) {
      yield put(GROUP_BYID.FAILED.create(error.message))
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    } else {
      yield put(GROUP_BYID.SUCCESS.create(response))
    }
  } catch (error) {
    yield put(GROUP_BYID.FAILED.create(error.message))
  }
}
