/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'

import { isFlareOptionAvailableSelector } from 'modules/geofence/selectors'
import {
  getFlaresGroupsSelected,
  getFlaresSelected,
} from 'modules/flares/selectors'
import { FlareItem, FlareItemGroup } from '../FlareListItem'

import ListHeader from '../ListHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 44,
    paddingRight: 44,
    overflowY: 'auto',
    overflow: 'hidden',
    marginTop: 44,
  },
  listWrapper: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.light}`,
    backgroundColor: '#fff',
    'box-shadow': '0px 8px 50px -35px rgb(0 0 0)',
  },
}))

function FlaresList({
  flaresAndGroup,
  historyMode,
  getMoreFlares,
  getFlareLogs,
  getMoreFlareLogs,
  handleUpdateDataFlare,
  handleAddNoteFlare,
  reportMode,
  totalFlares,
  selectedFilterFlareTypes,
  onFiltersUpdate,
  type,
  loading,
  flaresPage,
  addFlareToSelection,
  removeFlareFromSelection,
  handleCreateGroup,
  handleUpdateFlaresInGroup,
  handleUpdateDataGroup,
  isSuccessAddFlareInGroup,
  isSuccessCreateGroup,
  isSuccessUpdateGroup,
  isLoadingLogs,
}) {
  const classes = useStyles()
  const [page, setPage] = useState(flaresPage)

  const isFlareOptionAvailable = useSelector(isFlareOptionAvailableSelector)
  const flaresGroupsSelected = useSelector(getFlaresGroupsSelected)
  const flaresSelected = useSelector(getFlaresSelected)

  const totalCurrentFlares = () => {
    let total = 0
    if (flaresAndGroup.length > 0) {
      flaresAndGroup.forEach((item) => {
        if (item.group) {
          total += item.group.flares.length
        } else {
          total += 1
        }
      })
    }
    return total
  }

  const fetchData = () => {
    if (isFlareOptionAvailable) {
      getMoreFlares({ page: page + 1, isNextPage: true })
      setPage(page + 1)
    }
  }

  useEffect(() => {
    if (!flaresAndGroup?.length) {
      getMoreFlares({ page })
    }
  }, [flaresAndGroup?.length, getMoreFlares, page])

  useEffect(() => {
    setPage(flaresPage)
  }, [flaresPage])

  useEffect(() => {
    // reset
    if (
      (isSuccessAddFlareInGroup || isSuccessCreateGroup) &&
      (flaresGroupsSelected?.length > 0 || flaresSelected?.length > 0)
    )
      // adding flares to group or create
      removeFlareFromSelection()
  }, [isSuccessAddFlareInGroup, isSuccessCreateGroup])

  return (
    <div className={classes.root}>
      <ListHeader
        getMoreFlares={getMoreFlares}
        reportMode={reportMode}
        historyMode={historyMode}
        type={type}
        selectedFilterFlareTypes={selectedFilterFlareTypes}
        onFiltersUpdate={onFiltersUpdate}
        handleCreateGroup={handleCreateGroup}
        handleUpdateFlaresInGroup={handleUpdateFlaresInGroup}
        addFlareToSelection={addFlareToSelection}
        removeFlareFromSelection={removeFlareFromSelection}
      />
      <div className={classes.listWrapper}>
        {flaresAndGroup?.length ? (
          <InfiniteScroll
            next={fetchData}
            hasMore={totalFlares > totalCurrentFlares()}
            dataLength={flaresAndGroup?.length}
            loader={<h4>Loading...</h4>}
          >
            {flaresAndGroup.map((flareOrGroup) => {
              if (flareOrGroup.group) {
                return (
                  <FlareItemGroup
                    key={`flare-item-group-${flareOrGroup.group.id}`}
                    getFlareLogs={getFlareLogs}
                    getMoreFlareLogs={getMoreFlareLogs}
                    reportMode={reportMode}
                    historyMode={historyMode}
                    flaresGroup={flareOrGroup.group}
                    handleAddNoteFlare={handleAddNoteFlare}
                    addFlareToSelection={addFlareToSelection}
                    removeFlareFromSelection={removeFlareFromSelection}
                    handleUpdateDataGroup={handleUpdateDataGroup}
                    handleUpdateFlaresInGroup={handleUpdateFlaresInGroup}
                    isSuccessAddFlareInGroup={isSuccessAddFlareInGroup}
                    isSuccessCreateGroup={isSuccessCreateGroup}
                    isSuccessUpdateGroup={isSuccessUpdateGroup}
                    isLoadingLogs={isLoadingLogs}
                  />
                )
              }
              return (
                <FlareItem
                  key={`flare-item-${flareOrGroup.flare.id}`}
                  getFlareLogs={getFlareLogs}
                  getMoreFlareLogs={getMoreFlareLogs}
                  reportMode={reportMode}
                  historyMode={historyMode}
                  flare={flareOrGroup.flare}
                  handleUpdateDataFlare={handleUpdateDataFlare}
                  handleAddNoteFlare={handleAddNoteFlare}
                  addFlareToSelection={addFlareToSelection}
                  removeFlareFromSelection={removeFlareFromSelection}
                  isLoadingLogs={isLoadingLogs}
                />
              )
            })}
          </InfiniteScroll>
        ) : loading ? (
          <div>Loading...</div>
        ) : (
          <div>No flares</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(FlaresList)
