import React, { Component } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  LinearProgress,
} from '@material-ui/core'
import { CssDialogTitle } from './common'

import { DIALOG_TYPE } from '../constants'

const DialogData = {
  [DIALOG_TYPE.FORGOT_USERNAME]: {
    title: 'Forgot Username?',
    subtitle: 'Enter your email and we will send you a username.',
    placeholder: 'Email*',
  },
  [DIALOG_TYPE.FORGOT_PASSWORD]: {
    title: 'Forgot Password?',
    subtitle: 'Enter your username and we will send you a reset code.',
    placeholder: 'Username*',
  },
}

export default class ForgotDialog extends Component {
  state = {
    value: '',
  }

  onChangeInput = ({ target }) => {
    this.setState({ value: target.value })
  }

  render() {
    const { onSendHandle, handleCloseDialog, dialogType, isLoading } =
      this.props
    const { value } = this.state
    return (
      <Dialog
        open={Boolean(dialogType)}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        {isLoading ? (
          <LinearProgress
            variant="indeterminate"
            value={isLoading}
            style={styles.progress}
          />
        ) : (
          <div style={styles.progress} />
        )}
        <CssDialogTitle id="form-dialog-title">
          {dialogType ? DialogData[dialogType].title : ''}
        </CssDialogTitle>
        <DialogContent>
          <Typography>
            {dialogType ? DialogData[dialogType].subtitle : ''}
          </Typography>
          <TextField
            disabled={isLoading}
            variant="standard"
            autoFocus
            margin="dense"
            id={dialogType ? DialogData[dialogType].placeholder : ''}
            label={dialogType ? DialogData[dialogType].placeholder : ''}
            type={dialogType ? DialogData[dialogType].placeholder : ''}
            onChange={this.onChangeInput}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={handleCloseDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => onSendHandle(value)}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {
  progress: {
    width: '100%',
    height: '4px',
  },
}
