import { put, select } from 'redux-saga/effects'

import api from 'api'

import { WMH_CHANGE_STATE } from 'modules/watchMyHome/actions'
import { getWMHSelector } from '../selectors'

export default function* sendStageUpdateSaga(action) {
  const whmData = yield select((state) => getWMHSelector(state, action.id))
  const { response, error } = yield api.wmh.wmhStateChange(
    whmData,
    action.payload,
  )

  if (error) {
    console.log('sendStageUpdateSaga error', error)

    yield put(WMH_CHANGE_STATE(action.id).FAILED.create(error.message))
  } else {
    console.log('sendStageUpdateSaga response', response)

    yield put(WMH_CHANGE_STATE(action.id).SUCCESS.create(response))
  }
}
