import Geocode from 'react-geocode'
import { reportAPIError } from '../../utils/errorReports'
import { parseError } from '../../utils/parseError'

export default (wmhData) =>
  new Promise(async (resolve) => {
    try {
      const fullAddress = [
        wmhData.address,
        wmhData.city,
        wmhData.state,
        wmhData.zip,
      ].join(', ')
      const position = await Geocode.fromAddress(fullAddress)
      resolve(position.results[0].geometry.location)
    } catch (error) {
      reportAPIError({
        endpoint: 'getWMHCoords',
        payload: {
          wmhData,
        },
        error: parseError(error),
      })
      resolve(undefined)
    }
  })
