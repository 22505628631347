import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { ADD_STATION_ALERT_TYPE } from '../../modules/alerts/actions'
import { isLoadingSelector } from '../../modules/requestsStatuses/selectors'
import styles from './styles'

class AddTypesModal extends React.Component {
  state = {
    typeName: '',
    isNameError: false,
    description: 'description',
    // isDescriptionError: false,
    mandatoryNotified: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.handleClose()
    }
  }

  setTypeName = (e) => this.setState({ typeName: e.target.value })

  handleClose = () => {
    this.clearTypeName()
    this.props.handleCloseDialog()
  }

  clearTypeName = () =>
    this.setState({
      typeName: '',
      mandatoryNotified: false,
    })

  changeMandatoryNotifiedField = () => {
    this.setState(({ mandatoryNotified }) => ({
      mandatoryNotified: !mandatoryNotified,
    }))
  }

  checkError = (e) => {
    switch (e.target.id) {
      case 'name':
        if (!e.target.value.length) {
          this.setState({ isNameError: true })
        } else {
          this.setState({ isNameError: false })
        }
        break
      // case 'description':
      //   if (!e.target.value.length) {
      //     this.setState({ isDescriptionError: true })
      //   } else {
      //     this.setState({ isDescriptionError: false })
      //   }
      //   break
      default:
        break
    }
  }

  render() {
    const { type, title, isVisible, onSubmitModal, isLoading } = this.props
    const { typeName, isNameError, description, mandatoryNotified } = this.state

    return (
      <Dialog open={isVisible} fullWidth aria-labelledby="form-dialog-title">
        {isLoading ? (
          <LinearProgress variant="indeterminate" style={styles.progress} />
        ) : (
          <div style={styles.progress} />
        )}
        <DialogTitle id="form-dialog-title" style={styles.title}>
          {title}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled={isLoading}
            autoFocus
            variant="standard"
            margin="dense"
            id="name"
            label="Name"
            type="name"
            required
            value={typeName}
            onChange={this.setTypeName}
            fullWidth
            error={isNameError}
            onBlur={this.checkError}
          />
          {/* <TextField
          disabled={isLoading}
          variant="standard"
          margin="normal"
          required
          fullWidth
          label="Description"
          type="text"
          id="description"
          error={isDescriptionError}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={checkError}
        /> */}
          {type === 'alert' ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mandatoryNotified"
                    checked={mandatoryNotified}
                    color="primary"
                    onClick={this.changeMandatoryNotifiedField}
                  />
                }
                label="Always Notify Subscribers"
              />
            </FormGroup>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={this.handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading || !typeName.length} // disabled={isLoading || !description.length || !typeName.length}
            color="primary"
            onClick={() =>
              onSubmitModal(typeName, description, mandatoryNotified)
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state, ADD_STATION_ALERT_TYPE),
})

export default connect(mapStateToProps)(AddTypesModal)
