import { ACTIONS_SUBTYPES, createAction } from './actionCreator'

export const UTILS_GET_ALERTS_WMH_FLARES_DATA = createAction(
  'UTILS_GET_ALERTS_WMH_FLARES_DATA',
  {
    [ACTIONS_SUBTYPES.START]: () => true,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const STATE_REHYDRATION = createAction('STATE_REHYDRATION', {
  [ACTIONS_SUBTYPES.DONE]: true,
})
