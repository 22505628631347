/* eslint-disable no-restricted-syntax */
import { put } from 'redux-saga/effects'

import api from 'api'

import { ACTIVE_FLARES_GET_GROUPED } from 'modules/flares/actions'
import {
  PER_PAGE,
  FlareStatusActivate,
  initialFlareFilterState,
} from '../../../../constants'

export default function* getActiveFlaresGroupedSaga({ payload }) {
  const { page, isNextPage, filters = initialFlareFilterState } = payload
  const { response, error } = yield api.flare.getFlaresGrouped({
    perPage: PER_PAGE,
    page,
    filters: {
      ...filters,
      status: FlareStatusActivate,
    },
  })

  if (error) {
    console.log('getActiveFlaresGroupedSaga error', error)

    yield put(ACTIVE_FLARES_GET_GROUPED.FAILED.create(error.message))
  } else {
    console.log('getActiveFlaresGroupedSaga response', response)

    yield put(
      ACTIVE_FLARES_GET_GROUPED.SUCCESS.create({
        items: response.items,
        totalItems: +response.totalItems,
        isNextPage,
        page,
      }),
    )
  }
}
