import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FlareGroupingFilters } from 'components/FlaresGroupActions/FlaresGroupActions'
import { FlareAdditionalFilters } from '../../FlaresAdditionalFilters/FlaresAdditionalFilters'

const useStyles = makeStyles(() => ({
  headerWrapper: {
    width: '100%',
    display: 'flex',
    'box-sizing': 'border-box',
    alignItems: 'center',
    paddingRight: 51,
    padding: '0 51px 15px 30px',
  },
  summaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#555555',
  },
  moreIcon: {
    fill: '#555555',
  },
  reportMode: {
    paddingLeft: 17,
  },
}))

const ListHeader = ({
  reportMode,
  historyMode,
  getMoreFlares,
  selectedFilterFlareTypes,
  onFiltersUpdate,
  isSingleFlare,
  isSingleGroup,
  handleCreateGroup,
  handleUpdateFlaresInGroup,
  addFlareToSelection,
  removeFlareFromSelection,
}) => {
  const classes = useStyles()
  const [isAllFlaresSelected, setIsAllFlaresSelected] = useState(false)

  const handleDeselectAllFlares = () => {
    removeFlareFromSelection()
  }

  const handleSelectAllFlares = () => {
    addFlareToSelection()
  }

  return (
    <>
      <Grid container alignItems="flex-start">
        <Grid item xs={6}>
          <FlareGroupingFilters
            handleCreateGroup={handleCreateGroup}
            handleUpdateFlaresInGroup={handleUpdateFlaresInGroup}
          />
        </Grid>
        <Grid item xs={6}>
          {!(historyMode || reportMode || isSingleFlare) && (
            <FlareAdditionalFilters
              getMoreFlares={getMoreFlares}
              selectedFlareTypes={selectedFilterFlareTypes}
              updateSelectedFlareTypes={onFiltersUpdate}
            />
          )}
        </Grid>
      </Grid>
      <div className={classes.headerWrapper}>
        <Grid container alignItems="center">
          {!(reportMode || historyMode || isSingleFlare || isSingleGroup) && (
            <Grid item xs={1}>
              <input
                type="checkbox"
                id="allFlares"
                checked={isAllFlaresSelected}
                onChange={(e) => {
                  const { checked } = e.target
                  setIsAllFlaresSelected(checked)
                  if (checked) {
                    handleSelectAllFlares()
                  } else {
                    handleDeselectAllFlares()
                  }
                }}
              />
            </Grid>
          )}
          {!reportMode && (
            <Grid item xs={2}>
              <Typography classes={{ root: classes.summaryText }}>
                Name
                {/*  <ExpandMoreIcon className={classes.moreIcon} /> */}
              </Typography>
            </Grid>
          )}

          <Grid item xs={2}>
            <Typography classes={{ root: classes.summaryText }}>
              Type
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography classes={{ root: classes.summaryText }}>
              Status
            </Typography>
          </Grid>

          <Grid
            item
            xs={reportMode ? 4 : 3}
            className={reportMode ? classes.reportMode : ''}
          >
            <Typography classes={{ root: classes.summaryText }}>
              Address
            </Typography>
          </Grid>

          <Grid
            item
            xs={reportMode ? 3 : 2}
            className={reportMode ? classes.reportMode : ''}
          >
            <Typography classes={{ root: classes.summaryText }}>
              Created
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default React.memo(ListHeader)
