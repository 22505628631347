import React, { Component } from 'reactn'
import './Nav.css'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/styles'
import UnreadBadge from '../../components/UnreadBadge'

import { getUnreadFlaresCount } from '../../modules/flares/selectors'
import { getUnreadWMHCount } from '../../modules/watchMyHome/selectors'
// import { getUnreadAlertsCount } from '../../modules/alerts/selectors'
import {
  getMapAvailableSelector,
  getFlareAvailableSelector,
  getAlertAvailableSelector,
} from '../../modules/generalSettings/selectors'
import {
  stationSelector,
  isFlareReportAvailableSelector,
  isFlareOptionAvailableSelector,
  isAlertOptionAvailableSelector,
} from '../../modules/geofence/selectors'

const tabs = [
  { key: 1, id: 'map', label: 'Map' },
  { key: 2, id: 'flares', label: 'Flares' },
  { key: 3, id: 'watchMyHome', label: 'Watch My Home' },
  { key: 4, id: 'helplineResources', label: 'Helpline Resources' },
  { key: 5, id: 'activeAlerts', label: 'Active Alerts' },
  { key: 6, id: 'history', label: 'History' },
  { key: 7, id: 'report', label: 'Report' },
]

// Material UI styles
const styles = {
  palette: {
    white: '#fff',
  },
  root: {
    flexGrow: 1,
    zIndex: 999,
    height: '4rem',
    resize: 'horizontal',
    overflow: 'hidden',
  },
  tabs: {
    overflow: 'hidden',
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    height: '3rem',
  },
  tab: {
    backgroundColor: 'rgba(234, 234, 234, .75)',
    color: '#525252',
    borderRadius: '.5rem .5rem 0 0',
    transitionDuration: '.3s',
    fontSize: '0.7rem',
    width: '9.5rem',
    textAlign: 'center',
    justifyContent: 'center',
    minWidth: '1rem',
    opacity: 1,
    '&:hover': {
      color: '#767676',
      textDecoration: 'none',
      backgroundColor: 'rgba(255, 255, 255, .85)',
      transitionDuration: '.5s',
    },
    '&.Mui-selected': {
      color: '#000',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      transitionDuration: '.2s',
      zIndex: 99,
    },
    '&.Mui-selected:hover': {
      opacity: '1',
    },
  },
  indicator: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  title: {
    textAlign: 'left',
    marginRight: '1.5rem',
  },
}
//--------------------------

class TabMenu extends Component {
  state = {
    value: this.defaultValue(),
    tabNames: [],
  }

  // Set active tab on load

  componentDidMount() {
    let match = false
    let value = ''
    // eslint-disable-next-line no-plusplus
    for (let k = 0; k < tabs.length; k++) {
      if (this.props.history.location.pathname === `/dashboard/${tabs[k].id}`) {
        match = true
        break
      }
    }

    if (match) {
      value = this.props.history.location.pathname
    } else value = false

    window.onpopstate = () => {
      this.setState({ value })
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tabs.length; i++) {
      this.state.tabNames.push(`/dashboard/${tabs[i].id}`)
    }

    this.pathCheck()
  }

  // Change active tab on path update
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.pathCheck()
    }
  }

  // Prevent tab flicker on refresh
  defaultValue() {
    let match = false
    let value = ''
    // eslint-disable-next-line no-plusplus
    for (let k = 0; k < tabs.length; k++) {
      if (this.props.history.location.pathname === `/dashboard/${tabs[k].id}`) {
        match = true
        break
      }
    }

    if (match) {
      value = this.props.history.location.pathname
    } else value = false

    return value
  }

  // Checks if path matches any of the tabs
  pathCheck() {
    let match = false
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < this.state.tabNames.length; j++) {
      if (this.props.history.location.pathname === this.state.tabNames[j]) {
        match = true
        break
      }
    }
    if (match) {
      this.setState({ value: this.props.history.location.pathname })
    } else this.setState({ value: false })
  }

  render() {
    const {
      classes,
      isMapAvailable,
      isFlareAvailable,
      isFlareReportAvailable,
      isStationFlareOptionAvailable,
      isAlertOptionAvailable,
    } = this.props

    return (
      <Tabs
        value={this.state.value}
        className={classes.tabs}
        classes={{ indicator: classes.indicator }}
        variant="scrollable"
        scrollable="true"
        textColor="inherit"
      >
        {isMapAvailable ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/map"
            label="Map"
            component={Link}
            to="/dashboard/map"
          />
        ) : null}

        {isStationFlareOptionAvailable && isFlareAvailable ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/flares"
            label={
              <UnreadBadge count={this.props.unreadFlareCount} label="Flares" />
            }
            component={Link}
            to="/dashboard/flares"
          />
        ) : null}

        {/* {this.state.options.wmh ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/watchMyHome"
            label={
              <UnreadBadge
                count={this.props.unreadWMHCount}
                label="Watch My Home"
              />
            }
            component={Link}
            to="/dashboard/watchMyHome"
          />
        ) : null} */}

        {/* {this.state.options.helpline ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/helplineResources"
            label="Helpline Resources"
            component={Link}
            to="/dashboard/helplineResources"
          />
        ) : null} */}

        {isAlertOptionAvailable ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/activeAlerts"
            label={
              <UnreadBadge count={this.props.unreadAlertCount} label="Alerts" />
            }
            component={Link}
            to="/dashboard/activeAlerts"
          />
        ) : null}

        {isFlareAvailable &&
        (isStationFlareOptionAvailable || isAlertOptionAvailable) ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/history"
            label="History"
            component={Link}
            to="/dashboard/history"
          />
        ) : null}

        {isFlareAvailable && isFlareReportAvailable ? (
          <Tab
            disableRipple
            className={classes.tab}
            value="/dashboard/report"
            label="Report"
            component={Link}
            to="/dashboard/report"
          />
        ) : null}
      </Tabs>
    )
  }
}

function mapStateToProps(state) {
  return {
    unreadFlareCount: getUnreadFlaresCount(state),
    unreadWMHCount: getUnreadWMHCount(state),
    //  unreadAlertCount: getUnreadAlertsCount(state),
    unreadAlertCount: 0,
    isMapAvailable: getMapAvailableSelector(state),
    isFlareAvailable: getFlareAvailableSelector(state),
    isStationFlareOptionAvailable: isFlareOptionAvailableSelector(state),
    isAlertOptionAvailable:
      isAlertOptionAvailableSelector(state) && getAlertAvailableSelector(state),
    station: stationSelector(state),
    isFlareReportAvailable: isFlareReportAvailableSelector(state),
  }
}

export default withRouter(withStyles(styles)(connect(mapStateToProps)(TabMenu)))
