import { AGENT_API_NAME, AGENTS_PATH } from 'api/agents'
import axios from 'axios'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default ({ email }) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const path = `${AGENTS_PATH.latest}/forgot/username/${email}`

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}${path}`,
      )
      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: AGENT_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
