import updateStationSettings from './updateStationSettings'
import getStation from './getStation'
import { Version } from '../utils'

export const STATION_API_NAME = 'Geofence'

const RESOURCE_PATH = '/stations'

export const STATION_PATH = {
  legacy: RESOURCE_PATH,
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  getStation,
  updateStationSettings,
}
