import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FLARE_GROUP } from 'modules/flares/actions'

export default function* getFlareGroupSaga(action) {
  const { response, error } = yield api.flare.getFlareGroup(action.payload)

  if (error) {
    console.log('getFlareGroupSaga error', error)

    yield put(GET_FLARE_GROUP.FAILED.create(error.message))
  } else {
    console.log('getFlareGroupSaga response', response)

    yield put(GET_FLARE_GROUP.SUCCESS.create(response.group))
  }
}
