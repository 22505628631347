import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'

import { USER_LOG_OUT } from 'modules/user/actions'

export default function* logOutSaga() {
  const data = yield Auth.currentUserInfo()

  console.log('logOutSaga response', data)

  yield put(USER_LOG_OUT.SUCCESS.create())
}
