import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'

class LoginReroute extends Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  }

  componentDidMount() {
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => console.log('user: ', user))
        .catch((err) => {
          console.log('currentAuthenticatedUser Error', err)
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false })
        })
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({
          loaded: true,
          isAuthenticated: true,
        })
      })
      .catch((err) => {
        console.log('authenticate Error', err)
        this.props.history.push('/login')
      })
  }

  render() {
    const { loaded, isAuthenticated, TermOfServices } = this.state
    if (!loaded) return null
    return isAuthenticated ? (
      <Redirect
        to={{
          pathname: !TermOfServices ? '/dashboard' : '/terms',
        }}
      />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    )
  }
}

export default withRouter(LoginReroute)
