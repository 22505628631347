/* eslint-disable camelcase */
import { Auth } from 'aws-amplify'

export const getCurrentUserInfo = async () => {
  const user = await Auth.currentUserInfo()
  // TODO: user.id from amplify Auth is not user id in RDS but a value related to the userpool
  // if (user.id) {
  //   return { Id: user.id }
  // }
  if (user.attributes?.sub) {
    return { Sub: user.attributes.sub }
  }
  if (user.username) {
    return { Username: user.username }
  }
  if (user.attributes?.email) {
    return { Email: user.attributes?.email }
  }
  if (user.attributes?.phone_number) {
    return { 'Phone number': user.attributes?.phone_number }
  }
  return { msg: 'Unable to determine user data' }
}
