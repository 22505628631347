import firebase from 'firebase'

if (process.env.NODE_ENV !== 'development') {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_STATION_ANALYTIC_API_KEY,
    authDomain: process.env.REACT_APP_STATION_ANALYTIC_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_STATION_ANALYTIC_DATABASE_URL,
    projectId: process.env.REACT_APP_STATION_ANALYTIC_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STATION_ANALYTIC_STORAGE_BUCKET,
    messagingSenderId:
      process.env.REACT_APP_STATION_ANALYTIC_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_STATION_ANALYTIC_APP_ID,
    measurementId: process.env.REACT_APP_STATION_ANALYTIC_MEASUREMENT_ID,
  }
  firebase.initializeApp(firebaseConfig)
}

export const EVENT_LOGS = Object.freeze({
  API_ERROR: 'api_error',
})

const logEvent = (eventName) => {
  if (process.env.NODE_ENV !== 'development') {
    const analytic = firebase.analytics()
    return ({ endpoint, payload, error }) => {
      const parsedPayload = {
        endpoint,
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      }
      return analytic.logEvent(eventName, parsedPayload)
    }
  }
  return () => null
}

export const reportAPIError = logEvent(EVENT_LOGS.API_ERROR)
