export default {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_dynamodb_all_tables_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_dynamodb_table_schemas: [
    {
      tableName: 'Flare',
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
  ],
  aws_user_files_s3_bucket: 'hoplon2-test',
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cloud_logic_custom: [
    {
      name: 'Settings',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
    {
      name: 'FlaresCRUD',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
    {
      name: 'UserCRUD',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
    {
      name: 'FlareLogs',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
    {
      name: 'Geofence',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_REGION,
    },
    {
      name: 'UserForgotUsername_dev',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_REGION,
    },
    {
      name: 'AlertsCRUD_dev',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_REGION,
    },
    {
      name: 'Billing_CRUD_dev',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_REGION,
    },
    {
      name: 'Invite_CRUD_dev',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
    {
      name: 'Group_CRUD_dev',
      endpoint: process.env.REACT_APP_BASE_URL,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
  ],
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  oauth: {},

  Auth: {
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
}
