import React from 'react'
import { ReactComponent as CircleUncheckedIcon } from 'assets/icons/CircleUnchecked.svg'
import { ReactComponent as CircleCheckedFilledIcon } from 'assets/icons/CircleCheckedFilled.svg'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { styled } from '@material-ui/core/styles'
import StepConnector, {
  stepConnectorClasses,
} from '@material-ui/core/StepConnector'

import getParsedStatus from '../../../utils/getParsedStatus'
import { ProgressStage } from '../../../constants'

const progressStatuses = [
  ProgressStage.RECEIVED,
  ProgressStage.IN_REVIEW,
  ProgressStage.IN_PROGRESS,
  ProgressStage.COMPLETED,
]

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    flex: 1,
  },
  labelContainer: {
    marginTop: -20,
  },
  labelIcon: { zIndex: 1 },
  disabledIcon: {
    color: '#C2C2C2',
    zIndex: 1,
    backgroundColor: theme.palette.grey.light,
    borderRadius: '50%',
    border: '1px solid rgb(194, 194, 194)',
  },
}))

const StepperConnector = styled(StepConnector)(({ theme, disabled }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: disabled
        ? theme.palette.grey.light
        : theme.palette.blue.light,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: disabled
        ? theme.palette.grey.light
        : theme.palette.blue.light,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey.light,
    borderTopWidth: 6,
    borderRadius: 4,
  },
}))

function StepIcon(props) {
  const { active, completed, disabled } = props
  const classes = useStyles()

  if (disabled) {
    const Icon = completed || active ? CircleCheckedFilled : CircleUnchecked
    return <Icon className={classes.disabledIcon} />
  }
  const Icon =
    completed || active ? CircleCheckedFilledIcon : CircleUncheckedIcon
  return <Icon className={classes.labelIcon} />
}

export default function ProgressBar({ status, disabled, onClick, className }) {
  const classes = useStyles()
  const activeStep = progressStatuses.indexOf(status)

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StepperConnector disabled={disabled} />}
      classes={{ root: classes.stepperRoot && className }}
    >
      {progressStatuses.map((label) => {
        const { type, color } = getParsedStatus(label)
        const handleClick = () => onClick(label)

        return (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <StepIcon {...props} disabled={disabled} />
              )}
              classes={{ labelContainer: classes.labelContainer }}
              onClick={!disabled && handleClick}
            >
              <Typography style={{ color }} variant="body2">
                {type}
              </Typography>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}
