import { put } from 'redux-saga/effects'

import api from 'api'

import {
  CLOSED_FLARES_GET,
  GET_ACTIVE_FLARE_LOGS,
  UPDATE_ACTIVE_GROUP,
} from 'modules/flares/actions'

import { flareActions } from 'utils/getFormDialogInfo'

export default function* updateActiveGroupSaga({ payload }) {
  const {
    flareData,
    groupId,
    currentFlareId,
    flareAction,
    isSingleGroup = false,
  } = payload

  const { response, error } = yield api.flare.updateDataGroup(
    flareData,
    groupId,
  )

  if (error) {
    console.log('updateDataGroupSaga error', error)

    yield put(UPDATE_ACTIVE_GROUP.FAILED.create(error.message))
  } else {
    console.log('updateDataGroupSaga response', response)
    yield put(UPDATE_ACTIVE_GROUP.SUCCESS.create(payload))
    if (flareAction === flareActions.MOVE_TO_HISTORY && !isSingleGroup) {
      yield put(CLOSED_FLARES_GET.START.create({ page: 1 }))
    } else if (currentFlareId) {
      yield put(
        GET_ACTIVE_FLARE_LOGS.START.create({
          flareId: currentFlareId,
          groupId,
          isSingleGroup: isSingleGroup ?? false,
        }),
      )
    }
  }
}
