import { put } from 'redux-saga/effects'

import api from 'api'
import { getStationId } from '../../../components/StationDetect'
import { GET_LICENSES } from '../actions'

export default function* getLicensesSaga() {
  try {
    const response = yield api.billings.getLicenses({
      subdomain: getStationId().toLowerCase(),
    })
    yield put(GET_LICENSES.SUCCESS.create(response.billing))
  } catch (err) {
    yield put(GET_LICENSES.FAILED.create(err))
  }
}
