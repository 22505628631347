import { put } from 'redux-saga/effects'

import api from 'api'

import {
  UPDATE_FLARES_IN_GROUP,
  UPDATE_ACTIVE_GROUP,
} from 'modules/flares/actions'
import { flareActions } from 'utils/getFormDialogInfo'

export default function* addFlaresInGroupSaga(action) {
  const { flares, groupId, isAdding, updateDataGroup } = action.payload

  const { response, error } = yield api.flare.addFlaresInGroup(
    flares.map((flare) => flare.id),
    groupId,
  )

  if (error) {
    console.log('addFlaresInGroupSaga error', error)

    yield put(UPDATE_FLARES_IN_GROUP.FAILED.create(error.message))
  } else {
    console.log('addFlaresInGroupSaga response', response)
    if (isAdding) {
      yield put(
        UPDATE_ACTIVE_GROUP.START.create({
          ...updateDataGroup,
          flareAction: flareActions.SEND_MESSAGE,
        }),
      )
    }

    yield put(
      UPDATE_FLARES_IN_GROUP.SUCCESS.create({
        ...action.payload,
        response,
      }),
    )
  }
}
