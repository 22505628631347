import React from 'react'

export const ToastMessage = ({ data }) => (
  <div>
    These alert types have active alerts:
    <ul>
      {data.map((item) => (
        <li> {item.name}</li>
      ))}
    </ul>
  </div>
)
