import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { AGENT_API_NAME, AGENTS_PATH } from 'api/agents'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default () =>
  new Promise(async (resolve) => {
    const path = `${AGENTS_PATH.latest}/tos/accepted`
    const result = {
      error: undefined,
      response: undefined,
    }
    try {
      const token = await getAuthToken()

      const response = await API.patch(AGENT_API_NAME, path, {
        headers: {
          Authorization: token,
        },
      })

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: AGENT_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
