import { put } from 'redux-saga/effects'

import api from 'api'
import { GET_GENERAL_SETTINGS } from 'modules/generalSettings/actions'

export default function* getGeneralSettingsSaga() {
  try {
    const { response, error } = yield api.generalSettings.getGeneralSettings()
    if (error) {
      yield put(GET_GENERAL_SETTINGS.FAILED.create(error))
    } else {
      yield put(GET_GENERAL_SETTINGS.SUCCESS.create(response))
    }
  } catch (err) {
    yield put(GET_GENERAL_SETTINGS.FAILED.create(err))
  }
}
