import { put, select } from 'redux-saga/effects'

import api from 'api'

import { ADD_FLARE_NOTE, GET_FLARE_LOGS } from 'modules/flares/actions'

import { rootUserSelector } from '../../user/selectors'

export default function* addFlareNoteSaga(action) {
  const { flareId, message } = action.payload

  const user = yield select((state) => rootUserSelector(state))

  const { response, error } = yield api.logs.createFlareLog(
    flareId,
    message,
    user.formattedFullname,
  )

  if (error) {
    console.log('updateFlareSaga error', error)

    yield put(ADD_FLARE_NOTE.FAILED.create(error.message))
  } else {
    console.log('updateFlareSaga response', response)
    yield put(GET_FLARE_LOGS.START.create({ flareId }))
    yield put(ADD_FLARE_NOTE.SUCCESS.create(flareId))
  }
}
