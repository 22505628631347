import getLicenses from './getLicenses'
import addLicenses from './addLicenses'
import { Version } from '../utils'

export const ENDPOINT = 'Billing_CRUD_dev'

const RESOURCE_PATH = '/billing'

export const BILLING_PATH = {
  legacy: RESOURCE_PATH,
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  getLicenses,
  addLicenses,
}
