import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { ALERTS_ADD_LOG } from 'modules/alerts/actions'

import {
  isSuccessSelector,
  reasonFailedSelector,
} from 'modules/requestsStatuses/selectors'
import { formattingUrlInText } from '../../utils'

const toastId = 'CREATE_ALERT_LOG_TOAST'

export const CreateAlertLog = ({ isOpen, handleClose, alertId }) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')

  const error = useSelector((state) =>
    reasonFailedSelector(state, ALERTS_ADD_LOG),
  )
  const success = useSelector((state) =>
    isSuccessSelector(state, ALERTS_ADD_LOG),
  )

  useEffect(() => {
    if (error && isOpen) {
      showMessage(error, true)
    }
  }, [error])

  useEffect(() => {
    if (success && isOpen) {
      showMessage('Log successful added!')
    }
  }, [success])

  const showMessage = (reason, isError) => {
    if (isError) {
      toast.error(reason, {
        toastId,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    } else {
      toast.success(reason, {
        toastId,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
      handleCloseModalAndClearField()
    }
  }

  const handleOnChange = (e) => {
    setMessage(e.target.value)
  }

  const createAlertLog = () => {
    const formattedMessage = formattingUrlInText(message)
    dispatch(
      ALERTS_ADD_LOG.START.create({ alertId, message: formattedMessage }),
    )
  }

  const handleCloseModalAndClearField = () => {
    setMessage('')
    handleClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModalAndClearField}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Log</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action will add a log to the current alert. Those subscribed to
          the current priority level and above will be notified. To continue,
          please enter a reason below:
        </DialogContentText>
        <TextField
          autoFocus
          error={error}
          fullWidth
          helperText={error ? 'Message field is required' : ''}
          id="name"
          label="Message"
          margin="dense"
          variant="standard"
          value={message}
          multiline
          onChange={handleOnChange}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModalAndClearField} color="primary">
          Cancel
        </Button>
        <Button onClick={createAlertLog} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
