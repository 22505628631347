import { put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from 'api'
import { ADD_LICENSE, GET_LICENSES } from '../actions'

export default function* addLicenseSaga({ payload }) {
  try {
    const { response, error } = yield api.billings.addLicenses(payload)
    if (error) {
      toast.error(error.message, {
        toastId: 'add_license_error',
        position: 'top-center',
      })
      yield put(ADD_LICENSE.FAILED.create(error.message))
    } else {
      yield put(ADD_LICENSE.SUCCESS.create(response))
      yield put(GET_LICENSES.START.create())
    }
  } catch (err) {
    yield put(ADD_LICENSE.FAILED.create(err))
  }
}
