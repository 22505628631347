// eslint-disable-next-line import/no-cycle
import { API } from 'aws-amplify'
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { reportAPIError } from '../../utils/errorReports'
// eslint-disable-next-line import/no-cycle
import { getAuthToken } from '../utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { getStationId } from '../../components/StationDetect'

export default async (flareTypeIds) => {
  const geofenceId = getStationId().toLowerCase()
  const path = `${FLARE_PATH.latest}/types/from/station/${geofenceId}`
  const result = {
    error: undefined,
    response: undefined,
  }
  try {
    const token = await getAuthToken()
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        ids: flareTypeIds,
      },
    }
    const response = await API.patch(FLARE_API_NAME, path, request)
    result.response = response
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: FLARE_API_NAME + path,
      payload: { flareTypeIds },
      error: parsedError,
    })

    result.error = parsedError
  }
  return result
}
