/* eslint-disable no-param-reassign */
import produce from 'immer'
import {
  ALERTS_ADD_LOG,
  GET_ACTIVE_ALERTS,
  GET_ALERT_LOGS,
  ALERTS_CREATE,
  GET_ALERT_TYPES,
  GET_ACTIVE_ALERTS_NEXT_PAGE,
  GET_STATION_ALERT_TYPES,
  GET_CLOSED_ALERTS,
  GET_CLOSED_ALERTS_NEXT_PAGE,
  UPDATE_ALERT,
  ADD_STATION_ALERT_TYPE,
  CHECK_ALERT_TYPE_IS_ACTIVE,
} from './actions'
import { UPDATE_TYPE } from './sagas/updateAlertSaga'

const INITIAL_STATE = {
  active: {
    items: [],
    total: 0,
  },
  history: {
    items: [],
    total: 0,
  },
  usedAlertTypes: null,
  logs: {},
  types: [],
  stationTypes: [],
}

function alertsReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_ACTIVE_ALERTS.SUCCESS.type:
        {
          const { totalItems, alerts } = action.payload
          draft.active.items = alerts
          draft.active.total = totalItems
        }
        break

      case GET_ACTIVE_ALERTS_NEXT_PAGE.SUCCESS.type:
        {
          const { totalItems, alerts } = action.payload
          draft.active.items = [...draft.active.items, ...alerts]
          draft.active.total = totalItems
        }
        break

      case GET_CLOSED_ALERTS.SUCCESS.type:
        {
          const { totalItems, alerts } = action.payload
          draft.history.items = alerts
          draft.history.total = totalItems
        }
        break
      case GET_CLOSED_ALERTS_NEXT_PAGE.SUCCESS.type:
        {
          const { totalItems, alerts } = action.payload
          draft.history.items = [...draft.history.items, ...alerts]
          draft.history.total = totalItems
        }
        break
      case UPDATE_ALERT.SUCCESS.type:
        {
          const { type, alert: response } = action.payload
          switch (type) {
            case UPDATE_TYPE.MOVE_TO_ACTIVE: {
              draft.history.items = draft.history.items.filter(
                (alert) => alert.id !== response.id,
              )

              draft.active.items = [response, ...draft.active.items]
              break
            }

            case UPDATE_TYPE.MOVE_TO_CLOSE: {
              draft.active.items = draft.active.items.filter(
                (alert) => alert.id !== response.id,
              )

              draft.history.items = [response, ...draft.history.items]
              break
            }

            case UPDATE_TYPE.UPDATE_ALERT: {
              if (response.isClosed) {
                draft.history.items = draft.history.items.map((alert) => {
                  if (alert.id === response.id) {
                    return response
                  }
                  return alert
                })
              } else {
                draft.active.items = draft.active.items.map((alert) => {
                  if (alert.id === response.id) {
                    return response
                  }
                  return alert
                })
              }
              break
            }

            default:
              break
          }
          console.log(action.payload)
        }
        break
      case ALERTS_CREATE.SUCCESS.type:
        draft.active.items = [action.payload.alert, ...draft.active.items]
        break

      case ALERTS_ADD_LOG.SUCCESS.type:
        {
          const { log, alertId } = action.payload
          draft.logs[alertId].items = {
            [log.id]: log,
            ...draft.logs[alertId].items,
          }
        }

        break

      case GET_ALERT_LOGS.SUCCESS.type:
        {
          const { alertId, logs, totalItems, isPagination } = action.payload
          let alertLogs = {}
          // eslint-disable-next-line no-restricted-syntax
          for (const alertLog of logs) {
            alertLogs[alertLog.id] = alertLog
          }

          if (isPagination) {
            alertLogs = { ...draft.logs[alertId].items, ...alertLogs }
          }

          draft.logs[alertId] = {
            items: alertLogs,
            hasMore: Object.keys(alertLogs).length < totalItems,
          }
        }
        break

      case GET_ALERT_TYPES.SUCCESS.type:
        draft.types = action.payload
        break

      case GET_STATION_ALERT_TYPES.SUCCESS.type:
        draft.stationTypes = action.payload.types
        break

      case ADD_STATION_ALERT_TYPE.SUCCESS.type:
        draft.stationTypes = [...draft.stationTypes, action.payload.type]
        break

      case CHECK_ALERT_TYPE_IS_ACTIVE.SUCCESS.type:
        draft.usedAlertTypes = action.payload.data
        break

      default:
        return draft
    }
  })
}

export default alertsReducer
