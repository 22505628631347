import getGroupById from './getGroupById'
import getGroups from './getGroups'
import updateGroup from './updateGroup'

export const GROUP_API_NAME = 'Group_CRUD_dev'

export default {
  getGroupById,
  getGroups,
  updateGroup,
}
