/*  eslint-disable import/no-cycle */
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { GROUPS_GET, GROUP_BYID, GROUP_UPDATE } from '../actions'

import getGroupsSaga from './getGroupsSaga'
import updateGroupSaga from './udpateGroupSaga'
import getGroupByIdSaga from './getGroupByIdSaga'

function* rootGroupSaga() {
  yield all([
    takeLatest(GROUPS_GET.START.type, getGroupsSaga),
    takeEvery(GROUP_BYID.START.type, getGroupByIdSaga),
    takeLatest(GROUP_UPDATE.START.type, updateGroupSaga),
  ])
}

export default rootGroupSaga
