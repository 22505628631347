import React from 'react'
import { capitalize } from 'lodash'
import { DialogMessages, ProgressStage } from '../constants'
import getParsedStatus from './getParsedStatus'

export const flareActions = {
  CHANGE_STATUS: 'CHANGE_STATUS',
  SEND_MESSAGE: 'SEND_MESSAGE',
  MOVE_TO_HISTORY: 'MOVE_TO_HISTORY',
  ADD_NOTE: 'ADD_NOTE',
  MAKE_ACTIVE: 'MAKE_ACTIVE',
}

const formDialogInfo = {
  CHANGE_STATUS: {
    title: '',
    text: '',
    required: false,
  },
  SEND_MESSAGE: {
    title: 'Send Message',
    text: 'You are about to add a note to this flare. The user will be notified but the state will not change.',
    required: true,
  },
  MOVE_TO_HISTORY: {
    title: 'Move to History',
    text: DialogMessages.ABORT_TO_CHANGE_STATUS_RECEIVED,
    required: true,
  },
  ADD_NOTE: {
    title: 'Add a Note',
    text: DialogMessages.ABORT_TO_CHANGE_STATUS_COMPLETE,
    required: true,
  },
}

const getStatusInfo = (action, newStatus) => {
  const { color, type } = getParsedStatus(newStatus, false)
  if (action === flareActions.MAKE_ACTIVE) {
    return {
      title: (
        <span>
          Reopen Flare with Status:{' '}
          <span style={{ color }}>{capitalize(type)}</span>
        </span>
      ),
      text: DialogMessages.REOPEN,
      required: true,
    }
  }
  switch (newStatus) {
    case ProgressStage.NEW:
    case ProgressStage.RECEIVED:
    case ProgressStage.IN_REVIEW:
    case ProgressStage.IN_PROGRESS:
      return {
        title: (
          <span>
            Changing Status to: <span style={{ color }}>{type}</span>
          </span>
        ),
        text: DialogMessages.ABORT_TO_CHANGE_STATUS_NEW,
        required: false,
      }
    case ProgressStage.COMPLETED:
      return {
        title: (
          <span>
            <span style={{ color }}>Complete </span>Flare
          </span>
        ),
        text: DialogMessages.ABORT_TO_CHANGE_STATUS_CLOSED,
        required: true,
      }
    case ProgressStage.CLOSED:
      return {
        title: (
          <span>
            <span style={{ color }}>Move to History </span>
          </span>
        ),
        text: DialogMessages.ABORT_TO_CHANGE_STATUS_CLOSED,
        required: true,
      }
    default:
      break
  }
}

const getFormDialogInfo = (action, newStatus) => {
  if (newStatus) {
    return getStatusInfo(action, newStatus)
  }
  return formDialogInfo[action]
}
export default getFormDialogInfo
