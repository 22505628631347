const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  typography: {
    fontSize: '1rem',
  },
  resourceContainer: {
    marginTop: '1rem',
  },
  resourceCard: {
    width: '97%',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.50);',
  },
  heading: {
    fontSize: '1rem',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    paddingBottom: '.5rem',
    position: 'relative',
  },
  columnTitle: {
    flexBasis: '33.3%',
  },
  alertColumnTitle: {
    flexBasis: '50%',
  },
  infoColumn: { flexBasis: '37.5%' },
  infoColumnNoImage: { flexBasis: '75%' },
  imageColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mapColumn: {
    flexBasis: '25%',
    width: '15rem',
    height: '15rem',
  },
  progressView: {
    height: '100%',
    padding: '.25rem',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  helper: {
    borderLeft: '2px solid #989898',
    padding: '2rem',
  },
  link: {
    color: '#0052a5',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  showMap: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    backgroundColor: '#eaeaea',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  media: {
    width: '100%',
    objectFit: 'contain',
    overflow: 'overflow',
    cursor: 'pointer',
  },
  // expansionCol: {
  //     height: "35vh",
  //     overflow: "hidden"
  // }
}
export default styles
