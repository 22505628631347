/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import produce from 'immer'
import lodashMerge from 'lodash/merge'
import { EnabledState } from '../../constants'

import {
  USERS_GET,
  USERS_CREATE,
  USERS_DELETE,
  USERS_DEACTIVATE,
  USERS_ACTIVATE,
  RESET_PASSWORD,
  STATION_USER_UPDATE,
} from './actions'

const INITIAL_STATE = {
  usersById: {},
  lastId: null,
  count: 0,
  hasMoreAgents: false,
}

function usersReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case USERS_GET.SUCCESS.type:
        {
          delete draft.usersById.null

          const { agents, totalItems, isPagination } = action.payload
          let usersById = {}

          for (const agent of agents) {
            const { status, ...agentWithoutStatus } = agent
            usersById[agent.id] = {
              ...agentWithoutStatus,
              isEnabled: agent.status === EnabledState.ENABLED,
            }
          }

          if (isPagination) {
            usersById = { ...draft.usersById, ...usersById }
          }

          const data = {
            usersById,
            count: totalItems,
            lastId: agents[agents.length - 1].id,
            hasMoreAgents: Object.keys(usersById).length < totalItems,
          }

          lodashMerge(draft, data)
        }
        break
      case USERS_CREATE.SUCCESS.type:
        {
          const { agent } = action.payload
          const { status, ...agentWithoutStatus } = agent
          const newAgent = {
            ...agentWithoutStatus,
            isEnabled: status === EnabledState.ENABLED,
          }
          lodashMerge(draft.usersById, { [agent.id]: newAgent })
          draft.count += 1
        }
        break
      case STATION_USER_UPDATE.SUCCESS.type:
        {
          const agent = action.payload
          const { status, ...agentWithoutStatus } = agent
          const editedAgent = {
            ...agentWithoutStatus,
            isEnabled: status === EnabledState.ENABLED,
          }
          draft.usersById[agent.id] = editedAgent
        }
        break
      case USERS_ACTIVATE.SUCCESS.type:
        {
          const userId = action.payload
          const data = draft.usersById
          data[userId].isEnabled = true
          lodashMerge(draft.usersById, data)
        }
        break
      case USERS_DEACTIVATE.SUCCESS.type:
        {
          const userId = action.payload
          const data = draft.usersById
          data[userId].isEnabled = false
          lodashMerge(draft.usersById, data)
        }
        break

      case USERS_DELETE(null).SUCCESS.type:
        draft.count -= 1
        delete draft.usersById[action.id]
        break
      case RESET_PASSWORD.SUCCESS.type:
      default:
        break
    }
  })
}

export default usersReducer
