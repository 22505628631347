import { USER_SETTINGS, GENERAL_SETTINGS } from '../../constants' // eslint-disable-line no-unused-vars

export const generalSettingsSelector = (state) => state.generalSettings.general

export const generalUserSettingSelector = (state) => state.generalSettings.user

export const getMapAvailableSelector = (state) =>
  generalUserSettingSelector(state).find(
    (item) => item.option === USER_SETTINGS.MAP,
  )?.enabled === true

export const getFlareAvailableSelector = (state) =>
  generalUserSettingSelector(state).find(
    (item) => item.option === USER_SETTINGS.FLARE,
  )?.enabled === true

export const getCreateUserAvailableSelector = (state) =>
  generalUserSettingSelector(state).find(
    (item) => item.option === USER_SETTINGS.CREATE_USER,
  )?.enabled === true

export const getAlertAvailableSelector = (state) =>
  generalUserSettingSelector(state).find(
    (item) => item.option === USER_SETTINGS.ALERT,
  )?.enabled === true
