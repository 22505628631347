import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import Button from '@material-ui/core/Button'

export const CreateUserBeyondLicensesPrompt = ({ open, close }) => (
  <Dialog open={open} maxWidth="sm">
    <DialogContent>
      You are out of active licenses. Creating a new user may increase your
      bill. Do you want to do this?
    </DialogContent>
    <DialogActions>
      <Button onClick={() => close(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={() => close(true)} color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)
