import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Container,
  Button,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { CssTextField } from '../StyledComponents'

const cssStyles = {
  dialogContainer: {
    marginTop: '250px',
    marginBottom: '80px',
    paddingTop: 0,
    minWidth: '600px',
  },
  container: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'white',
    padding: '24px 72px 10px 0',
    zIndex: 10,
    marginRight: '48px',
    width: '100%',
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    marginLeft: '12px',
  },
  input: {
    marginLeft: '11px',
    paddingRight: '12px',
  },
  buttons: {
    position: 'absolute',
    bottom: 0,
    padding: '16px 48px 16px 0',
    width: '100%',
    height: '80px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    width: '140px',
  },
}

const InviteDialog = ({ isOpen, isLoading, handleClose, handleConfirm }) => {
  const handleCloseInvite = () => {
    formik.resetForm()
    handleClose()
  }

  const handleConfirmInvite = () => {
    const formatPhoneNumber = '+'.concat(formik.values.phoneNumber)
    handleConfirm(formik.values.email, formatPhoneNumber)
    formik.resetForm()
  }

  const initialValues = {
    email: '',
    phoneNumber: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .required('Required'),
    phoneNumber: Yup.string()
      .min(11, 'to short')
      .max(12, 'to long')
      .required('Required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: () => {},
  })

  return (
    <Dialog open={isOpen} maxWidth="sm">
      <DialogContent style={cssStyles.dialogContainer} id="dialog-container">
        <Container style={cssStyles.container}>
          <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
            Invite user
          </DialogTitle>

          <CssTextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            value={formik.values.email}
            label="Email"
            name="email"
            type="text"
            onChange={formik.handleChange('email')}
            error={!!formik.touched.email && !!formik.errors.email}
            onBlur={(e) => {
              formik.handleBlur(e)
            }}
          />

          <PhoneInput
            disabled={isLoading}
            buttonStyle={{ color: 'grey' }}
            style={{ marginTop: 20 }}
            defaultErrorMessage="Phone input"
            country="us"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange('phoneNumber')}
            isValid={!formik.errors.phoneNumber}
            onBlur={(e) => {
              formik.handleBlur(e)
            }}
          />

          <DialogActions style={{ marginTop: 20 }}>
            <Button
              disabled={isLoading}
              onClick={handleCloseInvite}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={
                !!formik.errors.email ||
                !!formik.errors.phoneNumber ||
                !formik.values.email ||
                !formik.values.phoneNumber
              }
              onClick={handleConfirmInvite}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default InviteDialog
