/* eslint-disable max-classes-per-file */
import React from 'react'
import { NavLink } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/styles'

const CssContainer = styled(Container)({
  flexDirection: 'column',
  alignItems: 'center',
})

export default class PageNotFound extends React.PureComponent {
  render() {
    return (
      <div style={{ height: '100vh', flex: 1 }}>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '45%',
            transform: 'translate(-50%, -50%)',
          }}
          className="NotFound"
        >
          <NotFound /> {/* 404 message */}
          <NavLink to="/login" style={{ textDecoration: 'none' }}>
            <Button color="primary" variant="contained">
              Take me back!
            </Button>
          </NavLink>
        </div>
      </div>
    )
  }
}

export class NoTab extends React.PureComponent {
  render() {
    return (
      <div style={{ height: '100vh', flex: 1 }}>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '45%',
            transform: 'translate(-50%, -50%)',
          }}
          className="NotFound"
        >
          <NotFound /> {/* 404 message */}
          <p style={{ margin: -20 }}>
            Please use the tabs or sidebar for navigation
          </p>
        </div>
      </div>
    )
  }
}

class NotFound extends React.PureComponent {
  render() {
    return (
      <CssContainer>
        <h1 style={{ fontSize: '6rem' }}>404</h1>
        <h3>Page Not Found</h3>
        <p>We think you might be lost</p>
      </CssContainer>
    )
  }
}
