import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const USERS_GET = createAction('USERS_GET', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (usersById) => usersById,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const USERS_CREATE = createAction('USER_CREATE', {
  [ACTIONS_SUBTYPES.START]: (userData) => userData,
  [ACTIONS_SUBTYPES.SUCCESS]: (userData) => userData,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const USERS_EDIT = createAction('USER_EDIT', {
  [ACTIONS_SUBTYPES.START]: (userData) => userData,
  [ACTIONS_SUBTYPES.SUCCESS]: (userData) => userData,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const USERS_DELETE = createAction(
  'USERS_DELETE',
  {
    [ACTIONS_SUBTYPES.START]: () => true,
    [ACTIONS_SUBTYPES.SUCCESS]: () => true,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
  true,
)

export const USERS_ACTIVATE = createAction('USERS_ACTIVATE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const USERS_DEACTIVATE = createAction('USERS_DEACTIVATE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const RESET_PASSWORD = createAction('RESET_PASSWORD', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const STATION_USER_UPDATE = createAction('STATION_USER_UPDATE', {
  [ACTIONS_SUBTYPES.START]: (response) => response,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})
