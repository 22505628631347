/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-cycle
import React, { useState, useEffect, useCallback, memo } from 'react'
import { useSelector, connect } from 'react-redux'

import { Button, Container } from '@material-ui/core'
import { CITIZEN_INVITE } from '../../../modules/invite/actions'
import {
  GROUPS_GET,
  GROUP_BYID,
  GROUP_UPDATE,
} from '../../../modules/group/actions'

import { getGroups } from '../../../modules/group/selectors'

import InviteDialog from '../../../components/InviteDialog'
import UsersDialog from '../../../components/UsersDialog'

import {
  isLoadingSelector,
  isSuccessSelector,
} from '../../../modules/requestsStatuses/selectors'

import Group from '../../../components/Group'
import { PaginationContainer } from '../../../components/PaginationContainer'

const ManageInvitedCitizens = memo((props) => {
  const itemsPerPage = 25
  const [page, setPage] = useState(1)
  const [groupsTotal, setGroupsTotal] = useState(1)
  const groups = useSelector(getGroups)

  const [selectedGroupsLocal, setSelectedGroupsLocal] = useState([])

  const [editGroup, setEditGroup] = useState({
    isEdit: false,
    groupId: '',
    users: [],
    name: '',
  })
  const [inviteUser, setInviteUser] = useState({
    isOpen: false,
    groupId: '',
    isLoading: false,
  })

  const getGroupsRequest = useCallback(async (newPage) => {
    props.getGroups(newPage)
  }, [])

  const getGroupByIdRequest = async (groupId) => {
    props.getGroupById(groupId)
  }

  useEffect(() => {
    getGroupsRequest(1)
  }, [])

  useEffect(() => {
    groups.forEach((group) => {
      if (group.id === editGroup.groupId) {
        setEditGroup({
          ...editGroup,
          users: group.citizens,
        })
      }
    })
    setGroupsTotal(groups.length)
  }, [groups])

  useEffect(() => {
    if (props.isCitizensInviteSuccess) {
      handleCloseInviteDialog(true)
    }
  }, [props.isCitizensInviteSuccess])

  useEffect(() => {
    if (props.isUpdateGroupSuccess) {
      handleCloseUsersDialog(true)
    }
  }, [props.isUpdateGroupSuccess])

  const handleCloseUsersDialog = (isRefresh) => {
    setEditGroup({
      isEdit: false,
      groupId: '',
      users: [],
      name: '',
    })
    if (isRefresh) {
      setPage(1)
      getGroupsRequest(1)
    }
  }

  const handleCloseInviteDialog = (isRefresh) => {
    setInviteUser({
      isOpen: false,
      groupId: '',
      isLoading: false,
    })
    if (isRefresh) {
      setPage(1)
      getGroupsRequest(1)
    }
  }

  const handleConfirmUpdateGroup = async (groupId, data) => {
    props.updateGroup({ groupId, data })
  }

  const handleEditGroup = (id, name, users) => {
    setEditGroup({
      isEdit: true,
      groupId: id,
      users,
      name,
    })
  }

  const onChangeSelectedGroupsLocal = (group) => {
    if (
      selectedGroupsLocal.find((selectedGroup) => selectedGroup.id === group.id)
    ) {
      setSelectedGroupsLocal(
        selectedGroupsLocal.filter(
          (selectedGroup) => selectedGroup.id !== group.id,
        ),
      )
    } else {
      setSelectedGroupsLocal([...selectedGroupsLocal, group])
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    getGroupsRequest(newPage)
  }

  const handleConfirmInviteDialog = (email, phoneNumber) => {
    props.inviteCitizen({
      email,
      phone: phoneNumber,
      groupId: inviteUser.groupId,
    })
    setInviteUser({
      ...inviteUser,
      isLoading: true,
    })
  }

  return (
    <Container maxWidth="lg">
      <Container
        maxWidth="lg"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '1rem 0',
        }}
      >
        <Button onClick={() => getGroupsRequest(1)}>Refresh</Button>
      </Container>

      {props.isGetGroupsLoading ? (
        <h4>Loading...</h4>
      ) : groups.length ? (
        groups.map((group) => (
          <Group
            key={`group-${group.id}`}
            item={group}
            handleEditGroup={handleEditGroup}
            selectedGroups={selectedGroupsLocal}
            setSelectedGroups={onChangeSelectedGroupsLocal}
            getGroupByIdRequest={getGroupByIdRequest}
            isLoading={props.isGetGroupByIdLoading}
          />
        ))
      ) : (
        <div
          style={{
            textAlign: 'center',
            margin: '48px 0',
          }}
        >
          <h4 style={{ color: '#989898' }}>No Groups</h4>
        </div>
      )}
      {!!groups.length && (
        <PaginationContainer
          page={page}
          itemsPerPage={itemsPerPage}
          total={groupsTotal}
          onChangePage={handleChangePage}
          color="primary"
        />
      )}
      {editGroup.isEdit && (
        <UsersDialog
          editGroup={editGroup}
          setInviteUser={setInviteUser}
          users={editGroup.users}
          isOpen={editGroup.isEdit}
          handleClose={handleCloseUsersDialog}
          getGroupByIdRequest={getGroupByIdRequest}
          handleUpdateGroup={handleConfirmUpdateGroup}
          isUpdateGroupLoading={props.isUpdateGroupLoading}
          isGetGroupByIdLoading={props.isGetGroupByIdLoading}
        />
      )}
      <InviteDialog
        isOpen={inviteUser.isOpen}
        isLoading={props.isCitizensInviteLoading}
        handleClose={handleCloseInviteDialog}
        handleConfirm={handleConfirmInviteDialog}
      />
    </Container>
  )
})

function mapStateToProps(state) {
  return {
    isGetGroupByIdLoading: isLoadingSelector(state, GROUP_BYID),
    isGetGroupsLoading: isLoadingSelector(state, GROUPS_GET),
    isUpdateGroupLoading: isLoadingSelector(state, GROUP_UPDATE),
    isUpdateGroupSuccess: isSuccessSelector(state, GROUP_UPDATE),
    isCitizensInviteLoading: isLoadingSelector(state, CITIZEN_INVITE),
    isCitizensInviteSuccess: isSuccessSelector(state, CITIZEN_INVITE),
  }
}

const mapDispatchToProps = {
  getGroups: GROUPS_GET.START.create,
  updateGroup: GROUP_UPDATE.START.create,
  getGroupById: GROUP_BYID.START.create,
  inviteCitizen: CITIZEN_INVITE.START.create,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageInvitedCitizens)
