/* eslint-disable  no-irregular-whitespace */
import React, { useState, useEffect } from 'react'
import { Typography, Container, Button, Checkbox } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CHANGE_TOS } from 'modules/user/actions'
import { decodeJWTToken } from '../utils/decodeJWTToken'

import {
  isSuccessSelector,
  isLoadingSelector,
  reasonFailedSelector,
} from '../modules/requestsStatuses/selectors'

function TermOfServices(props) {
  const [isToSAppruved, setToSAppruve] = useState(false)

  const onSignOut = async () => {
    try {
      await Auth.signOut()
      props.history.push('/login')
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const onSendToS = () => {
    props.changeToS()
  }

  useEffect(() => {
    if (props.error) {
      toast.error(props.error ? props.error : 'Server error!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    }
    if (props.isSuccess) {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => {
          const token = user.signInUserSession.idToken.jwtToken
          const decodedJWT = decodeJWTToken(token)
          if (decodedJWT.tosaccepted === 'true') {
            props.changeTos()
          }
        })
        .catch((error) => {
          toast.error(error ? error.message : 'Server error!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          })
        })
    }
  }, [props.error, props.isSuccess])

  return (
    <Container component="main">
      <ToastContainer style={{ textAlign: 'center' }} transition={Zoom} />
      <Button
        style={styles.signOutButton}
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSignOut}
      >
        SIGN OUT
      </Button>
      <Typography style={styles.textContainer}>
        <b>Web Application Terms of Service</b>
        <span style={styles.space} />
        Hoplon™ End User License Agreement
        <span style={styles.space} />
        This Agreement (“Agreement”) is a binding agreement between you (“End
        User” or “you”) and Bodyguard, LLC (“Company”). This Agreement governs
        your use of the Hoplon™ mobile application, (including all related
        documentation, the “Application”). The Application is licensed, not
        sold, to you.
        <span style={styles.space} />
        BY USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
        UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO
        ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE
        THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE
        TERMS, DO NOT USE THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.
        <span style={styles.space} />
        1. <u>License Grant.</u> Subject to the terms of this Agreement, Company
        grants you a limited, non-exclusive and nontransferable license to
        download, install and use the Application for your personal,
        non-commercial use on a single mobile device owned or otherwise
        controlled by you (“Mobile Device”) strictly in accordance with the
        Application’s documentation.
        <span style={styles.space} />
        2. <u>License Restrictions.</u> Licensee shall not:
        <br />
        (a) copy the Application, except as expressly permitted by this license;
        <br />
        (b) modify, translate, adapt or otherwise create derivative works or
        improvements, whether or not patentable, of the Application;
        <br />
        (c) reverse engineer, disassemble, decompile, decode or otherwise
        attempt to derive or gain access to the source code of the Application
        or any part thereof;
        <br />
        (d) remove, delete, alter or obscure any trademarks or any copyright,
        trademark, patent or other intellectual property or proprietary rights
        notices from the Application, including any copy thereof; or
        <br />
        (e) rent, lease, lend, sell, sublicense, assign, distribute, publish,
        transfer or otherwise make available the Application or any features or
        functionality of the Application, to any third party for any reason,
        including by making the Application available on a network where it is
        capable of being accessed by more than one device at any time.
        <span style={styles.space} />
        3. <u>Reservation of Rights.</u> You acknowledge and agree that the
        Application is provided under license, and not sold, to you. You do not
        acquire any ownership interest in the Application under this Agreement,
        or any other rights thereto other than to use the Application in
        accordance with the license granted, and subject to all terms,
        conditions and restrictions, under this Agreement. Company reserves and
        shall retain its entire right, title and interest in and to the
        Application, including all copyrights, trademarks and other intellectual
        property rights therein or relating thereto, except as expressly granted
        to you in this Agreement.
        <span style={styles.space} />
        4. <u>Collection and Use of Your Information.</u> You acknowledge that
        when you download, install or use the Application, Company may use
        automatic means (including, for example, cookies and web beacons) to
        collect information about your Mobile Device and about your use of the
        Application. You also may be required to provide certain information
        about yourself as a condition to downloading, installing or using the
        Application or certain of its features or functionality, and the
        Application may provide you with opportunities to share information
        about yourself with others. This information may include sensitive
        personal information. By downloading, installing, using and providing
        information to or through this Application, you consent to all actions
        taken by Company with respect to your information, as set forth in
        Company’s Privacy Policy.
        <span style={styles.space} />
        5. <u>Disclaimer of Liability for Emergency Response.</u> The
        Application is intended to facilitate communications with a specific
        local response center (the “Response Center”), which communications may
        include requests for assistance in an emergency. The Application may not
        function for communications with other Response Centers. The
        availability of certain features of this Application, depend on whether
        the Response Center supports those features, and are factors outside of
        Company’s control. Company relies on the Response Center to assist us in
        routing communications. Company does not have control over the Response
        Center, emergency responders, or other third parties. Company disclaims
        all responsibility for the conduct of the Response Center and all other
        third parties involved in the provision of any response to your
        communications, including emergency response services. You understand
        that Company does not guarantee that the system will work on demand.
        Accordingly, to the extent permitted by applicable law, YOU AGREE TO
        INDEMNIFY AND HOLD HARMLESS COMPANY, AND ANY THIRD-PARTY PROVIDER(S)
        FROM ANY AND ALL THIRD-PARTY CLAIMS, LOSSES, DAMAGES, FINES, OR
        PENALTIES ARISING OUT OF: (i) YOUR TRANSMISSION OF INCORRECT
        INFORMATION, INCLUDING PHYSICAL ADDRESSES; (ii) THE ABSENCE, FAILURE, OR
        OUTAGE OF SERVICE USING THE APPLICATION FOR ANY REASON; AND (iii) THE
        INABILITY OF THE RESPONSE CENTER TO BE ABLE TO RESPOND TO YOUR
        COMMUNICATION FOR ANY REASON.
        <span style={styles.space} />
        6. <u>Updates.</u> Company may from time to time in its sole discretion
        develop and provide Application updates, which may include upgrades, bug
        fixes, patches and other error corrections and/or new features
        (collectively, including related documentation, “Updates”). Updates may
        also modify or delete in their entirety certain features and
        functionality. You agree that Company has no obligation to provide any
        Updates or to continue to provide or enable any particular features or
        functionality. Based on your Mobile Device settings, when your Mobile
        Device is connected to the internet either:
        <br />
        (a) the Application will automatically download and install all
        available Updates; or
        <br />
        (b) you may receive notice of or be prompted to download and install
        available Updates. You shall promptly download and install all Updates
        and acknowledge and agree that the Application or portions thereof may
        not properly operate should you fail to do so. You further agree that
        all Updates will be deemed part of the Application and be subject to all
        terms and conditions of this Agreement.
        <span style={styles.space} />
        7. <u>Third Party Materials.</u> The Application may display, include or
        make available third party content (including data, information,
        applications and other products services and/or materials) or provide
        links to third party websites or services, including through third party
        advertising (“Third Party Materials”). You acknowledge and agree that
        Company is not responsible for Third Party Materials, including their
        accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality or any other aspect thereof. Company does not
        assume and will not have any liability or responsibility to you or any
        other person or entity for any Third-Party Materials. Third Party
        Materials and links thereto are provided solely as a convenience to you
        and you access and use them at entirely at your own risk and subject to
        such third parties’ terms and conditions.
        <span style={styles.space} />
        8. <u>Term and Termination.</u>
        <br />
        (a) The term of Agreement commences when you acknowledge your acceptance
        and will continue in effect until terminated by you or Company as set
        forth in this agreement.
        <br />
        (b) You may terminate this Agreement by deleting the Application and all
        copies thereof from your Mobile Device.
        <br />
        (c) Company may terminate this Agreement at any time without notice. In
        addition, this Agreement will terminate immediately and automatically
        without any notice if you violate any of the terms and conditions of
        this Agreement.
        <br />
        (d) Upon termination:
        <br />
        (I) all rights granted to you under this Agreement will also terminate;
        and
        <br />
        (II) you must cease all use of the Application and delete all copies of
        the Application from your Mobile Device and account.
        <br />
        (III) Termination will not limit any of Company’s rights or remedies at
        law or in equity.
        <span style={styles.space} />
        9. <u>Disclaimer of Warranties.</u> THE APPLICATION IS PROVIDED TO
        LICENSEE “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY
        KIND TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. COMPANY, ON
        ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
        RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
        WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH
        RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
        DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT
        LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR
        UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
        APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS,
        BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR
        SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
        RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN
        OR WILL BE CORRECTED.
        <span style={styles.space} />
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
        IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS
        OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
        MAY NOT APPLY TO YOU.
        <span style={styles.space} />
        10. <u>Limitation of Liability.</u> TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF
        ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY
        LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE
        APPLICATION OR THE CONTENT AND SERVICES FOR: (a) PERSONAL INJURY,
        PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES,
        LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE
        OR MALFUNCTION OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT,
        EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES (b) DIRECT DAMAGES IN AMOUNTS
        THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE
        APPLICATION.
        <span style={styles.space} />
        THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
        BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE AND
        REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
        ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
        LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
        <span style={styles.space} />
        11. <u>Indemnification.</u> You agree to indemnify, defend and hold
        harmless Company and its officers, directors, employees, agents,
        affiliates, successors and assigns from and against any and all losses,
        damages, liabilities, deficiencies, claims, actions, judgments,
        settlements, interest, awards, penalties, fines, costs, or expenses of
        whatever kind, including reasonable attorneys’ fees, arising from or
        relating to your use or misuse of the Application or your breach of this
        Agreement. Furthermore, you agree that Company assumes no responsibility
        for the content you submit or make available through this Application.
        <span style={styles.space} />
        12. <u>Export Regulation.</u> The Application may be subject to US
        export control laws, including the US Export Administration Act and its
        associated regulations. You shall not, directly or indirectly, export,
        re-export or release the Application to, or make the Application
        accessible from, any jurisdiction or country to which export, re-export
        or release is prohibited by law, rule or regulation. You shall comply
        with all applicable federal laws, regulations and rules, and complete
        all required undertakings (including obtaining any necessary export
        license or other governmental approval), prior to exporting,
        re-exporting, releasing or otherwise making the Application available
        outside the US.
        <span style={styles.space} />
        13. <u>Severability.</u> If any provision of this Agreement is illegal
        or unenforceable under applicable law, the remainder of the provision
        will be amended to achieve as closely as possible the effect of the
        original term and all other provisions of this Agreement will continue
        in full force and effect.
        <span style={styles.space} />
        14. <u>Governing Law.</u> This Agreement is governed by and construed in
        accordance with the internal laws of the State of Tennessee without
        giving effect to any choice or conflict of law provision or rule. Venue
        for judicial resolution of any dispute shall be fixed in the Business
        Court of the Chancery Court for Davidson County, Tennessee. You waive
        any and all objections to the exercise of jurisdiction over you by such
        courts and to venue in such courts.
        <span style={styles.space} />
        15. <u>Entire Agreement.</u> This Agreement constitutes the entire
        agreement between you and Company with respect to the Application and
        supersede all prior or contemporaneous understandings and agreements,
        whether written or oral, with respect to the Application.
        <span style={styles.space} />
        16. <u>Waiver.</u> No failure to exercise, and no delay in exercising,
        on the part of either party, any right or any power hereunder shall
        operate as a waiver thereof, nor shall any single or partial exercise of
        any right or power hereunder preclude further exercise of that or any
        other right hereunder. In the event of a conflict between this Agreement
        and any applicable purchase or other terms, the terms of this Agreement
        shall govern.
      </Typography>
      <Container maxWidth="xl" style={styles.checkboxContainer}>
        <Checkbox
          style={styles.checkbox}
          checked={isToSAppruved}
          color="primary"
          onClick={() => setToSAppruve(!isToSAppruved)}
        />
        <Typography style={styles.checkboxDescription}>
          I have read and agree to the Terms & Conditions
        </Typography>
      </Container>
      <Button
        disabled={!isToSAppruved || props.isLoading}
        style={styles.submitButton}
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSendToS}
      >
        Continue
      </Button>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state, CHANGE_TOS),
  error: reasonFailedSelector(state, CHANGE_TOS),
  isSuccess: isSuccessSelector(state, CHANGE_TOS),
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeToS: CHANGE_TOS.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TermOfServices)

const styles = {
  parahrafUpdarline: {
    textDecorationLine: 'underline',
  },
  textContainer: {
    margin: '0 0 1rem 0',
  },
  submitButton: {
    width: '100%',
    heigth: '3.5rem',
    fontSize: '1.5625rem',
    margin: '1.5rem auto 2rem',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  signOutButton: {
    display: 'block',
    margin: '1rem 0 2rem auto',
  },
  checkbox: {},
  checkboxDescription: {
    fontSize: '22px',
    lineHeight: '38px',
    marginLeft: '16px',
  },
  space: {
    display: 'block',
    height: '26px',
  },
}
