import { put } from 'redux-saga/effects'

import api from 'api'

import { WMH_GET_DATA } from 'modules/watchMyHome/actions'

export default function* getWMHSaga() {
  const { response, error } = yield api.wmh.getWMHData()

  if (error) {
    console.log('getWMHSaga error', error)

    yield put(WMH_GET_DATA.FAILED.create(error.message))
  } else {
    console.log('getWMHSaga response', response)

    yield put(WMH_GET_DATA.SUCCESS.create(response))
  }
}
