/* eslint-disable import/no-cycle */
import { API } from 'aws-amplify'

import { FLARE_API_NAME } from 'api/flare'
import { getAuthToken } from './getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (body) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const path = '/alerts/generate-file-payload'
    try {
      const token = await getAuthToken()
      const requestParams = {
        headers: {
          // Accept: 'application/json',
          // 'content-type': 'application/json',
          accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body,
      }

      const response = await API.patch(FLARE_API_NAME, path, requestParams)
      console.log(response, 'response')

      result.response = response
    } catch (error) {
      console.log(error, 'error')

      const parsedError = parseError(error)

      reportAPIError({
        endpoint: FLARE_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
