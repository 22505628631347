import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'

import {
  getFlaresSelected,
  getFlaresGroupsSelected,
} from 'modules/flares/selectors'

import Button from 'components/Button/Button'
import { withStyles } from '@material-ui/styles'

import {
  GET_FLARE_TYPES,
  CREATE_FLARE_GROUP,
  UPDATE_FLARES_IN_GROUP,
} from 'modules/flares/actions'
import {
  isLoadingSelector,
  isSuccessSelector,
} from 'modules/requestsStatuses/selectors'
import { CreateGroupingForm } from './CreateGroupingForm'

export const ColorButton = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    color: theme.palette.getContrastText('#eaffea'),
    backgroundColor: '#eaffea',
    '&:hover': {
      backgroundColor: '#d8ead8',
    },
  },
}))(Button)

const styles = {
  container: {
    textAlign: 'left',
    marginBottom: '24px',
    maxWidth: 'auto',
  },
  dialogContent: {
    minWidth: '500px',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  loading: {
    margin: '8xp auto',
  },
  buttonsContainer: {
    padding: '8px 24px',
  },
  inputLabel: {
    zIndex: 0,
  },
  dialogTitle: {
    fontSize: '28px',
  },
  dialogSubtitle: {
    fontSize: '18px',
  },
}

export const FlareGroupingFilters = ({
  handleCreateGroup,
  handleUpdateFlaresInGroup,
}) => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const flaresSelected = useSelector(getFlaresSelected)
  const flaresGroupsSelected = useSelector(getFlaresGroupsSelected)
  const [typeId, setTypeId] = useState(-1)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  const getFlareTypesRequest = () => {
    dispatch(GET_FLARE_TYPES.START.create())
  }

  useEffect(() => {
    if (isOpen) {
      getFlareTypesRequest()
    }
  }, [isOpen])

  const toggleModal = () => {
    setOpen(!isOpen)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const isLoading = useSelector(
    (state) =>
      isLoadingSelector(state, CREATE_FLARE_GROUP) ||
      isLoadingSelector(state, UPDATE_FLARES_IN_GROUP),
  )

  const isSuccess = useSelector(
    (state) =>
      isSuccessSelector(state, CREATE_FLARE_GROUP) ||
      isSuccessSelector(state, UPDATE_FLARES_IN_GROUP),
  )

  const onCreateGroup = () => {
    if (flaresGroupsSelected?.length > 0) {
      const flares = []
      if (flaresGroupsSelected) {
        flaresGroupsSelected.forEach((group) => {
          group.flares.forEach((flare) => flares.push(flare))
        })
      }
      if (flaresSelected) {
        flares.push(...flaresSelected)
      }

      const mainGroupId = flaresGroupsSelected[0]?.id
      const flareGroupIdsSelected = flaresGroupsSelected.map(
        (group) => group.id,
      )
      const groupIdsDeleted = flareGroupIdsSelected.filter(
        (groupId) => mainGroupId !== groupId,
      )

      handleUpdateFlaresInGroup({
        flares,
        groupId: mainGroupId,
        isAdding: true,
        updateDataGroup: {
          groupId: mainGroupId,
          flareData: {
            typeId,
            status,
            logs: [
              {
                message,
              },
            ],
          },
        },
        groupIdsDeleted,
      })
    } else {
      handleCreateGroup({
        flareIds: flaresSelected.map((flare) => flare.id),
        status,
        typeId,
        message,
      })
    }
  }

  const buttonTitle = 'Create Group'

  useEffect(() => {
    if (!isSuccess) return
    setOpen(false)
  }, [isSuccess, isLoading])

  return (
    <Container style={styles.container} maxWidth={false}>
      {((flaresSelected?.length > 0 && flaresGroupsSelected?.length > 0) ||
        flaresSelected?.length > 1 ||
        flaresGroupsSelected?.length > 1) && (
        <ColorButton variant="contained" onClick={toggleModal}>
          {buttonTitle}
        </ColorButton>
      )}
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={styles.dialogTitle}>
          Create Flare Group
        </DialogTitle>
        <DialogTitle id="form-dialog-title" style={styles.dialogSubtitle}>
          You are about to combine these flares into a group. You may provide a
          reason or update message below:
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          <CreateGroupingForm
            classes={styles}
            handleCreateGroup={onCreateGroup}
            setTypeId={setTypeId}
            setStatus={setStatus}
            setMessage={setMessage}
            close={closeModal}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </DialogContent>
      </Dialog>
    </Container>
  )
}
