import React, { useState } from 'react'
import {
  DialogTitle,
  Button,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Link } from 'react-router-dom'

import { styled, withStyles } from '@material-ui/styles'

export const CssTextField = withStyles((theme) => ({
  root: {
    '& label': {
      color: '#989898',
    },
    '& label.Mui-focused': {
      color: '#0052a5',
    },
    '& .MuiFormLabel-root': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        lineHeight: '1.735rem',
      },
      fontSize: '1.735rem',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0052a5',
    },
    '& .MuiOutlinedInput-root': {
      height: '4.33rem',
      fontSize: '1.735rem',
      borderRadius: '0.625rem',
      // marginBottom: '2.625rem',
      '& fieldset': {
        borderColor: '#grey.light',
      },
      '&:hover fieldset': {
        borderColor: '#989898',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0052a5',
      },
    },
  },
}))(TextField)

const CssInput = withStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    width: '100%',
    '& label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        lineHeight: '1.735rem',
      },
      backgroundColor: '#fafafa',
      marginTop: '16px',
      fontSize: '1.735rem',
      color: '#989898',
    },
    '& label.Mui-focused': {
      color: '#0052a5',
    },
    '& .label + .MuiInput-formControl': {
      marginTop: '24px',
    },
    '& .MuiInputBase-root': {
      height: '4.33rem',
      fontSize: '1.735rem',
      padding: '18px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '0.625rem',
    },
    '& .MuiInputBase-root:hover': {
      border: '1px solid #989898',
      borderRadius: '#0.625rem',
    },
    '& .MuiInput-root:focus-within': {
      border: '1px solid #0052a5',
      borderRadius: '0.625rem',
    },
    '& .MuiFocused': {
      border: '1px solid red',
    },
    '& .MuiInput-underline:before': {
      border: '0',
    },
    '& .MuiInput-underline:after': {
      border: '0',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: '0',
    },
  },
}))(FormControl)

export const CssPasswordInput = ({
  id,
  value,
  onChange,
  label,
  name,
  style,
  error,
  onBlur,
}) => {
  const [isVisiblePassword, setVisiblePassword] = useState(false)

  const onChangeVisiblePassword = () => {
    setVisiblePassword(!isVisiblePassword)
  }

  return (
    <CssInput style={style || null}>
      <InputLabel
        error={error}
        variant="outlined"
        htmlFor="standard-adornment-password"
      >
        {label}*
      </InputLabel>
      <Input
        style={error ? styles.inputError : {}}
        id={id}
        type={isVisiblePassword ? 'text' : 'password'}
        value={value}
        fullWidth
        margin="none"
        required
        onBlur={onBlur}
        onChange={(e) => onChange(name, e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onChangeVisiblePassword}
            >
              {isVisiblePassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </CssInput>
  )
}

export const CssContainer = styled(Container)({
  marginTop: '3.5625rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

export const CssVerifyContainer = styled(Container)({
  marginTop: '2.75rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const CssButton = withStyles({
  root: {
    position: 'relative',
    right: '7rem',
    marginBottom: '1rem',
    alignItems: 'left',
    color: '#0052a5',
    fontSize: '1.5625rem',
    textDecoration: 'none',
    justifyContent: 'left',
    '&:hover': {
      textDecoration: 'none',
      color: '#0052a5',
      backgroundColor: '#eaeaf8',
    },
  },
})(Button)

export const CssLicensesButton = styled(Button)({
  marginTop: '1rem',
  position: 'absolute',
  bottom: '.2rem',
  right: '.2rem',
  fontSize: '.5rem',
  color: '#989898',
})

export const CssDialogTitle = styled(DialogTitle)({
  paddingBottom: '0',
})

export const Logo = () => (
  <Link to="/">
    <img
      style={{ width: '182px' }}
      src={`${process.env.REACT_APP_S3_LOGO}/logo.svg`}
      alt="Hoplon Logo"
    />
  </Link>
)

const styles = {
  inputError: {
    color: '#E0162B',
  },
}
