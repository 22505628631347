/* eslint-disable no-restricted-syntax */
import lodashGet from 'lodash/get'

import { ACTIONS_SUBTYPES } from '../utils/actionCreator'
import { PROGRESS } from './actions'

const requestsStatusesRootSelector = (state) => state.requestsStatuses

export const getRequestsStatusForActionSelector = (state, action) =>
  lodashGet(
    requestsStatusesRootSelector(state),
    action.id !== undefined
      ? `${action.majorType}.${action.id}`
      : `${action.majorType}`,
  )

export const isLoadingSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(state, action)

  return !!(requestData && requestData.status === ACTIONS_SUBTYPES.START)
}

export const isAnyLoadingSelector = (state, action) => {
  const actionData = requestsStatusesRootSelector(state)[action.majorType]

  if (!actionData) {
    return false
  }

  for (const actionId of Object.keys(actionData)) {
    if (actionData[actionId].status === 'START') {
      return true
    }
  }

  return false
}

export const getLoadingPayloadSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(state, action)

  return requestData && requestData.status === ACTIONS_SUBTYPES.START
    ? requestData.payload
    : undefined
}

export const isSuccessSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(state, action)

  return lodashGet(requestData, 'status') === ACTIONS_SUBTYPES.SUCCESS
}

export const getSuccessPayloadSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(state, action)

  return requestData && requestData.status === ACTIONS_SUBTYPES.SUCCESS
    ? requestData.payload
    : undefined
}

export const reasonFailedSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(state, action)

  return requestData && requestData.status === ACTIONS_SUBTYPES.FAILED
    ? requestData.payload.reason
    : undefined
}

export const isFailedSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(state, action)

  return requestData && requestData.status === ACTIONS_SUBTYPES.FAILED
}

export const getProgressPayloadSelector = (state, action) => {
  const requestData = getRequestsStatusForActionSelector(
    state,
    PROGRESS(action.type),
  )

  return requestData ? requestData.payload : undefined
}
