import { API } from 'aws-amplify'
import { ALERT_API_NAME, ALERT_PATH } from 'api/alerts'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default () =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const path = `${ALERT_PATH.latest}/types`

    try {
      const token = await getAuthToken()
      const requestParams = {
        headers: {
          Authorization: token,
        },
      }
      result.response = await API.get(ALERT_API_NAME, path, requestParams)
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: 'get alert types',
        payload: {},
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
