import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Geocode from 'react-geocode'
import { makeStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import getParsedStatus from 'utils/getParsedStatus'
import { LinkItUrl } from 'react-linkify-it'
import { FlareAttachments } from './FlareAttachments'
import { isFloorsOptionAvailable } from '../../../modules/geofence/selectors'
import { ProgressStage } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    '&:not(:first-of-type)': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-root:before': {
      height: 0,
    },
  },
  accordionSummaryRoot: {
    padding: 0,
    minHeight: 50,
    margin: '0 24px 0 30px',
    '&.Mui-expanded': { minHeight: 50 },
  },
  accordionSummaryContent: {
    margin: '16px 0',
    '&.Mui-expanded': { margin: '16px 0' },
  },
  accordionDetailsRoot: {
    margin: '4px 30px 30px',
    padding: 0,
  },
  accordionStepperRoot: {
    padding: 0,
    marginRight: 15,
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    overflow: 'hidden',
  },
  summaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  scrollableContainer: {
    height: '200px',
    overflowY: 'auto',
  },
  contentColumn: {
    overflow: 'hidden',
  },
  logContainer: {
    width: '100%',
  },
  infoText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#555555',
    '-moz-hyphens': 'auto',
    '-webkit-hyphens': 'auto',
    '-o-hyphens': 'auto',
    hyphens: 'auto',
    overflow: 'auto',
    maxHeight: '3.6em', // show max 3 lines
  },
  coordination: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#555555',
  },
  fakeIcon: {
    height: 24,
    width: 24,
  },
  buttonRoot: {
    width: 91,
  },
  noteButton: {
    color: theme.palette.blue,
    backgroundColor: 'rgb(1, 42, 68, 0.1)',
  },
  finishButton: {
    color: theme.palette.finish,
    backgroundColor: 'rgba(244, 67, 54, 0.13)',
  },
  makeActiveButton: {
    backgroundColor: 'rgb(9, 33, 20, 0.1)',
    '&:hover': {
      backgroundColor: 'rgb(23, 83, 50, 0.05)',
    },
  },
  stepperPosition: {
    '&>div:first-of-type': {
      marginLeft: '-12%',
    },
    '&>div:last-of-type': {
      marginRight: '-12%',
    },
  },
}))

function FlareItemMin({ isSingleFlare, reportMode, flare, openManually }) {
  const classes = useStyles()
  const [panelExpanded, setPanelExpanded] = useState(false)
  const [address, setAddress] = useState('')
  const isFloorOptionAvailable = useSelector(isFloorsOptionAvailable)

  // Get address from latitude & longitude.
  useEffect(() => {
    if (flare.address) {
      setAddress(flare.address)
    } else if (flare && flare.latitude && flare.longitude) {
      Geocode.fromLatLng(flare.latitude, flare.longitude).then(
        (response) => {
          const addressResponse = response.results[0].formatted_address
          setAddress(addressResponse)
        },
        (error) => {
          console.error(error)
        },
      )
    } else {
      setAddress('******')
    }
  }, [flare.latitude, flare.longitude, flare.address])

  const handlePanelChange = () => {
    if (isSingleFlare || reportMode) {
      return
    }
    setPanelExpanded(!panelExpanded)
  }

  const { type, color } = getParsedStatus(flare.status, flare.isClosed)

  const fontWeight = useMemo(
    () => (flare.status === ProgressStage.NEW ? 'bolder' : 'normal'),
    [flare.status],
  )

  const expandIcon = useMemo(
    () =>
      !reportMode && !isSingleFlare ? (
        <ExpandMoreIcon />
      ) : (
        <div className={classes.fakeIcon} />
      ),
    [reportMode, isSingleFlare, classes.fakeIcon],
  )

  useEffect(() => {
    setPanelExpanded(openManually)
  }, [openManually])

  return (
    <>
      <Accordion
        expanded={panelExpanded}
        onChange={handlePanelChange}
        TransitionProps={{ unmountOnExit: true }}
        classes={{ root: classes.accordionRoot }}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
          }}
          expandIcon={expandIcon}
        >
          <Grid container alignItems="center" style={{ height: '19px' }}>
            <Grid item xs={2}>
              <Tooltip
                title={flare.type?.name || flare.type}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight }}
                >
                  {flare.type?.name ? flare.type?.name || flare.type : ''}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={2}>
              <Tooltip title={type} placement="bottom-start" enterDelay={500}>
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight, color }}
                >
                  {type}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={reportMode ? 4 : 3}>
              <Tooltip
                title={address}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight }}
                >
                  {address.split(',')[0]}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid item xs={reportMode ? 3 : 2}>
              <Tooltip
                title={flare.createdAt?.toLocaleString('en-US', {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  style={{ fontWeight }}
                >
                  {flare.createdAt?.toLocaleString('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                  })}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} classes={{ root: classes.contentColumn }}>
              <Grid container direction="column" spacing={1.25}>
                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Name
                  </Typography>

                  <Typography classes={{ root: classes.infoText }}>
                    {flare.citizen?.name}
                  </Typography>
                </Grid>

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Location
                  </Typography>

                  <Typography classes={{ root: classes.infoText }}>
                    {address}
                  </Typography>
                  <Typography
                    classes={{ root: classes.coordination }}
                    variant="body2"
                  >
                    {flare.latitude}, {flare.longitude}
                  </Typography>
                </Grid>

                {typeof flare.floor === 'number' || isFloorOptionAvailable ? (
                  <Grid item width="100%">
                    <Typography classes={{ root: classes.summaryText }}>
                      Floor
                    </Typography>

                    <Typography classes={{ root: classes.infoText }}>
                      {typeof flare.floor === 'number'
                        ? flare.floor
                        : 'Not Submitted'}
                    </Typography>
                  </Grid>
                ) : null}

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Phone
                  </Typography>

                  <Typography classes={{ root: classes.infoText }}>
                    {flare.citizen?.phoneNumber}
                  </Typography>
                </Grid>

                <Grid item width="100%">
                  <Typography classes={{ root: classes.summaryText }}>
                    Details
                  </Typography>
                  <LinkItUrl>
                    <Typography classes={{ root: classes.infoText }}>
                      {flare.message}
                    </Typography>
                  </LinkItUrl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} classes={{ root: classes.contentColumn }}>
              <FlareAttachments image={flare.files} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FlareItemMin
