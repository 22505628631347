import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_ALERT_TYPES } from 'modules/alerts/actions'

export default function* getAlertTypesSaga() {
  const { response, error } = yield api.alerts.getAlertTypes()

  if (error) {
    console.log('getAlertsSaga error', error)

    yield put(GET_ALERT_TYPES.FAILED.create(error.message))
  } else {
    console.log('getAlertsSaga response', response)

    yield put(GET_ALERT_TYPES.SUCCESS.create(response))
  }
}
