/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Button,
  TextField,
} from '@material-ui/core'

export default function EditTypeDialog({
  type,
  typeData,
  title,
  isVisible,
  isLoading,
  handleCloseDialog,
  onSubmitModal,
}) {
  const [typeName, setTypeName] = useState('')
  const [description, setDescription] = useState('')
  const [isNameError, setNameError] = useState(false)
  // const [isDescriptionError, setDescriptionError] = useState(false)
  const [mandatoryNotified, setMandatoryNotified] = useState(false)

  useEffect(() => {
    if (typeData) {
      setTypeName(typeData.name)
      setDescription(typeData.description)
      setMandatoryNotified(typeData.mandatory)
    }
  }, [typeData])

  const checkError = (e) => {
    switch (e.target.id) {
      case 'name':
        if (!e.target.value.length) {
          setNameError(true)
        } else {
          setNameError(false)
        }
        break
      // case 'description':
      //   if (!e.target.value.length) {
      //     setDescriptionError(true)
      //   } else {
      //     setDescriptionError(false)
      //   }
      //   break
      default:
        break
    }
  }
  return (
    <Dialog open={isVisible} fullWidth aria-labelledby="form-dialog-title">
      {isLoading ? (
        <LinearProgress variant="indeterminate" value style={styles.progress} />
      ) : (
        <div style={styles.progress} />
      )}
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          disabled={isLoading}
          autoFocus
          variant="standard"
          margin="dense"
          id="name"
          label="Name"
          type="name"
          required
          value={typeName}
          onChange={(e) => setTypeName(e.target.value)}
          fullWidth
          error={isNameError}
          onBlur={checkError}
        />
        {/* <TextField
          disabled={isLoading}
          variant="standard"
          margin="normal"
          required
          fullWidth
          label="Description"
          type="text"
          id="description"
          error={isDescriptionError}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={checkError}
        /> */}
        {type === 'alert' ? (
          <div>
            <input
              type="checkbox"
              id="mandatoryNotified"
              name="mandatoryNotified"
              checked={mandatoryNotified}
              onChange={() => setMandatoryNotified(!mandatoryNotified)}
              style={{ marginRight: '10px' }}
            />
            <label htmlFor="mandatoryNotified">Always Notify Subscribers</label>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleCloseDialog}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading || !typeName.length}
          color="primary"
          onClick={() =>
            onSubmitModal(typeName, description, mandatoryNotified)
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  progress: {
    width: '100%',
    height: '4px',
  },
}
