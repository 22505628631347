import React, { useState, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import isArray from 'lodash/isArray'
import 'react-image-gallery/styles/css/image-gallery.css'

export const FlareAttachments = ({ image }) => {
  const galleryRef = useRef(null)
  const [images, setImages] = useState([])

  useEffect(() => {
    if (!isArray(image)) {
      if (image) {
        setImages([
          {
            original: `https://${image}`,
            thumbnail: `https://${image}`,
          },
        ])
        return
      }
      setImages([
        {
          original: `https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png`,
          thumbnail: `https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png`,
        },
      ])
      return
    }
    if (!image.length) {
      setImages([
        {
          original: `https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png`,
          thumbnail: `https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png`,
        },
      ])
      return
    }
    setImages(
      image.map((imgUrl) => ({
        original: `https://${imgUrl}`,
        thumbnail: `https://${imgUrl}`,
      })),
    )
  }, [image])

  const handleImageClick = () => {
    if (galleryRef) {
      galleryRef.current.toggleFullScreen()
    }
  }

  const changeThumbnailPosition = (index) => {
    const thumbnailContainer = galleryRef?.current.thumbnails?.current
    const thumbnailItem = galleryRef?.current.thumbnails?.current?.children[0]

    if (thumbnailContainer && thumbnailItem) {
      // + 8 -> border compensation, (index - 1) * 2 -> paddingLeft compensation
      thumbnailContainer.scrollTo(
        (index + 0.5) * (thumbnailItem.clientWidth + 8) -
          thumbnailContainer.clientWidth / 2 +
          (index - 1) * 2,
        0,
      )
    }
  }

  return (
    <ImageGallery
      ref={galleryRef}
      items={images}
      showThumbnails={isArray(image) ? !!image.length : !!image}
      thumbnailPosition="bottom"
      showFullscreenButton
      showNav
      autoPlay={false}
      showPlayButton={false}
      useBrowserFullscreen={false}
      showIndex
      swipingTransitionDuration={100}
      slideDuration={100}
      disableThumbnailScroll
      onSlide={changeThumbnailPosition}
      onScreenChange={(fullScreen) => {
        if (!fullScreen) {
          setTimeout(() => {
            changeThumbnailPosition(galleryRef.current.state.currentIndex)
          }, 10)
        }
      }}
      onClick={handleImageClick}
    />
  )
}
