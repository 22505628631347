import React, { useEffect, useMemo, useState } from 'react'
import { ToastContainer, Zoom } from 'react-toastify'
import { Link, useLocation } from 'react-router-dom'

import { Button, CssBaseline, Typography } from '@material-ui/core'
import { CssContainer, CssTextField, Logo } from '../components/common'

import * as styles from './styles'

const EmailVerifyLink = ({ submitVerification, submitVerificationSuccess }) => {
  const [code, setCode] = useState()
  const [email, setEmail] = useState()
  const [isRedirectDashboardHidden, setIsRedirectDashboardHidden] =
    useState(true)
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const _email = searchParams.get('email')
    const _code = searchParams.get('code')
    setCode(_code)
    setEmail(_email)
    submitVerification({ attributeName: 'email', code: _code })
  }, [])

  useEffect(() => {
    if (submitVerificationSuccess) {
      setIsRedirectDashboardHidden(false)
    }
  }, [submitVerificationSuccess])

  return (
    <>
      <ToastContainer transition={Zoom} />
      <CssContainer component="main">
        <CssBaseline />
        <div style={styles.form}>
          <Logo />
          <Typography component="h1" variant="h5">
            Verify Account
          </Typography>

          <CssTextField
            value={email}
            style={styles.input}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            type="text"
            disabled
          />
          <CssTextField
            value={code}
            style={styles.second_input}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Verification Code"
            type="text"
            disabled
          />
          {isRedirectDashboardHidden && (
            <div style={styles.buttons_container}>
              <Link to="/dashboard" style={styles.navigationLink}>
                <Button style={styles.secondary_button} color="primary">
                  Go to dashboard
                </Button>
              </Link>
            </div>
          )}
        </div>
      </CssContainer>
    </>
  )
}

export default EmailVerifyLink
