/*  eslint-disable import/no-cycle */
import { put } from 'redux-saga/effects'
import api from 'api'

import { USERS_DEACTIVATE } from 'modules/users/actions'
import { toast } from 'react-toastify'
import { GET_LICENSES } from 'modules/geofence/actions'

export default function* deactivateUserSaga({ payload }) {
  try {
    const { error } = yield api.agents.deactivateUser(payload)

    if (error) {
      yield put(USERS_DEACTIVATE.FAILED.create(error.message))
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    } else {
      yield put(USERS_DEACTIVATE.SUCCESS.create(payload))
      yield put(GET_LICENSES.START.create())
    }
  } catch (error) {
    yield put(USERS_DEACTIVATE.FAILED.create(error.message))
  }
}
