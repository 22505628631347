import React, { Component } from 'reactn'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Grid,
  Divider,
  Button,
} from '@material-ui/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx'

import 'react-toastify/dist/ReactToastify.css'
import { withStyles } from '@material-ui/styles'
import { EmbedMapView } from '../MapFunctions'
import styles from '../QueueOutput/shared/styles'
import ShowProgress from '../QueueOutput/shared/ShowProgress'
import api from '../../api'

class PastWmhItem extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { enableImage: true }

  mounted = false

  state = {
    location: null,
    showMap: false,
    showProgress: false,
    enableImage: false,
  }

  // Array to store operator name and notes from status updates, passed to showProgress dialog as prop
  progressData = [
    // Currently holding placeholders, this should be pulled from database
    {
      key: 1,
      stage: 1,
      timestamp: 'mm/dd/yy @ 00:00 AM',
      operator: 'Operator Name 1',
    },
    {
      key: 2,
      stage: 2,
      timestamp: 'mm/dd/yy @ 00:00 AM',
      operator: 'Operator Name 2',
      note: 'Note',
    },
    {
      key: 3,
      stage: 3,
      timestamp: 'mm/dd/yy @ 00:00 AM',
      operator: 'Operator Name 3',
      note: 'Note',
    },
  ]

  componentDidMount() {
    if (this.props.image) {
      this.setState({ enableImage: true })
    }
    const address = {
      address: this.props.address.street,
      city: this.props.address.city,
      state: this.props.address.state,
      zip: this.props.address.zip,
    }
    api.wmh.getWMHCoords(address).then((coords) => {
      if (this.mounted) this.setState({ location: coords })
    })
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  showMap = () => {
    this.setState({ showMap: true })
  }

  formatPhoneNumber = (phoneNum) => {
    const digits = phoneNum.replace(/\D/, '')
    const prefix = digits.length === 11 ? '+' : ''
    const areaCode = digits.length > 7 ? `(${digits.slice(-10, -7)})` : ''
    // The result is of the form +<country code>-(<area code>)-<xxx-xxxx> .
    return `${prefix}${digits.slice(0, -10)} ${areaCode} ${digits.slice(
      -7,
      -4,
    )}-${digits.slice(-4)}`
  }

  /* Converts a `Date` object to its twelve-hour clock representation. */
  twelveHourTime = (date) => {
    let hour = date.getHours() % 12
    if (hour === 0) hour = 12
    const minute = date.getMinutes().toString().padStart(2, '0')
    const suffix = date.getHours() < 12 ? 'AM' : 'PM'
    return `${this.props.time.getMonth()}/${this.props.time.getDate()}/${this.props.time
      .getFullYear()
      .toString()
      .substr(-2)}@${hour}:${minute} ${suffix}`
  }

  formatDate = () =>
    `${this.props.time.getMonth()}/${this.props.time.getDate()}/${this.props.time
      .getFullYear()
      .toString()
      .substr(-2)} @ ${this.props.time
      .toLocaleTimeString()
      .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`

  // Progress dialog show/hide methods
  showProgress = () => {
    this.setState({ showProgress: true })
  }

  closeProgress = () => {
    this.setState({ showProgress: false })
  }

  render() {
    const { classes } = this.props
    return (
      <Grid
        container
        item
        xs={12}
        justify="center"
        className={classes.resourceContainer}
      >
        <Accordion
          className={classes.resourceCard}
          onChange={(event, expanded) => {
            if (expanded && !this.state.everExpanded) {
              this.setState({
                everExpanded: true,
                showMap: true,
              })
            } else if (expanded) {
              this.setState({ showMap: true })
            } else {
              setTimeout(() => {
                this.setState({ showMap: false })
              }, 250)
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className={classes.columnTitle}>
              <Typography className={classes.heading}>
                {this.props.name}
              </Typography>
            </div>
            <div className={classes.columnTitle}>
              <Typography className={classes.heading}>
                {this.props.address.street}
              </Typography>
            </div>
            <div className={classes.columnTitle}>
              <Typography className={classes.heading}>
                {this.formatDate()}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div
              className={
                this.state.enableImage
                  ? clsx(classes.infoColumn, classes.helper)
                  : clsx(classes.infoColumnNoImage, classes.helper)
              }
            >
              <Typography className={classes.typography} variant="caption">
                <p>
                  <strong>Receiving Agent: </strong>PLACEHOLDER
                </p>
                <p>
                  <strong>Full Address: </strong>
                  {this.props.address.street}, {this.props.address.city},{' '}
                  {this.props.address.state} {this.props.address.zip}
                </p>
                <p>
                  <strong>Duration: </strong>
                  {this.props.duration.start} - {this.props.duration.end}
                </p>
                <div>
                  <strong>Main Contact: </strong>
                  <ul>
                    <li>
                      <em>Phone: </em>
                      {this.props.contact.phone}
                    </li>
                    <li>
                      <em>Email: </em>
                      {this.props.contact.email}
                    </li>
                  </ul>
                </div>
                <div>
                  <strong>Emergency Contact: </strong>
                  <ul>
                    <li>
                      <em>Name: </em>
                      {this.props.emergencyContact.name}
                    </li>
                    <li>
                      <em>Phone: </em>
                      {this.formatPhoneNumber(
                        this.props.emergencyContact.phone,
                      )}
                    </li>
                    <li>
                      <em>Relationship: </em>
                      {this.props.emergencyContact.relationship}
                    </li>
                    {this.props.emergencyContact.email && (
                      <li>
                        <em>Email: </em>
                        {this.props.emergencyContact.email}
                      </li>
                    )}
                  </ul>
                </div>
                <p>
                  <strong>Message: </strong>
                  {this.props.message}
                </p>
                <div>
                  <strong>History Log:</strong>
                  <ul>
                    <li>
                      Note: {this.props.Note2}
                      <br /> Updated By: {this.props.Operator2},{' '}
                      {this.twelveHourTime(this.props.Date2)}
                    </li>
                    <li>
                      Note: {this.props.Note3}
                      <br /> Updated By: {this.props.Operator3},{' '}
                      {this.twelveHourTime(this.props.Date3)}
                    </li>
                    <li>
                      Note: {this.props.Note4}
                      <br /> Updated By: {this.props.Operator4},{' '}
                      {this.twelveHourTime(this.props.Date4)}
                    </li>
                  </ul>
                </div>
              </Typography>
            </div>
            {this.state.enableImage && (
              <div className={classes.imageColumn}>
                <img src={this.props.image} alt="" style={{ height: '30vh' }} />
              </div>
            )}

            <div className={classes.mapColumn} style={{ width: '15rem' }}>
              {this.state.showMap ? (
                <div>
                  {this.state.location && (
                    <EmbedMapView
                      zoom={17}
                      center={{
                        lat: this.state.location.lat,
                        lng: this.state.location.lng,
                      }}
                      height="15rem"
                    />
                  )}
                </div>
              ) : (
                <Button
                  size="small"
                  className={classes.showMap}
                  style={{ color: '#0052a5' }}
                  onClick={this.showMap}
                >
                  Show Map
                </Button>
              )}
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button
              onClick={this.showProgress}
              size="small"
              style={{
                color: '#0052a5',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              Show Progress History
            </Button>
            <Button size="small" style={{ color: '#175332' }}>
              Make Active
            </Button>
          </AccordionActions>
        </Accordion>
        <ShowProgress
          open={this.state.showProgress}
          cancel={this.closeProgress}
          progressData={this.progressData}
        />
      </Grid>
    )
  }
}
PastWmhItem.defaultProps = {
  enableImage: true,
}
const PastWmhOutput = withStyles(styles)(PastWmhItem)
export default PastWmhOutput
