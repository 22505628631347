import { API } from 'aws-amplify'

// eslint-disable-next-line import/no-cycle
import { LOGS_API_NAME, LOG_PATH } from 'api/logs'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (payload) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const token = await getAuthToken()
    const path = `${LOG_PATH.latest}/for/alerts`
    try {
      const response = await API.post(LOGS_API_NAME, path, {
        body: {
          itemId: payload.alertId,
          message: payload.message,
          operatorName: payload.operatorName,
        },
        headers: {
          Authorization: token,
        },
      })

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: LOGS_API_NAME + path,
        payload,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
