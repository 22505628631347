import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Button,
  ListItem,
  Container,
  Checkbox, // eslint-disable-line no-unused-vars
  FormControlLabel, // eslint-disable-line no-unused-vars
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

import {
  flareReportSummarySelector,
  getFlareTotalItemsSelector,
  rootFlaresSelector,
} from '../../../../modules/flares/selectors'
import {
  GET_FILTERED_FLARES,
  GET_FLARE_REPORT_SUMMARY,
} from '../../../../modules/flares/actions'
import MapView from '../../../../components/MapFunctions'
import { convertDurationToTime } from '../../../../modules/utils/convertDuration'
import FlareFiltersPanel from './FlareFiltersPanel'
import FlareReportList from './FlareReportList'
import { isSuccessSelector } from '../../../../modules/requestsStatuses/selectors'
import { ExportReportDialog } from './ExportReportDialog'
import { stationSelector } from '../../../../modules/geofence/selectors'
import { initialFlareFilterState } from '../../../../constants'

const useClasses = makeStyles({
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
})

const FlareReportContainer = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
})(Container)

const FlareReportHeader = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 10,
    '@media print': {
      display: 'none',
    },
  },
})(Container)

const FlareReportFilters = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})(Container)

const FlareReportSummary = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})(Container)

const SummaryText = withStyles({
  root: {
    fontSize: 20,
  },
})(Typography)

const SummaryStats = withStyles({
  root: {
    width: '30vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 15,
    margin: 20,
  },
})(Paper)

const SummaryMap = withStyles({
  root: {
    height: '40vh',
    width: '60vw',
    margin: 20,
    position: 'relative',
    textAlign: 'center',
  },
})(Paper)

const FlareReport = ({
  station,
  flares,
  isGetFlareSuccess,
  getMoreFlares,
  totalFlares,
  flareReportSummary,
  getFlareReportSummary,
}) => {
  const [filtersState, setFiltersState] = useState(initialFlareFilterState)
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [isEnabledExportButton, setIsEnabledExportButton] = useState(true)
  const [printAllFlares, setPrintAllFlares] = useState(false)
  const [printSummary, setPrintSummary] = useState(false)
  const [openManually, setOpenManually] = useState(false)
  const classes = useClasses()

  const toogleViewExport = () => {
    setOpen(!open)
  }

  const getAverageResponseTime = () =>
    convertDurationToTime(flareReportSummary.averageResponseTime)

  const getAverageCompleteTime = () =>
    convertDurationToTime(flareReportSummary.averageTimeToCompletion)

  useEffect(() => {
    getFlareReportSummary()
  }, [])

  useEffect(() => {
    if (printAllFlares && isGetFlareSuccess) {
      setTimeout(() => {
        window.print()
        setIsEnabledExportButton(true)
      }, 2000)
      setPrintAllFlares(false)
    }
  }, [isGetFlareSuccess, printAllFlares])

  const handlePrintSummary = () => {
    setIsEnabledExportButton(false)
    setPrintSummary(true)
    setTimeout(() => {
      window.print()
      setPrintSummary(false)
      setIsEnabledExportButton(true)
    }, 200)
    toogleViewExport()
  }

  const handlePrintCurrent = () => {
    setIsEnabledExportButton(false)
    setTimeout(() => {
      window.print()
      setIsEnabledExportButton(true)
    })
    toogleViewExport()
  }

  const handlePrintAllFlares = () => {
    setIsEnabledExportButton(false)
    getMoreFlares({ page: 1, perPage: totalFlares, filters: filtersState })
    setPrintAllFlares(true)
    setPage(1)
    toogleViewExport()
  }

  const handleApplyFilters = (filters) => {
    setFiltersState(filters)
    setPage(1)
  }

  const toogleShowDetail = () => {
    setOpenManually(!openManually)
  }

  return (
    <FlareReportContainer>
      <FlareReportHeader>
        <Button
          disabled={!isEnabledExportButton}
          aria-controls="export-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={toogleViewExport}
        >
          Export report
        </Button>
      </FlareReportHeader>
      <FlareReportFilters>
        <FlareFiltersPanel
          setPage={setPage}
          onApplyFilters={handleApplyFilters}
          toogleShowDetail={toogleShowDetail}
        />
      </FlareReportFilters>
      <FlareReportSummary>
        <SummaryStats>
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem>
              <SummaryText>
                Average response time: {getAverageResponseTime()}
              </SummaryText>
            </ListItem>
            <ListItem>
              <SummaryText>
                Average complete time: {getAverageCompleteTime()}
              </SummaryText>
            </ListItem>
            <ListItem>
              <SummaryText>
                Total Cases: {flareReportSummary.totalNumberOfFlares}
              </SummaryText>
            </ListItem>
            <ListItem>
              <SummaryText>
                Active: {flareReportSummary.totalNumberOfActiveFlares}
              </SummaryText>
            </ListItem>
            <ListItem>
              <SummaryText>
                Completed: {flareReportSummary.totalNumberOfCompletedFlares}
              </SummaryText>
            </ListItem>
            <ListItem>
              <SummaryText>
                Closed: {flareReportSummary.totalNumberOfClosedFlares}
              </SummaryText>
            </ListItem>
          </List>
        </SummaryStats>
        <SummaryMap>
          <MapView station={station} reportPage />
        </SummaryMap>
      </FlareReportSummary>
      <div
        style={{ width: '100%' }}
        className={printSummary ? classes.noPrint : ''}
      >
        <FlareReportList
          reportMode
          page={page}
          setPage={setPage}
          flares={flares}
          filters={filtersState}
          totalFlares={totalFlares}
          getMoreFlares={getMoreFlares}
          openManually={openManually}
        />
      </div>
      <ExportReportDialog
        open={open}
        handleClose={toogleViewExport}
        handlePrintAllFlares={handlePrintAllFlares}
        handlePrintSummary={handlePrintSummary}
        handlePrintCurrent={handlePrintCurrent}
      />
    </FlareReportContainer>
  )
}

const mapStateToProps = (state) => ({
  flares: rootFlaresSelector(state),
  isGetFlareSuccess: isSuccessSelector(state, GET_FILTERED_FLARES),
  station: stationSelector(state),
  totalFlares: getFlareTotalItemsSelector(state),
  flareReportSummary: flareReportSummarySelector(state),
})

const mapDispatchToProps = {
  getMoreFlares: (payload) => GET_FILTERED_FLARES.START.create(payload),
  getFlareReportSummary: (payload) =>
    GET_FLARE_REPORT_SUMMARY.START.create(payload),
}

export default connect(mapStateToProps, mapDispatchToProps)(FlareReport)
