import React from 'react'
import { makeStyles } from '@material-ui/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import WatchMyHomeListItem from '../WatchMyHomeListItem'
import WatchMyHomeHistoryListItem from '../WatchMyHomeHistoryListItem'

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    margin: '1rem auto',
    width: '97%',
    maxHeight: 'calc(100vh - 170px)',
    overflow: 'hidden',
    overflowY: 'auto',
  },
}))

function WatchMyHomesList({ wmhData, historyMode }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <InfiniteScroll dataLength={wmhData.length} loader={<h4>Loading...</h4>}>
        {wmhData.map((wmhId, index) =>
          historyMode ? (
            <WatchMyHomeHistoryListItem
              // eslint-disable-next-line react/no-array-index-key
              key={`WMH-list-Item-${index}`}
              id={wmhId}
            />
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <WatchMyHomeListItem key={`WMH-list-Item-${index}`} id={wmhId} />
          ),
        )}
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(WatchMyHomesList)
