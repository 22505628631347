import React from 'react'
import { makeStyles } from '@material-ui/styles'
// import InputBase from '@material-ui/core/InputBase'
// import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AlertsList from 'components/AlertsList'

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    width: '100%',
    display: 'flex',
    'box-sizing': 'border-box',
    alignItems: 'center',
    paddingRight: 51,
    padding: '0 51px 15px 15px',
  },
  summaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#555555',
  },
  root: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 44,
    paddingRight: 44,
    overflowY: 'auto',
    overflow: 'hidden',
    marginTop: 44,
  },
  labels: {
    display: 'flex',
    margin: '1rem 60px 1rem 24px',
    '& > *': {
      flexGrow: 1,
      overflow: 'hidden',
    },
  },
  filter: {
    display: 'flex',
    margin: '1rem auto',
  },
  inputRoot: {
    marginRight: '1rem',
  },
  inputInput: {
    border: '1px solid #DDDDDD',
    borderRadius: '5px',
    padding: '5px 5px',
    '&:hover': {
      border: '1px solid #BBBBBB',
    },
  },
}))

function AlertsPage({ historyMode }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/*
      TODO
      <div className={classes.filter}>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
        <Button>Add Filter</Button>
      </div> */}

      <div className={classes.headerWrapper}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography classes={{ root: classes.summaryText }}>
              Subject
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography classes={{ root: classes.summaryText }}>
              Location
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography classes={{ root: classes.summaryText }}>
              Priority
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography classes={{ root: classes.summaryText }}>
              Created
            </Typography>
          </Grid>
        </Grid>
      </div>

      <AlertsList historyMode={historyMode} />
    </div>
  )
}

export default React.memo(AlertsPage)
