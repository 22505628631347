import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { isLoadingSelector } from '../../../../modules/requestsStatuses/selectors'
import { GET_FILTERED_FLARES } from '../../../../modules/flares/actions'
import ListHeader from '../../../../components/FlareList/ListHeader'
import {
  FlareItemMin,
  FlareItemGroup,
} from '../../../../components/FlareList/FlareListItem'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 44,
    paddingRight: 44,
    overflowY: 'auto',
    overflow: 'hidden',
    marginTop: 44,
    marginBottom: 44,
  },
  listWrapper: {
    borderRadius: 8,
    border: '1px solid #DDDDDD',
    backgroundColor: '#fff',
    'box-shadow': '0px 8px 50px -35px rgb(0 0 0)',
  },
}))

function FlareReportList({
  flares,
  getMoreFlares,
  totalFlares,
  reportMode,
  type,
  page,
  setPage,
  filters,
  openManually,
}) {
  const classes = useStyles()
  const isGetFlareLoading = useSelector((state) =>
    isLoadingSelector(state, GET_FILTERED_FLARES),
  )

  const fetchData = () => {
    getMoreFlares({
      page: page + 1,
      isNextPage: true,
      filters,
    })
    setPage(page + 1)
  }

  useEffect(() => {
    getMoreFlares({ page })
  }, [])

  return (
    <div className={classes.root}>
      <ListHeader reportMode={reportMode} type={type} />
      <div className={classes.listWrapper}>
        {flares.length ? (
          <InfiniteScroll
            next={fetchData}
            hasMore={totalFlares > flares.length}
            dataLength={flares.length}
            loader={flares.length && <h4>Loading...</h4>}
          >
            {flares.map((flare) => {
              if (flare?.group) {
                return (
                  <FlareItemGroup
                    key={`flare-item-group-${flare.group?.id}`}
                    reportMode={reportMode}
                    flaresGroup={flare}
                  />
                )
              }
              return (
                <FlareItemMin
                  key={`flare-item-${flare?.id}`}
                  flare={flare}
                  openManually={openManually}
                />
              )
            })}
          </InfiniteScroll>
        ) : (
          <h4>Loading...</h4>
        )}
        {!isGetFlareLoading && !flares.length && (
          <div>No flares with such filters</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(FlareReportList)
