import { put, select } from 'redux-saga/effects'
import api from 'api'

import {
  uploadFilesToS3,
  getBucketFileObj,
} from 'modules/utils/uploadFilesToS3'
import getUploadFileBucket from 'api/utils/getUploadFileBucket'

import {
  UPDATE_ALERT,
  GET_ALERT_LOGS,
  GET_ACTIVE_ALERTS,
} from 'modules/alerts/actions'

import { activeAlertsCountSelector } from 'modules/alerts/selectors'

export const UPDATE_TYPE = {
  MOVE_TO_CLOSE: 'MOVE_TO_CLOSE',
  MOVE_TO_ACTIVE: 'MOVE_TO_ACTIVE',
  UPDATE_ALERT: 'UPDATE_ALERT',
}

export default function* updateAlertSaga(action) {
  const { alertId, body } = action.payload

  if (typeof body.files !== 'undefined') {
    const newFiles = body.files.filter((file) => typeof file !== 'string')
    const filesMime = newFiles.map((fileObj) => fileObj.type)
    const getUploadFileBucketResult = yield getUploadFileBucket({
      fileTypes: filesMime,
    })
    if (getUploadFileBucketResult.error) {
      throw getUploadFileBucketResult.error
    }
    const imageUploadUrls = getUploadFileBucketResult.response

    const uploadFilesToS3Response = yield uploadFilesToS3(
      imageUploadUrls,
      newFiles,
    )
    if (uploadFilesToS3Response.find((response) => response.status !== 204)) {
      throw Error('Can`t upload additional materials please try again!')
    }
    const s3BucketFilesObj = getBucketFileObj(
      uploadFilesToS3Response,
      newFiles,
      imageUploadUrls,
    )

    body.files = s3BucketFilesObj
  }

  const { response, error } = yield api.alerts.updateAlert(alertId, body)

  if (error) {
    console.log('updateAlertSaga error', error)

    yield put(UPDATE_ALERT.FAILED.create(error.message))
  } else {
    console.log('updateAlertSaga response', response)
    let type = UPDATE_TYPE.MOVE_TO_ACTIVE
    if (typeof body.isClosed === 'boolean' && body.isClosed) {
      type = UPDATE_TYPE.MOVE_TO_CLOSE
    } else if (typeof body.isClosed === 'undefined') {
      type = UPDATE_TYPE.UPDATE_ALERT
    }

    yield put(UPDATE_ALERT.SUCCESS.create({ type, alert: response.alert }))
    yield put(GET_ALERT_LOGS.START.create({ alertId }))
    if (type === UPDATE_TYPE.MOVE_TO_CLOSE) {
      const alertsCount = yield select(activeAlertsCountSelector)
      yield put(
        GET_ACTIVE_ALERTS.START.create({ page: 1, perPage: alertsCount + 1 }),
      )
    }
  }
}
