/* eslint-disable no-shadow */
import lodashGet from 'lodash/get'

const CALLBACK_ARGUMENT = Symbol('CALLBACK_ARGUMENT')
const CALLBACK_ERROR_ARGUMENT = Symbol('ERROR_CALLBACK_ARGUMENT')

export { CALLBACK_ARGUMENT, CALLBACK_ERROR_ARGUMENT }

const fsaCompliantArgumentCb = (action) => action.payload || action.data || {}
const fsaCompliantErrorArgumentCb = (action) => {
  const errorMessage = lodashGet(action.payload, 'reason.message', null)
  return errorMessage || 'Something went wrong'
}

export default function () {
  const pendingActionList = []
  const promisesList = []
  const getPromisesList = () => promisesList

  const middleware = () => (next) => (action) => {
    // eslint-disable-next-line no-plusplus
    for (let i = pendingActionList.length - 1; i >= 0; i--) {
      const pendingActionInfo = pendingActionList[i]
      if (pendingActionInfo.isSuccessAction(action)) {
        pendingActionInfo.resolveCallback(
          pendingActionInfo.successArgumentCb(action),
        )
      } else if (pendingActionInfo.isErrorAction(action)) {
        pendingActionInfo.rejectCallback(
          pendingActionInfo.errorArgumentCb(action),
        )
      } else {
        // eslint-disable-next-line no-continue
        continue
      }
      pendingActionList.splice(pendingActionList.indexOf(pendingActionInfo), 1)
    }

    if (!action.promisify) {
      return next(action)
    }

    const id = action.id || ''

    const successAction = `${action.type.split('->')[0].trim()} -> SUCCESS${id}`

    const errorAction = `${action.type.split('->')[0].trim()} -> FAILED${id}`

    const newPendingActionInfo = {}

    if (successAction) {
      if (typeof successAction === 'function') {
        newPendingActionInfo.isSuccessAction = successAction
      } else {
        newPendingActionInfo.isSuccessAction = (action) =>
          action.type + (action.id || '') === successAction
      }
    } else {
      newPendingActionInfo.isSuccessAction = () => false
    }

    if (errorAction) {
      if (typeof errorAction === 'function') {
        newPendingActionInfo.isErrorAction = errorAction
      } else {
        newPendingActionInfo.isErrorAction = (action) =>
          action.type + (action.id || '') === errorAction
      }
    } else {
      newPendingActionInfo.isErrorAction = () => false
    }

    newPendingActionInfo.successArgumentCb =
      action[CALLBACK_ARGUMENT] || fsaCompliantArgumentCb
    newPendingActionInfo.errorArgumentCb =
      action[CALLBACK_ERROR_ARGUMENT] || fsaCompliantErrorArgumentCb

    const promise = new Promise((resolve, reject) => {
      newPendingActionInfo.resolveCallback = resolve
      newPendingActionInfo.rejectCallback = reject
    })

    pendingActionList.push(newPendingActionInfo)
    promisesList.push(promise)

    next(action)

    return promise
  }

  return Object.assign(middleware, { getPromisesList })
}
