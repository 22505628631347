import { connect } from 'react-redux'
import { isSuccessSelector } from 'modules/requestsStatuses/selectors'
import {
  UPDATE_FLARES_IN_GROUP,
  CREATE_FLARE_GROUP,
} from 'modules/flares/actions'
import FlaresList from './FlaresList'

const mapStateToProps = (state) => ({
  isSuccessAddFlareInGroup: isSuccessSelector(state, UPDATE_FLARES_IN_GROUP),
  isSuccessCreateGroup: isSuccessSelector(state, CREATE_FLARE_GROUP),
})

export default connect(mapStateToProps, null)(FlaresList)
