/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import {
  Grid,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Button from 'components/Button/Button'
import { ButtonWithLoader } from 'ui/shared/common'

// import { isLoadingSelector } from 'modules/requestsStatuses/selectors'

import { getTypesFlaresSelected } from 'modules/flares/selectors'
import { GET_FLARE_TYPES } from 'modules/flares/actions'
import { StatusLevels, FlareStatusText, defaultType } from '../../constants'

export const CreateGroupingForm = ({
  handleCreateGroup,
  classes,
  setTypeId,
  setStatus,
  setMessage,
  close,
  isLoading,
}) => {
  const dispatch = useDispatch()
  const flareTypes = useSelector(getTypesFlaresSelected)

  useEffect(() => {
    setTypeId(-1)
    dispatch(GET_FLARE_TYPES.START.create())
  }, [])

  return (
    <Formik
      initialValues={{ status: '', type: defaultType.name, message: '' }}
      validationSchema={Yup.object().shape({
        status: Yup.string().required('Status is required'),
        type: Yup.string().nullable(true),
        message: Yup.string()
          .max(1000, 'Description is too long')
          .required('Message is required'),
      })}
      onSubmit={handleCreateGroup}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, values, handleSubmit, setValues }) => (
        <Grid item xs={12}>
          <form type="submit" onSubmit={handleSubmit}>
            <InputLabel>Group Combined Status*</InputLabel>
            <Select
              fullWidth
              error={errors.status}
              value={values.status}
              onChange={(e) => {
                FlareStatusText.forEach((status) => {
                  if (status.name === e.target.value) {
                    setStatus(status.value)
                  }
                })
                setValues({ ...values, status: e.target.value })
              }}
              id="status"
              name="status"
            >
              {Object.values(StatusLevels).map((value) => (
                <MenuItem key={`status-${value}`} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errors.status && (
              <FormHelperText error>{errors.status}</FormHelperText>
            )}

            <InputLabel>
              Group Type - select from types in the group can be null
            </InputLabel>
            <Select
              fullWidth
              error={errors.type}
              value={values.type}
              onChange={(e) => {
                flareTypes.forEach((type) => {
                  if (type.name === e.target.value) {
                    setTypeId(type.id)
                  }
                })
                setValues({ ...values, type: e.target.value })
              }}
              id="type"
              name="type"
            >
              {Object.values(flareTypes).map((value) => (
                <MenuItem key={`type-${value.id}`} value={value.name}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
            {errors.type && (
              <FormHelperText error>{errors.type}</FormHelperText>
            )}

            <TextField
              margin="dense"
              variant="outlined"
              name="details"
              label="Details *"
              type="text"
              // labelStyle={{ zIndex: 0 }}
              rows="2"
              style={{ marginTop: '1rem' }}
              multiline
              error={errors.message}
              fullWidth
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              // inputRef={register}
              onChange={(e) => {
                setMessage(e.target.value)
                setValues({ ...values, message: e.target.value })
              }}
            />

            <Typography
              fontSize="0.75rem"
              padding="0 0.5rem"
              style={{ opacity: '0.5' }}
            >
              {values.message.length} of 1000 characters
            </Typography>
            {errors.message && (
              <FormHelperText error>{errors.message}</FormHelperText>
            )}

            <Grid
              container
              item
              justify="space-between"
              md={6}
              xs={12}
              spacing={1}
              padding="15px 0 0 0"
            >
              <Grid container item md={12} xs={12} spacing={1}>
                <Grid item md={4} xs={12}>
                  <Button onClick={close} color="primary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ButtonWithLoader
                    text="send"
                    type="submit"
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Formik>
  )
}
