import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'

import { ALERTS_CREATE } from 'modules/alerts/actions'

import {
  isSuccessSelector,
  isLoadingSelector,
  reasonFailedSelector,
} from 'modules/requestsStatuses/selectors'

import { AlertAttachments } from './AlertAttachments'
import { SendFlareForm } from './SendAlertForm'

import { styles } from './styles'

import { formattingUrlInText } from '../../../utils'

const useStyles = makeStyles((theme) => styles(theme))

const toastId = 'SEND_ALERT_MODAL'

function SendAlertDialog({
  open,
  close,
  createAlert,
  isLoading,
  errorReason,
  isCreateSuccess,
}) {
  const classes = useStyles()
  const [files, setFiles] = useState([])

  const handleCreateAlert = ({ typeId, subject, details, ...rest }) => {
    const detailFormated = formattingUrlInText(details)
    if (typeId !== '') {
      createAlert({
        body: { typeId, subject: '', details: detailFormated, ...rest },
        files,
      })
    } else {
      createAlert({
        body: { subject, details: detailFormated, ...rest },
        files,
      })
    }
  }

  useEffect(() => {
    if (errorReason) {
      showMessage(errorReason, true)
    }
  }, [errorReason])

  useEffect(() => {
    if (isCreateSuccess) {
      showMessage('Alert successful created!')
    }
  }, [isCreateSuccess])

  const showMessage = (reason, isError) => {
    if (isError) {
      toast.error(reason, {
        toastId: `${toastId}-${reason}`,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    } else {
      toast.success(reason, {
        toastId,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
      close()
    }
  }
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      fullScreen={false}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Send Alert</DialogTitle>

      <DialogContent>
        <Grid container justify="flex-end" spacing={2}>
          {/* Send alert form */}
          <SendFlareForm
            classes={classes}
            isOpen={open}
            handleCreateAlert={handleCreateAlert}
            close={close}
            isLoading={isLoading}
          />
          {/* Alerts attachments */}
          <Grid item xs={6} className={classes.gridColumn}>
            <AlertAttachments
              isOpen={open}
              classes={classes}
              isLoading={isLoading}
              files={files}
              setFiles={setFiles}
              isEdit
              showMessage={showMessage}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    isCreateSuccess: isSuccessSelector(state, ALERTS_CREATE),
    isLoading: isLoadingSelector(state, ALERTS_CREATE),
    errorReason: reasonFailedSelector(state, ALERTS_CREATE),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAlert: ALERTS_CREATE.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SendAlertDialog)
