import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ForgotPassword from './forgotPassword'

import { navigationStateSelector } from '../../../modules/selectors'
import {
  reasonFailedSelector,
  isLoadingSelector,
  isSuccessSelector,
} from '../../../modules/requestsStatuses/selectors'

import {
  AUTH_FORGOT_PASSWORD_SUBMIT,
  AUTH_FORGOT_PASSWORD_RESEND_CODE,
} from '../../../modules/auth/actions'

function mapStateToProps(state) {
  return {
    resendResetPasswordErrorMessage: reasonFailedSelector(
      state,
      AUTH_FORGOT_PASSWORD_RESEND_CODE,
    ),
    resetPasswordSubmitErrorMessage: reasonFailedSelector(
      state,
      AUTH_FORGOT_PASSWORD_SUBMIT,
    ),
    isResendCodeSuccess: isSuccessSelector(
      state,
      AUTH_FORGOT_PASSWORD_RESEND_CODE,
    ),
    isResetPasswordFetching:
      isLoadingSelector(state, AUTH_FORGOT_PASSWORD_RESEND_CODE) ||
      isLoadingSelector(state, AUTH_FORGOT_PASSWORD_SUBMIT),
    routerState: navigationStateSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resendCode: AUTH_FORGOT_PASSWORD_RESEND_CODE.START.create,
      forgotPasswordSubmit: AUTH_FORGOT_PASSWORD_SUBMIT.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
