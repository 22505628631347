import { put } from 'redux-saga/effects'

import api from 'api'

import {
  CREATE_FLARE_GROUP,
  ACTIVE_FLARES_GET_GROUPED,
} from 'modules/flares/actions'

export default function* createFlareGroupSaga(action) {
  const { flareIds, status, typeId, message } = action.payload

  const { response, error } = yield api.flare.createFlareGroup(
    flareIds,
    status,
    typeId,
    message,
  )

  if (error) {
    console.log('createFlareGroupSaga error', error)

    yield put(CREATE_FLARE_GROUP.FAILED.create(error.message))
  } else {
    console.log('createFlareGroupSaga response', response)
    yield put(ACTIVE_FLARES_GET_GROUPED.START.create({ page: 1 }))
    yield put(CREATE_FLARE_GROUP.SUCCESS.create())
  }
}
