import React from 'reactn'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core'

import 'react-toastify/dist/ReactToastify.css'

// Progress History Dialog
export default function ShowProgress(props) {
  function getData(item) {
    return props.progressData.filter((stage) => stage.stage === item)[0]
  }
  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      scroll="paper"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Progress</DialogTitle>
      <DialogContent style={{ width: '100%', paddingBottom: '.5rem' }}>
        {props.stage === 1 ? (
          <Typography
            style={{ position: 'relative', justifyContent: 'center' }}
          >
            <span style={{ fontSize: 18, color: '#f04141' }}>
              Error: No Data
            </span>
          </Typography>
        ) : null}
        {props.stage === 2 ? (
          <ProgressItem
            stage="Received"
            timestamp={getData(1, props).timestamp}
            operator={getData(1, props).operator}
            note={getData(1, props).note}
          />
        ) : null}
        {props.stage === 3 ? (
          <ProgressItem
            stage="Reviewing"
            timestamp={getData(2, props).timestamp}
            operator={getData(2, props).operator}
            note={getData(2, props).note}
          />
        ) : null}
        {props.stage === 4 ? (
          <ProgressItem
            stage="In Progress"
            timestamp={getData(3, props).timestamp}
            operator={getData(3, props).operator}
            note={getData(3, props).note}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
ShowProgress.defaultProps = {
  progressData: [
    { key: 1, stage: 2, timestamp: '', operator: '' },
    { key: 2, stage: 3, timestamp: '', operator: '', note: '' },
    { key: 3, stage: 4, timestamp: '', operator: '', note: '' },
  ],
  stage: 4,
}

// Reusable card component for progress display
function ProgressItem(props) {
  return (
    <Card style={{ width: '100%', marginBottom: props.marginBottom }}>
      <CardContent>
        <Typography style={{ position: 'relative', justifyContent: 'center' }}>
          <span style={{ fontSize: 18 }}>{props.stage}</span>
          <span
            style={{ position: 'absolute', right: '1rem', color: '#989898' }}
          >
            {props.timestamp}
          </span>
        </Typography>
        <hr />
        <Typography>
          <p>
            <strong>Operator: </strong>
            <span>{props.operator}</span>
          </p>
          {props.note ? (
            <p>
              <strong>Note: </strong>
              <span>{props.note}</span>
            </p>
          ) : null}
        </Typography>
      </CardContent>
    </Card>
  )
}
ProgressItem.defaultProps = {
  marginBottom: '1rem',
}
