/* eslint-disable no-undef */
import axios from 'axios'

export const uploadFilesToS3 = (s3UploadCredentials, files) => {
  const s3UploadCredentialsRequest = s3UploadCredentials.map(
    async (credentials, index) => {
      const { url, fields } = credentials
      const formDataObj = getFormDataObj(fields, files[index])
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': ' multipart/form-data',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'OPTIONS,POST',
        },
        data: formDataObj,
        url,
      }
      const imageResponse = axios(options)

      return imageResponse
    },
  )
  return Promise.all(s3UploadCredentialsRequest)
}

function getFormDataObj(credentials, file) {
  const formData = new FormData()
  formData.append('key', credentials.key)
  formData.append('acl', credentials.acl)
  formData.append('Content-Type', credentials['Content-Type'])
  formData.append('bucket', credentials.bucket)
  formData.append('X-Amz-Algorithm', credentials['X-Amz-Algorithm'])
  formData.append('X-Amz-Credential', credentials['X-Amz-Credential'])
  formData.append('X-Amz-Date', credentials['X-Amz-Date'])
  formData.append('X-Amz-Security-Token', credentials['X-Amz-Security-Token'])
  formData.append('Policy', credentials.Policy)
  formData.append('X-Amz-Signature', credentials['X-Amz-Signature'])
  formData.append('file', file)
  return formData
}

export const getBucketFileObj = (axiosResponses, files, responseBuckets) =>
  axiosResponses.map((response, index) => ({
    key: responseBuckets[index].fields.key,
    mimeType: files[index].type,
  }))
