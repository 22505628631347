import { Version } from 'api/utils'
import inviteCitizenUser from './inviteCitizenUser'

export const INVITE_API_NAME = 'Invite_CRUD_dev'

const RESOURCE_PATH = '/citizens'

export const CITIZEN_PATH = {
  legacy: RESOURCE_PATH + Version.LEGACY,
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  inviteCitizenUser,
}
