import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import { push } from 'connected-react-router'

import { AUTH_FORGOT_PASSWORD } from '../actions'

export default function* forgotPasswordSaga(action) {
  const { username } = action.payload
  console.log(username)
  try {
    yield Auth.forgotPassword(username)
    yield put(AUTH_FORGOT_PASSWORD.SUCCESS.create())
    yield put(push('/forgotpassword', { username }))
  } catch (error) {
    let { message } = error
    switch (error.code) {
      case 'UserNotFoundException':
        message = 'User not exist'
        break
      case 'InvalidParameterException':
        yield Auth.resendSignUp(username).catch(console.log)
        yield put(push('/verifyusername', action.payload))
        break
      default:
        break
    }
    yield put(AUTH_FORGOT_PASSWORD.FAILED.create(message))
  }
}
