import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FLARE_OPERATORS } from 'modules/flares/actions'

export default function* getFlaresOperatorsSaga() {
  const { response, error } = yield api.flare.getFlaresOperators()

  if (error) {
    console.log('getFlaresOperatorsSaga error', error)

    yield put(GET_FLARE_OPERATORS.FAILED.create(error.message))
  } else {
    console.log('getFlaresOperatorsSaga response', response)

    yield put(GET_FLARE_OPERATORS.SUCCESS.create(response))
  }
}
