/* eslint-disable no-restricted-syntax */

import { API } from 'aws-amplify'
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { reportAPIError } from '../../utils/errorReports'
// eslint-disable-next-line import/no-cycle
import { parseError } from '../../utils/parseError'
import { getAuthToken } from '../utils/getAuthToken'
import { getStationId } from '../../components/StationDetect'

const addFlareType = async (typeName, description) => {
  const geofenceId = getStationId().toLowerCase()
  const path = `${FLARE_PATH.latest}/types/from/station/${geofenceId}`
  const result = {
    error: undefined,
    response: undefined,
  }
  try {
    const token = await getAuthToken()
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        name: typeName,
        description,
      },
    }
    result.response = await API.post(FLARE_API_NAME, path, request)
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: FLARE_API_NAME + path,
      payload: { typeName, description },
      error: parsedError,
    })
    result.error = parsedError
  }
  return result
}

export default addFlareType
