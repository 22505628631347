import React, { PureComponent } from 'reactn'
import { withStyles } from '@material-ui/styles'

import { connect } from 'react-redux'
import MapView from '../../../components/MapFunctions'
import {
  isLoadingSelector,
  isSuccessSelector,
} from '../../../modules/requestsStatuses/selectors'
import { GET_GEOFENCE } from '../../../modules/geofence/actions'
import { stationSelector } from '../../../modules/geofence/selectors'
import { MainMapLoader } from '../../../components/LoadingOverlay'

const styles = {
  markerLabel: {
    marginTop: 0,
    marginBottom: 0,
  },
}

class Map extends PureComponent {
  render() {
    const { isGetGeofenceLoading, isGetGeofenceSuccess, google, station } =
      this.props

    return (
      <div className="Map page">
        <MainMapLoader
          loading={isGetGeofenceLoading}
          display={isGetGeofenceSuccess}
        >
          {isGetGeofenceSuccess && (
            <MapView
              google={google}
              station={station}
              height="calc(100vh - 64px)"
            />
          )}
        </MainMapLoader>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  station: stationSelector(state),
  isGetGeofenceLoading: isLoadingSelector(state, GET_GEOFENCE),
  isGetGeofenceSuccess: isSuccessSelector(state, GET_GEOFENCE),
})

const MapPage = connect(mapStateToProps, null)(withStyles(styles)(Map))
export default MapPage
