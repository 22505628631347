import { put } from 'redux-saga/effects'
import api from 'api'

import { CHANGE_TOS } from 'modules/user/actions'

export default function* currentUserInfoSaga() {
  try {
    const { response, error } = yield api.agents.changeToS()

    if (error) {
      yield put(CHANGE_TOS.FAILED.create(error.message))
    } else {
      yield put(CHANGE_TOS.SUCCESS.create(response))
    }
  } catch (err) {
    yield put(CHANGE_TOS.FAILED.create(err))
  }
}
