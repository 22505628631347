/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import {
  Grid,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import MUIPlacesAutocomplete from 'mui-places-autocomplete'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { isLoadingSelector } from 'modules/requestsStatuses/selectors'

import { GET_STATION_ALERT_TYPES } from 'modules/alerts/actions'
import { alertStationTypesSelector } from 'modules/alerts/selectors'
import { isAlertEditableSubjectOptionAvailableSelector } from 'modules/geofence/selectors'

import { ButtonWithLoader } from 'ui/shared/common'

import { UrgencyLevels } from '../../../constants'

export const SendFlareForm = ({
  handleCreateAlert,
  isLoading,
  close,
  classes,
}) => {
  const dispatch = useDispatch()
  const types = useSelector(alertStationTypesSelector)
  const isTypesLoading = useSelector((state) =>
    isLoadingSelector(state, GET_STATION_ALERT_TYPES),
  )
  const isAlertEditableSubjectOptionAvailable = useSelector(
    isAlertEditableSubjectOptionAvailableSelector,
  )
  const activeTypes = types.filter((item) => item.enabled)

  useEffect(() => {
    dispatch(GET_STATION_ALERT_TYPES.START.create())
  }, [])

  return (
    <Formik
      initialValues={{ subject: '', address: '', details: '' }}
      validationSchema={
        isAlertEditableSubjectOptionAvailable
          ? Yup.object().shape({
              address: Yup.string().required('Address is required'),
              urgency: Yup.string().nullable(true),
              details: Yup.string().max(1000, 'Description is too long'),
              subject: Yup.string().required('Subject is required'),
            })
          : Yup.object().shape({
              address: Yup.string().required('Address is required'),
              urgency: Yup.string().nullable(true),
              details: Yup.string().max(1000, 'Description is too long'),
              typeId: Yup.number().required('Subject is required'),
            })
      }
      onSubmit={handleCreateAlert}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, values, handleChange, handleSubmit, setValues }) => (
        <Grid item xs={6}>
          <form type="submit" onSubmit={handleSubmit}>
            {isAlertEditableSubjectOptionAvailable ? (
              <>
                <Autocomplete
                  id="subject"
                  options={activeTypes}
                  getOptionLabel={(option) => option.name}
                  autoHighlight
                  loading={isTypesLoading}
                  onInputChange={(e, value) => {
                    if (
                      typeof e?.target.getAttribute('data-option-index') ===
                      'string'
                    ) {
                      const selectedType =
                        activeTypes[e?.target.getAttribute('data-option-index')]
                      if (selectedType) {
                        setValues({
                          ...values,
                          typeId: selectedType.id,
                          subject: selectedType.name,
                        })
                      }
                    } else if (e) {
                      setValues({ ...values, typeId: '', subject: value })
                    }
                  }}
                  inputValue={values.subject}
                  clearOnBlur={false}
                  renderOption={(props, option) => (
                    <Typography {...props} key={props.id}>
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subject"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isTypesLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                {errors.subject && (
                  <FormHelperText error>{errors.subject}</FormHelperText>
                )}
              </>
            ) : (
              <>
                <InputLabel>Subject</InputLabel>
                <Select
                  fullWidth
                  error={errors.typeId}
                  value={values.typeId}
                  onChange={(e) => {
                    setValues({ ...values, typeId: e.target.value })
                  }}
                  id="typeId"
                  name="typeId"
                >
                  {activeTypes.map((type) => (
                    <MenuItem key={`type-${type.name}`} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {errors.typeId && (
              <FormHelperText error>{errors.typeId}</FormHelperText>
            )}

            <MUIPlacesAutocomplete
              onSuggestionSelected={(suggestion) => {
                setValues({ ...values, address: suggestion.description })
              }}
              textFieldProps={{
                type: 'text',
                margin: 'dense',
                label: 'Address *',
                name: 'address',
                variant: 'standard',
                error: errors.address,
                fullWidth: true,
                // inputRef: register,
              }}
              renderTarget={() => <div style={{ zIndex: 100 }} />}
            />
            {errors.address && (
              <FormHelperText error>{errors.address}</FormHelperText>
            )}

            <InputLabel>Priority</InputLabel>
            <Select
              fullWidth
              error={errors.urgency}
              value={values.urgency}
              onChange={(e) => {
                setValues({ ...values, urgency: e.target.value })
              }}
              id="urgency"
              name="urgency"
            >
              {Object.values(UrgencyLevels).map((value) => (
                <MenuItem key={`urgency-${value}`} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errors.urgency && (
              <FormHelperText error>{errors.urgency}</FormHelperText>
            )}

            <TextField
              margin="dense"
              variant="outlined"
              name="details"
              label="Details *"
              type="text"
              labelstyle={{ zIndex: 0 }}
              rows="5"
              style={{ marginTop: '1rem' }}
              multiline
              error={errors.details}
              fullWidth
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              // inputRef={register}
              onChange={handleChange}
            />

            <Typography
              fontSize="0.75rem"
              padding="0 0.5rem"
              style={{ opacity: '0.5' }}
            >
              {values.details.length} of 1000 characters
            </Typography>
            {errors.details && (
              <FormHelperText error>{errors.details}</FormHelperText>
            )}

            <Grid
              container
              item
              justify="space-between"
              md={6}
              xs={12}
              spacing={1}
              padding="15px 0 0 0"
            >
              <Grid container item md={12} xs={12} spacing={1}>
                <Grid item md={4} xs={12}>
                  <Button onClick={close} color="primary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ButtonWithLoader
                    text="send"
                    type="submit"
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Formik>
  )
}
