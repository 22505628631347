import React from 'reactn'
import Drawer from '@material-ui/core/Drawer'
import { NavLink } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { withStyles } from '@material-ui/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { connect } from 'react-redux'
import { rootUserSelector } from '../../modules/user/selectors'
import './Nav.css'

const StyledDrawer = withStyles({
  paper: {
    '& > div': {
      backgroundColor: '#0052a5',
      maxWidth: '40vh',
      minWidth: '240px',
      height: '100%',
    },
  },
})(Drawer)

class SideMenu extends React.PureComponent {
  render() {
    return (
      <StyledDrawer
        anchor="left"
        open={this.props.drawerOpened}
        onClose={this.props.toggleDrawer(false)}
      >
        <div>
          <img
            className="topLogo"
            src={`${process.env.REACT_APP_S3_LOGO}/logo-white.svg`}
            alt=""
          />
          {this.props.currentUser.role === 'Admin' ? (
            <List id="adminOptions">
              <NavLink
                onClick={this.props.toggleDrawer(false)}
                to="/dashboard/agentList"
                className="bm-item"
              >
                <ListItem button key="agentList">
                  <ListItemText primary="Agent List" disableTypography />
                </ListItem>
              </NavLink>
              <NavLink
                onClick={this.props.toggleDrawer(false)}
                to="/dashboard/profile"
                className="bm-item"
              >
                <ListItem button key="agentList">
                  <ListItemText primary="Profile" disableTypography />
                </ListItem>
              </NavLink>
              <NavLink
                onClick={this.props.toggleDrawer(false)}
                to="/dashboard/mobileUsers"
                className="bm-item"
              >
                <ListItem button key="invite">
                  <ListItemText primary="Mobile Users" disableTypography />
                </ListItem>
              </NavLink>
              <NavLink
                onClick={this.props.toggleDrawer(false)}
                to="/dashboard/station-settings"
                className="bm-item"
              >
                <ListItem button key="stationSettings">
                  <ListItemText primary="Station Settings" disableTypography />
                </ListItem>
              </NavLink>
              {/* <NavLink
                onClick={this.props.toggleDrawer(false)}
                to="/dashboard/manageResources"
                className="bm-item"
              >
                <ListItem button key="manageResources">
                  <ListItemText primary="Manage Helpline Resources" />
                </ListItem>
              </NavLink> */}
              <Divider />
            </List>
          ) : (
            <List id="adminOptions">
              <NavLink
                onClick={this.props.toggleDrawer(false)}
                to="/dashboard/profile"
                className="bm-item"
              >
                <ListItem button key="agentList">
                  <ListItemText primary="Profile" disableTypography />
                </ListItem>
              </NavLink>
              <div
                style={{
                  padding: '1rem',
                  color: '#0052a5',
                  width: '13rem',
                  height: '1.5rem',
                }}
              />
            </List>
          )}
          <List
            style={{
              bottom: 0,
              position: 'absolute',
              width: '100%',
              paddingBottom: 0,
              color: '#eaeaea',
            }}
          >
            <Divider />
            <ListItem
              disableRipple
              button
              key="currentUser"
              className="current-user"
            >
              <p>
                Current User:{' '}
                <span>{this.props.currentUser.formattedFullname}</span>
              </p>
            </ListItem>
            <ListItem
              disableRipple
              button
              key="currentRole"
              className="current-user"
            >
              <p>
                Role: <span>{this.props.currentUser.role}</span>
              </p>
            </ListItem>
            <Divider />
            <a
              href="/"
              className="bm-item"
              onClick={() => {
                Auth.signOut()
                  .then(() => {
                    this.props.history.push('/')
                  })
                  .catch(() => console.log('error signing out...'))
              }}
            >
              <ListItem button key="logOut">
                <ListItemText primary="Log Out" disableTypography />
              </ListItem>
            </a>
          </List>
        </div>
      </StyledDrawer>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: rootUserSelector(state),
  }
}

export default connect(mapStateToProps)(SideMenu)
