import { API } from 'aws-amplify'
import { STATION_API_NAME, STATION_PATH } from 'api/station'
import { getAuthToken } from '../utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default async ({ subdomain = '' }) => {
  const path = `${STATION_PATH.latest}/by/${subdomain}`
  try {
    const token = await getAuthToken()
    console.log('token', subdomain)
    const request = {
      headers: {
        Authorization: token,
      },
    }
    const response = await API.get(STATION_API_NAME, path, request)
    return response.station
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: STATION_API_NAME + path,
      response: parsedError,
    })
    throw parsedError
  }
}
