import { put, select } from 'redux-saga/effects'
import api from 'api'

import { USERS_CREATE } from 'modules/users/actions'
import { GET_LICENSES } from 'modules/geofence/actions'
import { getStationId } from '../../../components/StationDetect'
import { licensesSelector } from '../../geofence/selectors'
import { getUserCountSelector } from '../selectors'

export default function* createUserSaga(action) {
  try {
    const licenses = yield select(licensesSelector)
    const usersCount = yield select(getUserCountSelector)
    const isBeyondLicenses = licenses <= usersCount
    let licensesResponse = {}
    let userResponse = {}

    if (isBeyondLicenses) {
      licensesResponse = yield api.billings.addLicenses({ licenses: 1 })
    }

    userResponse = yield api.agents.createUser({
      ...action.payload,
      stationRoleAccess: getStationId().toLowerCase(),
    })
    if (userResponse.error || licensesResponse.error) {
      yield put(
        USERS_CREATE.FAILED.create(
          userResponse.error.message || licensesResponse.error.message,
        ),
      )
    } else if (userResponse.response) {
      yield put(USERS_CREATE.SUCCESS.create(userResponse.response))
      yield put(GET_LICENSES.START.create())
    }
  } catch (error) {
    yield put(USERS_CREATE.FAILED.create(error.message))
  }
}
