import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const GROUP_UPDATE = createAction('GROUP_UPDATE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const GROUP_BYID = createAction('GROUP_BYID', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const GROUPS_GET = createAction('GROUPS_GET', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})
