import React, { useState, useMemo } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/Button/Button'
import getFormDialogInfo from '../../../utils/getFormDialogInfo'
import { formattingUrlInText } from '../../../utils'

export default function FormDialog(props) {
  const { modalAction, newStatus, onClose, onSend } = props
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  const { title, text, required } = getFormDialogInfo(modalAction, newStatus)

  const isValidForm = () => {
    const isValid = required ? !!message : true
    if (!isValid) {
      setError(true)
    }
    return isValid
  }

  const handleClose = () => {
    setMessage('')
    setError(false)
    onClose()
  }

  const handleSend = (event) => {
    event.preventDefault()
    const isValid = isValidForm()
    if (isValid) {
      onSend(formattingUrlInText(message))
      handleClose()
    }
  }

  const handleOnChange = (event) => {
    setError(false)
    setMessage(event.target.value)
  }

  const errorText = useMemo(
    () => (error ? 'Message field is required' : ''),
    [error],
  )

  return (
    <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSend} noValidate>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <TextField
            autoFocus
            error={error}
            fullWidth
            helperText={errorText}
            id="name"
            label="Message"
            margin="dense"
            variant="standard"
            multiline
            onChange={handleOnChange}
            onBlur={isValidForm}
            required={required}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
