import { Button } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const CustomButton = styled(Button)({
  padding: 15,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  lineHeight: '16px',
})

export default CustomButton
