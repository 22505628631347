import { API } from 'aws-amplify'
import { GROUP_API_NAME } from 'api/group/index'
import { getAuthToken } from 'api/utils/getAuthToken'
import { getStationId } from 'components/StationDetect'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (page) =>
  new Promise(async (resolve) => {
    const stationId = getStationId()
    let path = `/groups/from/station/${stationId}`

    if (page) {
      path = path.concat(`?page=${page}`)
    }
    const result = {
      error: undefined,
      response: undefined,
    }

    try {
      const token = await getAuthToken()

      const params = {
        headers: {
          Authorization: token,
        },
      }
      const response = await API.get(GROUP_API_NAME, path, params)

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)
      reportAPIError({
        endpoint: GROUP_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
