import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getWMHSelector } from 'modules/watchMyHome/selectors'
import WatchMyHomeListItem from './WatchMyHomeListItem'
import { flareWMHKeyFunc } from '../QueueOutput/queueItems'
import { toTitleCase } from '../../utils'
import { WMH_CHANGE_STATE } from '../../modules/watchMyHome/actions'
import { rootUserSelector } from '../../modules/user/selectors'

const mapStateToProps = (state, props) => {
  const wmhData = getWMHSelector(state, props.id)
  return {
    name: wmhData.userRealName,
    time: new Date(wmhData.creationTime),
    address: {
      street: wmhData.address,
      city: wmhData.city,
      state: wmhData.state,
      zip: wmhData.zip,
    },
    duration: { start: wmhData.startDate, end: wmhData.endDate },
    contact: {
      phone: wmhData.phoneNumber,
      email: 'EMAIL PLACEHOLDER',
    },
    emergencyContact: {
      name: `${toTitleCase(wmhData.ecfirstName)} ${toTitleCase(
        wmhData.eclastName,
      )}`,
      relationship: wmhData.relationship,
      phone: wmhData.ecphone,
      // placeholder for optional email field
      email: undefined,
    },
    message: wmhData.message,
    key: flareWMHKeyFunc(wmhData),
    image: wmhData.picture,
    userID: wmhData.userID,
    operator: wmhData.operator,
    currentUser: rootUserSelector(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { id } = ownProps
  return bindActionCreators(
    {
      wmhStateChange: WMH_CHANGE_STATE(id).START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchMyHomeListItem)
