import { Auth } from 'aws-amplify'

import { parseError } from '../../utils/parseError'

export default ({ user, previousPassword, proposedPassword }) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }

    try {
      result.response = await Auth.changePassword(
        user,
        previousPassword,
        proposedPassword,
      )
    } catch (error) {
      result.error = parseError(error)
    }

    resolve(result)
  })
