import { put } from 'redux-saga/effects'

import api from 'api'

import { USERS_GET } from 'modules/users/actions'

export default function* getUsersSaga({ payload = {} }) {
  const { page = 1 } = payload
  const { response, error } = yield api.agents.getUsers(payload)

  if (error) {
    console.log('getUsersSaga error', error)

    yield put(USERS_GET.FAILED.create(error.message))
  } else {
    console.log('getUsersSaga response', response)

    yield put(USERS_GET.SUCCESS.create({ ...response, isPagination: page > 1 }))
  }
}
