import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'

import { SIGN_UP_VERIFIRED } from '../actions'

export default function* singUpVerify(action) {
  try {
    yield Auth.confirmSignUp(action.payload.username, action.payload.code)
    yield put(SIGN_UP_VERIFIRED.SUCCESS.create())
  } catch (error) {
    yield put(SIGN_UP_VERIFIRED.FAILED.create())
  }
}
