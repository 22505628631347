import { API } from 'aws-amplify'
import { INVITE_API_NAME, CITIZEN_PATH } from 'api/citizens/index'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default ({ email, phone, groupId }) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const path = `${CITIZEN_PATH.latest}/invite`
    try {
      const token = await getAuthToken()
      const body = {
        email,
        phoneNumber: phone,
        groupIds: [groupId],
      }
      const params = {
        headers: {
          Authorization: token,
        },
        body,
      }
      result.response = await API.post(INVITE_API_NAME, path, params)
    } catch (error) {
      const parsedError = parseError(error)
      reportAPIError({
        endpoint: INVITE_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }
    resolve(result)
  })
