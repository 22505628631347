import { call, put } from 'redux-saga/effects'
import api from 'api'

import { USER_EDIT } from 'modules/user/actions'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'

export default function* editCurrentUserSaga({ payload: { userData } }) {
  try {
    const { response, error } = yield call(api.agents.editUser, userData)

    if (error) {
      yield put(USER_EDIT.FAILED.create(error.message))
      yield showToast(TOAST_TYPES.ERROR, error.message)
    } else {
      yield put(USER_EDIT.SUCCESS.create(response))
      showToast(TOAST_TYPES.SUCCESS, 'Profile has been updated successfully')
    }
  } catch (err) {
    yield put(USER_EDIT.FAILED.create(err))
  }
}
