/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import getID from '../utils/getId'

export const setWMHHelper = (draft, payload) => {
  for (const key in payload) {
    draft[getID(payload[key])] = payload[key]
  }
}
