import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FLARE_TYPES } from 'modules/flares/actions'

export default function* getFlareTypesSaga({ payload: geofenceId } = {}) {
  const { response, error } = yield api.flare.getFlareTypes(geofenceId)

  if (error) {
    console.log('getFlareTypesSaga error', error)

    yield put(GET_FLARE_TYPES.FAILED.create(error.message))
  } else {
    console.log('getFlareTypesSaga response', response)

    yield put(GET_FLARE_TYPES.SUCCESS.create(response))
  }
}
