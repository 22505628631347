import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { CssPasswordInput } from '../../ui/auth/components/common'
import { validatePasswordInput } from '../../utils'
import * as styles from '../../ui/auth/forgotPassword/styles'
import { ModalDialog } from '../Dialog'

export const ChangePasswordModalWrapper = ({
  isChangePasswordDialogOpen,
  isChangeCurrentUserPasswordLoading,
  handleChangePasswordSubmit,
  toggleChangePasswordDialog,
}) => {
  const [
    { previousPassword, proposedPassword, confirmedPassword },
    setPasswords,
  ] = useState({
    previousPassword: '',
    proposedPassword: '',
    confirmedPassword: '',
  })
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(true)
  const [passwordCompareError, setIsPasswordCompareError] = useState(false)
  const [newPasswordValidationError, setIsNewPasswordValidationError] =
    useState(false)
  const [newPasswordValidationErrors, setNewPasswordValidationErrors] =
    useState({
      isNotHaveUppercase: false,
      isNotHaveLowercase: false,
      isNotHaveNumber: false,
      isNotHaveSpecialSymbol: false,
      isShort: false,
    })

  useEffect(() => {
    const validatePasswords = () => {
      const isEmpty =
        proposedPassword === '' ||
        confirmedPassword === '' ||
        previousPassword === ''
      const isInvalid =
        isEmpty || passwordCompareError || newPasswordValidationError
      setIsPasswordInvalid(isInvalid)
    }
    validatePasswords()
  }, [
    passwordCompareError,
    newPasswordValidationError,
    proposedPassword,
    confirmedPassword,
    previousPassword,
  ])

  const validateNewPassword = () => {
    const errors = validatePasswordInput(proposedPassword)
    const isError = Object.values(errors).includes(true)

    setNewPasswordValidationErrors((validationErrors) => ({
      ...validationErrors,
      ...errors,
    }))
    setIsNewPasswordValidationError(() => isError)
    comparePasswords()
    return isError
  }

  const comparePasswords = () => {
    setIsPasswordCompareError(() => proposedPassword !== confirmedPassword)
  }

  const handlePasswordsChange = (name, value) => {
    setPasswords((passwords) => ({ ...passwords, [name]: value }))
  }
  const onSubmit = () => {
    handleChangePasswordSubmit({ previousPassword, proposedPassword })
  }

  return (
    <ModalDialog
      title="Change Password"
      isLoading={isChangeCurrentUserPasswordLoading}
      maxWidth="sm"
      buttonText="Submit"
      open={isChangePasswordDialogOpen}
      onSubmit={onSubmit}
      isSubmitButtonDisabled={isPasswordInvalid}
      close={toggleChangePasswordDialog}
    >
      <ChangeCurrentUserPassword
        onPasswordsChange={handlePasswordsChange}
        passwordCompareError={passwordCompareError}
        newPasswordValidationError={newPasswordValidationError}
        comparePasswords={comparePasswords}
        newPasswordValidationErrors={newPasswordValidationErrors}
        validateNewPassword={validateNewPassword}
      />
    </ModalDialog>
  )
}

const useClasses = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    marginTop: 10,
    maxWidth: 100,
  },
  error: {
    color: '#E0162B',
  },
})

export const ChangeCurrentUserPassword = ({
  onPasswordsChange,
  newPasswordValidationErrors,
  newPasswordValidationError,
  validateNewPassword,
  passwordCompareError,
  comparePasswords,
}) => {
  const classes = useClasses()
  const {
    isShort,
    isNotHaveUppercase,
    isNotHaveLowercase,
    isNotHaveNumber,
    isNotHaveSpecialSymbol,
  } = newPasswordValidationErrors

  const handlePasswordChange = (name, value) => {
    onPasswordsChange(name, value)
  }

  return (
    <div className={classes.container}>
      <CssPasswordInput
        id="old_password"
        label="Old Password"
        name="previousPassword"
        onChange={handlePasswordChange}
      />
      <Typography style={styles.input_description}>
        Password requirements: Must be at{' '}
        <span className={isShort ? classes.error : ''}>least 8 characters</span>{' '}
        and contain a{' '}
        <span className={isNotHaveUppercase ? classes.error : ''}>
          capital letter
        </span>
        ,{' '}
        <span className={isNotHaveLowercase ? classes.error : ''}>
          lowercase letter
        </span>
        , <span className={isNotHaveNumber ? classes.error : ''}>number</span>,
        and{' '}
        <span className={isNotHaveSpecialSymbol ? classes.error : ''}>
          special character of the following (!@#$%^&*).{' '}
        </span>
        <span className={passwordCompareError ? classes.error : ''}>
          Passwords must match
        </span>
      </Typography>
      <CssPasswordInput
        error={newPasswordValidationError || passwordCompareError}
        id="new_password"
        label="New Password"
        name="proposedPassword"
        onChange={handlePasswordChange}
        onBlur={validateNewPassword}
      />
      <CssPasswordInput
        error={passwordCompareError}
        id="confirm_new_password"
        label="Confirm New Password"
        name="confirmedPassword"
        onChange={handlePasswordChange}
        onBlur={comparePasswords}
      />
    </div>
  )
}
