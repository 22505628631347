import React from 'react'
import linkIcon from 'assets/icons/externalLink.svg'

const ExternalLinkButton = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{
      textDecoration: 'none',
      elevate: 5,
      position: 'relative',
      top: '0px',
      left: '20px',
      display: 'inline-block',
      backgroundColor: '#0052a5',
      borderRadius: '4px',
      padding: '5px',
      width: '40px',
      height: '40px',
    }}
  >
    <img
      src={linkIcon}
      alt="External link icon"
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '8px',
        width: '24px',
        height: '24px',
      }}
    />
  </a>
)

export default ExternalLinkButton
