import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const FLARES_GET = createAction('FLARES_GET', {
  [ACTIONS_SUBTYPES.START]: (id) => id,
  [ACTIONS_SUBTYPES.SUCCESS]: (flaresById) => flaresById,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const RECENT_FLARES_GET = createAction('RECENT_FLARES_GET', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (flaresById) => flaresById,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const ACTIVE_FLARES_GET = createAction('ACTIVE_FLARES_GET', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (flaresById) => flaresById,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const ACTIVE_FLARES_GET_GROUPED = createAction(
  'ACTIVE_FLARES_GET_GROUPED',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (flaresById) => flaresById,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const CLOSED_FLARES_GET = createAction('CLOSED_FLARES_GET', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (flaresById) => flaresById,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const FLARE_UPDATE = createAction(
  'FLARE_UPDATE',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
  true,
)

export const GET_FLARE = createAction('GET_FLARE', {
  [ACTIONS_SUBTYPES.START]: (id) => id,
  [ACTIONS_SUBTYPES.SUCCESS]: (flare) => flare,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const GET_FLARE_GROUP = createAction('GET_FLARE_GROUP', {
  [ACTIONS_SUBTYPES.START]: (id) => id,
  [ACTIONS_SUBTYPES.SUCCESS]: (group) => group,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const GET_FLARE_LOGS = createAction('GET_FLARE_LOGS', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const ACTIVE_FLARE_UPDATE = createAction(
  'ACTIVE_FLARE_UPDATE',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
  true,
)

export const GET_ACTIVE_FLARE_LOGS = createAction('GET_ACTIVE_FLARE_LOGS', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const ADD_ACTIVE_FLARE_NOTE = createAction('ADD_ACTIVE_FLARE_NOTE', {
  [ACTIONS_SUBTYPES.START]: (data) => data,
  [ACTIONS_SUBTYPES.SUCCESS]: (log) => log,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const CLOSED_FLARE_UPDATE = createAction(
  'CLOSED_FLARE_UPDATE',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
  true,
)

export const GET_CLOSED_FLARE_LOGS = createAction('GET_CLOSED_FLARE_LOGS', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const ADD_CLOSED_FLARE_NOTE = createAction('ADD_CLOSED_FLARE_NOTE', {
  [ACTIONS_SUBTYPES.START]: (data) => data,
  [ACTIONS_SUBTYPES.SUCCESS]: (log) => log,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const ADD_FLARE_NOTE = createAction('ADD_FLARE_NOTE', {
  [ACTIONS_SUBTYPES.START]: (data) => data,
  [ACTIONS_SUBTYPES.SUCCESS]: (log) => log,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const GET_FLARE_TYPES = createAction('GET_FLARE_TYPES', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (types) => types,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const ADD_FLARE_TYPE = createAction('ADD_FLARE_TYPE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (types) => types,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const UPDATE_STATION_SETTINGS = createAction('UPDATE_STATION_SETTINGS', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (types) => types,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const UPDATE_ACTIVE_FLARE_ADDITIONAL_FILTER = createAction(
  'UPDATE_ACTIVE_FLARE_ADDITIONAL_FILTER',
  {
    [ACTIONS_SUBTYPES.START]: (data) => data,
  },
)

export const UPDATE_CLOSED_FLARE_ADDITIONAL_FILTER = createAction(
  'UPDATE_CLOSED_FLARE_ADDITIONAL_FILTER',
  {
    [ACTIONS_SUBTYPES.START]: (data) => data,
  },
)

export const GET_FILTERED_FLARES = createAction('GET_FILTERED_FLARES', {
  [ACTIONS_SUBTYPES.START]: (filters) => filters,
  [ACTIONS_SUBTYPES.SUCCESS]: (flaresById) => flaresById,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const GET_FLARE_REPORT_SUMMARY = createAction(
  'GET_FLARE_REPORT_SUMMARY',
  {
    [ACTIONS_SUBTYPES.START]: () => true,
    [ACTIONS_SUBTYPES.SUCCESS]: (summary) => summary,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
  },
)

export const GET_FLARE_OPERATORS = createAction('GET_FLARE_OPERATORS', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (operators) => operators,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const GET_FLARE_USERS = createAction('GET_FLARE_USERS', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (users) => users,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const GET_FLARE_FROM_SOCKET_MESSAGE = createAction(
  'GET_FLARE_FROM_SOCKET_MESSAGE',
  {
    [ACTIONS_SUBTYPES.START]: (socketData) => socketData,
    [ACTIONS_SUBTYPES.SUCCESS]: (flare) => flare,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
  },
)

export const CREATE_FLARE_GROUP = createAction('CREATE_FLARE_GROUP', {
  [ACTIONS_SUBTYPES.START]: (data) => data,
  [ACTIONS_SUBTYPES.SUCCESS]: (log) => log,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const UPDATE_FLARES_IN_GROUP = createAction('UPDATE_FLARES_IN_GROUP', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (types) => types,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const UPDATE_ACTIVE_GROUP = createAction('UPDATE_ACTIVE_GROUP', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (types) => types,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const UPDATE_CLOSED_GROUP = createAction('UPDATE_CLOSED_GROUP', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (types) => types,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({ reason }),
})

export const ADD_FLARE_TO_SELECTION = createAction('ADD_FLARE_TO_SELECTION', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
})

export const REMOVE_FLARE_FROM_SELECTION = createAction(
  'REMOVE_FLARE_FROM_SELECTION',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
  },
)
