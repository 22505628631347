/* eslint-disable import/no-cycle */
import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import { Typography, Container } from '@material-ui/core'

import getParsedStatus from '../../../utils/getParsedStatus'
import { Origin } from '../../../constants'
import { formatDate } from '../../../utils/Date'

export const MapLabel = (props) => {
  const { flare } = props
  const parsedStatus = flare && getParsedStatus(flare.status, false)

  return flare ? (
    <div
      style={{
        padding: '0 8px',
        paddingBottom: 0,
        position: 'relative',
        overflowWrap: 'break-word',
      }}
    >
      <Container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Typography style={styles.markerLabel}>
          {flare.citizen?.name}
        </Typography>

        <Link
          to={`/dashboard/flares/${flare.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton style={{ padding: 0 }}>
            <OpenInNewIcon />
          </IconButton>
        </Link>
      </Container>
      <Typography style={styles.markerLabel}>
        {formatDate(flare.createdAt)}
      </Typography>

      {origin === Origin.WMH ? (
        <>
          <Typography style={styles.markerLabel}>{flare.address}</Typography>
          <Typography style={styles.markerLabel}>{flare.createdAt}</Typography>
          {/* <p style={styles.markerLabel}>
            {label.startDate}- {label.endDate}
          </p> */}
        </>
      ) : (
        <>
          <Typography style={styles.markerLabel}>{flare.type.name}</Typography>
          {Boolean(flare) && (
            <Typography style={styles.markerLabel}>
              {flare?.latitude}, {flare?.longitude}
            </Typography>
          )}
          <Typography
            style={styles.markerLabel}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            style={{ color: parsedStatus.color }}
          >
            {parsedStatus.type}
          </Typography>
        </>
      )}

      {props.isLastItem && <div style={styles.separator} />}
    </div>
  ) : null
}

const styles = {
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#000',
    marginTop: 8,
    marginBottom: 8,
  },
  clusterContainer: {
    height: '290px',
  },
  clusterLengthText: {
    fontSize: 11,
  },
  clusterMarkerOverlay: {
    opacity: 0,
  },
  clusterTextWrapper: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  markerLabel: {
    color: 'black',
    marginTop: 0,
    marginBottom: 0,
  },
}
