/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
import produce from 'immer'

import { flareActions } from 'utils/getFormDialogInfo'
import {
  FLARE_UPDATE,
  GET_FLARE,
  GET_FLARE_LOGS,
  ACTIVE_FLARES_GET,
  CLOSED_FLARES_GET,
  GET_FLARE_TYPES,
  GET_FLARE_OPERATORS,
  GET_FLARE_USERS,
  GET_FLARE_REPORT_SUMMARY,
  GET_FILTERED_FLARES,
  GET_ACTIVE_FLARE_LOGS,
  ACTIVE_FLARE_UPDATE,
  UPDATE_ACTIVE_FLARE_ADDITIONAL_FILTER,
  CLOSED_FLARE_UPDATE,
  GET_CLOSED_FLARE_LOGS,
  UPDATE_CLOSED_FLARE_ADDITIONAL_FILTER,
  GET_FLARE_FROM_SOCKET_MESSAGE,
  CREATE_FLARE_GROUP,
  UPDATE_FLARES_IN_GROUP,
  UPDATE_ACTIVE_GROUP,
  UPDATE_CLOSED_GROUP,
  ADD_FLARE_TO_SELECTION,
  REMOVE_FLARE_FROM_SELECTION,
  ACTIVE_FLARES_GET_GROUPED,
  GET_FLARE_GROUP,
} from './actions'
import { mapFlareData, mapFlare, mapGroup } from '../../utils/mapFlareData'
import { isFlareClosed } from '../../utils/isFlareClosed'
import {
  LogType,
  ProgressStage,
  initialFlareFilterState,
} from '../../constants'

const INITIAL_STATE = {
  closed: {
    flares: [],
    allIds: [],
    totalItems: null,
    page: 1,
    filters: initialFlareFilterState,
  },
  active: {
    flares: [],
    allIds: [],
    page: 1,
    totalItems: null,
    filters: initialFlareFilterState,
  },
  flares: [],
  groups: [],
  totalItems: null,
  flareTypes: [],
  flareTypesAll: [],
  flareOperators: [],
  flareUsers: [],
  flareReportSummary: {},
  flaresGroupsSelected: [],
  flaresSelected: [],
}

function addLogs(currentLogs, newLogs, isPagination) {
  if (isPagination) {
    return [...currentLogs, ...newLogs]
  }
  return newLogs
}

function flareReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_FILTERED_FLARES.SUCCESS.type: {
        const { flares, totalItems, isNextPage } = action.payload
        const mappedFlares = flares.map((flare) => mapFlare(flare))
        draft.totalItems = totalItems
        if (isNextPage) {
          draft.flares = [...draft.flares, ...mappedFlares]
        } else {
          draft.flares = mappedFlares
        }
        draft.totalItems = totalItems
        break
      }

      case GET_FLARE_REPORT_SUMMARY.SUCCESS.type: {
        draft.flareReportSummary = action.payload
        break
      }

      case GET_FLARE_OPERATORS.SUCCESS.type: {
        draft.flareOperators = action.payload.operators
        break
      }

      case GET_FLARE_USERS.SUCCESS.type: {
        draft.flareUsers = action.payload.citizens
        break
      }

      case ACTIVE_FLARES_GET_GROUPED.SUCCESS.type: {
        const { items, totalItems, isNextPage, page } = action.payload
        const mappedItems = items.map((item) => mapFlareData(item))
        if (isNextPage) {
          draft.active.flares = [...draft.active.flares, ...mappedItems]
        } else {
          draft.active.flares = mappedItems
        }
        draft.active.totalItems = totalItems
        draft.active.page = page
        break
      }

      case ACTIVE_FLARES_GET.SUCCESS.type: {
        const { flares, totalItems, isNextPage, page } = action.payload
        const mappedFlares = flares.map((flare) => mapFlareData(flare))
        if (isNextPage) {
          draft.active.flares = [...draft.active.flares, ...mappedFlares]
        } else {
          draft.active.flares = mappedFlares
        }
        draft.active.totalItems = totalItems
        draft.active.page = page
        break
      }

      case CLOSED_FLARES_GET.SUCCESS.type: {
        const { items, totalItems, isNextPage, page } = action.payload
        const mappedItems = items.map((item) => mapFlareData(item))
        if (isNextPage) {
          draft.closed.flares = [...draft.closed.flares, ...mappedItems]
        } else {
          draft.closed.flares = mappedItems
        }
        draft.closed.totalItems = totalItems
        draft.closed.page = page
        break
      }

      case GET_FLARE_FROM_SOCKET_MESSAGE.SUCCESS.type:
        const { flare, type } = action.payload
        const flareType = isFlareClosed(flare) ? 'closed' : 'active'
        if (type === LogType.CREATED) {
          draft[flareType].flares.unshift(mapFlareData(flare))
        } else {
          draft[flareType].flares = draft[flareType].flares.map((item) => {
            if (item.id === flare.id) {
              return {
                ...item,
                ...mapFlareData(flare),
              }
            }
            return item
          })
        }
        break

      case GET_FLARE_GROUP.SUCCESS.type:
        draft.groups = [{ group: mapGroup(action.payload) }]
        break

      case FLARE_UPDATE(null).SUCCESS.type:
        draft.flares = draft.flares.map((flare) => {
          if (flare.id === action.id) {
            return {
              ...flare,
              ...mapFlare(action.payload),
            }
          }
          return flare
        })
        break

      case GET_FLARE.SUCCESS.type:
        if (draft.flares.length === 0) {
          draft.flares = [mapFlare(action.payload)]
        } else {
          draft.flares = draft.flares.length
            ? draft.flares.map((flare) => {
                if (flare.id === action.payload.id) {
                  return {
                    ...flare,
                    ...mapFlare(action.payload),
                  }
                }
                return flare
              })
            : [mapFlare(action.payload)]
        }
        break

      case GET_FLARE_LOGS.SUCCESS.type:
        draft.flares = draft.flares.map((flare) => {
          if (flare.id === action.payload.flareId) {
            return {
              ...flare,
              logs: addLogs(
                flare.logs,
                action.payload.logs,
                action.payload.isPagination,
              ),
              logsTotal: action.payload.totalItems,
            }
          }
          return flare
        })
        break

      case ACTIVE_FLARE_UPDATE(null).SUCCESS.type:
        {
          const { updatedFlare } = action.payload
          const flare = mapFlare(updatedFlare.flare)

          if (flare.isClosed || flare.status === ProgressStage.COMPLETED) {
            // Remove flare from ACTIVE
            draft.active.flares = draft.active.flares.filter(
              (item) => item.flare?.id !== flare.id,
            )
          } else {
            draft.active.flares = draft.active.flares.map((item) => {
              if (item.flare) {
                if (item.flare?.id === flare.id) {
                  return {
                    flare: {
                      logs: item.flare?.logs ?? [],
                      ...flare,
                    },
                  }
                }
              }
              return item
            })
          }
        }
        break

      case GET_ACTIVE_FLARE_LOGS.SUCCESS.type: {
        const {
          flareId,
          logs,
          totalItems,
          groupId = null,
          isSingleGroup = false,
          isPagination = false,
        } = action.payload

        const currentFlaresList = isSingleGroup
          ? draft.groups
          : draft.active.flares

        const newFlaresList = currentFlaresList.map((item) => {
          if (item.group) {
            if (item.group.id === groupId) {
              const flaresInGroup = item.group.flares.map((flareInGroup) => {
                if (flareInGroup.id === flareId) {
                  return {
                    ...flareInGroup,
                    logs: addLogs(flareInGroup.logs, logs, isPagination),
                    logsTotal: totalItems,
                  }
                }
                return flareInGroup
              })
              return {
                group: {
                  ...item.group,
                  flares: flaresInGroup,
                },
              }
            }
          }
          if (item.flare?.id === flareId) {
            return {
              flare: {
                ...item.flare,
                logs: addLogs(item.flare.logs, logs, isPagination),
                logsTotal: totalItems,
              },
            }
          }

          return item
        })

        if (isSingleGroup) {
          draft.groups = newFlaresList
        } else {
          draft.active.flares = newFlaresList
        }

        break
      }

      case CLOSED_FLARE_UPDATE(null).SUCCESS.type:
        {
          const { updatedFlare } = action.payload
          const flare = mapFlare(updatedFlare.flare)

          if (!flare.isClosed) {
            // Remove flare from CLOSED
            draft.closed.flares = draft.closed.flares.filter(
              (item) => item.flare?.id !== flare.id,
            )
          } else {
            draft.closed.flares = draft.closed.flares.map((item) => {
              if (item.flare?.id === flare.id) {
                return {
                  flare: {
                    logs: item.flare.logs ?? [],
                    ...flare,
                  },
                }
              }
              return item
            })
          }
        }
        break

      case GET_CLOSED_FLARE_LOGS.SUCCESS.type: {
        const {
          flareId,
          logs,
          totalItems,
          groupId = null,
          isPagination = false,
        } = action.payload

        draft.closed.flares = draft.closed.flares.map((item) => {
          if (item.group) {
            if (item.group.id === groupId) {
              const flaresInGroup = item.group.flares.map((flareInGroup) => {
                if (flareInGroup.id === flareId) {
                  return {
                    ...flareInGroup,
                    logs: addLogs(flareInGroup.logs, logs, isPagination),
                    logsTotal: totalItems,
                  }
                }
                return flareInGroup
              })
              return {
                group: {
                  ...item.group,
                  flares: flaresInGroup,
                },
              }
            }
          }
          if (item.flare?.id === flareId) {
            return {
              flare: {
                ...item.flare,
                logs: addLogs(item.flare.logs, logs, isPagination),
                logsTotal: totalItems,
              },
            }
          }
          return item // group or flare
        })
        break
      }

      case GET_FLARE_TYPES.SUCCESS.type:
        draft.flareTypes = action.payload.types
        break
      case UPDATE_ACTIVE_FLARE_ADDITIONAL_FILTER.START.type: {
        if (!action.payload.flareTypes.length) {
          draft.active.flares = []
        }
        draft.active.filters.types = action.payload.flareTypes
        break
      }

      case UPDATE_CLOSED_FLARE_ADDITIONAL_FILTER.START.type: {
        draft.closed.filters.types = action.payload.flareTypes
        break
      }
      case UPDATE_FLARES_IN_GROUP.SUCCESS.type: {
        // flares: flares of the current group (even if increase or decrease the flares)
        const {
          flares,
          groupId,
          response,
          groupIdsDeleted = [],
          isSingleGroup = false,
        } = action.payload

        const currentFlaresList = isSingleGroup
          ? draft.groups
          : draft.active.flares
        let newFlareList = []

        const flareIds = flares.map((flare) => flare.id)
        const groupFormat = mapFlareData(response)

        if (groupFormat.group.flares.length === 0) {
          // delete group from main list
          newFlareList = currentFlaresList.filter(
            (item) => item.group?.id !== groupId,
          )
        } else {
          // Add flares belong to current group
          newFlareList = currentFlaresList.map((item) => {
            if (item.group?.id === groupId) {
              return {
                group: {
                  ...groupFormat.group,
                  flares,
                },
              }
            }
            return item
          })
        }

        // Deleted groups, to add to another main group
        if (groupIdsDeleted.length > 0) {
          newFlareList = newFlareList.filter(
            (item) => !groupIdsDeleted.includes(item.group?.id),
          )
        }

        // Add to main list the flares don't belong to group
        newFlareList = newFlareList.filter(
          (item) => !flareIds.includes(item.flare?.id),
        )

        if (isSingleGroup) {
          draft.groups = newFlareList
        } else {
          draft.active.flares = newFlareList.sort(
            (a, b) =>
              new Date(b.group?.createdAt || b.flare?.createdAt) -
              new Date(a.group?.createdAt || a.flare?.createdAt),
          )
        }
        break
      }
      case UPDATE_ACTIVE_GROUP.SUCCESS.type: {
        const {
          flareData,
          groupId,
          flareAction,
          isSingleGroup = false,
        } = action.payload

        const currentFlaresList = isSingleGroup
          ? draft.groups
          : draft.active.flares
        let newFlareList = []

        if (isSingleGroup) {
          newFlareList = currentFlaresList.map((item) => {
            if (item.group) {
              if (item.group.id === groupId) {
                item.group = {
                  ...item.group,
                  flares: item.group.flares.map((flare) => ({
                    ...flare,
                    isClosed: flareData.status === ProgressStage.CLOSED,
                  })),
                  status: flareData.status,
                }
              }
            }
            return item
          })
        } else if (flareAction === flareActions.MOVE_TO_HISTORY) {
          newFlareList = currentFlaresList.filter((item) => {
            if (item.group) {
              return item.group.id !== groupId
            }
            return item
          })
        } else {
          // CHANGE STATUS
          newFlareList = currentFlaresList.map((item) => {
            if (item.group) {
              if (item.group.id === groupId) {
                item.group = {
                  ...item.group,
                  status: flareData.status,
                }
              }
            }
            return item
          })
        }

        if (isSingleGroup) {
          draft.groups = newFlareList
        } else {
          draft.active.flares = newFlareList
        }

        break
      }
      case UPDATE_CLOSED_GROUP.SUCCESS.type: {
        const { groupId, flareAction } = action.payload

        if (flareAction === flareActions.MAKE_ACTIVE) {
          // remove group of closed list
          draft.closed.flares = draft.closed.flares.filter((item) => {
            if (item.group) {
              return item.group.id !== groupId
            }
            return item
          })
        }
        break
      }
      case CREATE_FLARE_GROUP.SUCCESS.type: {
        break
      }

      case ADD_FLARE_TO_SELECTION.START.type: {
        if (action.payload === undefined) {
          draft.active.flares = draft.active.flares.map((item) => {
            if (item.group) {
              item.group.isSelected = true
              draft.flaresGroupsSelected = [
                ...draft.flaresGroupsSelected,
                item.group,
              ]
            } else {
              item.flare.isSelected = true
              draft.flaresSelected = [...draft.flaresSelected, item.flare]
            }
            return item
          })
        } else if (action.payload.flares) {
          // group flare
          if (draft.flaresGroupsSelected) {
            draft.flaresGroupsSelected = [
              ...draft.flaresGroupsSelected,
              action.payload,
            ]
          } else {
            draft.flaresGroupsSelected = [action.payload]
          }

          draft.active.flares = draft.active.flares.map((item) => {
            if (item.group) {
              if (item.group.id === action.payload.id) {
                item.group.isSelected = true
              }
            }
            return item
          })
        } else {
          // singe flare
          draft.flaresSelected = draft.flaresSelected
            ? [...draft.flaresSelected, action.payload]
            : [action.payload]

          draft.active.flares = draft.active.flares.map((item) => {
            if (item.flare) {
              if (item.flare?.id === action.payload.id) {
                item.flare.isSelected = true
              }
            }
            return item
          })
        }
        break
      }

      case REMOVE_FLARE_FROM_SELECTION.START.type: {
        if (action.payload === undefined) {
          // deselect all
          draft.flaresGroupsSelected = []
          draft.flaresSelected = []
          draft.active.flares = draft.active.flares.map((item) => {
            if (item.group) {
              item.group.isSelected = false
            } else {
              item.flare.isSelected = false
            }
            return item
          })
        } else if (action.payload.flares) {
          // group flare
          draft.flaresGroupsSelected = draft.flaresGroupsSelected?.filter(
            (group) => group.id !== action.payload.id,
          )

          draft.active.flares = draft.active.flares.map((item) => {
            if (item.group) {
              if (item.group.id === action.payload.id) {
                item.group.isSelected = false
              }
            }
            return item
          })
        } else {
          // singe flare
          draft.flaresSelected = draft.flaresSelected.filter(
            (flare) => flare.id !== action.payload.id,
          )

          draft.active.flares = draft.active.flares.map((item) => {
            if (item.flare) {
              if (item.flare.id === action.payload.id) {
                item.flare.isSelected = false
              }
            }
            return item
          })
        }
        break
      }

      default:
        break
    }
  })
}

export default flareReducer
