import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export default function ImageFocus(props) {
  return (
    <>
      {props.open && (
        <Lightbox
          style={{ zIndex: 999 }}
          mainSrc={props.image}
          // imageTitle={props.type + " " + props.time}
          // imageCaption={"Address: " + props.address}
          imageTitle={props.title}
          imageCaption={props.caption}
          onCloseRequest={() => props.close()}
        />
      )}
    </>
  )
}
