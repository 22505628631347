/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import Autocomplete from 'react-google-autocomplete'
import useSupercluster from 'use-supercluster'

import { Typography } from '@material-ui/core'

// Image imports
import pinMarker from 'assets/icons/pinMarker.svg'
import clusterMarker from 'assets/icons/clusterMarker.svg'
import flareMarker from 'assets/icons/flareMarker.svg'
import googleClusterMarker from 'assets/icons/googleCluster.png'
import flareMarkerGroup from 'assets/icons/flareMarkerGroup.svg'
import flareMarkerGroupOverlapping from 'assets/icons/flareMarkerGroupOverlapping.svg'
import {
  LabelListContainer,
  LabelGroupListContainer,
  LabelGroupOverlappingListContainer,
} from './components/LabelListContainer'
import { findCenter } from '../../utils'

const Marker = ({ children }) => children

export function GoogleMap(props) {
  const mapRef = useRef()

  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)
  const [idMarkerLabel, setMarkerLabel] = useState(undefined)
  const [clusterId, setClusterId] = useState(undefined)
  const [popupPosition, setPopupPosition] = useState({})

  let singleFlareMarkers = []
  let groupFlareMarkers = []

  useEffect(() => {
    if (
      !props.reportPage &&
      !props.flareMarkers.length &&
      props.isFlareOptionAvailable
    ) {
      props.getActiveFlares({})
    }
  }, [props.flareMarkers.length])

  const handleApiLoaded = (map, maps) => {
    mapRef.current = map
    const triangleCoords = props.geofence

    const bermudaTriangle = new maps.Polygon({
      paths: triangleCoords,
      strokeColor: '#0080ff',
      fillColor: '#0080ff',
      strokeOpacity: 0.3,
      strokeWeight: 2,
      fillOpacity: 0.1,
      cursor: 'grab',
    })
    bermudaTriangle.setMap(map)
  }

  const flareItems = props.flareMarkers.map((flareOrGroup) => {
    if (flareOrGroup.group) {
      const { lat, lng } = findCenter(
        flareOrGroup.group.flares.map((flare) => ({
          lat: flare.latitude,
          lng: flare.longitude,
        })),
      )
      const groupModified = {
        group: {
          ...flareOrGroup.group,
          latitude: lat,
          longitude: lng,
        },
      }
      groupFlareMarkers = [...groupFlareMarkers, groupModified.group]
      return groupModified
    }
    singleFlareMarkers = [...singleFlareMarkers, flareOrGroup.flare]
    return flareOrGroup
  })

  const points = flareItems.map((flareOrGroup) => {
    if (flareOrGroup.group) {
      return {
        type: 'Feature',
        properties: {
          cluster: false,
          id: flareOrGroup.group.id,
          category: 'group',
        },
        geometry: {
          type: 'Point',
          coordinates: [
            flareOrGroup.group.longitude,
            flareOrGroup.group.latitude,
          ],
        },
      }
    }
    return {
      type: 'Feature',
      properties: {
        cluster: false,
        id: flareOrGroup.flare.id,
        category: 'flare',
      },
      geometry: {
        type: 'Point',
        coordinates: [
          flareOrGroup.flare.longitude,
          flareOrGroup.flare.latitude,
        ],
      },
    }
  })

  const checkPosition = (e, isSingle) => {
    const position = {}
    const headerHeight = isSingle ? 54 : 80
    const elemHeight = 290
    const elemWidth = 230
    const elemSingleHeight = 233
    const y = e.clientY - headerHeight
    const x = e.clientX
    if (x < elemWidth) {
      position.right = true
    } else {
      position.left = true
    }
    if (y < (isSingle ? elemSingleHeight : elemHeight)) {
      position.bottom = true
    } else {
      position.top = true
    }
    setPopupPosition(position)
  }

  const getGroupById = (id) =>
    groupFlareMarkers.find((group) => group.id === id)

  const getFlareById = (id) =>
    singleFlareMarkers.find((flare) => flare.id === id)

  const separateByIdSingleFlareAndFlareGroup = (ids) => {
    const flareIds = ids.filter((id) => getFlareById(id))
    const groupIds = ids.filter((id) => getGroupById(id))
    const flares = flareIds.map((flareId) => getFlareById(flareId))
    const groups = groupIds.map((groupId) => getGroupById(groupId))
    return { flares, groups }
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 90,
      maxZoom: 25,

      reduce: (acc, point) => {
        // eslint-disable-next-line eqeqeq
        if (acc.ids == undefined) {
          acc.ids = [point.id]
          return acc
        }
        if (point.ids) {
          const flares = [...acc.ids, ...point.ids, point.id]
          acc.ids = [...flares]
          return acc
        }
        const flares = [...acc.ids, point.id]
        acc.ids = [...flares]
        return acc
      },
    },
  })

  const handleAutocompleteOnChange = (ev) => {
    props.setAddressState(ev.target.value)
  }

  const handleCloseLabelList = () => {
    setMarkerLabel(undefined)
  }
  const isBigWindow = window.innerWidth > 768

  return (
    <>
      {isBigWindow && !props.reportPage ? (
        <Autocomplete
          style={styles.autocomplete}
          placeholder="Search Address"
          onPlaceSelected={props.onPlaceSelected}
          value={props.address}
          onChange={handleAutocompleteOnChange}
          onFocus={props.clearAddress}
          types={AUTOCOMPLETE_TYPES}
        />
      ) : null}
      <GoogleMapReact
        {...GOOGLE_MAP_DEFAULT_PROPS}
        zoom={props.zoom}
        center={props.center}
        defaultCenter={props.center}
        onDragEnd={(map) => {
          props.clearAddress()
          props.updateCenter({
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          })
        }}
        yesIWantToUseGoogleMapApiInternals // this is important!
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        style={{
          height: '100%',
          width: '100%',
        }}
        onChange={(e) => {
          setZoom(e.zoom)
          setBounds([
            e.bounds.nw.lng,
            e.bounds.se.lat,
            e.bounds.se.lng,
            e.bounds.nw.lat,
          ])
        }}
        onClick={() => {
          if (idMarkerLabel) setMarkerLabel(undefined)
        }}
        options={{
          scrollwheel: !idMarkerLabel,
          zoomControl: isBigWindow,
          fullscreenControl: !props.reportPage,
        }}
      >
        {/* --------- Pin --------- */}
        {props.activeSearch && (
          <Marker key="pin" lat={props.center.lat} lng={props.center.lng}>
            <div
              style={{
                ...markerStyle,
                backgroundImage: `url(${pinMarker})`,
                width: `21.34px`,
                height: `31.5px`,
                marginTop: '-31.5px',
                marginLeft: '-10.7px',
                cursor: 'pointer',
                WebkitPrintColorAdjust: 'exact',
              }}
            />
          </Marker>
        )}

        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pointCount,
            id,
            ids = [],
          } = cluster.properties

          const { flares, groups } = separateByIdSingleFlareAndFlareGroup([
            id,
            ...ids,
          ])

          if (zoom === 22 || !isCluster) {
            const collectionMarkers = []

            if (flares.length > 0) {
              if (flares.length === 1) {
                // --------- single flare ---------

                const flare = flares[0]

                collectionMarkers.push(
                  <Marker
                    key={`cluster-${flare.id}`}
                    lat={flare.latitude}
                    lng={flare.longitude}
                  >
                    <div
                      style={{
                        ...markerStyle,
                        backgroundImage: `url(${flareMarker})`,
                        width: `21.34px`,
                        height: `31.5px`,
                        marginTop: '-31.5px',
                        marginLeft: '-10.7px',
                        cursor: 'pointer',
                        WebkitPrintColorAdjust: 'exact',
                      }}
                      onClick={(e) => {
                        checkPosition(e, true)
                        setMarkerLabel([flare.id])
                        setClusterId(flare.id)
                      }}
                    >
                      {idMarkerLabel && clusterId === flare.id ? (
                        <LabelListContainer
                          isSingleElement
                          popupPosition={popupPosition}
                          flares={[flare]}
                          onClose={handleCloseLabelList}
                        />
                      ) : null}
                    </div>
                  </Marker>,
                )
              } else {
                // --------- multiples single flares ---------

                collectionMarkers.push(
                  <Marker
                    key={`iscluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    {idMarkerLabel &&
                    clusterId === cluster.id &&
                    flares.length ? (
                      <LabelListContainer
                        popupPosition={popupPosition}
                        flares={flares}
                        onClose={handleCloseLabelList}
                      />
                    ) : null}
                    <div
                      style={{
                        ...markerStyle,
                        backgroundImage: `url(${clusterMarker})`,
                        width: `27px`,
                        height: `37px`,
                        marginTop: '-37px',
                        marginLeft: '-13.5px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        checkPosition(e)
                        setMarkerLabel(flares)
                        setClusterId(cluster.id)
                      }}
                    >
                      <Typography
                        style={{
                          paddingBottom: '10px',
                          fontSize: 12,
                          color: '#ffffff',
                        }}
                      >
                        {flares.length}
                      </Typography>
                    </div>
                  </Marker>,
                )
              }
            }

            // groups
            if (groups.length > 0) {
              if (groups.length === 1) {
                // --------- group ---------

                const group = groups[0]

                collectionMarkers.push(
                  <Marker
                    key={`isGroup-${group.id}`}
                    lat={group.latitude}
                    lng={group.longitude}
                  >
                    {idMarkerLabel &&
                    clusterId === group.id &&
                    group.flares.length ? (
                      <LabelGroupListContainer
                        popupPosition={popupPosition}
                        group={group}
                        onClose={handleCloseLabelList}
                      />
                    ) : null}
                    <div
                      style={{
                        ...markerStyle,
                        backgroundImage: `url(${flareMarkerGroup})`,
                        width: `45px`,
                        height: `45px`,
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        checkPosition(e)
                        setMarkerLabel(group.flares)
                        setClusterId(group.id)
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: 14,
                          color: '#ffffff',
                        }}
                      >
                        1 / {group.flares.length}
                      </Typography>
                    </div>
                  </Marker>,
                )
              } else {
                // --------- overlapping ---------

                let totalFlaresInOverlappingGroups = 0
                const masterGroup = groups[0]

                const collectionTotalFlares = groups.map(
                  (group) => group.flares.length,
                )
                totalFlaresInOverlappingGroups = collectionTotalFlares.reduce(
                  (a, b) => a + b,
                  0,
                )

                const overlapping = {
                  id: masterGroup.id,
                  groups,
                  totalFlares: totalFlaresInOverlappingGroups,
                  coordinates: {
                    latitude: masterGroup.latitude,
                    longitude: masterGroup.longitude,
                  },
                }

                collectionMarkers.push(
                  <Marker
                    key={`isOverlappingGroup-${overlapping.id}`}
                    lat={overlapping.coordinates.latitude}
                    lng={overlapping.coordinates.longitude}
                  >
                    {idMarkerLabel &&
                    clusterId === overlapping.id &&
                    overlapping.groups.length ? (
                      <LabelGroupOverlappingListContainer
                        popupPosition={popupPosition}
                        overlapping={overlapping}
                        onClose={handleCloseLabelList}
                      />
                    ) : null}
                    <div
                      style={{
                        ...markerStyle,
                        backgroundImage: `url(${flareMarkerGroupOverlapping})`,
                        width: `45px`,
                        height: `45px`,
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        checkPosition(e)
                        setMarkerLabel(overlapping)
                        setClusterId(overlapping.id)
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: 14,
                          color: '#ffffff',
                        }}
                      >
                        {overlapping.groups.length} / {overlapping.totalFlares}
                      </Typography>
                    </div>
                  </Marker>,
                )
              }
            }

            return collectionMarkers
          }
          // --------- cluster ---------

          return (
            <Marker
              key={`iscluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}
            >
              <div
                style={{
                  ...markerStyle,
                  WebkitPrintColorAdjust: 'exact',
                  backgroundImage: `url(${googleClusterMarker})`,
                  width: `52.98px`,
                  height: `50px`,
                  marginTop: '-25px',
                  marginLeft: '-26.5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    22,
                  )

                  mapRef.current.setZoom(expansionZoom)
                  mapRef.current.panTo({ lat: latitude, lng: longitude })
                }}
              >
                <Typography
                  style={{
                    paddingTop: '4px',
                    fontSize: 12,
                    color: '#ffffff',
                  }}
                >
                  {pointCount}
                </Typography>
              </div>
            </Marker>
          )
        })}
      </GoogleMapReact>
    </>
  )
}

const GOOGLE_MAP_DEFAULT_PROPS = {
  minZoom: 4,
  defaultZoom: 14,
  bootstrapURLKeys: {
    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['places'],
  },
}

const AUTOCOMPLETE_TYPES = ['address']

const markerStyle = {
  backgroundColor: 'transparent',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
}

const styles = {
  autocomplete: {
    position: 'absolute',
    minWidth: '20vw',
    width: '30vw',
    height: '2.5rem',
    paddingLeft: '1rem',
    marginTop: '.125rem',
    top: '4.5rem',
    left: '.6rem',
    borderRadius: '3px',
    border: 'none',
    boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.15)',
    zIndex: 100,
  },
}
