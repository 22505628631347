import { useSelector } from 'react-redux'
import {
  isFailedSelector,
  isLoadingSelector,
  isSuccessSelector,
  reasonFailedSelector,
} from '../modules/requestsStatuses/selectors'

export const useStatusesSelector = (type) => {
  const isSuccess = useSelector((state) => isSuccessSelector(state, type))
  const isLoading = useSelector((state) => isLoadingSelector(state, type))
  const isFailed = useSelector((state) => isFailedSelector(state, type))
  const reasonFailed = useSelector((state) => reasonFailedSelector(state, type))

  return [isSuccess, isLoading, isFailed, reasonFailed]
}
