import { all, takeLatest } from 'redux-saga/effects'

import getAlertsSaga from './getAlertsSaga'
import addAlertLog from './addAlertLogSaga'
import createAlertSaga from './createAlertSaga'
import addStationAlertTypeSaga from './addStationAlertTypeSaga'
import getStationAlertTypesSaga from './getStationAlertTypesSaga'
import getAlertLogsSaga from './getAlertLogsSaga'
import getAlertTypesSaga from './getAlertTypesSaga'
import getAlertsNextPageSaga from './getAlertsNextPageSaga'
import getClosedAlertsSaga from './getClosedAlertsSaga'
import getClosedAlertsNextPageSaga from './getClosedAlertsNextPage'
import updateAlertSaga from './updateAlertSaga'

import {
  ALERTS_ADD_LOG,
  ALERTS_CREATE,
  ADD_STATION_ALERT_TYPE,
  GET_ACTIVE_ALERTS,
  GET_ALERT_LOGS,
  GET_ALERT_TYPES,
  GET_ACTIVE_ALERTS_NEXT_PAGE,
  GET_STATION_ALERT_TYPES,
  GET_CLOSED_ALERTS,
  GET_CLOSED_ALERTS_NEXT_PAGE,
  UPDATE_ALERT,
  UPDATE_ALERT_TYPE,
  CHECK_ALERT_TYPE_IS_ACTIVE,
} from '../actions'
import updateAlertTypeSaga from './updateAlertTypeSaga'
import checkAlertTypeIsActiveSaga from './checkAlertTypeIsActiveSaga'

function* rootAlertsSaga() {
  yield all([
    takeLatest(ALERTS_ADD_LOG.START.type, addAlertLog),
    takeLatest(ALERTS_CREATE.START.type, createAlertSaga),
    takeLatest(GET_ACTIVE_ALERTS.START.type, getAlertsSaga),
    takeLatest(GET_ALERT_LOGS.START.type, getAlertLogsSaga),
    takeLatest(GET_ALERT_TYPES.START.type, getAlertTypesSaga),
    takeLatest(GET_ACTIVE_ALERTS_NEXT_PAGE.START.type, getAlertsNextPageSaga),
    takeLatest(GET_STATION_ALERT_TYPES.START.type, getStationAlertTypesSaga),
    takeLatest(GET_CLOSED_ALERTS.START.type, getClosedAlertsSaga),
    takeLatest(
      GET_CLOSED_ALERTS_NEXT_PAGE.START.type,
      getClosedAlertsNextPageSaga,
    ),
    takeLatest(UPDATE_ALERT.START.type, updateAlertSaga),
    takeLatest(GET_STATION_ALERT_TYPES.START.type, getStationAlertTypesSaga),
    takeLatest(ADD_STATION_ALERT_TYPE.START.type, addStationAlertTypeSaga),
    takeLatest(UPDATE_ALERT_TYPE.START.type, updateAlertTypeSaga),
    takeLatest(
      CHECK_ALERT_TYPE_IS_ACTIVE.START.type,
      checkAlertTypeIsActiveSaga,
    ),
  ])
}

export default rootAlertsSaga
