import { Auth } from 'aws-amplify'

import { parseError } from '../../utils/parseError'

export default ({ user, attributeName, code }) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }

    try {
      result.response = await Auth.verifyUserAttributeSubmit(
        user,
        attributeName,
        code,
      )
    } catch (error) {
      result.error = parseError(error)
    }

    resolve(result)
  })
