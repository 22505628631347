import produce from 'immer'
import lodashMerge from 'lodash/merge'
import { ADD_LICENSE, GET_GEOFENCE, GET_LICENSES } from './actions'

const INITIAL_STATE = {
  station: {},
  licenses: null,
  totalActiveAccounts: 0,
}

function geofenceReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_GEOFENCE.SUCCESS.type:
        {
          const parsedGeofence = { ...action.payload }
          parsedGeofence.geofence = parsedGeofence.geofence[0].reduce(
            (acc, point) => [...acc, { lng: point[0], lat: point[1] }],
            [],
          )
          lodashMerge(draft.station, parsedGeofence)
        }

        break
      case GET_LICENSES.SUCCESS.type:
        lodashMerge(draft, action.payload)
        break
      case ADD_LICENSE.SUCCESS.type:
      default:
        return draft
    }
  })
}

export default geofenceReducer
