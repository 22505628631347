/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Menu, MenuItem } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/styles'
import ConfirmDialog from 'components/ConfirmDialog'
import {
  isLoadingSelector,
  isSuccessSelector,
} from '../../../modules/requestsStatuses/selectors'
import {
  USERS_ACTIVATE,
  USERS_DEACTIVATE,
  USERS_DELETE,
  RESET_PASSWORD,
  STATION_USER_UPDATE,
} from '../../../modules/users/actions'
import { getUserSelector } from '../../../modules/users/selectors'
import { AddUser } from '../../ManageUsers'

import { rootUserSelector } from '../../../modules/user/selectors'

const styles = {
  // MUI Styles
  resourceContainer: {
    marginTop: '1rem',
  },
  resourceCard: {
    width: '97%',
  },
  column: {
    flexBasis: '50%',
  },
  editColumn: {
    flexBasis: '25%',
    paddingTop: '.1rem',
  },
  heading: {
    fontSize: '1rem',
  },
  disabledUser: {
    color: '#989898',
  },
  details: {
    alignItems: 'center',
  },
  helper: {
    borderLeft: '2px solid #989898',
    padding: '2rem',
  },
  editContainer: {
    width: '97%',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '.8rem',
    paddingBottom: '.6rem',
  },
  editContent: {
    flexBasis: '32%',
  },
  button: {
    '&:hover': {
      backgroundColor: 'default',
    },
  },
}

export const UserItem = React.memo(
  ({
    classes,
    user,
    currentUser,
    deleteUser,
    isEditSuccess,
    isEditLoading,
    editStationUser,
    activateUser,
    deactivateUser,
    resetPassword,
    isResetPasswordSuccess,
  }) => {
    const ITEM_HEIGHT = 55
    const isUserEnabled = user.isEnabled
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmActivation, setConfirmActivation] = useState(false)
    const [confirmDeactivation, setConfirmDeactivation] = useState(false)
    const [confirmResetPassword, setConfirmResetPassword] = useState(false)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const openDeleteMenu = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const closeDeleteMenu = () => {
      setAnchorEl(null)
    }

    useEffect(() => {
      if (isEditSuccess || isResetPasswordSuccess) {
        setIsEditDialogOpen(false)
      }
    }, [isEditSuccess, isResetPasswordSuccess])

    const toggleEditDialog = () => setIsEditDialogOpen(!isEditDialogOpen)

    const handleDeleteUser = (isConfirmed) => {
      if (isConfirmed) {
        deleteUser()
      }
      closeDeleteMenu()
      setConfirmDelete(!confirmDelete)
    }

    const handleUserActivate = () => {
      closeDeleteMenu()
      setConfirmActivation(!confirmActivation)
    }

    const handleUserActivateConfirm = (isConfirmed) => {
      if (isConfirmed) {
        activateUser(user.id)
      }
      setConfirmActivation(!confirmActivation)
    }

    const handleUserDeactivate = () => {
      closeDeleteMenu()
      setConfirmDeactivation(!confirmDeactivation)
    }

    const handleUserDeactivateConfirm = (isConfirmed) => {
      if (isConfirmed) {
        deactivateUser(user.id)
      }
      setConfirmDeactivation(!confirmDeactivation)
    }

    const handleResetPasswordConfirm = (isConfirmed) => {
      if (isConfirmed) {
        resetPassword(user.email)
      }
      setConfirmResetPassword(!confirmResetPassword)
    }

    const handleEditStationUser = (userData) => {
      editStationUser({
        userData: {
          ...userData,
          prevEmail: user.email,
        },
      })
    }

    const displayedUsername =
      user.username === undefined || user.username === null
        ? user.email
        : user.username

    return (
      <Paper className={classes.editContainer} style={{ minWidth: '425px' }}>
        <Grid container>
          <Grid
            container
            style={{ flex: 1 }}
            className={!isUserEnabled && classes.disabledUser}
          >
            <Grid item className={classes.editColumn}>
              <Typography className={classes.heading} variant="inherit">
                {displayedUsername}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.editColumn}
              style={{ textAlign: 'center' }}
            >
              <Typography variant="inherit">
                {user.givenName} {user.familyName}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.editColumn}
              style={{ textAlign: 'center' }}
            >
              <Typography variant="inherit">{user.phoneNumber}</Typography>
            </Grid>
            <Grid
              item
              className={classes.editColumn}
              style={{ textAlign: 'center' }}
            >
              <Typography variant="inherit">{user.email}</Typography>
            </Grid>
          </Grid>
          <Grid container style={{ width: '4.5rem' }}>
            <div style={{ flex: 1 }} />
            {currentUser.role === 'Admin' && (
              <Grid item>
                <IconButton
                  size="small"
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    paddingLeft: 'auto',
                  }}
                  onClick={toggleEditDialog}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <IconButton
                size="small"
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  paddingLeft: 'auto',
                }}
                onClick={openDeleteMenu}
              >
                <DeleteIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={closeDeleteMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 2.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem onClick={() => setConfirmDelete(true)}>
                  <Typography variant="body2">Delete</Typography>
                </MenuItem>
                {isUserEnabled ? (
                  <MenuItem onClick={handleUserDeactivate}>
                    <Typography variant="body2">Deactivate</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={handleUserActivate}>
                    <Typography variant="body2">Activate</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        {isEditDialogOpen && (
          <AddUser
            isEditLoading={isEditLoading}
            open={isEditDialogOpen}
            isEditStationUser
            onUserEdit={handleEditStationUser}
            user={user}
            isEditMode
            close={toggleEditDialog}
            setConfirmResetPassword={setConfirmResetPassword}
          />
        )}
        <ConfirmDialog
          username={displayedUsername}
          title="Delete"
          detail="This action cannot be undone"
          open={confirmDelete}
          close={handleDeleteUser}
        />
        <ConfirmDialog
          username={displayedUsername}
          title="Activate"
          open={confirmActivation}
          close={handleUserActivateConfirm}
        />
        <ConfirmDialog
          username={displayedUsername}
          title="Deactivate"
          open={confirmDeactivation}
          close={handleUserDeactivateConfirm}
        />
        <ConfirmDialog
          username={displayedUsername}
          title="Reset password"
          open={confirmResetPassword}
          close={handleResetPasswordConfirm}
        />
      </Paper>
    )
  },
)

const UserItemWithStyles = withStyles(styles)(UserItem)

function mapStateToProps(state, props) {
  return {
    user: getUserSelector(state, props.id),
    currentUser: rootUserSelector(state),
    isLoading: isLoadingSelector(state, USERS_DELETE),
    isEditLoading: isLoadingSelector(state, STATION_USER_UPDATE),
    isEditSuccess: isSuccessSelector(state, STATION_USER_UPDATE),
    isResetPasswordSuccess: isSuccessSelector(state, RESET_PASSWORD),
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      deleteUser: USERS_DELETE(props.id).START.create,
      activateUser: USERS_ACTIVATE.START.create,
      deactivateUser: USERS_DEACTIVATE.START.create,
      editStationUser: STATION_USER_UPDATE.START.create,
      resetPassword: RESET_PASSWORD.START.create,
    },
    dispatch,
  )
}

const UserItemWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserItemWithStyles)

export const UserOutput = UserItemWithRedux
