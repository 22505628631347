import { createTheme } from '@material-ui/core'
import 'fontsource-roboto'

export default function configTheme() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0052a5',
      },
      secondary: {
        main: '#f44336',
      },
      grey: {
        light: '#DDDDDD',
        main: '#B8B8B8',
        dark: '#555555',
      },
      blue: {
        light: '#5D9DF4',
        main: '#036CAD',
        dark: '#0052A5',
      },
      white: '#fff',
      red: '#F44336',
      new: '#7BBDCA',
      received: '#036CAD',
      reviewing: '#FFAB2B',
      inProgress: '#FF7F22',
      complete: '#009600',
      finish: '#F44336',
    },
    typography: {
      fontFamily: ['Open Sans', 'Roboto'].join(','),
      body1: {
        color: '#555555',
      },
      body2: {
        color: '#000000',
        fontSize: '1rem',
      },
    },
  })

  return { theme }
}
