import { put } from 'redux-saga/effects'
import Geocode from 'react-geocode'
import isEmpty from 'lodash/isEmpty'
import api from 'api'

import { getStationId } from 'components/StationDetect'
import { ALERTS_CREATE } from 'modules/alerts/actions'
import {
  uploadFilesToS3,
  getBucketFileObj,
} from 'modules/utils/uploadFilesToS3'
import getUploadFileBucket from 'api/utils/getUploadFileBucket'

import { UrgencyLevels } from '../../../constants'

export default function* createAlertSaga(action) {
  let imageUploadUrls = []
  let s3BucketFilesObj = []
  const { body, files } = action.payload

  try {
    if (!isEmpty(files)) {
      const filesMime = files.map((fileObj) => fileObj.type)
      const getUploadFileBucketResult = yield getUploadFileBucket({
        fileTypes: filesMime,
      })
      if (getUploadFileBucketResult.error) {
        throw getUploadFileBucketResult.error
      }
      imageUploadUrls = getUploadFileBucketResult.response
    }
    if (!isEmpty(imageUploadUrls)) {
      const uploadFilesToS3Response = yield uploadFilesToS3(
        imageUploadUrls,
        files,
      )
      if (uploadFilesToS3Response.find((response) => response.status !== 204)) {
        throw Error('Can`t upload additional materials please try again!')
      }
      s3BucketFilesObj = getBucketFileObj(
        uploadFilesToS3Response,
        files,
        imageUploadUrls,
      )
    }

    const locationCoordinatesResponse = yield Geocode.fromAddress(
      action.payload.body.address,
    )
    const { lat, lng } =
      locationCoordinatesResponse.results[0].geometry.location

    body.subdomain = getStationId()
    body.latitude = lat
    body.longitude = lng
    const urgencyValue = Object.keys(UrgencyLevels).find(
      (key) => UrgencyLevels[key] === body.urgency,
    )
    if (urgencyValue !== undefined) {
      body.urgency = urgencyValue
    }

    const { response, error } = yield api.alerts.createAlert({
      ...body,
      files: s3BucketFilesObj,
    })
    console.log(response, error, 'response, error')

    if (error) {
      console.log('createAlertSaga error', error)

      yield put(ALERTS_CREATE.FAILED.create('error create alert'))
    } else {
      console.log('createAlertSaga response', response)

      yield put(ALERTS_CREATE.SUCCESS.create(response))
    }
  } catch (error) {
    console.log(error, 'error error error')
    yield put(ALERTS_CREATE.FAILED.create('error create alert'))
  }
}
