import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Pagination } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

const PaginationWrapper = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})(Pagination)

const PaginationSubText = withStyles({
  root: {
    color: 'gray',
    marginRight: 10,
  },
})(Typography)

export const PaginationContainer = ({
  page,
  itemsPerPage,
  total,
  onChangePage,
}) => (
  <Box display="flex" alignItems="baseline" justifyContent="flex-end">
    <PaginationSubText variant="subtitle1" alignLeft>
      Showing results
      {` ${(page - 1) * itemsPerPage + 1} - 
            ${page * itemsPerPage > total ? total : page * itemsPerPage}`}
    </PaginationSubText>
    <PaginationWrapper
      page={page}
      count={Math.ceil(total / itemsPerPage)}
      onChange={onChangePage}
      color="primary"
    />
  </Box>
)
