import { put } from 'redux-saga/effects'
import api from 'api'
import { toast } from 'react-toastify'

import { ADD_STATION_ALERT_TYPE } from 'modules/alerts/actions'

export default function* addStationAlertTypeSaga(action) {
  const { typeName, description, mandatoryNotified } = action.payload
  const { response, error } = yield api.alerts.addStationAlertType(
    typeName,
    description,
    mandatoryNotified,
  )

  if (error) {
    console.log('addStationAlertTypeSaga error', error)
    const errorMessage = error.message || 'Can`t create new type'

    yield put(ADD_STATION_ALERT_TYPE.FAILED.create(errorMessage))
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  } else {
    console.log('addStationAlertTypeSaga response', response)

    toast.success('Alert type has been added successfully', {
      position: toast.POSITION.TOP_CENTER,
    })
    yield put(ADD_STATION_ALERT_TYPE.SUCCESS.create(response))
  }
}
