/* eslint-disable no-nested-ternary */

import React from 'react'

import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionActions'
import AccordionSummary from '@material-ui/core/AccordionSummary'

export const styles = {
  // MUI Styles
  column: {
    flexBasis: '50%',
  },
  editColumn: {
    flexBasis: '25%',
    paddingTop: '.1rem',
  },
  heading: {
    textAlign: 'left',
    fontSize: '1rem',
  },
  secondaryHeading: {
    color: '#989898',
    textAlign: 'left',
  },
  editContainer: {
    width: '97%',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '.8rem',
    paddingBottom: '.6rem',
  },
}

const Group = ({
  item,
  classes,
  handleEditGroup,
  getGroupByIdRequest,
  isLoading,
}) => {
  if (!item) return null

  return (
    <Accordion
      square
      TransitionProps={{ unmountOnExit: true }}
      style={{ backgroundColor: '#fff', width: '100%' }}
      onChange={(_, expanded) =>
        expanded && !item.citizens ? getGroupByIdRequest(item.id) : null
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          style={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item className={classes.editColumn}>
            <Typography className={classes.heading}>{item.name}</Typography>
          </Grid>
          <Grid container style={{ width: '6.5rem' }}>
            <Grid style={{ margin: 'auto' }} item>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  handleEditGroup(item.id, item.name, item.citizens)
                }}
                onFocus={(event) => event.stopPropagation()}
                size="small"
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  paddingLeft: 'auto',
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        style={{
          justifyContent: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container style={{ padding: '0 32px' }}>
          <Grid item className={classes.editColumn}>
            <Typography className={classes.heading}>User id</Typography>
          </Grid>
          <Grid
            item
            className={classes.editColumn}
            style={{ textAlign: 'center' }}
          >
            <Typography
              style={{ textAlign: 'left' }}
              className={classes.heading}
            >
              User full name
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.editColumn}
            style={{ textAlign: 'center' }}
          />
          <Grid
            item
            className={classes.editColumn}
            style={{ textAlign: 'center' }}
          >
            <Typography className={classes.heading}>Email</Typography>
          </Grid>
        </Grid>
        {isLoading && !item.citizens ? (
          <h4>Loading....</h4>
        ) : item.citizens && item.citizens.length ? (
          item.citizens.map((user) => (
            <Paper
              key={`user-group-${user.id}`}
              className={classes.editContainer}
              style={{ minWidth: '425px' }}
            >
              <Grid container>
                <Grid container style={{ flex: 1 }}>
                  <Grid item className={classes.editColumn}>
                    <Typography className={classes.heading}>
                      {user.id}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.editColumn}
                    style={{ textAlign: 'center' }}
                  >
                    <Typography className={classes.secondaryHeading}>
                      {user.name.includes('null null')
                        ? 'Pending Account Set-up'
                        : user.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.editColumn}
                    style={{ textAlign: 'center' }}
                  />
                  <Grid
                    item
                    className={classes.editColumn}
                    style={{ textAlign: 'center' }}
                  >
                    <Typography className={classes.secondaryHeading}>
                      {user.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <div
            style={{
              textAlign: 'center',
              margin: '48px 0',
            }}
          >
            <h4 style={{ color: '#989898' }}>No Users</h4>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
export default withStyles(styles)(Group)
