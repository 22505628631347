import { call, put } from 'redux-saga/effects'
import api from 'api'

import { USER_GET_INFO, USER_SUBMIT_VERIFICATION } from 'modules/user/actions'
import { getCurrentAuthenticatedUser } from '../../../api/utils/getAuthenticatedUser'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'

export default function* changeCurrentUserPasswordSaga({
  payload: { attributeName, code },
}) {
  try {
    const user = yield call(getCurrentAuthenticatedUser)

    const { response, error } = yield call(api.agents.submitVerification, {
      user,
      attributeName,
      code,
    })

    if (error) {
      yield put(USER_SUBMIT_VERIFICATION.FAILED.create(error.message))
      yield showToast(TOAST_TYPES.ERROR, error.message)
    } else {
      yield showToast(TOAST_TYPES.SUCCESS, 'Email has been verified')
      yield put(USER_SUBMIT_VERIFICATION.SUCCESS.create(response))
      yield put(USER_GET_INFO.START.create())
    }
  } catch (err) {
    yield put(USER_SUBMIT_VERIFICATION.FAILED.create(err))
    yield showToast(TOAST_TYPES.ERROR, err.message)
  }
}
