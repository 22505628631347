import { all, takeLatest, takeEvery } from 'redux-saga/effects'

import {
  FLARE_UPDATE,
  GET_FLARE,
  ADD_FLARE_NOTE,
  ACTIVE_FLARES_GET,
  CLOSED_FLARES_GET,
  RECENT_FLARES_GET,
  GET_FLARE_TYPES,
  GET_FLARE_USERS,
  GET_FLARE_OPERATORS,
  GET_FLARE_REPORT_SUMMARY,
  GET_FILTERED_FLARES,
  ADD_FLARE_TYPE,
  UPDATE_STATION_SETTINGS,
  GET_ACTIVE_FLARE_LOGS,
  ACTIVE_FLARE_UPDATE,
  ADD_ACTIVE_FLARE_NOTE,
  GET_CLOSED_FLARE_LOGS,
  CLOSED_FLARE_UPDATE,
  ADD_CLOSED_FLARE_NOTE,
  GET_FLARE_LOGS,
  GET_FLARE_FROM_SOCKET_MESSAGE,
  CREATE_FLARE_GROUP,
  UPDATE_FLARES_IN_GROUP,
  UPDATE_ACTIVE_GROUP,
  UPDATE_CLOSED_GROUP,
  ACTIVE_FLARES_GET_GROUPED,
  GET_FLARE_GROUP,
} from '../actions'

import getRecentFlaresSaga from './getRecentFlaresSaga'
import getActiveFlaresSaga from './activeFlares/getActiveFlaresSaga'
import getClosedFlaresSaga from './closedFlares/getClosedFlaresSaga'
import updateFlareSaga from './updateFlareSaga'
import getFlareSaga from './getFlareSaga'
import addFlareNoteSaga from './addFlareNoteSaga'
import getFlareTypesSaga from './getFlareTypesSaga'
import getFlaresUsersSaga from './getFlaresUsersSaga'
import getFlaresOperatorsSaga from './getFlaresOperatorsSaga'
import getFlaresReportSummarySaga from './getFlaresReportSummarySaga'
import getFilteredFlaresSaga from './getFilteredFlaresSaga'
import updateActiveFlareSaga from './activeFlares/updateActiveFlareSaga'
import getActiveFlareLogsSaga from './activeFlares/getActiveFlaresLogSaga'
import addActiveFlareNoteSaga from './activeFlares/addActiveFlareNoteSaga'
import updateClosedFlareSaga from './closedFlares/updateClosedFlareSaga'
import getClosedFlareLogsSaga from './closedFlares/getClosedFlaresLogSaga'
import addClosedFlareNoteSaga from './closedFlares/addClosedFlareNoteSaga'
import getFlareLogsSaga from './getFlareLogsSaga'
import getFlareFromSocketMessageSaga from './getFlareFromSocketMessageSaga'
import addFlareTypeSaga from './addFlareTypeSaga'
import updateStationSettingsSaga from './updateStationSettingsSaga'
import createFlareGroupSaga from './flaresGroup/createFlareGroupSaga'
import addFlaresInGroupSaga from './flaresGroup/addFlaresInGroupSaga'
import updateActiveGroupSaga from './flaresGroup/updateActiveGroupSaga'
import updateClosedGroupSaga from './flaresGroup/updateClosedGroupSaga'
import getActiveFlaresGroupedSaga from './flaresGroup/getActiveFlaresGroupedSaga'
import getFlareGroupSaga from './flaresGroup/getFlareGroupSaga'

function* rootFlareSaga() {
  yield all([
    takeLatest(FLARE_UPDATE(null).START.type, updateFlareSaga),
    takeLatest(RECENT_FLARES_GET.START.type, getRecentFlaresSaga),
    takeEvery(GET_FLARE.START.type, getFlareSaga),
    takeEvery(GET_FLARE_LOGS.START.type, getFlareLogsSaga),
    takeEvery(ADD_FLARE_NOTE.START.type, addFlareNoteSaga),
    takeLatest(ACTIVE_FLARES_GET.START.type, getActiveFlaresSaga),
    takeLatest(CLOSED_FLARES_GET.START.type, getClosedFlaresSaga),
    takeLatest(GET_FLARE_TYPES.START.type, getFlareTypesSaga),
    takeLatest(ADD_FLARE_TYPE.START.type, addFlareTypeSaga),
    takeLatest(UPDATE_STATION_SETTINGS.START.type, updateStationSettingsSaga),
    takeLatest(GET_FLARE_REPORT_SUMMARY.START.type, getFlaresReportSummarySaga),
    takeLatest(GET_FLARE_USERS.START.type, getFlaresUsersSaga),
    takeLatest(GET_FLARE_OPERATORS.START.type, getFlaresOperatorsSaga),
    takeLatest(GET_FILTERED_FLARES.START.type, getFilteredFlaresSaga),
    takeEvery(GET_ACTIVE_FLARE_LOGS.START.type, getActiveFlareLogsSaga),
    takeLatest(ACTIVE_FLARE_UPDATE(null).START.type, updateActiveFlareSaga),
    takeLatest(ADD_ACTIVE_FLARE_NOTE.START.type, addActiveFlareNoteSaga),
    takeLatest(GET_CLOSED_FLARE_LOGS.START.type, getClosedFlareLogsSaga),
    takeLatest(CLOSED_FLARE_UPDATE(null).START.type, updateClosedFlareSaga),
    takeLatest(ADD_CLOSED_FLARE_NOTE.START.type, addClosedFlareNoteSaga),
    takeLatest(
      GET_FLARE_FROM_SOCKET_MESSAGE.START.type,
      getFlareFromSocketMessageSaga,
    ),
    takeLatest(CREATE_FLARE_GROUP.START.type, createFlareGroupSaga),
    takeLatest(UPDATE_FLARES_IN_GROUP.START.type, addFlaresInGroupSaga),
    takeLatest(UPDATE_ACTIVE_GROUP.START.type, updateActiveGroupSaga),
    takeLatest(UPDATE_CLOSED_GROUP.START.type, updateClosedGroupSaga),
    takeLatest(
      ACTIVE_FLARES_GET_GROUPED.START.type,
      getActiveFlaresGroupedSaga,
    ),
    takeLatest(GET_FLARE_GROUP.START.type, getFlareGroupSaga),
  ])
}

export default rootFlareSaga
