/* Contains constants and enum-like objects that are not logically contained within a single module. */

import { filter } from './utils'
/*
 * An enum-like object whose constants represent the extent to which a flares or watch-my-home request has been responded
 * to.
 *
 * The constants are cumulative, i.e. each constant implies the completion of the steps represented by each previous
 * constant. They are ordered accordingly, via their integer values. Note that this makes their comparison meaningful.
 */
export const FlareActiveStatusText = Object.freeze({
  NEW: 'New',
  RECEIVED: 'Received',
  IN_REVIEW: 'In Review',
  IN_PROGRESS: 'In Progress',
})

export const FlareStatusText = [
  {
    name: 'New',
    value: 'NEW',
  },
  {
    name: 'Received',
    value: 'RECEIVED',
  },
  {
    name: 'In Review',
    value: 'IN_REVIEW',
  },
  {
    name: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    name: 'Completed',
    value: 'COMPLETED',
  },
  {
    name: 'Closed',
    value: 'CLOSED',
  },
]

export const FlareStatusActivate = [
  {
    name: 'New',
    value: 'NEW',
  },
  {
    name: 'Received',
    value: 'RECEIVED',
  },
  {
    name: 'In Review',
    value: 'IN_REVIEW',
  },
  {
    name: 'In Progress',
    value: 'IN_PROGRESS',
  },
]

export const FlareStatusClosed = [
  {
    name: 'Completed',
    value: 'COMPLETED',
  },
  {
    name: 'Closed',
    value: 'CLOSED',
  },
]

export const ProgressStage = Object.freeze({
  NEW: 'NEW',
  RECEIVED: 'RECEIVED',
  IN_REVIEW: 'IN_REVIEW',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  1: 'NEW',
  2: 'RECEIVED',
  3: 'IN_REVIEW',
  4: 'IN_PROGRESS',
  5: 'COMPLETED',
  6: 'CLOSED',
})

export const ProgressStageIndex = Object.freeze({
  NEW: 1,
  RECEIVED: 2,
  IN_REVIEW: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
})

export const LogType = Object.freeze({
  CREATED: 'CREATED',
  STATUS_UPDATE: 'STATUS_UPDATE',
  CLOSED: 'CLOSED',
  FILES_UPDATED: 'FILES_UPDATED',
  NOTE_ADDED: 'NOTE_ADDED',
})

export const initialFlareFilterState = {
  types: [],
  status: [],
  operator: [],
  user: [],
  date: ['', ''],
}

// export const AlertLogType = Object.freeze({
//   CREATED: 'CREATED',
//   NOTE_ADDED: 'NOTE_ADDED',
//   STATUS_UPDATE: 'STATUS_UPDATE',
//   CLOSED: 'CLOSED',
// })

export const DialogMessages = Object.freeze({
  ABORT_TO_CHANGE_STATUS_NEW:
    'You are about to change the status of this flare. You may optionally add a message to this operation.',
  ABORT_TO_CHANGE_STATUS_CLOSED:
    'You are about to change the status of this flare to complete. Please provide a reason below:',
  ABORT_TO_CHANGE_STATUS_RECEIVED:
    'You are about to move this flare to the history tab. Please provide a reason below:',
  ABORT_TO_CHANGE_STATUS_COMPLETE:
    'You are about to add a note to an inactive flare. The user will not be notified; however, they can still access these notes. Please enter the message below:',
  REOPEN:
    'You are about to reopen this flare.  It will be put back with active flares. Please provide a reason below:',
})

export const UrgencyLevels = Object.freeze({
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  CRITICAL: 'Critical',
})

export const UrgencyLevelsByKeys = Object.freeze({
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Critical',
})

export const Origin = Object.freeze({
  FLARE: 0,
  ALERT: 1,
  WMH: 3,
})

// See `export` statement below for `FlareFilter` documentation comment.
const _FlareFilter = {
  NONE: 'none',
  DEAD_ANIMAL: 'Dead Animal',
  FIRE: 'Fire',
  FOUND_CHILD: 'Found Child',
  LOST_CHILD: 'Lost Child',
  MAJOR_INJURY: 'Major Injury',
  MEDICAL_EMERGENCY: 'Medical Emergency',
  MINOR_INJURY: 'Minor Injury',
  POWER_OUTAGE: 'Power Outage',
  POWERLINE_DOWN: 'Powerline Down',
  SEVERE_WEATHER: 'Severe Weather',
  SUSPICIOUS_ACTIVITY: 'Suspicious Activity',
  SUSPICIOUS_PERSON: 'Suspicious Person',
  TRAFFIC_HAZARD: 'Traffic Hazard',
  VEHICLE_ISSUE: 'Vehicle Issue',
  WILD_ANIMAL: 'Wild Animal',
  GARBAGE_PICKUP: 'Garbage Pickup',
  OTHER: 'Other',
}
// Methods are defined using `Object.defineProperties` so that they are not enumerable, ensuring this object's only
// enumerable properties are its constants.
Object.defineProperties(_FlareFilter, {
  /*
   * Returns a copy of `filterStr` such that all non-alphabetic characters are removed, all alphabetic characters
   * are converted to lowercase, and runs of whitespace are replaced by single underscores.
   */
  normalize: {
    value(filterStr) {
      return filterStr
        .replace(/[^a-zA-Z\s]/, '')
        .toLowerCase()
        .split(/\s+/)
        .join('_')
    },
  },

  _constantValues: {
    value: new Set(Object.values(_FlareFilter)),
  },

  /*
   * For `flares`, an iterable of objects containing flares data, returns those whose `alertType` property matches
   * `filterStr`. If `filterStr` is not a constant belonging to `FlareFilter`, an error is thrown.
   */
  filtered: {
    *value(flares, filterStr) {
      if (!this._constantValues.has(filterStr))
        throw new Error(`${filterStr} is not contained in FlareFilter`)
      const iter =
        filterStr === this.NONE
          ? flares
          : filter(
              flares,
              (flare) => this.normalize(flare.alertType) === filterStr,
            )
      yield* iter
    },
  },
})

/* An enum-like object whose constants represent common situations for which flares are sent. */
export const FlareFilter = Object.freeze(_FlareFilter)

export const PER_PAGE = 20

export const USER_SETTINGS = Object.freeze({
  MAP: 'MAP',
  FLARE: 'FLARE',
  CREATE_USER: 'CREATE_USER',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  ALERT: 'ALERT',
})

export const GENERAL_SETTINGS = Object.freeze({
  ALERT: 'ALERT',
})

/*
 * Flare alerts types bitmap
 * New alert types should be added to _FLARE_ALERT_TYPES constant,
 * every new item should have shifted value by 1 according to previous value in right side.
 * For example we are going to add VIBER and WHATSAPP,
 * VIBER should be 1 << 2, and WHATSAPP should be 1 << 3
 */

export const FLARE_ALERTS_TYPES = Object.freeze({
  EMAIL: 1 << 0,
  SMS: 1 << 1,
})

export const StatusLevels = Object.freeze({
  RECEIVED: 'Received',
  IN_REVIEW: 'In Review',
  IN_PROGRESS: 'In Progress',
})

export const defaultType = Object.freeze({
  id: -1,
  name: '─────',
})

export const EnabledState = Object.freeze({
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
})
