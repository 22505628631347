/* Call component:
<UnreadBadge count={int} label={string} />
*/

import React from 'react'
import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  label: {
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  badge: {
    width: '90%',
    textAlign: 'center',
  },
})

export default function (props) {
  const classes = useStyles()
  return (
    <Badge
      className={classes.badge}
      color="secondary"
      badgeContent={props.count > 0 ? props.count : 0}
      max={99}
    >
      <p className={classes.label}>{props.label}</p>
    </Badge>
  )
}
