import { API } from 'aws-amplify'

// eslint-disable-next-line import/no-cycle
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { getAuthToken } from 'api/utils/getAuthToken'
import { getStationId } from 'components/StationDetect'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (flareIds, status, typeId, message) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const token = await getAuthToken()
    const stationId = getStationId()
    const path = `${FLARE_PATH.latest}/from/station/${stationId}/grouped`

    const body = {
      flareIds,
      status,
      message,
    }

    if (typeId !== -1) body.typeId = typeId

    try {
      const response = await API.post(FLARE_API_NAME, path, {
        body,
        headers: {
          Authorization: token,
        },
      })

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: FLARE_API_NAME + path,
        payload: {
          flareIds,
          status,
          typeId,
          message,
        },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
