/* eslint-disable import/no-cycle */
import flare from './flare'
import alerts from './alerts'
import wmh from './watchMyHome'
import station from './station'
import generalSettings from './generalSettings'
import citizens from './citizens'
import group from './group'
import logs from './logs'
import billings from './billings'
import agents from './agents'

const api = {
  flare,
  alerts,
  wmh,
  station,
  generalSettings,
  citizens,
  group,
  logs,
  billings,
  agents,
}

export default api
