/* eslint-disable react/no-unused-state */
import React, { Component } from 'reactn'
import { PropTypes } from 'prop-types'
import './Nav.css'
import MenuIcon from '@material-ui/icons/Menu'
import { connect } from 'react-redux'

import {
  AppBar,
  Toolbar,
  IconButton,
  useScrollTrigger,
  // Button,
  // Typography,
} from '@material-ui/core'
import Geocode from 'react-geocode'

import { withStyles } from '@material-ui/styles'
import { isAlertOptionAvailableSelector } from 'modules/geofence/selectors'
import { getAlertAvailableSelector } from 'modules/generalSettings/selectors'
import Button from 'components/Button/Button'
import SideMenu from './SideMenu'
import TabMenu from './Tabs'
import SendAlertForm from './SendAlert/SendAlertModal'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY)

function ElevationScroll(props) {
  const { children, window } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 0 : 0,
  })
}
ElevationScroll.propTypes = {
  children: PropTypes.node.isRequired,
  window: PropTypes.func,
}

// Material UI styles
const styles = {
  palette: {
    white: '#fff',
  },
  root: {
    zIndex: 999,
    height: '4rem',
    resize: 'horizontal',
    overflow: 'hidden',
    '@media print': {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: '1rem',
    height: '100%',
  },
  tabs: {
    overflow: 'hidden',
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    height: '3rem',
  },
  tab: {
    backgroundColor: '#eaeaea',
    color: '#525252',
    borderRadius: '.5rem .5rem 0 0',
    transitionDuration: '.3s',
    fontSize: '0.7rem',
    width: '10rem',
    minWidth: '1rem',
    '&:hover': {
      color: '#767676',
      textDecoration: 'none',
      opacity: '.85',
      transitionDuration: '.5s',
    },
    '&.Mui-selected': {
      color: '#000',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      transitionDuration: '.2s',
      zIndex: 99,
    },
    '&.Mui-selected:hover': {
      opacity: '1',
    },
  },
  indicator: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  alertButton: {
    paddingTop: '.6rem',
    paddingBottom: '.6rem',
    alignItems: 'right',
    backgroundColor: '#aa2222',
    boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.3)',
    display: 'flex',
    width: '8rem',
    float: 'right',
    marginRight: '1rem',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#c0392b',
      boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
    },
  },
  title: {
    textAlign: 'left',
    marginRight: '1.5rem',
  },
  alertBox: {
    padding: '1rem',
    paper: {
      margin: '2rem',
    },
  },
}

//--------------------------

class Nav extends Component {
  state = {
    drawerOpened: false,
    openDialog: false,
    success: false,
  }

  timeouts = []

  componentWillUnmount() {
    // eslint-disable-next-line no-cond-assign
    for (let cb; (cb = this.timeouts.pop()); clearTimeout(cb));
  }

  // Toggle Side Menu
  toggleDrawer = (booleanValue) => () => {
    this.setState({
      drawerOpened: booleanValue,
    })
  }

  // Open Send Alert window
  handleClickOpen = () => () => {
    this.setState((state) => ({
      openDialog: !state.openDialog,
    }))
  }

  // Changes dialog to show success message
  revealSuccessDialog = () => {
    this.setState({ success: true })
  }

  // Closes dialog and sets the content back to the send alert form
  closeSuccess = () => {
    this.setState({ openDialog: false })
    this.timeouts.push(
      setTimeout(() => {
        this.setState({ success: false })
      }, 500),
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <ElevationScroll class>
          <AppBar position="fixed" style={{ zIndex: 998 }}>
            <Toolbar disableGutters>
              <IconButton
                color="inherit"
                aria-label="Menu"
                style={{ marginLeft: '.75rem', marginRight: '.75rem' }}
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <SideMenu
                drawerOpened={this.state.drawerOpened}
                toggleDrawer={this.toggleDrawer}
              />
              <div className={classes.title}>
                <img
                  src={`${process.env.REACT_APP_S3_LOGO}/logo-white.svg`}
                  height="30rem"
                  alt="Logo"
                />
              </div>
              <TabMenu />
              {this.props.isShowAlertButton ? (
                <div style={{ flexGrow: 1 }}>
                  <Button
                    onClick={this.handleClickOpen(true)}
                    className={classes.alertButton}
                  >
                    Send Alert
                  </Button>
                </div>
              ) : null}
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <SendAlertForm
          open={this.state.openDialog}
          handleClickOpen={this.handleClickOpen}
          showSuccess={this.state.success}
          revealSuccess={this.revealSuccessDialog}
          close={this.closeSuccess}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isShowAlertButton:
    isAlertOptionAvailableSelector(state) && getAlertAvailableSelector(state),
})

export default connect(mapStateToProps, null)(withStyles(styles)(Nav))
