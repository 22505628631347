import { all } from 'redux-saga/effects'

import rootFlareSaga from 'modules/flares/sagas'
import rootAlertsSaga from './alerts/sagas'
import rootWMHSaga from './watchMyHome/sagas'
import rootUserSaga from './user/sagas'
// import rootUtilsSaga from './utils/sagas'
import rootAuthSaga from './auth/sagas'
import rootUsersSaga from './users/sagas'
import rootGeneralSettings from './generalSettings/sagas'
import rootGeofenceSaga from './geofence/sagas'
import rootGroupSaga from './group/saga'
import rootInviteSaga from './invite/saga'

function* rootSaga() {
  yield all([
    rootFlareSaga(),
    rootWMHSaga(),
    rootAlertsSaga(),
    // rootUtilsSaga(),
    rootUserSaga(),
    rootAuthSaga(),
    rootUsersSaga(),
    rootGeneralSettings(),
    rootGeofenceSaga(),
    rootGroupSaga(),
    rootInviteSaga(),
  ])
}

export default rootSaga
