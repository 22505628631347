/* eslint-disable no-nested-ternary */
import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

import getParsedStatus from 'utils/getParsedStatus'

import { makeStyles, withStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import { LinkItUrl } from 'react-linkify-it'
import { ProgressStage, LogType, FlareStatusText } from '../../../constants'

const StyledCircularProgress = withStyles({
  root: {
    marginTop: '50px',
    marginLeft: '100px',
  },
})(CircularProgress)

const useStyles = makeStyles(() => ({
  listRoot: {
    padding: 0,
  },
  listItemRoot: {
    padding: '4px 0',
  },
  listItemTextRoot: {
    marginTop: 0,
  },
}))

const Logs = ({
  flareLogs,
  flareLogsTotal,
  fetchPrevFlareLogs,
  isLoadingLogs,
}) => {
  const classes = useStyles()
  const _parseStatusEnumToString = (value) =>
    FlareStatusText.find((status) => status.value === value).name
  const hasMore = flareLogs ? flareLogsTotal > flareLogs.length : false

  const _parseByTypeAndStatus = (flareLog) => {
    const payload = {}
    const parsedPayload = flareLog.payload ?? {}
    switch (flareLog.type) {
      case LogType.CREATED:
        payload.status = 'Created'
        break
      case LogType.NOTE_ADDED:
        payload.status = 'Add message'
        payload.message = parsedPayload.message
        break
      case LogType.STATUS_UPDATE:
        if (
          ProgressStage[parsedPayload.oldStatus] === ProgressStage.COMPLETED ||
          ProgressStage[parsedPayload.newStatus] === ProgressStage.COMPLETED
        ) {
          if (
            ProgressStage[parsedPayload.oldStatus] === ProgressStage.COMPLETED
          ) {
            payload.status = `Moved to ${_parseStatusEnumToString(
              ProgressStage[parsedPayload.newStatus],
            )} from Complete`
          } else {
            payload.status = `Moved to ${
              getParsedStatus(
                ProgressStage[parsedPayload.newStatus],
                flareLog.isClosed,
              ).type
            }`
          }
        } else if (
          ProgressStage[parsedPayload.oldStatus] ===
          ProgressStage[parsedPayload.newStatus]
        ) {
          if (flareLog.isClosed) {
            payload.status = `Moved to Close from ${_parseStatusEnumToString(
              ProgressStage[parsedPayload.newStatus],
            )}`
          } else if (
            ProgressStage[parsedPayload.oldStatus] === ProgressStage.CLOSED
          ) {
            payload.status = `Flare open to ${_parseStatusEnumToString(
              ProgressStage[parsedPayload.newStatus],
            )} from Close`
          } else {
            payload.status = `Flare ${
              getParsedStatus(
                ProgressStage[parsedPayload.newStatus],
                flareLog.isClosed,
              ).type
            }`
          }
        } else {
          payload.status = `Flare ${
            getParsedStatus(
              ProgressStage[parsedPayload.newStatus],
              flareLog.isClosed,
            ).type
          }`
        }

        payload.message = parsedPayload.message
        break
      case LogType.CLOSED:
        payload.status = parsedPayload
          ? getParsedStatus(ProgressStage[parsedPayload.oldStatus], true).type
          : 'Close'
        payload.message = parsedPayload.message
        break
      default:
        break
    }
    return payload
  }

  return (
    <>
      {flareLogs && flareLogs.length ? (
        <List classes={{ root: classes.listRoot }}>
          {flareLogs.map((flareLog) => {
            const payloadData = _parseByTypeAndStatus(flareLog)

            return (
              <React.Fragment key={flareLog.id}>
                <ListItem
                  alignItems="flex-start"
                  classes={{ root: classes.listItemRoot }}
                >
                  <ListItemText
                    classes={{ root: classes.listItemTextRoot }}
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={`${
                      payloadData.status ? payloadData.status : ''
                    } (${new Date(flareLog.createdAt).toLocaleString('en-US', {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })})`}
                    secondary={
                      <LinkItUrl>
                        <Typography style={descriptionStyle} component="span">
                          {flareLog.operator?.name}
                          {payloadData.message
                            ? ` - ${payloadData.message}`
                            : null}
                        </Typography>
                      </LinkItUrl>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            )
          })}
        </List>
      ) : null}
      {flareLogs?.length === 0 && !isLoadingLogs ? (
        'No logs'
      ) : isLoadingLogs ? (
        <StyledCircularProgress size={32} style={{ marginTop: 0 }} />
      ) : hasMore ? (
        <Button fullWidth onClick={() => fetchPrevFlareLogs()} color="primary">
          Load More
        </Button>
      ) : null}
    </>
  )
}

export default Logs

const descriptionStyle = {
  wordBreak: 'break-word',
  MozHyphens: 'auto',
  WebkitHyphens: 'auto',
  OHyphens: 'auto',
  hyphens: 'auto',
  whiteSpace: 'break-spaces',
}
