import { connect } from 'react-redux'
import WatchMyHomesList from './WatchMyHomesList'
import {
  getWMHByStatusIdsSelector,
  getWMHFilteredByActive,
} from '../../modules/watchMyHome/selectors'

const mapStateToProps = (state, props) => {
  const wmhData = props.historyMode
    ? getWMHByStatusIdsSelector(state, props.historyMode)
    : getWMHFilteredByActive(state, props.activeStatus)
  return {
    wmhData,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WatchMyHomesList)
