import React, { setGlobal } from 'reactn'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import './index.css'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'

import configStore from './configStore'
import configTheme from './configTheme'
import VerifyProvider from './context/VerifyProvider'

export const { store, persistor } = configStore()

export const { theme } = configTheme()

// Set an initial global state directly:
setGlobal({
  loading: true,
  currentUser: {
    username: '', // Store Username
    givenName: '', // User First Name
    familyName: '', // User Last Name
    formattedFullname: '', // Format full name as {name} + {familyName}
    role: '', // Get user role
  },
  mapData: {
    center: {
      lat: 0,
      lng: 0,
    },
    options: {
      alerts: false,
      flares: false,
      wmh: false,
      helpline: false,
    },
    zoom: 0,
  },
  geofence: [],
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <VerifyProvider>
          <App />
        </VerifyProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
