import { useState } from 'react'

const ONE_SECOND = 1000
const DEFAULT_COUNTDOWN_START_TIME = 60
const STOP_NUMBER = 0

export const useCountdownTimer = () => {
  const [countdownTime, setCountdownTime] = useState(
    DEFAULT_COUNTDOWN_START_TIME,
  )
  const [isCountdownActive, setIsCountdownActive] = useState(false)
  let interval
  let countdownTimer = DEFAULT_COUNTDOWN_START_TIME
  const startCountdown = () => {
    setIsCountdownActive(true)
    if (!interval) {
      interval = setInterval(() => {
        // eslint-disable-next-line no-plusplus
        countdownTimer--
        setCountdownTime(countdownTimer)
        if (countdownTimer < STOP_NUMBER) {
          stopCountdown()
        }
      }, ONE_SECOND)
    }
  }

  const stopCountdown = () => {
    setIsCountdownActive(false)
    clearInterval(interval)
    interval = null
    setCountdownTime(DEFAULT_COUNTDOWN_START_TIME)
  }

  return [isCountdownActive, countdownTime, startCountdown, stopCountdown]
}
