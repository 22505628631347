import { put } from 'redux-saga/effects'

import api from 'api'

import { FLARE_UPDATE, GET_FLARE, GET_FLARE_LOGS } from 'modules/flares/actions'
import { ProgressStage } from '../../../constants'

export default function* updateFlareSaga(action) {
  const { flareId, flareData } = action.payload

  const { response, error } = yield api.flare.updateFlare(flareId, flareData)

  if (error) {
    console.log('updateFlareSaga error', error)

    yield put(FLARE_UPDATE(action.id).FAILED.create(error.message))
  } else {
    console.log('updateFlareSaga response', response)
    yield put(FLARE_UPDATE(action.id).SUCCESS.create(response.flare))

    if (
      response.flare.isClosed ||
      response.flare.status === ProgressStage.COMPLETED
    ) {
      // flare move to history and it's a single flare screen
      yield put(GET_FLARE.START.create(flareId))
    } else {
      // flare is still open
      yield put(GET_FLARE_LOGS.START.create({ flareId }))
    }
  }
}
