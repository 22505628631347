import { all, takeLatest } from 'redux-saga/effects'

import getGeneralSettingsSaga from './getGeneralSettingsSaga'
import getUserSettingsSaga from './getUserGeneralSettingsSaga'

import { GET_GENERAL_SETTINGS, GET_USER_GENERAL_SETTINGS } from '../actions'

function* rootAuthSaga() {
  yield all([
    takeLatest(GET_GENERAL_SETTINGS.START.type, getGeneralSettingsSaga),
    takeLatest(GET_USER_GENERAL_SETTINGS.START.type, getUserSettingsSaga),
  ])
}

export default rootAuthSaga
