import { toast } from 'react-toastify'

export const TOAST_TYPES = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
})

export const showToast = (type, msg, autoClose = 3000) => {
  // eslint-disable-next-line no-unused-vars
  const toastId = toast[type](msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose,
  })
}
