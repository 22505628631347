import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FLARE } from '../actions'

export default function* getFlareSaga(action) {
  const { response, error } = yield api.flare.getFlare(action.payload)

  if (error) {
    console.log('getFlareSaga error', error)

    yield put(GET_FLARE.FAILED.create(error.message))
  } else {
    console.log('getFlareSaga response', response)
    yield put(GET_FLARE.SUCCESS.create(response.flare))
  }
}
