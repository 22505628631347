/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import produce from 'immer'

import { GROUP_BYID, GROUPS_GET, GROUP_UPDATE } from './actions'

const INITIAL_STATE = {
  groups: [],
}

function usersReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GROUPS_GET.SUCCESS.type:
        delete draft.groups
        const { groups } = action.payload
        draft.groups = groups
        break
      case GROUP_BYID.SUCCESS.type:
        draft.groups = draft.groups.map((group) => {
          if (group.id === action.payload.group.id) {
            return {
              ...group,
              citizens: action.payload.group.citizens,
            }
          }
          return group
        })
        break
      case GROUP_UPDATE.SUCCESS.type:
        break
      default:
        break
    }
  })
}

export default usersReducer
