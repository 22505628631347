import { put } from 'redux-saga/effects'

import api from 'api'

import { USERS_DELETE } from 'modules/users/actions'
import { GET_LICENSES } from 'modules/geofence/actions'

export default function* deleteUserSaga(action) {
  const { response, error } = yield api.agents.deleteUser(action.id)

  if (error) {
    console.log('deleteUserSaga error', error)

    yield put(USERS_DELETE(action.id).FAILED.create(error.message))
  } else {
    console.log('deleteUserSaga response', response)

    yield put(USERS_DELETE(action.id).SUCCESS.create())
    yield put(GET_LICENSES.START.create())
  }
}
