import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { Tooltip } from '@material-ui/core'

export default function VerificationIcon({ isVerified, tooltipType, styles }) {
  const iconSize = 18
  const iconColor = isVerified ? 'rgb(0, 162, 81)' : 'orange'
  const title = isVerified
    ? `${tooltipType} is verified`
    : `${tooltipType} isn't verified`

  return (
    <Tooltip title={title}>
      {isVerified ? (
        <CheckCircleIcon
          style={{ color: iconColor, fontSize: iconSize, ...styles }}
        />
      ) : (
        <ErrorIcon
          style={{ color: iconColor, fontSize: iconSize, ...styles }}
        />
      )}
    </Tooltip>
  )
}
