import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_STATION_ALERT_TYPES } from 'modules/alerts/actions'

export default function* getStationAlertTypesSaga() {
  const { response, error } = yield api.alerts.getStationAlertTypes()

  if (error) {
    console.log('getStationAlertTypesSaga error', error)

    yield put(GET_STATION_ALERT_TYPES.FAILED.create(error.message))
  } else {
    console.log('getStationAlertTypesSaga response', response)

    yield put(GET_STATION_ALERT_TYPES.SUCCESS.create(response))
  }
}
