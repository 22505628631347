export const mapFlareData = (flareData) => {
  const placeholder = '******'
  if (flareData?.group) {
    // group flare
    return {
      group: {
        ...flareData?.group,
        createdAt: new Date(flareData.group.createdAt) || placeholder,
        flares: flareData.group.flares.map((flare) => mapFlare(flare)),
      },
    }
  }
  // single Flare
  return { flare: mapFlare(flareData.flare || flareData) }
}

export const mapGroup = (groupData) => {
  const placeholder = '******'
  return {
    ...groupData,
    createdAt: new Date(groupData.createdAt) || placeholder,
    flares: groupData.flares.map((flare) => mapFlare(flare)),
  }
}

export const mapFlare = (flare) => {
  const placeholder = '******'

  return {
    ...flare,
    status: flare.status && flare.status.toUpperCase(),
    message: flare.message || placeholder,
    latitude: flare.latitude || 0,
    longitude: flare.longitude || 0,
    address: flare.address || placeholder,
    floor: flare.floor || 0,
    citizen: {
      ...flare.citizen,
      name: flare.citizen?.name || placeholder,
      phoneNumber: flare.citizen?.phoneNumber || placeholder,
    },
    createdAt: new Date(flare.createdAt) || placeholder,
    type: {
      ...flare.type,
      name: flare.type?.name || placeholder,
    },
    isClosed: flare.isClosed || false,
  }
}
