import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_CLOSED_ALERTS } from 'modules/alerts/actions'

export default function* getClosedAlertsSaga() {
  const { response, error } = yield api.alerts.getAlerts({
    isClosed: true,
  })

  if (error) {
    console.log('getClosedAlertsSaga error', error)

    yield put(GET_CLOSED_ALERTS.FAILED.create(error.message))
  } else {
    console.log('getClosedAlertsSaga response', response)

    yield put(GET_CLOSED_ALERTS.SUCCESS.create(response))
  }
}
