import React, { Component } from 'reactn'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Grid,
  Divider,
  Button,
} from '@material-ui/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx'
import 'react-toastify/dist/ReactToastify.css'
import { withStyles } from '@material-ui/styles'
import { EmbedMapView } from '../MapFunctions'
import ProgressBar from '../ProgressBar'
import { ProgressStage } from '../../constants'
import CardTitle from '../QueueOutput/shared/CardTitle'
import styles from '../QueueOutput/shared/styles'
import UpdateStage from '../QueueOutput/shared/UpdateStage'
import ShowProgress from '../QueueOutput/shared/ShowProgress'
import ImageFocus from '../QueueOutput/shared/ImageFocus'
import api from '../../api'

// @TODO implement moveToHistory

class WmhItem extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { enableImage: true }

  mounted = false

  state = {
    enableImage: false,
    progressStage: ProgressStage.NEW,
    location: null,
    everExpanded: false,
    showMap: false,
    sendStageChange: false,
    incomingStage: '',
    showProgress: false,
  }

  // Array to store operator name and notes from status updates, passed to showProgress dialog as prop
  progressData = [
    // Currently holding placeholders, this should be pulled from database
    {
      key: 1,
      stage: 1,
      timestamp: 'mm/dd/yy @ 00:00 AM',
      operator: 'Operator Name 1',
    },
    {
      key: 2,
      stage: 2,
      timestamp: 'mm/dd/yy @ 00:00 AM',
      operator: 'Operator Name 2',
      note: 'Note',
    },
    {
      key: 3,
      stage: 3,
      timestamp: 'mm/dd/yy @ 00:00 AM',
      operator: 'Operator Name 3',
      note: 'Note',
    },
  ]

  timeouts = []

  // eslint-disable-next-line react/sort-comp
  async componentDidMount() {
    this.mounted = true
    const address = {
      address: this.props.address.street,
      city: this.props.address.city,
      state: this.props.address.state,
      zip: this.props.address.zip,
    }

    api.wmh.getWMHCoords(address).then((coords) => {
      if (this.mounted) this.setState({ location: coords })
    })

    if (this.props.image) {
      this.setState({ enableImage: true })
    }
    this.mountFunction()
  }

  timeouts = []

  componentDidUpdate(prevProps, prevState) {
    if (prevState.everExpanded !== this.state.everExpanded) {
      if (prevState.progressStage === ProgressStage.NEW) {
        this.props.wmhStateChange(ProgressStage.RECEIVED)
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          progressStage: ProgressStage.RECEIVED,
        })
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
    // eslint-disable-next-line no-cond-assign
    for (let cb; (cb = this.timeouts.pop()); clearTimeout(cb));
  }

  showMap = () => {
    this.setState({ showMap: true })
  }

  mountFunction = async () => {
    // const docClient = new AWS.DynamoDB.DocumentClient()
    // const params = {
    //   TableName: 'WatchMyHome-test',
    //   Key: {
    //     userID: this.props.userID,
    //     creationTime: this.props.time.toISOString(),
    //   },
    // }
    // const foo = await docClient.get(params).promise()
    // let updatedStatus
    // switch (foo.Item.currentstatus.toLowerCase()) {
    //   case 'active':
    //     updatedStatus = ProgressStage.ACTIVE
    //     break
    //   case 'received':
    //     updatedStatus = ProgressStage.RECEIVED
    //     break
    //   case 'reviewing':
    //     updatedStatus = ProgressStage.IN_REVIEW
    //     break
    //   case 'in_progress':
    //     updatedStatus = ProgressStage.IN_PROGRESS
    //     break
    //   case 'complete':
    //     updatedStatus = ProgressStage.COMPLETED
    //     break
    //   default:
    //     updatedStatus = ProgressStage.ACTIVE
    //     break
    // }
    // this.setState({
    //   progressStage: updatedStatus,
    // })
  }

  updateStage = (stage) => {
    this.setState({
      incomingStage: stage,
      sendStageChange: true,
    })
  }

  sendStageUpdate = (message, myStage) => {
    let text
    let statustitle
    switch (myStage) {
      case 1:
        text = 'Your request has been received.'
        statustitle = 'Received'
        break
      case 2:
        text = 'Your request is being reviewed'
        statustitle = 'Reviewing'
        break
      case 3:
        text = `Your request is in progress by ${this.props.currentUser.formattedFullname}`
        statustitle = 'In Progress'
        break
      case 4:
        text = 'Your request is complete'
        statustitle = 'Complete'
        break
      default:
        return
    }
    const params = {
      table: 'WatchMyHome-test',
      updateflare: true,
      userID: this.props.userID,
      creationTime: this.props.time.toISOString(),
      newStatus: statustitle,
      mytext: text,
      operator: this.props.currentUser.formattedFullname,
      stage: this.state.progressStage,
      operatornote: message || 'None',
      updateop: !this.props.operator,
      mytitle: statustitle,
      Note: this.props.Note,
      Date: this.props.Date,
      Operator: this.props.Operator,
      notifyparam: this.props.userID,
    }
    console.log(params)
    this.props.wmhStateChange(this.state.incomingStage)

    this.setState((state) => ({
      sendStageChange: true,
      progressStage: state.incomingStage,
    }))
  }

  cancelStageUpdate = () => {
    this.setState({ sendStageChange: false })
    this.timeouts.push(
      setTimeout(() => {
        this.setState({ incomingStage: '' })
      }, 250),
    )
  }

  formatPhoneNumber = (phoneNum) => {
    const digits = phoneNum.replace(/\D/, '')
    const prefix = digits.length === 11 ? '+' : ''
    const areaCode = digits.length > 7 ? `(${digits.slice(-10, -7)})` : ''
    // The result is of the form +<country code>-(<area code>)-<xxx-xxxx> .
    return `${prefix}${digits.slice(0, -10)} ${areaCode} ${digits.slice(
      -7,
      -4,
    )}-${digits.slice(-4)}`
  }

  // Progress dialog show/hide methods
  showProgress = () => {
    this.setState({ showProgress: true })
  }

  closeProgress = () => {
    this.setState({ showProgress: false })
  }

  formatDate = () =>
    `${
      this.props.time.getMonth() + 1
    }/${this.props.time.getDate()}/${this.props.time
      .getFullYear()
      .toString()
      .substr(-2)} @ ${this.props.time
      .toLocaleTimeString()
      .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`

  render() {
    const { classes } = this.props
    return (
      <div>
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.resourceContainer}
        >
          <Accordion
            className={classes.resourceCard}
            onChange={(event, expanded) => {
              if (expanded && !this.state.everExpanded) {
                this.setState({
                  everExpanded: true,
                  showMap: true,
                })
              } else if (expanded) {
                this.setState({ showMap: true })
              } else {
                setTimeout(() => {
                  this.setState({ showMap: false })
                }, 250)
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div className={classes.columnTitle}>
                <Typography className={classes.heading}>
                  <CardTitle
                    unread={this.state.progressStage === ProgressStage.NEW}
                  >
                    {this.props.name}
                  </CardTitle>
                </Typography>
              </div>
              <div className={classes.columnTitle}>
                <Typography className={classes.heading}>
                  <CardTitle
                    unread={this.state.progressStage === ProgressStage.NEW}
                  >
                    {this.props.address.street}
                  </CardTitle>
                </Typography>
              </div>
              <div className={classes.columnTitle}>
                <Typography className={classes.heading}>
                  <CardTitle
                    unread={this.state.progressStage === ProgressStage.NEW}
                  >
                    {this.formatDate()}
                  </CardTitle>
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div
                className={
                  this.state.enableImage
                    ? clsx(classes.infoColumn, classes.helper)
                    : clsx(classes.infoColumnNoImage, classes.helper)
                }
              >
                <Typography className={classes.typography} variant="caption">
                  <p>
                    <strong>Full Address: </strong>
                    {this.props.address.street}, {this.props.address.city},{' '}
                    {this.props.address.state} {this.props.address.zip}
                  </p>
                  <p>
                    <strong>Duration: </strong>
                    {this.props.duration.start} - {this.props.duration.end}
                  </p>
                  <div>
                    <strong>Main Contact: </strong>
                    <ul>
                      <li>
                        <em>Phone: </em>
                        {this.formatPhoneNumber(this.props.contact.phone)}
                      </li>
                      <li>
                        <em>Email: </em>
                        {this.props.contact.email}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <strong>Emergency Contact: </strong>
                    <ul>
                      <li>
                        <em>Name: </em>
                        {this.props.emergencyContact.name}
                      </li>
                      <li>
                        <em>Phone: </em>
                        {this.formatPhoneNumber(
                          this.props.emergencyContact.phone,
                        )}
                      </li>
                      <li>
                        <em>Relationship: </em>
                        {this.props.emergencyContact.relationship}
                      </li>
                      {this.props.emergencyContact.email && (
                        <li>
                          <em>Email: </em>
                          {this.props.emergencyContact.email}
                        </li>
                      )}
                    </ul>
                  </div>
                  <p>
                    <strong>Message: </strong>
                    {this.props.message}
                  </p>
                </Typography>
              </div>
              {this.state.enableImage && (
                <div className={classes.imageColumn}>
                  <img
                    src={this.props.image}
                    alt=""
                    style={{ height: '30vh' }}
                  />
                  <ImageFocus
                    open={this.state.zoomImage}
                    close={() => this.setState({ zoomImage: false })}
                    title={`${this.props.name} ${this.formatDate()}`}
                    caption={this.props.address.street}
                  />
                </div>
              )}
              <div className={classes.mapColumn}>
                {this.state.showMap ? (
                  <div>
                    {this.state.location && (
                      <EmbedMapView
                        zoom={17}
                        center={{
                          lat: this.state.location.lat,
                          lng: this.state.location.lng,
                        }}
                        height="15rem"
                      />
                    )}
                  </div>
                ) : (
                  <Button
                    size="small"
                    className={classes.showMap}
                    style={{ color: '#0052a5' }}
                    // onClick={this.showMap}
                  >
                    Show Map
                  </Button>
                )}
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <ProgressBar
                onStageChange={this.updateStage}
                currentStage={this.state.progressStage}
              />
              <Button
                onClick={this.showProgress}
                size="small"
                style={{
                  color: '#0052a5',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
                // eslint-disable-next-line react/no-children-prop
                children="Show Progress History"
              />
              <Button
                // onClick={this.props.moveToHistory} not implemented anywhere
                size="small"
                style={{
                  color: '#f04141',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
                // eslint-disable-next-line react/no-children-prop
                children="Move To History"
              />
            </AccordionActions>
          </Accordion>
        </Grid>
        <UpdateStage
          open={this.state.sendStageChange}
          send={this.sendStageUpdate}
          cancel={this.cancelStageUpdate}
          incomingStage={this.state.incomingStage}
        />
        <ShowProgress
          open={this.state.showProgress}
          cancel={this.closeProgress}
          stage={this.state.progressStage}
          progressData={this.progressData}
        />
      </div>
    )
  }
}
export default withStyles(styles)(WmhItem)
