import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Verify from './verify'

import { navigationStateSelector } from '../../../modules/selectors'

import {
  SIGN_UP_VERIFIRED,
  RESEND_SING_UP_VERIFY_CODE,
} from '../../../modules/auth/actions'

import { reasonFailedSelector } from '../../../modules/requestsStatuses/selectors'

function mapStateToProps(state) {
  return {
    routerState: navigationStateSelector(state),
    resetSignUpVerifyCodeErrorMessage: reasonFailedSelector(
      state,
      RESEND_SING_UP_VERIFY_CODE,
    ),
    signUpVirifyCodeErrorMessage: reasonFailedSelector(
      state,
      SIGN_UP_VERIFIRED,
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signUpVerify: SIGN_UP_VERIFIRED.START.create,
      resendSignUpVerifyCode: RESEND_SING_UP_VERIFY_CODE.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify)
