export default {
  '@craco/craco@5.2.3': {
    licenses: 'Apache-2.0',
    repository: 'https://github.com/sharegate/craco',
    licenseUrl: 'https://github.com/sharegate/craco/raw/master/LICENSE',
    parents: 'station3',
  },
  '@material-ui/core@4.0.2': {
    licenses: 'MIT',
    repository: 'https://github.com/mui-org/material-ui',
    licenseUrl: 'https://github.com/mui-org/material-ui/raw/master/LICENSE',
    parents: 'station3',
  },
  '@material-ui/icons@4.0.1': {
    licenses: 'MIT',
    repository: 'https://github.com/mui-org/material-ui',
    licenseUrl: 'https://github.com/mui-org/material-ui/raw/master/LICENSE',
    parents: 'station3',
  },
  '@material-ui/styles@4.0.2': {
    licenses: 'MIT',
    repository: 'https://github.com/mui-org/material-ui',
    licenseUrl: 'https://github.com/mui-org/material-ui/raw/master/LICENSE',
    parents: 'station3',
  },
  '@types/googlemaps@3.36.5': {
    licenses: 'MIT',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    licenseUrl:
      'https://github.com/DefinitelyTyped/DefinitelyTyped/raw/master/LICENSE',
    parents: 'station3',
  },
  '@types/markerclustererplus@2.1.33': {
    licenses: 'MIT',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    licenseUrl:
      'https://github.com/DefinitelyTyped/DefinitelyTyped/raw/master/LICENSE',
    parents: 'station3',
  },
  'aws-amplify@1.1.28': {
    licenses: 'Apache-2.0',
    repository: 'https://github.com/aws/aws-amplify',
    licenseUrl: 'https://github.com/aws/aws-amplify/raw/master/LICENSE',
    parents: 'station3',
  },
  'aws-sdk@2.483.0': {
    licenses: 'Apache-2.0',
    repository: 'https://github.com/aws/aws-sdk-js',
    licenseUrl: 'https://github.com/aws/aws-sdk-js/raw/master/LICENSE.txt',
    parents: 'station3',
  },
  'axios@0.19.0': {
    licenses: 'MIT',
    repository: 'https://github.com/axios/axios',
    licenseUrl: 'https://github.com/axios/axios/raw/master/LICENSE',
    parents: 'station3',
  },
  'bootstrap@4.3.1': {
    licenses: 'MIT',
    repository: 'https://github.com/twbs/bootstrap',
    licenseUrl: 'https://github.com/twbs/bootstrap/raw/master/LICENSE',
    parents: 'station3',
  },
  'eslint@5.16.0': {
    licenses: 'MIT',
    repository: 'https://github.com/eslint/eslint',
    licenseUrl: 'https://github.com/eslint/eslint/raw/master/LICENSE',
    parents: 'station3',
  },
  'graceful-fs@4.2.0': {
    licenses: 'ISC',
    repository: 'https://github.com/isaacs/node-graceful-fs',
    licenseUrl: 'https://github.com/isaacs/node-graceful-fs/raw/master/LICENSE',
    parents: 'station3',
  },
  'jquery@3.4.1': {
    licenses: 'MIT',
    repository: 'https://github.com/jquery/jquery',
    licenseUrl: 'https://github.com/jquery/jquery/raw/master/LICENSE.txt',
    parents: 'station3',
  },
  'jss-preset-default@4.5.0': {
    licenses: 'MIT',
    repository: 'https://github.com/cssinjs/jss-preset-default',
    licenseUrl:
      'https://github.com/cssinjs/jss-preset-default/raw/master/LICENSE',
    parents: 'station3',
  },
  'jss@9.8.7': {
    licenses: 'MIT',
    repository: 'https://github.com/cssinjs/jss',
    licenseUrl: 'https://github.com/cssinjs/jss/raw/master/LICENSE',
    parents: 'station3',
  },
  'lodash@4.17.11': {
    licenses: 'MIT',
    repository: 'https://github.com/lodash/lodash',
    licenseUrl: 'https://github.com/lodash/lodash/raw/master/LICENSE',
    parents: 'station3',
  },
  'material-ui@1.0.0-beta.47': {
    licenses: 'MIT',
    repository: 'https://github.com/mui-org/material-ui',
    licenseUrl: 'https://github.com/mui-org/material-ui/raw/master/LICENSE',
    parents: 'station3',
  },
  'popper.js@1.15.0': {
    licenses: 'MIT',
    repository: 'https://github.com/FezVrasta/popper.js',
    licenseUrl: 'https://github.com/FezVrasta/popper.js',
    parents: 'station3',
  },
  'prop-types@15.7.2': {
    licenses: 'MIT',
    repository: 'https://github.com/facebook/prop-types',
    licenseUrl: 'https://github.com/facebook/prop-types/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-bootstrap-typeahead@3.4.5': {
    licenses: 'MIT',
    repository: 'https://github.com/ericgio/react-bootstrap-typeahead',
    licenseUrl:
      'https://github.com/ericgio/react-bootstrap-typeahead/raw/master/LICENSE.md',
    parents: 'station3',
  },
  'react-bootstrap@1.0.0-beta.9': {
    licenses: 'MIT',
    repository: 'https://github.com/react-bootstrap/react-bootstrap',
    licenseUrl:
      'https://github.com/react-bootstrap/react-bootstrap/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-dom@16.8.6': {
    licenses: 'MIT',
    repository: 'https://github.com/facebook/react',
    licenseUrl: 'https://github.com/facebook/react/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-geocode@0.1.2': {
    licenses: 'MIT',
    repository: 'https://github.com/shukerullah/react-geocode',
    licenseUrl:
      'https://github.com/shukerullah/react-geocode/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-google-autocomplete@1.1.0': {
    licenses: 'ISC',
    repository: 'https://github.com/ErrorPro/react-google-autocomplete',
    licenseUrl:
      'https://github.com/ErrorPro/react-google-autocomplete/raw/master/LICENSE.md',
    parents: 'station3',
  },
  'react-google-maps@9.4.5': {
    licenses: 'MIT',
    repository: 'https://github.com/tomchentw/react-google-maps',
    licenseUrl:
      'https://github.com/tomchentw/react-google-maps/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-router-dom@5.0.1': {
    licenses: 'MIT',
    repository: 'https://github.com/ReactTraining/react-router',
    licenseUrl:
      'https://github.com/ReactTraining/react-router/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-scripts@3.0.1': {
    licenses: 'MIT',
    repository: 'https://github.com/facebook/create-react-app',
    licenseUrl:
      'https://github.com/facebook/create-react-app/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-sweet-progress@1.1.2': {
    licenses: 'MIT',
    repository: 'https://github.com/abraztsov/react-sweet-progress',
    licenseUrl:
      'https://github.com/abraztsov/react-sweet-progress/raw/master/LICENSE',
    parents: 'station3',
  },
  'react-tap-event-plugin@3.0.3': {
    licenses: 'Apache-2.0',
    repository: 'https://github.com/zilverline/react-tap-event-plugin',
    licenseUrl: 'http://www.apache.org/licenses/LICENSE-2.0',
    parents: 'station3',
  },
  'react-toastify@5.2.1': {
    licenses: 'MIT',
    repository: 'https://github.com/fkhadra/react-toastify',
    licenseUrl: 'https://github.com/fkhadra/react-toastify/raw/master/LICENSE',
    parents: 'station3',
  },
  'react@16.8.6': {
    licenses: 'MIT',
    repository: 'https://github.com/facebook/react',
    licenseUrl: 'https://github.com/facebook/react/raw/master/LICENSE',
    parents: 'station3',
  },
  'redux@4.0.1': {
    licenses: 'MIT',
    repository: 'https://github.com/reduxjs/redux',
    licenseUrl: 'https://github.com/reduxjs/redux/raw/master/LICENSE.md',
    parents: 'station3',
  },
  'typescript@3.5.1': {
    licenses: 'Apache-2.0',
    repository: 'https://github.com/Microsoft/TypeScript',
    licenseUrl:
      'https://github.com/Microsoft/TypeScript/raw/master/LICENSE.txt',
    parents: 'station3',
  },
  'uuid@3.3.2': {
    licenses: 'MIT',
    repository: 'https://github.com/kelektiv/node-uuid',
    licenseUrl: 'https://github.com/kelektiv/node-uuid/raw/master/LICENSE.md',
    parents: 'station3',
  },
}
