/* eslint-disable no-restricted-syntax */
export const setUserHelper = (draft, payload) => {
  for (const key in payload) {
    // eslint-disable-next-line no-prototype-builtins
    if (payload.hasOwnProperty(key)) {
      // eslint-disable-next-line no-param-reassign
      draft[key] = payload[key]
    }
  }
}
