import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { persistStore } from 'redux-persist'

import { rootReducer, rootSaga } from 'modules'
import createReduxWaitForAction from './utils/middlewares/reduxWaitForAction'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const logger = createLogger({
  duration: true,
  collapsed: true,
  logErrors: true,
  diff: true,
})

function configMiddleware() {
  const middleware = []

  middleware.push(sagaMiddleware)
  middleware.push(createReduxWaitForAction())
  middleware.push(routerMiddleware(history))
  middleware.push(logger)

  return middleware
}

const composer = composeWithDevTools

export default () => {
  const middlewares = configMiddleware()

  const store = createStore(
    rootReducer,
    composer(applyMiddleware(...middlewares)),
  )

  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
