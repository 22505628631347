// eslint-disable-next-line import/no-cycle
import { API } from 'aws-amplify'
import { ALERT_API_NAME, ALERT_PATH } from 'api/alerts'
import { reportAPIError } from '../../utils/errorReports'
// eslint-disable-next-line import/no-cycle
import { getAuthToken } from '../utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { getStationId } from '../../components/StationDetect'

export default async (alertTypeIds) => {
  const path = `${ALERT_PATH.latest}/types/enable`
  const result = {
    error: undefined,
    response: undefined,
  }
  try {
    const token = await getAuthToken()
    const subdomain = getStationId().toLowerCase()
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        typeIds: alertTypeIds,
        subdomain,
      },
    }
    const response = await API.patch(ALERT_API_NAME, path, request)
    result.response = response
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: ALERT_API_NAME + path,
      payload: { alertTypeIds },
      error: parsedError,
    })

    result.error = parsedError
  }
  return result
}
