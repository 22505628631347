import { put } from 'redux-saga/effects'
import api from 'api'
import { toast } from 'react-toastify'

import { ADD_FLARE_TYPE, GET_FLARE_TYPES } from 'modules/flares/actions'

export default function* addFlareTypeSaga({ payload: { typeName } }) {
  const { response, error } = yield api.flare.addFlareType(typeName)

  if (error) {
    console.log('addFlareType error', error)
    const errorMessage = error.message || 'Can`t create new type'

    yield put(ADD_FLARE_TYPE.FAILED.create(errorMessage))
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    })
  } else {
    console.log('addFlareType response', response)

    toast.success('Flare type has been added successfully', {
      position: toast.POSITION.TOP_CENTER,
    })

    yield put(ADD_FLARE_TYPE.SUCCESS.create(response))
    yield put(GET_FLARE_TYPES.START.create())
  }
}
