import { API, Auth } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { AGENT_API_NAME, AGENTS_PATH } from 'api/agents'
import { isArray } from 'lodash'
import { parseErrorLatest } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default (userData) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const path = `${AGENTS_PATH.latest}/${userData.id}`
    const auth = await Auth.currentAuthenticatedUser({ bypassCache: true })
    const token = auth.signInUserSession.idToken.jwtToken
    const currentUser = await Auth.currentUserInfo()

    const params = {
      body: {
        role: userData.role,
        givenName: userData.givenName,
        familyName: userData.familyName,
        stationRoleAccess: userData.stationRoleAccess,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        username: userData.username,
        notificationSettings: userData.notificationSettings,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const emailVerified = currentUser.attributes.email === userData.email
      const response = await API.put(AGENT_API_NAME, path, params)
      const updatedAgent = { ...response.agent, emailVerified }
      result.response = updatedAgent
    } catch (error) {
      const parsedError = parseErrorLatest(error)

      reportAPIError({
        endpoint: AGENT_API_NAME + path,
        payload: userData,
        error: parsedError,
      })
      result.error = {
        message: isArray(parsedError.message)
          ? parsedError.message[0]
          : parsedError.message,
      }
    }

    resolve(result)
  })
