/* eslint-disable no-nested-ternary */
import lodashReduce from 'lodash/reduce'

export const ACTION_DIVIDER = ' -> '

export const ACTIONS_SUBTYPES = Object.freeze({
  PRE_START: 'PRE_START',
  START: 'START',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',

  STATE: 'STATE',

  DONE: 'DONE',

  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  UNDO: 'UNDO',
})

export function createAction(
  type,
  structure,
  isMultiInstanceProcess = false,
  promisify = false,
) {
  const actionGenerator = (...actionIdParts) => {
    const actionObject = {
      majorType: type,
      id:
        actionIdParts.length === 0
          ? undefined
          : actionIdParts.length === 1
          ? actionIdParts[0]
          : lodashReduce(
              actionIdParts,
              (result, n) => `${result}${result ? ACTION_DIVIDER : ''}${n}`,
              '',
            ),
    }

    if (structure) {
      Object.keys(structure).forEach((actionPart) => {
        actionObject[actionPart] = {}

        const actionPartType = `${type}${ACTION_DIVIDER}${actionPart.toUpperCase()}`

        actionObject[actionPart].type = actionPartType
        actionObject[actionPart].id = actionObject.id

        if (structure[actionPart]) {
          if (structure[actionPart] instanceof Function) {
            // Use function provided by consumer

            actionObject[actionPart].create = (...params) => ({
              type: actionPartType,
              payload: structure[actionPart](...params),
              id: actionObject.id,
              promisify,
            })
          } else {
            // Use default creator without payload

            actionObject[actionPart].create = () => ({
              type: actionPartType,
              id: actionObject.id,
              promisify,
            })
          }
        }

        actionObject[actionPart] = Object.freeze(actionObject[actionPart])
      })
    } else {
      actionObject.type = type
      actionObject.promisify = promisify
    }

    return Object.freeze(actionObject)
  }

  return isMultiInstanceProcess ? actionGenerator : actionGenerator()
}
