/* eslint-disable camelcase */
import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'

import { USER_GET_INFO } from 'modules/user/actions'
import { decodeJWTToken } from '../../../utils/decodeJWTToken'
import { FLARE_ALERTS_TYPES } from '../../../constants'

function convertFlareAlertsTypesToBoolean(flareType) {
  return Object.entries(FLARE_ALERTS_TYPES).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.toLowerCase()]: !!(flareType & value),
    }),
    {},
  )
}

export default function* currentUserInfoSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser({ bypassCache: true })
    const {
      user_id,
      name,
      family_name,
      role,
      email,
      email_verified,
      station,
      phone_number,
      flare_alerts,
      preferred_username,
      phone_number_verified,
    } = decodeJWTToken(user.signInUserSession.idToken.jwtToken)
    const currentUser = {
      id: user_id,
      username: preferred_username, // preferred_username is the username of the agent object
      givenName: name,
      familyName: family_name,
      email,
      formattedFullname: `${name} ${family_name}`,
      role,
      emailVerified: email_verified,
      phoneNumber: phone_number,
      phoneNumberVerified: phone_number_verified,
      notificationSettings: convertFlareAlertsTypesToBoolean(
        parseInt(flare_alerts, 10),
      ),
      stationRoleAccess: station,
    }
    console.log('currentUserInfoSaga response', currentUser)

    yield put(USER_GET_INFO.SUCCESS.create(currentUser))
  } catch (err) {
    console.log('currentUserInfoSaga Error', err)
    yield put(USER_GET_INFO.FAILED.create())
  }
}
