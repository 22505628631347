/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FlareItemGroup from 'components/FlareList/FlareListItem/FlaresListItemGroup'
import { getGroupSelector } from 'modules/flares/selectors'
import {
  isSuccessSelector,
  isLoadingSelector,
} from 'modules/requestsStatuses/selectors'
import {
  GET_FLARE_GROUP,
  GET_ACTIVE_FLARE_LOGS,
  ADD_ACTIVE_FLARE_NOTE,
  UPDATE_ACTIVE_GROUP,
  UPDATE_FLARES_IN_GROUP,
} from '../../../../modules/flares/actions'
import ListHeader from '../../../../components/FlareList/ListHeader'
import { flareActions } from '../../../../utils/getFormDialogInfo'

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 44,
    paddingRight: 44,
    marginTop: 44,
  },
}))

const GroupFlare = () => {
  const classes = useClasses()
  const { id } = useParams()
  const group = useSelector((state) => getGroupSelector(state, id))
  const dispatch = useDispatch()
  const [historyMode, setHistoryMode] = useState(false)

  const isSuccessAddFlareInGroup = useSelector((state) =>
    isSuccessSelector(state, UPDATE_FLARES_IN_GROUP),
  )
  const isSuccessUpdateGroup = useSelector((state) =>
    isSuccessSelector(state, UPDATE_ACTIVE_GROUP),
  )
  const isLoadingLogs = useSelector((state) =>
    isLoadingSelector(state, GET_ACTIVE_FLARE_LOGS),
  )

  useEffect(() => {
    dispatch(GET_FLARE_GROUP.START.create(id))
  }, [])

  const getFlareLogs = useCallback(
    (payload) => {
      payload = {
        ...payload,
        isSingleGroup: true,
      }
      dispatch(GET_ACTIVE_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )
  const getMoreFlareLogs = useCallback(
    (payload) => {
      payload = {
        ...payload,
        isSingleGroup: true,
      }
      dispatch(GET_ACTIVE_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )

  const handleAddNoteFlare = useCallback(
    (payload) => {
      payload = {
        ...payload,
        isSingleGroup: true,
      }
      dispatch(ADD_ACTIVE_FLARE_NOTE.START.create(payload))
    },
    [dispatch],
  )

  const handleUpdateDataGroup = useCallback((payload) => {
    payload = {
      ...payload,
      isSingleGroup: true,
    }
    const { flareAction } = payload

    // to do change  no siempre es closed y ahora los closed serán
    if (flareAction === flareActions.MOVE_TO_HISTORY) {
      setHistoryMode(true)
    }
    if (flareAction === flareActions.MAKE_ACTIVE) {
      setHistoryMode(false)
    }

    dispatch(UPDATE_ACTIVE_GROUP.START.create(payload))
  })

  const handleUpdateFlaresInGroup = useCallback(
    (payload) => {
      payload = {
        ...payload,
        isSingleGroup: true,
      }
      dispatch(UPDATE_FLARES_IN_GROUP.START.create(payload))
    },
    [dispatch],
  )

  return (
    <div className={classes.root}>
      <ListHeader isSingleGroup />

      {console.log(group)}

      {group && (
        <FlareItemGroup
          key={`flare-item-group-${id}`}
          flaresGroup={group.group}
          getFlareLogs={getFlareLogs}
          historyMode={historyMode}
          getMoreFlareLogs={getMoreFlareLogs}
          handleAddNoteFlare={handleAddNoteFlare}
          handleUpdateDataGroup={handleUpdateDataGroup}
          handleUpdateFlaresInGroup={handleUpdateFlaresInGroup}
          isSuccessAddFlareInGroup={isSuccessAddFlareInGroup}
          isSuccessUpdateGroup={isSuccessUpdateGroup}
          isLoadingLogs={isLoadingLogs}
          isSingleGroup
        />
      )}
    </div>
  )
}

export default GroupFlare
