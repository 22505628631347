import { put, select } from 'redux-saga/effects'

import api from 'api'

import {
  GET_ACTIVE_FLARE_LOGS,
  GET_CLOSED_FLARE_LOGS,
  GET_FLARE_FROM_SOCKET_MESSAGE,
} from 'modules/flares/actions'

import { activeFilterSelector } from 'modules/flares/selectors'

import { isFlareClosed } from 'utils/isFlareClosed'

export default function* getFlareFromSocketMessageSaga({
  payload: { flareId, type },
}) {
  const activeFlareFilters = yield select(activeFilterSelector)

  const { response, error } = yield api.flare.getFlare(flareId)

  if (error) {
    yield put(GET_FLARE_FROM_SOCKET_MESSAGE.FAILED.create(error.message))
  } else {
    if (activeFlareFilters.types.length) {
      const flareType = activeFlareFilters.types
        .split(',')
        .find((flareTypeId) => +flareTypeId === response.flare.type.id)
      if (flareType) {
        yield put(
          GET_FLARE_FROM_SOCKET_MESSAGE.SUCCESS.create({
            flare: response.flare,
            type,
          }),
        )
      }
    } else {
      yield put(
        GET_FLARE_FROM_SOCKET_MESSAGE.SUCCESS.create({
          flare: response.flare,
          type,
        }),
      )
    }

    if (isFlareClosed(response.flare)) {
      yield put(GET_CLOSED_FLARE_LOGS.START.create({ flareId }))
    } else {
      yield put(GET_ACTIVE_FLARE_LOGS.START.create({ flareId }))
    }
  }
}
