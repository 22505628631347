import { Dialog, ListItem, ListItemText, Tooltip } from '@material-ui/core'
import List from '@material-ui/core/List'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const InfoIconContainer = withStyles({
  root: {
    paddingLeft: 10,
    color: '#ffc107',
  },
})(Typography)

export const ExportReportDialog = ({
  open,
  handleClose,
  handlePrintAllFlares,
  handlePrintSummary,
  handlePrintCurrent,
}) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="simple-dialog-title"
    open={open}
  >
    {/* <DialogTitle id="simple-dialog-title">Export report</DialogTitle> */}
    <List component="nav" aria-label="main mailbox folders">
      <ListItem button onClick={handlePrintSummary}>
        <ListItemText primary="Export summary only" />
      </ListItem>
      <ListItem button onClick={handlePrintCurrent}>
        <ListItemText primary="Export with current (flare on page)" />
      </ListItem>
      <ListItem button onClick={handlePrintAllFlares}>
        <ListItemText primary=" Export with flares" />
        <Tooltip title="Could be longer loading time when a large number of flares match the filters">
          <InfoIconContainer item>
            <InfoIcon />
          </InfoIconContainer>
        </Tooltip>
      </ListItem>
    </List>
  </Dialog>
)
