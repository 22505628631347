import { createSelector } from 'reselect'
import { getStationId } from '../../components/StationDetect'

export const rootUsersSelector = (state) => state.users.usersById

export const getUsersIdsSelector = createSelector(
  rootUsersSelector,
  (users) => Object.keys(users) || [],
)

export const getUserSelector = (state, id) => rootUsersSelector(state)[id]
export const getUserCountSelector = (state) => state.users.count
export const getLastUserIdSelector = (state) => state.users.lastId
export const getHasMoreAgentsSelector = (state) => state.users.hasMoreAgents

// before filtered by:
// data.Users[i].Username !== getStationId() + 'Admin' &&
// data.Users[i].Username !== this.props.currentUser.username

export const getUsersIdsByStationSelector = (state) =>
  getUsersIdsSelector(state).filter(
    (userId) =>
      getUserSelector(state, userId)?.stationRoleAccess === getStationId(),
  )

export const getUsernameSelector = (state, id) =>
  getUserSelector(state, id).username
