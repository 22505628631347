import { createAction, ACTIONS_SUBTYPES } from '../utils/actionCreator'

export const GET_GENERAL_SETTINGS = createAction('GET_GENERAL_SETTINGS', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
})

export const GET_USER_GENERAL_SETTINGS = createAction(
  'GET_USER_GENERAL_SETTINGS',
  {
    [ACTIONS_SUBTYPES.START]: () => true,
    [ACTIONS_SUBTYPES.SUCCESS]: (payload) => payload,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
  },
)
