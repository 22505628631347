import getLogs from './getLogs'
import createAlertLog from './createAlertLog'
import createFlareLog from './createFlareLog'
import { Version } from '../utils'

export const LOGS_API_NAME = 'FlareLogs'

const RESOURCE_PATH = '/logs'

export const LOG_PATH = {
  legacy: RESOURCE_PATH + Version.LEGACY,
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  getLogs,
  createAlertLog,
  createFlareLog,
}
