import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_CLOSED_FLARE_LOGS } from '../../actions'

export default function* getClosedFlareLogsSaga(action) {
  const { flareId, lastId = null, groupId = null } = action.payload
  const { response, error } = yield api.logs.getLogs({
    itemId: flareId,
    lastId,
  })

  if (error) {
    console.log('getClosedFlareLogsSaga error', error)

    yield put(GET_CLOSED_FLARE_LOGS.FAILED.create(error.message))
  } else {
    console.log('getClosedFlareLogsSaga response', response)

    yield put(
      GET_CLOSED_FLARE_LOGS.SUCCESS.create({
        ...response,
        flareId,
        isPagination: !!lastId,
        groupId,
      }),
    )
  }
}
