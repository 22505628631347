/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { getAuthToken } from 'api/utils/getAuthToken'
import { getStationId } from '../../components/StationDetect'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'
import { flareFiltersMapper } from '../../modules/utils/flareFiltersMapper'

export default (payload = {}) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }

    const token = await getAuthToken()
    const { perPage = 20, page = 1, filters } = payload

    const geofenceId = getStationId().toLowerCase()
    const path = `${FLARE_PATH.latest}/from/station/${geofenceId}/grouped`

    try {
      const requestParams = {
        queryStringParameters: {},
        headers: {
          Authorization: token,
        },
      }

      if (filters) {
        requestParams.queryStringParameters = flareFiltersMapper(
          filters,
          requestParams,
        )
      }
      if (page) requestParams.queryStringParameters.page = page

      requestParams.queryStringParameters.perPage = perPage

      const response = await API.get(FLARE_API_NAME, path, requestParams)

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: FLARE_API_NAME + path,
        payload,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
