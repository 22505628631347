import { Version } from 'api/utils'
import changeToS from './changeToS'
import deleteUser from './deleteUser'
import editUser from './editUser'
import createUser from './createUser'
import getUsers from './getUsers'
import changeCurrentUserPassword from './changePassword'
import requestVerificationCode from './requestVerificationCode'
import submitVerification from './submitVerification'
import activateUser from './activateUser'
import deactivateUser from './deactivateUser'
import forgotUsername from './forgotUsername'
import resetPassword from './resetPassword'

export const AGENT_API_NAME = 'UserCRUD'

const RESOURCE_PATH = '/agents'

export const AGENTS_PATH = {
  legacy: RESOURCE_PATH,
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  changeToS,
  deleteUser,
  editUser,
  createUser,
  getUsers,
  changeCurrentUserPassword,
  requestVerificationCode,
  submitVerification,
  activateUser,
  deactivateUser,
  forgotUsername,
  resetPassword,
}
