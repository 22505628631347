/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-escape */
function implDeepFreeze(object, doNotFreeze) {
  // `doNotFreeze` contains objects that are either down the call stack (circular references) or have already been
  // deep-frozen.
  doNotFreeze.add(object)
  for (const propName of Object.getOwnPropertyNames(object).concat(
    Object.getOwnPropertySymbols(object),
  )) {
    const prop = object[propName]
    if (prop instanceof Object && !doNotFreeze.has(prop))
      implDeepFreeze(prop, doNotFreeze)
  }
  Object.freeze(object)
}

/* Calls `Object.freeze` on an object, as well as every other object reachable from it. */
export function deepFreeze(object) {
  implDeepFreeze(object, new Set())
  return object
}

/*
 * Yields the elements of an iterable for which a given predicate is true.
 *
 * Similar to `Array.prototype.filter`, but for an arbitrary iterable.
 */
export function* filter(iterable, predicate) {
  if (!iterable) return
  for (const x of iterable) {
    if (predicate(x)) yield x
  }
}

/* Yields the first `n` elements of an iterable. */
export function* iterFirstN(iterable, n) {
  if (!iterable) return
  let i = 0
  for (const x of iterable) {
    // eslint-disable-next-line no-plusplus
    if (++i > n) break
    yield x
  }
}

export function toTitleCase(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

const specialSymbolRex = /^(?=.*[!@#$%^&*])/

export function validatePasswordInput(password) {
  const errors = {}
  errors.isShort = password.length < 8
  errors.isNotHaveLowercase = password.search(/[a-z]/) < 0
  errors.isNotHaveUppercase = password.search(/[A-Z]/) < 0
  errors.isNotHaveNumber = password.search(/[0-9]/) < 0
  errors.isNotHaveSpecialSymbol = !specialSymbolRex.test(password)
  return errors
}

export function getMiddle(prop, markers) {
  let values = markers.map((m) => m[prop])
  let min = Math.min(...values)
  let max = Math.max(...values)
  if (prop === 'lng' && max - min > 180) {
    values = values.map((val) => (val < max - 180 ? val + 360 : val))
    min = Math.min(...values)
    max = Math.max(...values)
  }
  let result = (min + max) / 2
  if (prop === 'lng' && result > 180) {
    result -= 360
  }
  return result
}

export function findCenter(markers) {
  return {
    lat: getMiddle('lat', markers),
    lng: getMiddle('lng', markers),
  }
}

export function formattingUrlInText(text) {
  const regexHttp = /(https:\/\/|http:\/\/)/g
  const textWithoutHttp = text.replace(regexHttp, '')
  const regex =
    /(?<!https:\/\/)(?<!\/)((?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?<!@[a-zA-Z0-9-.]+))/g
  const result = textWithoutHttp.replace(regex, 'https://$1')
  return result
}
