/* eslint-disable import/no-cycle */
import getAlerts from './getAlerts'
import createAlert from './createAlert'
import getAlertTypes from './getAlertTypes'
import updateAlert from './updateAlert'

import getStationAlertTypes from './getStationAlertTypes'
import addStationAlertType from './addStationAlertType'
import updateStationAlertTypes from './updateStationAlertTypes'
import checkAlertTypeIsActive from './checkAlertTypeIsActive'
import updateAlertType from './updateAlertType'
import { Version } from '../utils'

export const ALERT_API_NAME = 'AlertsCRUD_dev'

const RESOURCE_PATH = '/alert'

export const ALERT_PATH = {
  legacy: RESOURCE_PATH,
  latest: RESOURCE_PATH + Version.LATEST,
}

export default {
  getAlerts,
  createAlert,
  getAlertTypes,
  getStationAlertTypes,
  updateAlert,
  addStationAlertType,
  updateAlertType,
  updateStationAlertTypes,
  checkAlertTypeIsActive,
}
