/* eslint-disable no-case-declarations */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */

import React, { useEffect, useState } from 'reactn'
import { makeStyles } from '@material-ui/styles'

/* Misc. */
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from 'components/Button/Button'

/* Expansion Panel */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionActions from '@material-ui/core/AccordionActions'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

import { AlertAttachments } from 'dashboard/nav/SendAlert/AlertAttachments'
import {
  UpdateAlertDialog,
  DIALOG_TYPE,
} from 'components/AlertListItem/components/UpdateAlertDialog'

import { LinkItUrl } from 'react-linkify-it'
import { EmbedMapView } from '../MapFunctions'

import { UpdateFilesDialog } from './components/UpdateFilesDialog'

import { AlertLogs } from './AlertLogs'
import { CreateAlertLog } from './CreateAlertLog'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
  },
  logContainer: {
    width: '100%',
    maxHeight: 150,
    overflow: 'hidden',
    overflowY: 'auto',
  },
  detailsText: {
    maxHeight: 150,
    overflow: 'hidden',
    overflowWrap: 'anywhere',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
  },
  summaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  paper: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  gridColumn: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      height: '450px',
    },
  },
  completeButton: {
    color: theme.palette.finish,
  },
}))

function formatTimestamp(ts) {
  return new Date(ts).toLocaleString('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short',
  })
}

function AlertItem(props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAttachUpdateOpen, setAttachUpdateOpen] = useState(false)
  const [updateDialogType, setUpdateDialogType] = useState(undefined)
  const [subject, setSubject] = useState('')
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 })
  const classes = useStyles()

  // Validate the coordinate values and set the local state
  useEffect(() => {
    if (props.coordinates) {
      if (
        props.coordinates.lat < -90 ||
        props.coordinates.lat > 90 ||
        props.coordinates.lng < -180 ||
        props.coordinates.lng > 180
      ) {
        setCoordinates({ lat: 0, lng: 0 })
        return
      }
      setCoordinates(props.coordinates)
    }
  }, [props.coordinates])

  // Validate the subject length and set the local state
  useEffect(() => {
    if (props.subject) {
      if (props.subject.length > 30) {
        setSubject(`${props.subject.substring(0, 30)}...`)
        return
      }
      setSubject(props.subject)
    }
  }, [props.subject])

  /* Handlers */
  const handleAttachFilesClick = () => {
    setAttachUpdateOpen(true)
  }

  const handleAddNoteClick = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleCloseFilesDialog = () => {
    setAttachUpdateOpen(false)
  }

  const handleChangeUrgencyClick = () => {
    setUpdateDialogType(DIALOG_TYPE.CHANGE_URGENCY)
  }

  const handleCompleteClick = () => {
    setUpdateDialogType(DIALOG_TYPE.MOVE_TO_CLOSE)
  }

  return (
    <>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={4}>
              <Tooltip
                title={subject}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  variant="body2"
                >
                  {subject}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip
                title={props.address}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  variant="body2"
                >
                  {props.address.split(',')[0]}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={props.urgency}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  variant="body2"
                >
                  {props.urgency}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip
                title={formatTimestamp(props.timestamp)}
                placement="bottom-start"
                enterDelay={500}
              >
                <Typography
                  classes={{ root: classes.summaryText }}
                  variant="body2"
                >
                  {formatTimestamp(props.timestamp)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">Subject</Typography>
                  <Typography>{props.subject}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Location</Typography>
                  <Typography>{props.address}</Typography>
                  <Typography>
                    {coordinates.lat}, {coordinates.lng}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Priority</Typography>
                  <Typography>{props.urgency}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    classes={{ root: classes.scrollableContainer }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body2">Details</Typography>
                      <LinkItUrl>
                        <Typography classes={{ root: classes.detailsText }}>
                          {props.details}
                        </Typography>
                      </LinkItUrl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Logs</Typography>
                      <Grid container>
                        <Grid item classes={{ root: classes.logContainer }}>
                          <AlertLogs alertId={props.id} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12} className={classes.gridColumn}>
              <AlertAttachments files={props.attachments} />
            </Grid>
            <Grid item sm={4} xs={12} className={classes.gridColumn}>
              <EmbedMapView zoom={17} center={coordinates} height="404" />
            </Grid>
          </Grid>
        </AccordionDetails>
        {!props.isClosed ? (
          <AccordionActions>
            <Button onClick={handleAttachFilesClick}>Attach Files</Button>
            <Button onClick={handleAddNoteClick}>Add Note</Button>
            <Button onClick={handleChangeUrgencyClick}>Change Priority</Button>
            <Button
              color="secondary"
              onClick={handleCompleteClick}
              classes={{ root: classes.completeButton }}
            >
              Complete
            </Button>
          </AccordionActions>
        ) : (
          <AccordionActions>
            <Button onClick={handleAddNoteClick}>Add Note</Button>
          </AccordionActions>
        )}
      </Accordion>

      <CreateAlertLog
        isOpen={isDialogOpen}
        alertId={props.id}
        handleClose={handleCloseDialog}
      />
      <UpdateAlertDialog
        isOpen={!!updateDialogType}
        handleClose={() => setUpdateDialogType(undefined)}
        alertId={props.id}
        dialogType={updateDialogType}
        alertUrgency={props.urgency}
      />
      <UpdateFilesDialog
        isOpen={isAttachUpdateOpen}
        files={props.attachments}
        alertId={props.id}
        handleClose={handleCloseFilesDialog}
      />
    </>
  )
}

export default AlertItem
