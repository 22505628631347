import { put } from 'redux-saga/effects'

import api from 'api'

import { RESET_PASSWORD } from 'modules/users/actions'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'

export default function* resetPasswordSaga({ payload }) {
  const { response, error } = yield api.agents.resetPassword(payload)

  if (error) {
    console.log('resetPasswordSaga error', error)

    yield put(RESET_PASSWORD.FAILED.create(error.message))
  } else {
    yield showToast(
      TOAST_TYPES.SUCCESS,
      'Your password has been reset successfully, check your email',
    )
    console.log('resetPasswordSaga response', response)

    yield put(RESET_PASSWORD.SUCCESS.create(response))
  }
}
