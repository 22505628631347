import { all, takeLatest } from 'redux-saga/effects'

import getWMHSaga from './getWMHSaga'
import wmhStateChangeSaga from './wmhStateChangeSaga'

import { WMH_GET_DATA, WMH_CHANGE_STATE } from '../actions'

function* rootWMHSaga() {
  yield all([
    takeLatest(WMH_GET_DATA.START.type, getWMHSaga),
    takeLatest(WMH_CHANGE_STATE(null).START.type, wmhStateChangeSaga),
  ])
}

export default rootWMHSaga
