/* eslint-disable no-restricted-syntax */
import { put, select } from 'redux-saga/effects'
// eslint-disable-next-line import/no-cycle
import api from 'api'

import { toast } from 'react-toastify'

import {
  isAlertOptionAvailableSelector,
  isFlareOptionAvailableSelector,
} from 'modules/geofence/selectors'
import {
  getAlertAvailableSelector,
  getFlareAvailableSelector,
} from 'modules/generalSettings/selectors'

import {
  GET_FLARE_TYPES,
  UPDATE_STATION_SETTINGS,
} from 'modules/flares/actions'
import { GET_GEOFENCE } from '../../geofence/actions'
import { GET_STATION_ALERT_TYPES } from '../../alerts/actions'

export default function* updateStationSettingsSaga({
  payload: { stationId, flareTypeIds, alertTypeIds, stationData },
}) {
  const isAlertAvailable = yield select(
    (state) =>
      isAlertOptionAvailableSelector(state) && getAlertAvailableSelector(state),
  )
  const isFlareAvailable = yield select(
    (state) =>
      isFlareOptionAvailableSelector(state) && getFlareAvailableSelector(state),
  )

  const stationSettingsResponse = yield isFlareAvailable
    ? api.station.updateStationSettings(stationId, stationData)
    : {}
  const flareTypesResponse = yield isFlareAvailable
    ? api.flare.updateStationFlareTypes(flareTypeIds)
    : {}
  const alertTypesResponse = yield isAlertAvailable
    ? api.alerts.updateStationAlertTypes(alertTypeIds)
    : {}

  if (
    stationSettingsResponse.error ||
    flareTypesResponse.error ||
    alertTypesResponse.error
  ) {
    if (stationSettingsResponse.error) {
      yield put(
        UPDATE_STATION_SETTINGS.FAILED.create(
          stationSettingsResponse.error.message,
        ),
      )
      toast.error(stationSettingsResponse.error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    }

    if (flareTypesResponse.error) {
      yield put(
        UPDATE_STATION_SETTINGS.FAILED.create(flareTypesResponse.error.message),
      )
      toast.error(flareTypesResponse.error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    }

    if (alertTypesResponse.error) {
      yield put(
        UPDATE_STATION_SETTINGS.FAILED.create(alertTypesResponse.error.message),
      )
      toast.error(alertTypesResponse.error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      })
    }
  } else {
    toast.success('Station settings has been updated successfully', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    })
    yield put(UPDATE_STATION_SETTINGS.SUCCESS.create())
    yield put(GET_GEOFENCE.START.create())
    yield put(GET_FLARE_TYPES.START.create())
    yield put(GET_STATION_ALERT_TYPES.START.create())
  }
}
