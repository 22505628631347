import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FLARE_USERS } from 'modules/flares/actions'

export default function* getFlaresUsersSaga() {
  const { response, error } = yield api.flare.getFlaresUsers()

  if (error) {
    console.log('getFlaresUsersSaga error', error)

    yield put(GET_FLARE_USERS.FAILED.create(error.message))
  } else {
    console.log('getFlaresUsersSaga response', response)

    yield put(GET_FLARE_USERS.SUCCESS.create(response))
  }
}
