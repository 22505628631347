import { put, select } from 'redux-saga/effects'

import api from 'api'

import {
  ADD_CLOSED_FLARE_NOTE,
  GET_CLOSED_FLARE_LOGS,
} from 'modules/flares/actions'
import { rootUserSelector } from '../../../user/selectors'

export default function* addClosedFlareNoteSaga(action) {
  const { flareId, message, groupId = null } = action.payload

  const user = yield select((state) => rootUserSelector(state))

  const { response, error } = yield api.logs.createFlareLog(
    flareId,
    message,
    user.formattedFullname,
  )

  if (error) {
    console.log('addClosedFlareNoteSaga error', error)

    yield put(ADD_CLOSED_FLARE_NOTE.FAILED.create(error.message))
  } else {
    console.log('addClosedFlareNoteSaga response', response)
    yield put(ADD_CLOSED_FLARE_NOTE.SUCCESS.create(flareId))
    yield put(GET_CLOSED_FLARE_LOGS.START.create({ flareId, groupId }))
  }
}
