import { ACTIONS_SUBTYPES, createAction } from '../utils/actionCreator'

export const GET_GEOFENCE = createAction('GET_GEOFENCE', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const GET_LICENSES = createAction('GET_LICENSES', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const ADD_LICENSE = createAction('ADD_LICENSE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})
