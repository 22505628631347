import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FLARE_REPORT_SUMMARY } from 'modules/flares/actions'

export default function* getFlaresReportSummarySaga() {
  const { response, error } = yield api.flare.getFlaresReportSummary()

  if (error) {
    console.log('getFlaresReportSummary error', error)

    yield put(GET_FLARE_REPORT_SUMMARY.FAILED.create(error.message))
  } else {
    console.log('getFlaresReportSummary response', response)

    yield put(GET_FLARE_REPORT_SUMMARY.SUCCESS.create(response))
  }
}
