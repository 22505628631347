import { call, put } from 'redux-saga/effects'
import api from 'api'

import { CURRENT_USER_CHANGE_PASSWORD } from 'modules/user/actions'
import { getCurrentAuthenticatedUser } from '../../../api/utils/getAuthenticatedUser'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'

export default function* changeCurrentUserPasswordSaga({
  payload: { previousPassword, proposedPassword },
}) {
  try {
    const user = yield call(getCurrentAuthenticatedUser)

    const { response, error } = yield call(
      api.agents.changeCurrentUserPassword,
      {
        user,
        previousPassword,
        proposedPassword,
      },
    )

    if (error) {
      yield put(CURRENT_USER_CHANGE_PASSWORD.FAILED.create(error.message))
      yield showToast(TOAST_TYPES.ERROR, error.message)
    } else {
      yield showToast(
        TOAST_TYPES.SUCCESS,
        'Password has been changed successfully',
      )
      yield put(CURRENT_USER_CHANGE_PASSWORD.SUCCESS.create(response))
    }
  } catch (err) {
    yield put(CURRENT_USER_CHANGE_PASSWORD.FAILED.create(err))
    yield showToast(TOAST_TYPES.ERROR, err.message)
  }
}
