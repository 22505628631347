import moment from 'moment'

export const flareFiltersMapper = (filters) => {
  const {
    types,
    status,
    operator,
    user,
    date: [startDate, endDate],
  } = filters
  const queryStringParameters = {}
  if (types.length) {
    queryStringParameters.typeIds = types.map((item) => item.id).join(',')
  }
  if (status.length) {
    queryStringParameters.statusSet = status.map((item) => item.value).join(',')
  }
  if (operator.length) {
    queryStringParameters.operatorIds = operator
      .map((item) => item.id)
      .join(',')
  }
  if (user.length) {
    queryStringParameters.citizenIds = user.map((item) => item.id).join(',')
  }
  if (startDate) {
    queryStringParameters.startDate = moment(startDate).format('YYYY-MM-DD')
  }
  if (endDate) {
    queryStringParameters.endDate = moment(endDate).format('YYYY-MM-DD')
  }
  return queryStringParameters
}
