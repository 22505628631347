import { put } from 'redux-saga/effects'
import api from 'api'
import { AUTH_FORGOT_USERNAME } from '../actions'

export default function* forgotUserNameSaga(action) {
  const { email } = action.payload
  try {
    const { error } = yield api.agents.forgotUsername({ email })
    if (error) {
      yield put(AUTH_FORGOT_USERNAME.FAILED.create(error.message))
    } else {
      yield put(AUTH_FORGOT_USERNAME.SUCCESS.create())
    }
  } catch (error) {
    yield put(AUTH_FORGOT_USERNAME.FAILED.create(error.message))
  }
}
