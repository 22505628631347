/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { ButtonGroup, List, ListItem, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import VerificationIcon from '../../../components/VerificationIcon'
import { rootUserSelector } from '../../../modules/user/selectors'
import { AddUser } from '../../../components/ManageUsers'
import { VerifyEmailDialogWrapper } from '../../../components/VerifyEmail/VerifyEmail'
import {
  CURRENT_USER_CHANGE_PASSWORD,
  USER_EDIT,
  USER_GET_INFO,
  USER_REQUEST_VERIFICATION_CODE,
  USER_SUBMIT_VERIFICATION,
} from '../../../modules/user/actions'
import {
  isLoadingSelector,
  isSuccessSelector,
} from '../../../modules/requestsStatuses/selectors'
import { ChangePasswordModalWrapper } from '../../../components/ChangePassword/ChangePassword'
import { DataLoadWrapper } from '../../../components/LoadingOverlay'
import { showToast, TOAST_TYPES } from '../../../utils/showToast'
import packageJson from '../../../../package.json'

const useProfileStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 20,
  },
  container: {
    maxWidth: 500,
  },
  buttonsSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5,
    marginBottom: 8,
  },
})

export const ProfileComponent = ({
  user,
  isUserLoading,
  editCurrentUser,
  changeCurrentUserPassword,
  isEditCurrentUserLoading,
  isEditCurrentUserSuccess,
  isChangeCurrentUserPasswordLoading,
  isChangeCurrentUserPasswordSuccess,
  requestVerificationCode,
  submitVerification,
  requestVerificationCodeLoading,
  submitVerificationLoading,
  submitVerificationSuccess,
}) => {
  const classes = useProfileStyles()

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isVerifyEmailDialogOpen, setIsVerifyEmailDialogOpen] = useState(false)
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState(false)

  useEffect(() => {
    if (isEditCurrentUserSuccess) {
      setIsEditDialogOpen(false)
      if (!user.emailVerified) {
        setIsVerifyEmailDialogOpen(true)
        showToast(
          TOAST_TYPES.INFO,
          'Your email has been change. The verification code is send to your new email',
          5000,
        )
      }
    }
  }, [isEditCurrentUserSuccess, user])

  useEffect(() => {
    if (submitVerificationSuccess) {
      setIsVerifyEmailDialogOpen(false)
    }
  }, [submitVerificationSuccess])

  useEffect(() => {
    if (isChangeCurrentUserPasswordSuccess) {
      setIsChangePasswordDialogOpen(false)
    }
  }, [isChangeCurrentUserPasswordSuccess])

  const toggleEditDialog = () => setIsEditDialogOpen(!isEditDialogOpen)
  const toggleVerifyEmailDialog = () =>
    setIsVerifyEmailDialogOpen(!isVerifyEmailDialogOpen)
  const toggleChangePasswordDialog = () =>
    setIsChangePasswordDialogOpen(!isChangePasswordDialogOpen)

  const handleVerificationCodeSubmit = (code) => {
    submitVerification({ attributeName: 'email', code })
  }
  const handleChangePasswordSubmit = ({
    previousPassword,
    proposedPassword,
  }) => {
    changeCurrentUserPassword({
      previousPassword,
      proposedPassword,
    })
  }
  const handleEditUser = (userData) => {
    editCurrentUser({ userData })
  }

  return (
    <div className={classes.root}>
      <DataLoadWrapper
        loading={isUserLoading}
        display={!isUserLoading}
        path="/dashboard/profile"
      >
        <Paper className={classes.container}>
          {user && <UserProfileInfo user={user} />}
          <div className={classes.buttonsSection}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button onClick={toggleEditDialog}>Edit</Button>
              {!user.emailVerified && (
                <Button onClick={toggleVerifyEmailDialog}>Verify email</Button>
              )}
              <Button onClick={toggleChangePasswordDialog}>
                Change password
              </Button>
            </ButtonGroup>
          </div>
        </Paper>
      </DataLoadWrapper>
      {isEditDialogOpen && (
        <AddUser
          open={isEditDialogOpen}
          isEditLoading={isEditCurrentUserLoading}
          user={user}
          onUserEdit={handleEditUser}
          isEditMode
          isEditCurrentUser
          close={toggleEditDialog}
        />
      )}
      {isVerifyEmailDialogOpen && (
        <VerifyEmailDialogWrapper
          handleVerificationCodeSubmit={handleVerificationCodeSubmit}
          submitVerificationLoading={submitVerificationLoading}
          requestVerificationCodeLoading={requestVerificationCodeLoading}
          isVerifyEmailDialogOpen={isVerifyEmailDialogOpen}
          toggleVerifyEmailDialog={toggleVerifyEmailDialog}
          requestVerificationCode={requestVerificationCode}
          isEmailChanged={!user.emailVerified}
        />
      )}
      {isChangePasswordDialogOpen && (
        <ChangePasswordModalWrapper
          isChangeCurrentUserPasswordLoading={
            isChangeCurrentUserPasswordLoading
          }
          isChangePasswordDialogOpen={isChangePasswordDialogOpen}
          handleChangePasswordSubmit={handleChangePasswordSubmit}
          toggleChangePasswordDialog={toggleChangePasswordDialog}
        />
      )}
      <div style={{ position: 'absolute', bottom: 0, right: 0, padding: 5 }}>
        Version: {packageJson.version}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: rootUserSelector(state),
    isUserLoading: isLoadingSelector(state, USER_GET_INFO),
    isEditCurrentUserLoading: isLoadingSelector(state, USER_EDIT),
    isEditCurrentUserSuccess: isSuccessSelector(state, USER_EDIT),
    isChangeCurrentUserPasswordLoading: isLoadingSelector(
      state,
      CURRENT_USER_CHANGE_PASSWORD,
    ),
    isChangeCurrentUserPasswordSuccess: isSuccessSelector(
      state,
      CURRENT_USER_CHANGE_PASSWORD,
    ),
    requestVerificationCodeLoading: isLoadingSelector(
      state,
      USER_REQUEST_VERIFICATION_CODE,
    ),
    submitVerificationLoading: isLoadingSelector(
      state,
      USER_SUBMIT_VERIFICATION,
    ),
    submitVerificationSuccess: isSuccessSelector(
      state,
      USER_SUBMIT_VERIFICATION,
    ),
  }
}

const mapDispatchToProps = {
  editCurrentUser: USER_EDIT.START.create,
  changeCurrentUserPassword: CURRENT_USER_CHANGE_PASSWORD.START.create,
  requestVerificationCode: USER_REQUEST_VERIFICATION_CODE.START.create,
  submitVerification: USER_SUBMIT_VERIFICATION.START.create,
}

export const Profile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileComponent)

const useProfileInfoStyles = makeStyles({
  container: {
    display: 'flex',
    width: 500,
  },
  list: {
    width: 500,
  },
})

const UserProfileInfo = ({ user }) => {
  const classes = useProfileInfoStyles()
  const {
    givenName,
    familyName,
    email,
    emailVerified,
    phoneNumber,
    role,
    username,
  } = user
  const iconStyles = { marginLeft: 5 }
  const usernameData = username || email

  return (
    <List className={classes.list}>
      <UserProfileInfoItem title="Name" data={`${givenName} ${familyName}`} />
      <UserProfileInfoItem
        title="Email"
        data={email}
        icon={
          <VerificationIcon
            tooltipType="Email"
            styles={iconStyles}
            isVerified={emailVerified}
          />
        }
      />
      <UserProfileInfoItem title="Phone number" data={phoneNumber} />
      <UserProfileInfoItem title="Role" data={role} />
      <UserProfileInfoItem
        title="Username"
        data={usernameData}
        divider={false}
      />
    </List>
  )
}

const UserProfileInfoItemStyles = makeStyles({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const UserProfileInfoItem = ({ icon, title, data }) => {
  const classes = UserProfileInfoItemStyles()

  return (
    <ListItem divider className={classes.listItem}>
      <Typography fontWeight="fontWeightBold" variant="body2">
        {title}
        {icon}
      </Typography>
      <Typography variant="body2">{data}</Typography>
    </ListItem>
  )
}
