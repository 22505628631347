import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import { push } from 'connected-react-router'

import { AUTH_FORGOT_PASSWORD_SUBMIT } from '../actions'

export default function* forgotPasswordSubmitSaga(action) {
  // eslint-disable-next-line camelcase
  const { username, code, new_password } = action.payload
  try {
    yield Auth.forgotPasswordSubmit(username, code, new_password)
    yield put(AUTH_FORGOT_PASSWORD_SUBMIT.SUCCESS.create())
    yield put(push('/login'))
  } catch (error) {
    yield put(AUTH_FORGOT_PASSWORD_SUBMIT.FAILED.create(error.message))
  }
}
