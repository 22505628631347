/* eslint-disable no-restricted-syntax */
import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_FILTERED_FLARES, GET_FLARE_REPORT_SUMMARY } from '../actions'

export default function* getFilteredFlaresSaga(action) {
  const { page, isNextPage } = action.payload
  const flaresResponse = yield api.flare.getFlares(action.payload)
  const summaryResponse = yield api.flare.getFlaresReportSummary(
    action.payload.filters,
  )

  if (flaresResponse && flaresResponse.error) {
    console.log('getFilteredFlaresSaga error', flaresResponse.error)
    yield put(GET_FILTERED_FLARES.FAILED.create(flaresResponse.error.message))
  } else if (summaryResponse && summaryResponse.error) {
    yield put(
      GET_FLARE_REPORT_SUMMARY.FAILED.create(summaryResponse.error.message),
    )
  } else {
    console.log('getFilteredFlaresSaga response', flaresResponse.response)
    const { flares } = flaresResponse.response

    yield put(
      GET_FILTERED_FLARES.SUCCESS.create({
        flares,
        totalItems: +flaresResponse.response.totalItems,
        isNextPage,
        page,
      }),
    )

    yield put(GET_FLARE_REPORT_SUMMARY.SUCCESS.create(summaryResponse.response))
  }
}
