import { API } from 'aws-amplify'
import { BILLING_PATH, ENDPOINT } from 'api/billings'
import { getAuthToken } from '../utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'
import { getStationId } from '../../components/StationDetect'

export default async ({ licenses = 1 }) => {
  const result = {
    error: undefined,
    response: undefined,
  }
  const subdomain = getStationId().toLowerCase()
  const path = BILLING_PATH.latest
  try {
    const token = await getAuthToken()
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        subdomain,
        licenses,
      },
    }
    result.response = await API.post(ENDPOINT, path, request)
  } catch (err) {
    const parsedError = parseError(err)

    reportAPIError({
      endpoint: ENDPOINT + path,
      response: parsedError,
    })
    result.error = parsedError
  }
  return result
}
