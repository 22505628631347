import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const GET_ACTIVE_ALERTS = createAction('GET_ACTIVE_ALERTS', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const GET_ACTIVE_ALERTS_NEXT_PAGE = createAction(
  'GET_ACTIVE_ALERTS_NEXT_PAGE',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const GET_CLOSED_ALERTS = createAction('GET_CLOSED_ALERTS', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const GET_CLOSED_ALERTS_NEXT_PAGE = createAction(
  'GET_CLOSED_ALERTS_NEXT_PAGE',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const ALERTS_ADD_LOG = createAction('ALERTS_ADD_LOG', {
  [ACTIONS_SUBTYPES.START]: (params) => params,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const ALERTS_CREATE = createAction(
  'ALERTS_CREATE',
  {
    [ACTIONS_SUBTYPES.START]: (params) => params,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
  false,
  true,
)

export const GET_STATION_ALERT_TYPES = createAction('GET_STATION_ALERT_TYPES', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const ADD_STATION_ALERT_TYPE = createAction('ADD_STATION_ALERT_TYPE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const GET_ALERT_LOGS = createAction('GET_ALERT_LOGS', {
  [ACTIONS_SUBTYPES.START]: (alertId) => alertId,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
})

export const GET_ALERT_TYPES = createAction('GET_ALERT_TYPES', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
})

export const UPDATE_ALERT = createAction('UPDATE_ALERT', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
})

export const UPDATE_ALERT_TYPE = createAction('UPDATE_ALERT_TYPE', {
  [ACTIONS_SUBTYPES.START]: (payload) => payload,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
})

export const CHECK_ALERT_TYPE_IS_ACTIVE = createAction(
  'CHECK_ALERT_TYPE_IS_ACTIVE',
  {
    [ACTIONS_SUBTYPES.START]: (payload) => payload,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => reason,
  },
)
