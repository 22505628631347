import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import EmailVerifyLink from 'ui/auth/emailVerfyLink/emailVerifyLink'
import PrivateRoute from './PrivateRoute' // Auth Check
import LoginReroute from './LoginReroute' // Reroute to dashboard if already logged in

import Login from '../front/Login' // Login Page
import MainPage from '../front/MainPage' // Container for all content
import Error404 from '../front/Error404' // All undefined routes lead here

import Verify from '../ui/auth/verify/index'
import ChangePassword from '../ui/auth/changePassword'
import ForgotPassword from '../ui/auth/forgotPassword'

import TermsOfService from './TermsOfService'

import { history } from '../configStore'

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" exact render={() => <LoginReroute />} />
      <Route path="/login" component={Login} />
      {/* Route to login by default */}
      <Route path="/verify" component={EmailVerifyLink} />
      <Route path="/verifyusername" component={Verify} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      {/* First time sign in - verify account & change password */}
      <Route path="/changepassword" component={ChangePassword} />
      {/* Change Password after first sign in */}
      <PrivateRoute path="/dashboard" component={MainPage} />
      <PrivateRoute path="/terms" component={TermsOfService} />
      {/* Routes to first tab available to user (Map by default) */}
      {/* Catch Unmatched Routes */}
      <Route component={Error404} />
    </Switch>
  </Router>
)

export default Routes
