/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect, useCallback } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import isArray from 'lodash/isArray'
import Files from 'react-files'
import { Document, Page, pdfjs } from 'react-pdf'
import axios from 'axios'
import ClearIcon from '@material-ui/icons/Clear'
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import ImageIcon from '@material-ui/icons/Image'

import AudioPlayer from 'react-h5-audio-player'
import ImageGallery from 'react-image-gallery'

import 'react-h5-audio-player/lib/styles.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import { ModalDialog } from 'components/Dialog'
import { withStyles } from '@material-ui/styles'
import ExternalLinkButton from 'components/ExternalLinkButton'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StyledCircularProgress = withStyles({
  root: {
    margin: '50px auto',
  },
})(CircularProgress)

const MAX_FILE_SIZE = 50000000 // 50 MB base 10 (this will match most file systems file size listing)
// TO DO
// 'video/avi': 'video/avi',
// 'image/tiff': 'image/tiff',
// 'video/x-ms-wmv': 'video/x-ms-wmv',
// .mov / 'video/quicktime': 'video/quicktime',
export const MIME_TYPE = {
  'audio/mpeg': 'audio/mpeg',
  'image/png': 'image/png',
  'image/jpeg': 'image/jpeg',
  'image/jpg': 'image/jpg',
  'video/mp4': 'video/mp4',
  'image/svg+xml': 'image/svg+xml',
  'application/pdf': 'application/pdf',
  'text/plain': 'text/plain',
}

export const AlertAttachments = ({
  isLoading,
  files,
  setFiles,
  isEdit,
  isOpen,
  showMessage,
  isRemoveDisable,
}) => {
  const [fileToRemove, setFileToRemove] = useState(undefined)
  const [numFilesPages, setNumPages] = useState([])
  const [pagePdfNumber, setPagePdfNumber] = useState(1)
  const [isGalleryFullScreen, setIsGalleryFullScreen] = useState(false)
  const [galleryFiles, setGalleryFiles] = useState([])
  const [galleryFilesLoading, setGalleryFilesLoading] = useState(true)
  const galleryRef = useRef(null)
  const [isSendAlert, setIsSendAlert] = useState(false)

  useEffect(() => {
    if (!isOpen && isEdit && setGalleryFiles) {
      setFiles([])
      setGalleryFiles([])
    }
  }, [isOpen])

  const changeFiles = useCallback(async () => {
    if (isArray(files)) {
      setNumPages(numFilesPages.splice(0, numFilesPages.length))
      setGalleryFilesLoading(true)
      const parsedData = files.map(async (file) => {
        if (typeof file === 'string') {
          const fileResponse = await axios({
            url: `https://${file}`,
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
          }).catch((err) => console.log('error axios: ', err.message))
          if (fileResponse === undefined) {
            return {
              original: null,
              thumbnail: null,
              file,
              data: null,
            }
          }
          const mimeType = fileResponse?.headers['content-type']
          if (mimeType === MIME_TYPE['application/pdf']) {
            const base64Pdf = Buffer.from(fileResponse.data).toString('base64')
            return {
              original: `data:application/pdf;base64,${base64Pdf}`,
              thumbnail: `data:application/pdf;base64,${base64Pdf}`,
              type: mimeType,
              data: fileResponse.data,
              url: `https://${file}`,
            }
          }
          if (mimeType === MIME_TYPE['text/plain']) {
            const txt = Buffer.from(fileResponse.data).toString('utf8')
            return {
              original: txt,
              thumbnail: txt,
              type: mimeType,
              data: txt,
            }
          }
          return {
            original: `https://${file}`,
            thumbnail: `https://${file}`,
            type: mimeType,
            data: fileResponse.data,
          }
        }
        return {
          original: null,
          thumbnail: null,
          file,
          data: null,
        }
      })

      const imagesResponse = await Promise.all(parsedData)

      setNumPages([])
      const pdfs = imagesResponse.filter((file) => {
        if (file.file === undefined) {
          // files in alert
          return file.type === MIME_TYPE['application/pdf']
        }
        // drag and drop -> send alert
        return file.file?.type === MIME_TYPE['application/pdf']
      })
      const others = imagesResponse.filter((file) => {
        if (file.file === undefined) {
          // files in alert
          return file.type !== MIME_TYPE['application/pdf']
        }
        // drag and drop -> send alert
        return file.file?.type !== MIME_TYPE['application/pdf']
      })

      // drag and drop -> send alert
      if (pdfs.length >= 1 && pdfs[0].file !== undefined) {
        setIsSendAlert(true)
        pdfs.sort((a, b) =>
          a.file.size > b.file.size ? 1 : b.file.size > a.file.size ? -1 : 0,
        )
      } else {
        setIsSendAlert(false)
      }

      const total = others.concat(pdfs)

      // other files
      others.forEach(() => {
        const arrayPdfNumPage = numFilesPages
        arrayPdfNumPage.push(0)
        setNumPages(arrayPdfNumPage)
      })
      // pdf files
      const promisePdfFiles = pdfs.map((file) => {
        const arr = new Uint8Array(file.data)
        return pdfjs.getDocument(arr).promise
      })

      Promise.all(promisePdfFiles).then(
        (file) => {
          file.forEach((pdf) => {
            const arrayPdfNumPage = numFilesPages
            arrayPdfNumPage.push(pdf._pdfInfo.numPages)
            setNumPages(arrayPdfNumPage)
          })
        },
        (reason) => {
          // PDF loading error
          console.error(reason)
        },
      )
      setGalleryFilesLoading(false)
      setGalleryFiles(total)
    }
  }, [files])

  useEffect(() => {
    changeFiles()
  }, [files])

  const onDocumentLoadSuccess = ({ numPages }) => {
    if (isSendAlert) {
      const arrayPdfNumPage = numFilesPages
      arrayPdfNumPage.push(numPages)
      setNumPages(arrayPdfNumPage)
      setPagePdfNumber(1)
    }
  }

  const renderGalleryItem = (file) => {
    const type = file.type ? file.type : file.file.type

    switch (type) {
      case MIME_TYPE['audio/mpeg']:
        return renderAudio(file)
      case MIME_TYPE['video/mp4']:
        return renderVideo(file)

      case MIME_TYPE['application/pdf']:
        return renderPdf(file)
      case MIME_TYPE['text/plain']:
        return renderText(file)
      // images
      case MIME_TYPE['image/jpeg']:
        return renderImage(file)
      case MIME_TYPE['image/png']:
        return renderImage(file)
      case MIME_TYPE['image/jpg']:
        return renderImage(file)
      case MIME_TYPE['image/svg+xml']:
        return renderImage(file)

      default:
        return <div />
    }
  }

  const renderThumbnail = (file) => {
    const type = file?.type ? file.type : file.file.type

    switch (type) {
      case MIME_TYPE['audio/mpeg']:
        return renderAudio(file, true)
      case MIME_TYPE['video/mp4']:
        return renderVideo(file, true)

      case MIME_TYPE['application/pdf']:
        return renderPdf(file, true)
      case MIME_TYPE['text/plain']:
        return renderText(file, true)
      case MIME_TYPE['image/jpeg']:
        return renderImage(file, true)
      case MIME_TYPE['image/png']:
        return renderImage(file, true)
      case MIME_TYPE['image/jpg']:
        return renderImage(file, true)
      case MIME_TYPE['image/svg+xml']:
        return renderImage(file, true)

      default:
        return <div />
    }
  }

  const onFilesChange = async (selectedFiles) => {
    let selectedFilesFilter = selectedFiles

    // size filter
    selectedFilesFilter = selectedFiles.filter((file) => {
      if (file.size <= MAX_FILE_SIZE) {
        return true
      }
      showMessage(`${file.name} is too large"`, true)
      return false
    })

    // MIME type filter
    selectedFilesFilter = selectedFilesFilter.filter((file) => {
      if (file.type in MIME_TYPE) {
        return true
      }
      showMessage(`${file.name} has an invalid file format`, true)
      return false
    })

    const parsedFiles = selectedFilesFilter.map(async (file) => {
      if (file.type === MIME_TYPE['application/pdf']) {
        const blob = await fetch(URL.createObjectURL(file))

        return Object.assign(file, {
          preview: await blob.blob(),
        })
      }

      if (file.type === MIME_TYPE['text/plain']) {
        const text = await new Promise((resolve) => {
          const fileReader = new FileReader()
          fileReader.onload = () => resolve(fileReader.result)
          fileReader.readAsText(file)
        })

        return Object.assign(file, {
          preview: text,
        })
      }

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    })

    const filesObject = await Promise.all(parsedFiles)

    setFiles([...files, ...filesObject])
  }

  const onFilesError = (error) => {
    let msg = error.message
    let file = 'file' // TODO have this directly get the file name
    switch (error.code) {
      default:
        break
      case 1: // invalid file type
        if (msg.length > 45) {
          file = msg
            .substring(0, 10)
            .concat(` ... ${msg.substring(msg.length - 35, msg.length - 25)}`)
        } else {
          file = msg.substring(0, msg.length - 25)
        }
        msg = `${file} has an invalid file format`
        break

      case 2: // file too large
        if (msg.length > 45) {
          file = msg
            .substring(0, 10)
            .concat(` ... ${msg.substring(msg.length - 23, msg.length - 13)}`)
          msg = `${file} is too large`
        }
        break

      case 3: // file too small - originally - we have no min file size
      case 4: // max file count reached - originally - no max file count
        break
    }
    showMessage(msg, true)
  }

  const changeThumbnailPosition = (index) => {
    setPagePdfNumber(1)
    const thumbnailContainer = galleryRef?.current.thumbnails?.current
    const thumbnailItem = galleryRef?.current.thumbnails?.current.children[0]

    if (thumbnailContainer && thumbnailItem) {
      // + 8 -> border compensation, (index - 1) * 2 -> paddingLeft compensation
      thumbnailContainer.scrollTo(
        (index + 0.5) * (thumbnailItem.clientWidth + 8) -
          thumbnailContainer.clientWidth / 2 +
          (index - 1) * 2,
        0,
      )
    }
  }

  const renderAudio = (file, isThumbnail) =>
    isThumbnail ? (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'white',
          height: '100px',
        }}
      >
        {!isRemoveDisable && isEdit && !isLoading ? (
          <ClearIcon
            aria-label="remove"
            style={{
              color: 'rgba(255, 255, 255, 0.54)',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: '0',
              width: '32px',
              height: '32px',
              left: 0,
            }}
            onClick={() => onRemoveFile(file)}
          />
        ) : null}
        <LibraryMusicIcon style={{ margin: '30px 0' }} />
        <p style={{ padding: 0 }}>Audio</p>
      </div>
    ) : (
      <div
        style={{
          padding: '100px 80px',
          height: isGalleryFullScreen ? '500px' : '350px',
        }}
      >
        <AudioPlayer src={file.type ? file.original : file.file.preview} />
      </div>
    )

  const renderImage = (file, isThumbnail) =>
    isThumbnail ? (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'white',
          height: '100px',
        }}
      >
        {!isRemoveDisable && isEdit && !isLoading ? (
          <ClearIcon
            aria-label="remove"
            style={{
              color: 'rgba(255, 255, 255, 0.54)',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: '0',
              width: '32px',
              height: '32px',
              left: 0,
            }}
            onClick={() => onRemoveFile(file)}
          />
        ) : null}
        <ImageIcon style={{ margin: '30px 0' }} />
        <p style={{ margin: 0 }}>Image</p>
      </div>
    ) : (
      <img
        style={{
          width: '100%',
          height: isGalleryFullScreen ? '500px' : '350px',
          objectFit: 'contain',
        }}
        src={file.original ? file.original : file.file.preview}
        alt="No img"
      />
    )

  const renderPdf = (file, isThumbnail) => {
    if (isThumbnail) {
      return (
        <div
          style={{
            position: 'relative',
            backgroundColor: 'white',
            height: '100px',
          }}
        >
          {!isRemoveDisable && isEdit && !isLoading ? (
            <ClearIcon
              aria-label="remove"
              style={{
                color: 'rgba(255, 255, 255, 0.54)',
                position: 'absolute',
                backgroundColor: 'rgba(0,0,0,0.8)',
                borderRadius: '0',
                width: '32px',
                height: '32px',
                left: 0,
              }}
              onClick={() => onRemoveFile(file)}
            />
          ) : null}
          <PictureAsPdfIcon style={{ margin: '30px 0' }} />
          <p style={{ margin: 0 }}>PDF</p>
        </div>
      )
    }
    return (
      <div
        style={{
          height: isGalleryFullScreen ? '500px' : '350px',
          position: 'relative',
        }}
      >
        <Document
          file={file.original ? file.original : file.file.preview}
          // file={file.url}
          onLoadSuccess={onDocumentLoadSuccess}
          style={{ position: 'absolute' }}
          // externalLinkTarget={"_blank"}
          // externalLinkRel={"noreferrer"}
        >
          <Page pageNumber={pagePdfNumber} />
        </Document>
        {file.url !== undefined && (
          <div style={{ position: 'absolute', top: '0px' }}>
            <ExternalLinkButton url={file.url} />
          </div>
        )}
      </div>
    )
  }

  const renderVideo = (file, isThumbnail) =>
    isThumbnail ? (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'white',
          height: '100px',
        }}
      >
        {!isRemoveDisable && isEdit && !isLoading ? (
          <ClearIcon
            aria-label="remove"
            style={{
              color: 'rgba(255, 255, 255, 0.54)',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: '0',
              width: '32px',
              height: '32px',
              left: 0,
            }}
            onClick={() => onRemoveFile(file)}
          />
        ) : null}
        <VideoLibraryIcon style={{ margin: '30px 0' }} />
        <p style={{ margin: 0 }}>Video</p>
      </div>
    ) : (
      <div
        style={{
          margin: '0 80px',
          height: isGalleryFullScreen ? '500px' : '350px',
        }}
      >
        <video
          controls
          src={isEdit ? file.file.preview : file.original}
          width="100%"
          height={isGalleryFullScreen ? '500px' : '220px'}
          style={{ margin: '50px 0' }}
        >
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    )

  const renderText = (file, isThumbnail) =>
    isThumbnail ? (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'white',
          height: '100px',
        }}
      >
        {!isRemoveDisable && isEdit && !isLoading ? (
          <ClearIcon
            aria-label="remove"
            style={{
              color: 'rgba(255, 255, 255, 0.54)',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: '0',
              width: '32px',
              height: '32px',
              left: 0,
            }}
            onClick={() => onRemoveFile(file)}
          />
        ) : null}
        <TextFieldsIcon style={{ margin: '30px 0' }} />
        <p style={{ margin: 0 }}>Text</p>
      </div>
    ) : (
      <div
        style={{
          position: 'relative',
          height: isGalleryFullScreen ? '500px' : '350px',
          wordBreak: 'break-word',
          MozHyphens: 'auto',
          WebkitHyphens: 'auto',
          OHyphens: 'auto',
          hyphens: 'auto',
          whiteSpace: 'break-spaces',
          overflow: 'scroll',
          zIndex: 100,
          backgroundColor: 'white',
          margin: '0 80px',
        }}
      >
        <Typography>{file.type ? file.original : file.file.preview}</Typography>
      </div>
    )

  const onRemoveFile = (file) => {
    setFileToRemove(file)
  }

  const onRemoveModalClose = () => {
    setFileToRemove(undefined)
  }

  const onSubmitRemoveFile = () => {
    const updatedFiles = files.filter(
      (file) => file.id !== fileToRemove.file.id,
    )
    setFiles(updatedFiles)
    setFileToRemove(undefined)
  }

  return (
    <>
      <ModalDialog
        open={!!fileToRemove}
        close={onRemoveModalClose}
        title={<p>Do you want remove this file?</p>}
        buttonText="Remove"
        onSubmit={onSubmitRemoveFile}
      />
      <div
        style={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          paddingBottom: '36px',
        }}
      >
        {galleryFilesLoading ? (
          <StyledCircularProgress size={32} />
        ) : galleryFiles.length ? (
          <ImageGallery
            ref={galleryRef}
            items={galleryFiles}
            showThumbnails={
              isArray(galleryFiles) ? !!galleryFiles.length : !!galleryFiles
            }
            thumbnailPosition="bottom"
            showFullscreenButton
            showNav
            autoPlay={false}
            showPlayButton={false}
            useBrowserFullscreen={false}
            showIndex
            swipingTransitionDuration={100}
            slideDuration={100}
            disableThumbnailScroll
            onSlide={changeThumbnailPosition}
            renderItem={renderGalleryItem}
            renderThumbInner={renderThumbnail}
            onScreenChange={(fullScreen) => {
              setIsGalleryFullScreen(fullScreen)
              if (!fullScreen) {
                setTimeout(() => {
                  changeThumbnailPosition(galleryRef.current.state.currentIndex)
                }, 10)
              }
            }}
          />
        ) : (
          <Typography fontSize="1.5rem" margin="2rem auto" variant="body2">
            No files
          </Typography>
        )}

        {!isLoading && isEdit ? (
          <Files
            style={{
              textAlign: 'center',
              borderWidth: '1px',
              borderRadius: '5px',
              padding: galleryFiles.length ? '8px 0' : '180px 0',
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}
            onChange={onFilesChange}
            onError={onFilesError}
            multiple
            minFileSize={0}
          >
            Drop files here or click to upload
            <p style={{ padding: '0 20px', margin: 0 }}>
              Supported formats:{' '}
              <b>
                <span>.png, .jpg, .svg, .mp3, .mp4, .pdf, .txt</span>
              </b>
            </p>
            <p style={{ padding: '0 20px', margin: 0 }}>Max file size 50 MB</p>
          </Files>
        ) : null}
      </div>
    </>
  )
}
