import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { FLARE_API_NAME, FLARE_PATH } from 'api/flare'
import { getAuthToken } from 'api/utils/getAuthToken'
import { getStationId } from 'components/StationDetect'

import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default () =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const geofenceId = getStationId().toLowerCase()
    const path = `${FLARE_PATH.latest}/types/from/station/${geofenceId}`

    try {
      const token = await getAuthToken()
      const requestParams = {
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
          Authorization: token,
        },
      }
      const response = await API.get(FLARE_API_NAME, path, requestParams)

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: FLARE_API_NAME + path,
        payload: { geofenceId },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
