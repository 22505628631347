import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { LOGS_API_NAME, LOG_PATH } from 'api/logs'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default ({ itemId, lastId = null }) =>
  new Promise(async (resolve) => {
    let path = `${LOG_PATH.latest}/from/${itemId}`

    if (lastId) {
      path += `?lastId=${lastId}&perPage=5`
    } else {
      path += `?perPage=5`
    }

    const result = {
      error: undefined,
      response: undefined,
    }

    const token = await getAuthToken()
    try {
      const response = await API.get(LOGS_API_NAME, path, {
        headers: {
          Authorization: token,
        },
      })

      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: LOGS_API_NAME + path,
        payload: {
          itemId,
        },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
