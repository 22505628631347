/* eslint-disable no-restricted-syntax */
import { put, select } from 'redux-saga/effects'

import api from 'api'

import { activeFilterSelector } from 'modules/flares/selectors'
import { ACTIVE_FLARES_GET } from 'modules/flares/actions'
import { PER_PAGE } from '../../../../constants'

export default function* getActiveFlaresSaga({ payload }) {
  const { page, isNextPage } = payload
  const filters = yield select(activeFilterSelector)
  const { response, error } = yield api.flare.getFlares({
    perPage: PER_PAGE,
    page,
    filters,
  })

  if (error) {
    console.log('getActiveFlaresSaga error', error)

    yield put(ACTIVE_FLARES_GET.FAILED.create(error.message))
  } else {
    console.log('getActiveFlaresSaga response', response)

    yield put(
      ACTIVE_FLARES_GET.SUCCESS.create({
        flares: response.flares,
        totalItems: +response.totalItems,
        isNextPage,
        page,
      }),
    )
  }
}
