import React, { Component } from 'react'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Howler } from 'howler'

import { getStationId } from 'components/StationDetect'
import { LogType } from 'constants.js'
import { decodeJWTToken } from '../utils/decodeJWTToken'
import { GET_FLARE_FROM_SOCKET_MESSAGE } from '../modules/flares/actions'
import { GET_USER_GENERAL_SETTINGS } from '../modules/generalSettings/actions'
import { audioPlayer } from '../utils/notificationSound'

export const Version = {
  LEGACY: '',
  LATEST: '/new',
}

export const WEBSOCKET_PATH = {
  legacy: process.env.REACT_APP_SOCKET_URL + Version.LEGACY,
  latest: process.env.REACT_APP_SOCKET_URL + Version.LATEST,
}

class PrivateRoute extends Component {
  socket = null

  socketReconectedCount = 0

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      isAuthenticated: false,
      appruveToS: false,
    }
  }

  // Checks if user is authenticated
  componentDidMount() {
    this.props.getUserGeneralSettings()
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser().catch(() => {
        if (this.state.isAuthenticated) {
          if (this.socket) this.socket.close()
          this.setState({ isAuthenticated: false })
        }
      })
    })
    Howler.autoUnlock = true
    Howler.usingWebAudio = true
  }

  componentWillUnmount() {
    this.unlisten()
    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
  }

  _changeToS = () => {
    this.setState({ appruveToS: true })
  }

  socketOnMessageCb = (message) => {
    if (message && message.data) {
      /* eslint-disable camelcase */
      const { FeatureId, FeatureType, Type } = JSON.parse(message.data)
      if (FeatureType === 'Flare') {
        const id = FeatureId
        if (Type === LogType.CREATED) {
          const notificationSound = audioPlayer()
          notificationSound.play()
        }
        this.props.getFlareFromSocketMessage({ flareId: id, type: Type })
      }
    }
  }

  setUpWebsocketConnection = async (onMessageCb) => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })

    const socket = new W3CWebSocket(
      `${WEBSOCKET_PATH.latest}?token=${
        user.signInUserSession.idToken.jwtToken
      }&station=${getStationId()}`,
      undefined,
      undefined,
      undefined,
      undefined,
    )

    socket.onopen = () => {
      this.socketReconectedCount = 0
      console.log('WebSocket Client Connected')
      this.socketReconectedCount = 0
    }

    socket.onmessage = (message) => {
      onMessageCb(message)
    }

    socket.onclose = () => {
      console.log('Socket close')
      if (this.socketReconectedCount <= 5) {
        this.socketReconectedCount += 1
        setTimeout(() => this.setUpWebsocketConnection(onMessageCb), 5000)
      }
    }

    socket.onerror = (error) => {
      console.log('Socket error', error)
      if (this.socketReconectedCount <= 5) {
        this.socketReconectedCount += 1
        setTimeout(() => this.setUpWebsocketConnection(onMessageCb), 5000)
      }
    }

    return socket
  }

  // eslint-disable-next-line lines-between-class-members
  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const token = user.signInUserSession.idToken.jwtToken
        const decodedJWT = decodeJWTToken(token)

        this.socket = await this.setUpWebsocketConnection(
          this.socketOnMessageCb,
        )

        this.setState({
          loaded: true,
          isAuthenticated: true,
          appruveToS: decodedJWT.tosaccepted === 'true',
        })
      })
      .catch(() => {
        if (this.socket) {
          this.socket.close()
          this.socket = null
        }
        this.props.history.push('/login', { ...this.props.location })
      })
  }

  _renderTos(routerProps) {
    // eslint-disable-next-line no-shadow
    const { path, component: Component } = this.props
    const { appruveToS } = this.state
    if (path === '/terms' && !appruveToS) {
      return <Component {...routerProps} changeTos={this._changeToS} />
    }
    if (appruveToS && path === '/terms') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
          }}
        />
      )
    }
    if (appruveToS && path !== '/terms') {
      return <Component {...routerProps} />
    }
    return (
      <Redirect
        to={{
          pathname: '/terms',
        }}
      />
    )
  }

  // Reroute to login unless authenticated
  render() {
    // eslint-disable-next-line no-shadow
    const { component: Component, ...rest } = this.props
    const { loaded, isAuthenticated } = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            this._renderTos(props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          )
        }
      />
    )
  }
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFlareFromSocketMessage: GET_FLARE_FROM_SOCKET_MESSAGE.START.create,
      getUserGeneralSettings: GET_USER_GENERAL_SETTINGS.START.create,
    },
    dispatch,
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PrivateRoute)
