import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import { CognitoIdentityServiceProvider } from 'aws-sdk'
import { push } from 'connected-react-router'
import { getStationId } from '../../../components/StationDetect'
import { decodeJWTToken } from '../../../utils/decodeJWTToken'
import { CHANGE_PASSWORD } from '../actions'

export default function* forgotPasswordSaga(action) {
  // eslint-disable-next-line camelcase
  const { username, session, new_password } = action.payload
  const params = {
    ChallengeName: 'NEW_PASSWORD_REQUIRED',
    ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: new_password,
    },
    Session: session,
  }

  try {
    const credentials = yield Auth.currentCredentials()
    const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
      apiVersion: '2016-04-18',
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
      credentials: Auth.essentialCredentials(credentials),
    })

    yield cognitoIdentityServiceProvider
      .respondToAuthChallenge(params)
      .promise()

    yield Auth.signIn(username, new_password)
    const userData = yield Auth.currentAuthenticatedUser()

    const token = userData.signInUserSession.idToken.jwtToken
    const decodedJWT = yield decodeJWTToken(token)

    if (
      decodedJWT.station === getStationId().toLowerCase() ||
      decodedJWT.station === 'All'
    ) {
      yield put(push('/dashboard'))
    } else {
      throw new Error('Station not exist')
    }
  } catch (error) {
    yield put(CHANGE_PASSWORD.FAILED.create(error.message))
  }
}
