import { put } from 'redux-saga/effects'

import api from 'api'

import {
  ACTIVE_FLARE_UPDATE,
  GET_ACTIVE_FLARE_LOGS,
  CLOSED_FLARES_GET,
} from 'modules/flares/actions'
import { ProgressStage } from '../../../../constants'

export default function* updateActiveFlareSaga(action) {
  const { flareId, flareData } = action.payload

  const { response, error } = yield api.flare.updateFlare(flareId, flareData)

  if (error) {
    console.log('updateActiveFlareSaga error', error)

    yield put(ACTIVE_FLARE_UPDATE(action.id).FAILED.create(error.message))
  } else {
    console.log('updateActiveFlareSaga response', response)
    yield put(
      ACTIVE_FLARE_UPDATE(action.id).SUCCESS.create({
        updatedFlare: response,
      }),
    )
    if (
      response.flare.isClosed ||
      response.flare.status === ProgressStage.COMPLETED
    ) {
      // flare move to history
      yield put(CLOSED_FLARES_GET.START.create({ page: 1 }))
    } else {
      // flare is still open
      yield put(GET_ACTIVE_FLARE_LOGS.START.create({ flareId }))
    }
  }
}
