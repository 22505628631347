/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import { GET_ALERT_LOGS } from 'modules/alerts/actions'

import {
  alertLogs,
  logsHasMore,
  getLastAlertLogId,
} from 'modules/alerts/selectors'
import { isLoadingSelector } from 'modules/requestsStatuses/selectors'

import { LinkItUrl } from 'react-linkify-it'
import { LogType, UrgencyLevels } from '../../constants'

const StyledCircularProgress = withStyles({
  root: {
    marginTop: '50px',
    marginLeft: '100px',
  },
})(CircularProgress)

export const AlertLogs = ({ alertId }) => {
  const dispatch = useDispatch()

  const logs = useSelector((state) => alertLogs(state, alertId))
  const hasMore = useSelector((state) => logsHasMore(state, alertId))
  const isLoading = useSelector((state) =>
    isLoadingSelector(state, GET_ALERT_LOGS),
  )
  const lastId = useSelector((state) => getLastAlertLogId(state, alertId))
  useEffect(() => {
    dispatch(GET_ALERT_LOGS.START.create({ alertId }))
  }, [])

  const loadMoreHandle = () => {
    if (!logs) return
    dispatch(GET_ALERT_LOGS.START.create({ alertId, lastId }))
  }

  return (
    <>
      {logs && logs.length ? (
        <List>
          {logs.map((flareLog) => {
            const parsedPayload = flareLog.payload ?? {}
            const payload = {}
            switch (flareLog.type) {
              case LogType.CREATED:
                payload.status = 'Created'
                break
              case LogType.NOTE_ADDED:
                payload.status = 'Add message'
                payload.message = parsedPayload.message
                break
              case LogType.STATUS_UPDATE: {
                if (parsedPayload.newUrgency) {
                  payload.status = `Change priority to ${
                    Object.values(UrgencyLevels)[parsedPayload.newUrgency - 1]
                  } from ${
                    parsedPayload.oldUrgency === 0 ||
                    parsedPayload.oldUrgency === null
                      ? 'N/A'
                      : Object.values(UrgencyLevels)[
                          parsedPayload.oldUrgency - 1
                        ]
                  }`
                  payload.message = parsedPayload.message
                }
                break
              }
              case LogType.FILES_UPDATED: {
                payload.status = 'Added files'
                payload.message = parsedPayload.message
                  ? `${parsedPayload.message}`
                  : ''
                break
              }
              case LogType.CLOSED: {
                if (parsedPayload.oldUrgency) {
                  payload.status = `Closed`
                  payload.message = parsedPayload.message
                }
                break
              }
              default:
                break
            }
            return (
              <React.Fragment key={flareLog.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={`${
                      payload.status ? payload.status : ''
                    } (${new Date(flareLog.createdAt).toLocaleString('en-US', {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })})`}
                    secondary={
                      <LinkItUrl>
                        <Typography style={descriptionStyle} component="span">
                          {flareLog.operator?.name}
                          {payload.message ? ` - ${payload.message}` : null}
                        </Typography>
                      </LinkItUrl>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            )
          })}
        </List>
      ) : null}
      {logs?.length === 0 && !isLoading ? (
        'No logs'
      ) : isLoading ? (
        <StyledCircularProgress size={32} style={{ marginTop: 0 }} />
      ) : hasMore ? (
        <Button fullWidth onClick={() => loadMoreHandle()} color="primary">
          Load More
        </Button>
      ) : null}
    </>
  )
}

const descriptionStyle = {
  wordBreak: 'break-word',
  MozHyphens: 'auto',
  WebkitHyphens: 'auto',
  OHyphens: 'auto',
  hyphens: 'auto',
  whiteSpace: 'break-spaces',
}
