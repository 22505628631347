import React, { useCallback } from 'react'
import FlaresList from 'components/FlareList/FlaresList'
import { useDispatch, useSelector } from 'react-redux'
import {
  activeFlaresPageSelector,
  activeFlareTypesFilterSelector,
  getActiveFlares,
  totalActiveFlaresSelector,
} from '../../../../modules/flares/selectors'
import {
  ACTIVE_FLARE_UPDATE,
  ACTIVE_FLARES_GET,
  ADD_ACTIVE_FLARE_NOTE,
  GET_ACTIVE_FLARE_LOGS,
  UPDATE_ACTIVE_FLARE_ADDITIONAL_FILTER,
  ADD_FLARE_TO_SELECTION,
  REMOVE_FLARE_FROM_SELECTION,
  CREATE_FLARE_GROUP,
  UPDATE_ACTIVE_GROUP,
  UPDATE_FLARES_IN_GROUP,
  ACTIVE_FLARES_GET_GROUPED,
} from '../../../../modules/flares/actions'
import {
  isLoadingSelector,
  isSuccessSelector,
} from '../../../../modules/requestsStatuses/selectors'
import { initialFlareFilterState } from '../../../../constants'

const ActiveFlares = () => {
  const flares = useSelector(getActiveFlares)
  const totalFlares = useSelector(totalActiveFlaresSelector)
  const selectedFilterFlareTypes = useSelector(activeFlareTypesFilterSelector)
  const activeFlaresPage = useSelector(activeFlaresPageSelector)
  const activeFlareLoading = useSelector((state) =>
    isLoadingSelector(state, ACTIVE_FLARES_GET),
  )
  const isLoadingLogs = useSelector((state) =>
    isLoadingSelector(state, GET_ACTIVE_FLARE_LOGS),
  )
  const dispatch = useDispatch()
  const isSuccessUpdateGroup = useSelector((state) =>
    isSuccessSelector(state, UPDATE_ACTIVE_GROUP),
  )

  const getMoreActiveFlares = useCallback(
    (payload) => {
      dispatch(ACTIVE_FLARES_GET_GROUPED.START.create(payload))
    },
    [dispatch],
  )
  const getFlareLogs = useCallback(
    (payload) => {
      dispatch(GET_ACTIVE_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )
  const getMoreFlareLogs = useCallback(
    (payload) => {
      dispatch(GET_ACTIVE_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )
  const handleUpdateDataFlareActive = useCallback(
    (payload) => {
      const { flareId } = payload
      dispatch(ACTIVE_FLARE_UPDATE(flareId).START.create(payload))
    },
    [dispatch],
  )

  const handleAddNoteFlareActive = useCallback(
    (payload) => {
      dispatch(ADD_ACTIVE_FLARE_NOTE.START.create(payload))
    },
    [dispatch],
  )

  const handleFiltersUpdate = useCallback(
    (payload) => {
      dispatch(
        UPDATE_ACTIVE_FLARE_ADDITIONAL_FILTER.START.create({
          flareTypes: payload,
        }),
      )
      dispatch(
        ACTIVE_FLARES_GET_GROUPED.START.create({
          filters: {
            ...initialFlareFilterState,
            types: payload,
          },
        }),
      )
    },
    [dispatch],
  )

  const addFlareToSelection = useCallback(
    (flare) => {
      dispatch(ADD_FLARE_TO_SELECTION.START.create(flare))
    },
    [dispatch],
  )

  const removeFlareFromSelection = useCallback(
    (flare) => {
      dispatch(REMOVE_FLARE_FROM_SELECTION.START.create(flare))
    },
    [dispatch],
  )

  const handleCreateGroup = useCallback(
    (payload) => {
      dispatch(CREATE_FLARE_GROUP.START.create(payload))
    },
    [dispatch],
  )

  const handleUpdateFlaresInGroup = useCallback(
    (payload) => {
      dispatch(UPDATE_FLARES_IN_GROUP.START.create(payload))
    },
    [dispatch],
  )

  const handleUpdateDataGroup = useCallback((payload) => {
    dispatch(UPDATE_ACTIVE_GROUP.START.create(payload))
  })

  return (
    <FlaresList
      flaresAndGroup={flares}
      getMoreFlares={getMoreActiveFlares}
      getFlareLogs={getFlareLogs}
      getMoreFlareLogs={getMoreFlareLogs}
      totalFlares={totalFlares}
      handleUpdateDataFlare={handleUpdateDataFlareActive}
      handleAddNoteFlare={handleAddNoteFlareActive}
      selectedFilterFlareTypes={selectedFilterFlareTypes}
      onFiltersUpdate={handleFiltersUpdate}
      loading={activeFlareLoading}
      flaresPage={activeFlaresPage}
      addFlareToSelection={addFlareToSelection}
      removeFlareFromSelection={removeFlareFromSelection}
      handleCreateGroup={handleCreateGroup}
      handleUpdateFlaresInGroup={handleUpdateFlaresInGroup}
      handleUpdateDataGroup={handleUpdateDataGroup}
      isLoadingLogs={isLoadingLogs}
      isSuccessUpdateGroup={isSuccessUpdateGroup}
    />
  )
}

export default React.memo(ActiveFlares)
