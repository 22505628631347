import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ALERTS_ADD_LOG } from 'modules/alerts/actions'
import { rootUserSelector } from 'modules/user/selectors'

import AlertListItem from './AlertListItem'

import { UrgencyLevels } from '../../constants'

const mapStateToProps = (state, props) => {
  const { alert } = props

  const placeholder = 'Data unavailable'

  const subjectSelector = () => {
    if (alert.type && alert.type.name) {
      return alert.type.name
    }
    if (alert.subject) {
      return alert.subject
    }
    return placeholder
  }

  return {
    id: alert.id ? alert.id : '',
    currentUser: rootUserSelector(state),
    geofence: alert.subdomain ? alert.subdomain : placeholder,
    timestamp: new Date(alert.createdAt).toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    }),
    isClosed: alert.isClosed,
    subject: subjectSelector(),
    coordinates:
      alert.latitude && alert.longitude
        ? { lat: alert.latitude, lng: alert.longitude }
        : { lat: 0, lng: 0 },
    address: alert.address ? alert.address : placeholder,
    urgency: alert.urgency ? UrgencyLevels[alert.urgency] : 'N/A',
    details: alert.details ? alert.details : placeholder,
    attachments: alert.files ? alert.files : [],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addAlertLog: ALERTS_ADD_LOG.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertListItem)
