import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { UPDATE_ALERT } from 'modules/alerts/actions'

import {
  isLoadingSelector,
  reasonFailedSelector,
  isSuccessSelector,
} from 'modules/requestsStatuses/selectors'

import { AlertAttachments } from 'dashboard/nav/SendAlert/AlertAttachments'
import { ButtonWithLoader } from 'ui/shared/common'

const toastId = 'UPDATE_FILES_ALERT_TOAST'

export const UpdateFilesDialog = ({ isOpen, alertId, handleClose }) => {
  const dispatch = useDispatch()
  const [updatedFiles, seUpdatedFiles] = useState([])
  const isLoading = useSelector((state) =>
    isLoadingSelector(state, UPDATE_ALERT),
  )
  const error = useSelector((state) =>
    reasonFailedSelector(state, UPDATE_ALERT),
  )
  const success = useSelector((state) => isSuccessSelector(state, UPDATE_ALERT))

  useEffect(() => {
    if (error && isOpen) {
      showMessage(error, true)
    }
  }, [error])

  useEffect(() => {
    if (success && isOpen) {
      showMessage('Alert successful updated!')
    }
  }, [success])

  const showMessage = (reason, isError) => {
    if (isError) {
      toast.error(reason, {
        toastId: `${toastId}-${reason}`,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    } else {
      toast.success(reason, {
        toastId,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
      handleClose()
    }
  }

  const updateAlert = () => {
    const body = {
      files: updatedFiles,
    }

    dispatch(UPDATE_ALERT.START.create({ alertId, body }))
  }

  return (
    <Dialog open={isOpen}>
      <div style={{ padding: '20px', margin: 0 }}>
        <AlertAttachments
          isEdit
          isLoading={isLoading}
          files={updatedFiles}
          setFiles={seUpdatedFiles}
          isOpen={isOpen}
          showMessage={showMessage}
        />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ButtonWithLoader
            isDisabled={!updatedFiles.length}
            onClick={updateAlert}
            isLoading={isLoading}
            text="Submit"
          />
        </DialogActions>
      </div>
    </Dialog>
  )
}
