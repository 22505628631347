/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import ClearIcon from '@material-ui/icons/Clear'
import { includes } from 'lodash'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/core'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import EditTypeDialog from './AlertTypesDialogs/EditTypeDialog'
import { UPDATE_ALERT_TYPE } from '../modules/alerts/actions'
import { useStatusesSelector } from '../hooks/useStatusesSelector'

const StyledList = withStyles({
  root: {
    height: 250,
    minWidth: 450,
    margin: '10px 0',
    overflowY: 'scroll',
  },
})(List)

const override = css`
  display: block;
  margin: 100px auto;
`

export const TypeSettingsList = memo(
  ({
    data,
    type,
    selectedTypes,
    usedAlertTypes,
    setSelectedTypes,
    placeholder,
  }) => {
    const [options, setOptions] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [usedAlertType, setUsedAlertType] = useState(null)
    const [isUsedAlertTypesDialogOpen, setIsUsedAlertTypesDialogOpen] =
      useState(false)

    useEffect(() => {
      if (data.length) {
        setOptions(data)
      }
    }, [data])

    useEffect(() => {
      if (selectedTypes.length) {
        setSelectedItems(selectedTypes)
      }
    }, [selectedTypes])

    useEffect(() => {
      if (searchValue) {
        setOptions(() =>
          data.filter((option) => {
            const formattedName = option.name.toLowerCase()
            return includes(formattedName, searchValue.toLowerCase())
          }),
        )
        return
      }
      setOptions(data)
    }, [searchValue, data])

    const handleSearch = (e) => {
      setSearchValue(e.target.value)
    }

    const handleToggle = useCallback(
      (value) => () => {
        const isAlreadyAdded = selectedItems.find((i) => i.name === value.name)
        if (isAlreadyAdded) {
          if (usedAlertTypes?.some((item) => item.id === value.id)) {
            setUsedAlertType(value)
            // return setIsUsedAlertTypesDialogOpen(true)
            const updatedTypes = selectedTypes.filter(
              (i) => i.name !== value.name,
            )
            setSelectedItems(updatedTypes)
            setSelectedTypes(updatedTypes)
            return
          }
          const updatedTypes = selectedTypes.filter(
            (i) => i.name !== value.name,
          )
          setSelectedItems(updatedTypes)
          setSelectedTypes(updatedTypes)
          return
        }
        const updatedTypes = [...selectedTypes, value]
        setSelectedItems(updatedTypes)
        setSelectedTypes(updatedTypes)
      },
      [selectedItems],
    )

    const handleUsedAlertSubmit = () => {
      setIsUsedAlertTypesDialogOpen(false)
      const updatedTypes = selectedTypes.filter(
        (i) => i.name !== usedAlertType.name,
      )
      setSelectedItems(updatedTypes)
      setSelectedTypes(updatedTypes)
    }

    return (
      <>
        <TextField
          variant="standard"
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSearch}
          InputProps={{
            endAdornment: searchValue && (
              <InputAdornment style={{ cursor: 'pointer' }} position="end">
                <ClearIcon onClick={() => setSearchValue('')} />
              </InputAdornment>
            ),
          }}
        />
        <StyledList
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        >
          {options.length ? (
            options.map((item) => (
              <TypeSettingsListItem
                type={type}
                key={item.id}
                item={item}
                selectedItems={selectedItems}
                handleToggle={handleToggle}
              />
            ))
          ) : searchValue ? (
            <Typography fontSize="1.5rem" align="center">
              Not found
            </Typography>
          ) : (
            <ClipLoader css={override} sizeUnit="%" size={35} color="#0052a5" />
          )}
        </StyledList>
        {isUsedAlertTypesDialogOpen && (
          <CheckAlertDialog
            isVisible={isUsedAlertTypesDialogOpen}
            alertType={usedAlertType}
            handleCloseDialog={() => setIsUsedAlertTypesDialogOpen(false)}
            onSubmitModal={handleUsedAlertSubmit}
          />
        )}
      </>
    )
  },
)

const TypeSettingsListItem = memo(
  ({ item, type, selectedItems, handleToggle }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isEditAlertTypesSuccess, isEditAlertTypesLoading] =
      useStatusesSelector(UPDATE_ALERT_TYPE)
    const dispatch = useDispatch()
    const labelId = `checkbox-list-label-${item.id}`

    useEffect(() => {
      if (isEditAlertTypesSuccess) {
        setIsModalVisible(false)
      }
    }, [isEditAlertTypesSuccess])

    const handleEditAlertType = (e) => {
      e.stopPropagation()
      setIsModalVisible(true)
    }

    const handleCloseModal = () => {
      setIsModalVisible(false)
    }

    const handleSubmitAlertTypeEditing = (
      typeName,
      description,
      mandatoryNotified,
    ) => {
      dispatch(
        UPDATE_ALERT_TYPE.START.create({
          typeId: item.id,
          typeName,
          description: description === '' ? 'N/A' : description,
          mandatoryNotified,
        }),
      )
    }

    return (
      <ListItem disablePadding>
        <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selectedItems.indexOf(item) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={item.name} />
          <ListItemIcon>
            {type === 'alert' && item.subdomain !== null ? (
              <IconButton onClick={handleEditAlertType} aria-label="edit">
                <EditIcon fontSize="small" />
              </IconButton>
            ) : null}
          </ListItemIcon>
        </ListItemButton>
        <EditTypeDialog
          title="Edit alert type"
          type="alert"
          typeData={item}
          isVisible={isModalVisible}
          isLoading={isEditAlertTypesLoading}
          onSubmitModal={handleSubmitAlertTypeEditing}
          handleCloseDialog={handleCloseModal}
        />
      </ListItem>
    )
  },
)

const CheckAlertDialog = ({
  isVisible,
  alertType,
  handleCloseDialog,
  onSubmitModal,
}) => (
  <Dialog open={isVisible} fullWidth aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 10 }}>
      Warning
    </DialogTitle>
    <DialogContent>
      <p>This alert type &quot;{alertType.name}&quot; has active alerts.</p>
      <p>
        If you disable this alert type, users won&apos;t get notifications when
        alerts will be updated. Do you want to continue?
      </p>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDialog} color="primary">
        Cancel
      </Button>
      <Button color="primary" onClick={onSubmitModal}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)
