import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ListHeader from '../../../../components/FlareList/ListHeader'
import { FlareItem } from '../../../../components/FlareList/FlareListItem'
import {
  ADD_FLARE_NOTE,
  FLARE_UPDATE,
  GET_FLARE,
  GET_FLARE_LOGS,
} from '../../../../modules/flares/actions'
import { getFlareSelector } from '../../../../modules/flares/selectors'
import { flareActions } from '../../../../utils/getFormDialogInfo'

const useClasses = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 44,
    paddingRight: 44,
    marginTop: 44,
  },
}))

const SingleFlare = () => {
  const classes = useClasses()
  const { id } = useParams()
  const flare = useSelector((state) => getFlareSelector(state, id))
  const dispatch = useDispatch()
  const [historyMode, setHistoryMode] = useState(false)

  useEffect(() => {
    dispatch(GET_FLARE.START.create(id))
  }, [])

  const getFlareLogs = useCallback(
    (payload) => {
      dispatch(GET_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )

  const getMoreFlareLogs = useCallback(
    (payload) => {
      dispatch(GET_FLARE_LOGS.START.create(payload))
    },
    [dispatch],
  )

  const handleUpdateDataFlare = useCallback(
    (payload) => {
      const { flareId, flareAction } = payload
      if (flareAction === flareActions.MOVE_TO_HISTORY) {
        setHistoryMode(true)
      }
      if (flareAction === flareActions.MAKE_ACTIVE) {
        setHistoryMode(false)
      }
      dispatch(FLARE_UPDATE(flareId).START.create(payload))
    },
    [dispatch],
  )

  const handleAddNoteFlare = useCallback(
    (payload) => {
      dispatch(ADD_FLARE_NOTE.START.create(payload))
    },
    [dispatch],
  )

  return (
    <div className={classes.root}>
      <ListHeader isSingleFlare />

      {flare && (
        <FlareItem
          key={`flare-item-${id}`}
          flare={flare}
          historyMode={historyMode}
          getFlareLogs={getFlareLogs}
          getMoreFlareLogs={getMoreFlareLogs}
          handleUpdateDataFlare={handleUpdateDataFlare}
          handleAddNoteFlare={handleAddNoteFlare}
          isSingleFlare
        />
      )}
    </div>
  )
}

export default SingleFlare
