import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ChangePassword from './changePassword'

import { navigationStateSelector } from '../../../modules/selectors'
import {
  reasonFailedSelector,
  isLoadingSelector,
} from '../../../modules/requestsStatuses/selectors'

import { CHANGE_PASSWORD } from '../../../modules/auth/actions'

function mapStateToProps(state) {
  return {
    changePasswordErrorMessage: reasonFailedSelector(state, CHANGE_PASSWORD),
    isChangePasswordFetching: isLoadingSelector(state, CHANGE_PASSWORD),
    routerState: navigationStateSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changePassword: CHANGE_PASSWORD.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
