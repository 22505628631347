import { API } from 'aws-amplify'
import { ALERT_API_NAME, ALERT_PATH } from 'api/alerts'
import { getAuthToken } from 'api/utils/getAuthToken'
import { getStationId } from 'components/StationDetect'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'

export default ({ isClosed = false, page = 1, perPage = 20 } = {}) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const stationId = getStationId()
    const path = `${ALERT_PATH.latest}/from/station/${stationId}`

    try {
      const token = await getAuthToken()

      const requestParams = {
        queryStringParameters: {
          page,
          perPage,
          isClosed,
        },
        headers: {
          Authorization: token,
        },
      }

      result.response = await API.get(ALERT_API_NAME, path, requestParams)
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: 'AWS get alerts',
        payload: {
          geofence: getStationId(),
        },
        error: parsedError,
      })

      result.error = parsedError
    }

    resolve(result)
  })
