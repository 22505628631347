import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'

import { AUTH_FORGOT_PASSWORD_RESEND_CODE } from '../actions'

export default function* forgotPasswordSaga(action) {
  const { username } = action.payload
  try {
    yield Auth.forgotPassword(username)
    yield put(AUTH_FORGOT_PASSWORD_RESEND_CODE.SUCCESS.create())
  } catch (error) {
    if (error.code === 'UserNotFoundException') {
      yield put(AUTH_FORGOT_PASSWORD_RESEND_CODE.SUCCESS.create())
      return
    }
    yield put(AUTH_FORGOT_PASSWORD_RESEND_CODE.FAILED.create(error.message))
  }
}
