import React, { useState } from 'react'
// noinspection ES6CheckImport
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import {
  isFlareOptionAvailableSelector,
  isAlertOptionAvailableSelector,
} from 'modules/geofence/selectors'
import { getAlertAvailableSelector } from 'modules/generalSettings/selectors'

import AlertsPage from '../AlertsPage'
import WatchMyHomePage from '../WatchMyHome'
import PastFlares from './PastFlares/PastFlares'

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
  tabs: {
    marginTop: '1rem',
    margin: '0 auto',
    border: '1px solid #c4c4c4',
    borderRadius: '.25rem',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.50)',
    boxSizing: 'border-box',
    width: 'max-content',
  },
  tab: {
    width: 310,
    maxWidth: 310,
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      opacity: '.85',
      transitionDuration: '.5s',
    },
    '&.Mui-selected': {
      backgroundColor: '#c3d9ff',
      transitionDuration: '.2s',
      zIndex: 99,
    },
    '&.Mui-selected:hover': {
      opacity: '1',
    },
  },
  indicator: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
}))

export default function HistoryTabs() {
  const classes = useStyles()
  const isFlareOptionAvailable = useSelector(isFlareOptionAvailableSelector)
  const isAlertOptionAvailable = useSelector(
    (state) =>
      getAlertAvailableSelector(state) && isAlertOptionAvailableSelector(state),
  )

  const [value, setValue] = useState(isFlareOptionAvailable ? 0 : 1)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        centered
        className={classes.tabs}
        classes={{ indicator: classes.indicator }}
        value={value}
        onChange={handleChange}
      >
        {isFlareOptionAvailable ? (
          <LinkTab label="Flares" href="/pastFlares" className={classes.tab} />
        ) : null}

        {isAlertOptionAvailable ? (
          <LinkTab label="Alerts" href="/pastAlerts" className={classes.tab} />
        ) : null}

        {/* <LinkTab
          label="Watch My Home"
          href="/pastWatchMyHome"
          className={classes.tabRight}
        /> */}
      </Tabs>

      {value === 0 && (
        <TabContainer>
          <PastFlares />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <AlertsPage historyMode />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <WatchMyHomePage historyMode />
        </TabContainer>
      )}
    </div>
  )
}
