/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'

import { Link } from 'react-router-dom'
import { Typography, Container } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import InfiniteScroll from 'react-infinite-scroll-component'

import { makeStyles } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { MapLabel } from './Label'
import { MapLabelGroup, MapLabelGroupOverlapping } from './LabelGroup'

const useLabelListClasses = makeStyles({
  scrollContainer: {
    maxHeight: '260px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'scrollbarbutton-right',
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
})

export const LabelListContainer = (props) => {
  const { bottom, right } = props.popupPosition
  // eslint-disable-next-line no-nested-ternary
  const topPosition = bottom
    ? '2px'
    : props.isSingleElement
    ? '-200px'
    : '-330px'
  const rightPosition = right ? '-230px' : '-10px'
  const classes = useLabelListClasses()

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      style={{
        width: '230px',
        height: props.isSingleElement ? '' : '290px',
        backgroundColor: 'white',
        position: 'absolute',
        top: topPosition,
        right: rightPosition,
        zIndex: 999,
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'default',
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onMouseEnter={(e) => {
        e.stopPropagation()
      }}
    >
      <Close
        style={{
          fill: 'black',
          margin: '8px 4px 0 auto',
          cursor: 'pointer',
          display: 'block',
        }}
        onClick={props.onClose}
      />
      <div id="scrollableDiv" className={classes.scrollContainer}>
        <InfiniteScroll
          dataLength={props.flares.length}
          scrollableTarget="scrollableDiv"
        >
          {props.flares.map((flare, index) => (
            <MapLabel
              key={`cluster-item-flare-${flare.id}`}
              flare={flare}
              isLastItem={index !== props.flares.length - 1}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export const LabelGroupListContainer = (props) => {
  const { bottom, right } = props.popupPosition
  // eslint-disable-next-line no-nested-ternary
  const topPosition = bottom
    ? '25px'
    : props.isSingleElement
    ? '-180px'
    : '-280px'
  const rightPosition = right ? '-230px' : '-10px'
  const classes = useLabelListClasses()
  const { group } = props

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      style={{
        width: '230px',
        height: props.isSingleElement ? '' : '290px',
        backgroundColor: 'white',
        position: 'absolute',
        top: topPosition,
        right: rightPosition,
        zIndex: 999,
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'default',
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onMouseEnter={(e) => {
        e.stopPropagation()
      }}
    >
      <Close
        style={{
          fill: 'black',
          margin: '8px 4px 0 auto',
          cursor: 'pointer',
          display: 'block',
        }}
        onClick={props.onClose}
      />
      <div id="scrollableDiv" className={classes.scrollContainer}>
        <InfiniteScroll
          dataLength={group.flares.length}
          scrollableTarget="scrollableDiv"
        >
          <Container
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            <Typography style={styles.markerLabel}>Group</Typography>
            <Link
              to={`/dashboard/group/${group.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton style={{ padding: 0 }}>
                <OpenInNewIcon />
              </IconButton>
            </Link>
          </Container>
          <div style={styles.separator} />
          {group.flares.map((flare, index) => (
            <MapLabelGroup
              key={`cluster-item-group-${flare.id}`}
              flare={flare}
              isLastItem={index !== group.flares.length - 1}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export const LabelGroupOverlappingListContainer = (props) => {
  const { bottom, right } = props.popupPosition
  // eslint-disable-next-line no-nested-ternary
  const topPosition = bottom
    ? '25px'
    : props.isSingleElement
    ? '-180px'
    : '-280px'
  const rightPosition = right ? '-230px' : '-10px'
  const classes = useLabelListClasses()
  const { overlapping } = props

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      style={{
        width: '230px',
        height: props.isSingleElement ? '' : '290px',
        backgroundColor: 'white',
        position: 'absolute',
        top: topPosition,
        right: rightPosition,
        zIndex: 999,
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'default',
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onMouseEnter={(e) => {
        e.stopPropagation()
      }}
    >
      <Close
        style={{
          fill: 'black',
          margin: '8px 4px 0 auto',
          cursor: 'pointer',
          display: 'block',
        }}
        onClick={props.onClose}
      />
      <div id="scrollableDiv" className={classes.scrollContainer}>
        <InfiniteScroll
          dataLength={overlapping.groups.length}
          scrollableTarget="scrollableDiv"
        >
          {overlapping.groups.map((group, index) => (
            <MapLabelGroupOverlapping
              key={`cluster-item-group-overlapping-${group.id}`}
              group={group}
              indexGroup={index + 1}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const styles = {
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#000',
    marginTop: 8,
    marginBottom: 8,
  },
  markerLabel: {
    color: 'black',
    marginTop: 0,
    marginBottom: 0,
  },
}
