import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_CLOSED_ALERTS_NEXT_PAGE } from 'modules/alerts/actions'

export default function* getClosedAlertsNextPageSaga(action) {
  const { page } = action.payload
  const { response, error } = yield api.alerts.getAlerts({
    isClosed: true,
    page,
  })

  if (error) {
    console.log('getClosedAlertsNextPageSaga error', error)

    yield put(GET_CLOSED_ALERTS_NEXT_PAGE.FAILED.create(error.message))
  } else {
    console.log('getClosedAlertsNextPageSaga response', response)

    yield put(GET_CLOSED_ALERTS_NEXT_PAGE.SUCCESS.create(response))
  }
}
