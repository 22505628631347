import { API } from 'aws-amplify'

import { getAuthToken } from 'api/utils/getAuthToken'

import { SETTINGS_PATH, GENERAL_SETTINGS } from 'api/generalSettings'

export default () =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    try {
      const token = await getAuthToken()

      const response = await API.get(
        GENERAL_SETTINGS,
        `${SETTINGS_PATH.latest}/station`,
        {
          headers: {
            Authorization: token,
          },
        },
      )
      console.log(response, 'response')
      result.response = response
    } catch (error) {
      console.log(error, 'error')
      // result.error = parseError(error);
    }

    resolve(result)
  })
