import { useTheme } from '@material-ui/styles'
import { capitalize } from 'lodash'
import { ProgressStage, FlareStatusActivate } from '../constants'

export default function (flareStatus, isClosed, oldStatus) {
  const theme = useTheme()
  switch (flareStatus) {
    case ProgressStage.NEW:
      return isClosed
        ? { type: 'Closed from New', color: '#000' }
        : { type: 'New', color: theme.palette.new }

    case ProgressStage.IN_REVIEW:
      return isClosed
        ? { type: 'Closed from In Review', color: '#000' }
        : { type: 'In Review', color: theme.palette.reviewing }

    case ProgressStage.RECEIVED:
      return isClosed
        ? { type: 'Closed from Received', color: '#000' }
        : { type: 'Received', color: theme.palette.received }

    case ProgressStage.IN_PROGRESS:
      return isClosed
        ? { type: 'Closed from In Progress', color: '#000' }
        : { type: 'In Progress', color: theme.palette.inProgress }

    case ProgressStage.COMPLETED:
      return isClosed
        ? {
            type: `Complete from ${
              FlareStatusActivate.find((status) => status.value === oldStatus)
                .name
            }`,
            color: '#000',
          }
        : { type: 'Complete', color: theme.palette.complete }

    default:
      return {
        type: isClosed ? `Closed from ${capitalize(flareStatus)}` : flareStatus,
        color: '#000',
      }
  }
}
