/* eslint-disable camelcase */
import React, { Component } from 'react'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import { Link } from 'react-router-dom'
import { Button, CssBaseline, Typography } from '@material-ui/core'

import * as styles from './styles'
import * as sharedStyles from '../../shared/styles'

import { validatePasswordInput } from '../../../utils'

import {
  CssContainer,
  CssTextField,
  Logo,
  CssPasswordInput,
} from '../components/common'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: props.routerState?.username || '',
      code: '',
      new_password: '',
      confirm_new_password: '',
      isNotPasswordCompareError: false,
      newPasswordErrors: {
        isNotHaveUppercase: false,
        isNotHaveLowercase: false,
        isNotHaveNumber: false,
        isNotHaveSpecialSymbol: false,
        isShort: false,
      },
      isNewPasswordError: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.resetPasswordSubmitErrorMessage !==
        this.props.resetPasswordSubmitErrorMessage &&
      this.props.resetPasswordSubmitErrorMessage
    ) {
      this.showError(this.props.resetPasswordSubmitErrorMessage)
    }
    if (
      prevProps.resendResetPasswordErrorMessage !==
        this.props.resendResetPasswordErrorMessage &&
      this.props.resendResetPasswordErrorMessage
    ) {
      this.showError(this.props.resendResetPasswordErrorMessage)
    }
    if (
      prevProps.isResendCodeSuccess !== this.props.isResendCodeSuccess &&
      this.props.isResendCodeSuccess
    ) {
      toast.dismiss()
      toast.success('We sent a new code to your email', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    }
  }

  validateNewPassword = () => {
    const { new_password } = this.state
    const { newPasswordErrors } = this.state
    const errors = validatePasswordInput(new_password)

    this.setState({
      newPasswordErrors: { newPasswordErrors, ...errors },
      isNewPasswordError: Object.values(errors).includes(true),
    })
    return Object.values(errors).includes(true)
  }

  validateNewPasswordConfirm = () => {
    const { new_password, confirm_new_password } = this.state
    this.setState({
      isNotPasswordCompareError: new_password !== confirm_new_password,
    })
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  showError = (error) => {
    let message = ''
    if (error === 'mismatch') {
      message = "Passwords don't match"
    } else {
      message = error
    }
    toast.dismiss()
    this.toastId = toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    })
  }

  resendCode = () => {
    const { username } = this.state
    if (username) {
      this.props.resendCode({ username })
    }
  }

  resetPassword = () => {
    const { code, username, new_password, confirm_new_password } = this.state
    const isNewPasswordError = this.validateNewPassword()
    if (new_password !== confirm_new_password) {
      this.setState({ isNotPasswordCompareError: true })
    } else {
      this.setState({ isNotPasswordCompareError: false })
      if (!isNewPasswordError) {
        this.props.forgotPasswordSubmit({ username, code, new_password })
      }
    }
  }

  render() {
    const {
      username,
      newPasswordErrors,
      isNewPasswordError,
      isNotPasswordCompareError,
    } = this.state
    const { isResetPasswordFetching, routerState } = this.props
    const { isNotHaveLowercase } = newPasswordErrors
    const { isNotHaveNumber } = newPasswordErrors
    const { isNotHaveSpecialSymbol } = newPasswordErrors
    const { isNotHaveUppercase } = newPasswordErrors
    const { isShort } = newPasswordErrors
    return (
      <>
        <ToastContainer transition={Zoom} />
        <CssContainer component="main">
          <CssBaseline />
          <div className="paper" style={sharedStyles.form}>
            <Logo />
            <Typography
              style={{ marginTop: '1rem' }}
              component="h1"
              variant="h4"
            >
              {routerState?.title || 'Password Recovery'}
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              noValidate
            >
              <CssTextField
                style={{ marginTop: '1.43rem', marginBottom: '1rem' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                type="text"
                value={username}
                onChange={(evt) => this.onChange('username', evt.target.value)}
                autoFocus
              />
              <CssTextField
                style={{ marginBottom: '1rem', marginTop: '0' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="verification_code"
                label="Verification Code"
                name="verification_code"
                type="test"
                onChange={(evt) => this.onChange('code', evt.target.value)}
              />
              <Typography style={styles.input_description}>
                Verification code sent to your email.
              </Typography>
              <div>
                <Typography
                  style={styles.reset_code_button}
                  onClick={isResetPasswordFetching ? null : this.resendCode}
                >
                  Resend Code?
                </Typography>
              </div>
              <Typography style={styles.input_description}>
                Password requirements: Must be at{' '}
                <span style={isShort ? styles.error : {}}>
                  least 8 characters
                </span>{' '}
                and contain a{' '}
                <span style={isNotHaveUppercase ? styles.error : {}}>
                  capital letter
                </span>
                ,{' '}
                <span style={isNotHaveLowercase ? styles.error : {}}>
                  lowercase letter
                </span>
                ,{' '}
                <span style={isNotHaveNumber ? styles.error : {}}>number</span>,
                and{' '}
                <span style={isNotHaveSpecialSymbol ? styles.error : {}}>
                  special character of the following (!@#$%^&*).{' '}
                </span>
                <span style={isNotPasswordCompareError ? styles.error : {}}>
                  Passwords must match
                </span>
              </Typography>

              <CssPasswordInput
                error={isNewPasswordError || isNotPasswordCompareError}
                id="new_password"
                label="New Password"
                name="new_password"
                onChange={this.onChange}
                onBlur={this.validateNewPassword}
              />
              <CssPasswordInput
                error={isNotPasswordCompareError}
                id="confirm_new_password"
                label="Confirm New Password"
                name="confirm_new_password"
                onChange={this.onChange}
                onBlur={this.validateNewPasswordConfirm}
              />
              <Button
                style={styles.button}
                disabled={isResetPasswordFetching}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                onClick={this.resetPassword}
              >
                Submit
              </Button>
            </form>
            <div style={styles.buttons_container}>
              <Link to="/login" style={styles.navigationLink}>
                <Button style={styles.secondary_button} color="primary">
                  Return to login
                </Button>
              </Link>
            </div>
          </div>
        </CssContainer>
      </>
    )
  }
}

export default ResetPassword
