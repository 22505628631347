import { all, takeLatest } from 'redux-saga/effects'

import resendSignUpVerifyCodeSaga from './resendSignUpVerifyCodeSaga'
import signUpVerifySaga from './signUpVerifySaga'
import forgotPasswordSaga from './forgotPasswordSaga'
import forgotPasswordSubmitSaga from './forgotPasswordSubmitSaga'
import resendForgotPasswordSubmitSaga from './resendForgotPasswordCodeSaga'
import forgotUserNameSaga from './forgotUserNameSaga'
import changePasswordSaga from './changePasswordSaga'

import {
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_SUBMIT,
  AUTH_FORGOT_PASSWORD_RESEND_CODE,
  AUTH_FORGOT_USERNAME,
  CHANGE_PASSWORD,
  SIGN_UP_VERIFIRED,
  RESEND_SING_UP_VERIFY_CODE,
} from '../actions'

function* rootAuthSaga() {
  yield all([
    takeLatest(CHANGE_PASSWORD.START.type, changePasswordSaga),
    takeLatest(AUTH_FORGOT_PASSWORD.START.type, forgotPasswordSaga),
    takeLatest(
      AUTH_FORGOT_PASSWORD_SUBMIT.START.type,
      forgotPasswordSubmitSaga,
    ),
    takeLatest(
      AUTH_FORGOT_PASSWORD_RESEND_CODE.START.type,
      resendForgotPasswordSubmitSaga,
    ),
    takeLatest(AUTH_FORGOT_USERNAME.START.type, forgotUserNameSaga),
    takeLatest(SIGN_UP_VERIFIRED.START.type, signUpVerifySaga),
    takeLatest(
      RESEND_SING_UP_VERIFY_CODE.START.type,
      resendSignUpVerifyCodeSaga,
    ),
  ])
}

export default rootAuthSaga
