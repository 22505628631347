import React from 'reactn'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { getMapAvailableSelector } from '../modules/generalSettings/selectors'
import { decodeJWTToken } from '../utils/decodeJWTToken'

class AdminRoute extends React.PureComponent {
  state = {
    loaded: false,
    isAdmin: false,
  }

  // Checks if user is authenticated
  componentDidMount() {
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser().catch(() => {
        if (this.state.isAdmin) this.setState({ isAdmin: false })
      })
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const token = user.signInUserSession.idToken.jwtToken
        const decodedJWT = decodeJWTToken(token)
        console.log(decodedJWT, 'decodedJWT')
        if (decodedJWT.role === 'Admin')
          this.setState({ loaded: true, isAdmin: true })
      })
      .catch(() =>
        this.props.history.push(
          this.props.isMapAvailable ? '/dashboard/map' : '/dashboard',
        ),
      )
  }

  // Reroute to map unless admin
  render() {
    const { component: Component, ...rest } = this.props
    const { loaded, isAdmin } = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={(props) =>
          isAdmin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: this.props.isMapAvailable
                  ? '/dashboard/map'
                  : '/dashboard',
              }}
            />
          )
        }
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    isMapAvailable: getMapAvailableSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminRoute)
