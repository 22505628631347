/* eslint-disable camelcase */
export const title = {
  fontSize: '2.5rem',
  margin: '1.5rem 0 1.5rem 0',
}

export const toas_container = {
  textAlign: 'center',
}

export const input = {
  marginTop: '1.5rem',
  marginBottom: '1rem',
  borderRadius: '100px',
}

export const second_input = {
  marginTop: '0',
  marginBottom: '1rem',
}

export const button = {
  heigth: '3.5rem',
  fontSize: '1.5625rem',
  marginTop: '1.5rem',
}

export const form = {
  width: '877px',
}

export const buttons_container = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '6px',
}

export const secondary_button = {
  color: '#0052A5',
  marginTop: '0.75rem',
  fontSize: '1rem',
  textDecoration: 'underline',
}

export const input_description = {
  color: '#7F7F7F',
  fontSize: '1.5rem',
  lineHeigth: '1.75rem',
  textAlign: 'left',
  fontFamily: 'Roboto, sans-serif',
  paddingBottom: '0.5rem',
}

export const reset_code_button = {
  color: '#0052A5',
  fontSize: '1.5rem',
  fontFamily: 'Roboto, sans-serif',
  paddingBottom: '1rem',
  cursor: 'pointer',
  width: 'fit-content',
  margin: '1rem 0 0 auto',
}

export const navigationLink = {
  width: 'fit-content',
}
