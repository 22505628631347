import React, { Component } from 'reactn'

import WatchMyHomesList from 'components/WatchMyHomesList'
import { WatchMyHomeBar } from '../Appbars'

export default class WatchMyHomePage extends Component {
  constructor() {
    super()
    this.state = {
      value: 'requests',
    }
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { historyMode } = this.props
    const { value } = this.state
    return (
      <div className="WatchMyHome page" style={{ paddingBottom: '1rem' }}>
        {!historyMode ? (
          <WatchMyHomeBar
            value={this.state.value}
            handleChange={this.handleChange}
          />
        ) : null}
        <WatchMyHomesList
          historyMode={historyMode}
          activeStatus={value !== 'requests'}
        />
      </div>
    )
  }
}
