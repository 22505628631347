import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const USER_GET_INFO = createAction('USER_GET_INFO', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const CURRENT_USER_CHANGE_PASSWORD = createAction(
  'CURRENT_USER_CHANGE_PASSWORD',
  {
    [ACTIONS_SUBTYPES.START]: (response) => response,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const USER_EDIT = createAction('USER_EDIT', {
  [ACTIONS_SUBTYPES.START]: (response) => response,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const USER_REQUEST_VERIFICATION_CODE = createAction(
  'USER_REQUEST_VERIFICATION_CODE',
  {
    [ACTIONS_SUBTYPES.START]: (response) => response,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const USER_SUBMIT_VERIFICATION = createAction(
  'USER_SUBMIT_VERIFICATION',
  {
    [ACTIONS_SUBTYPES.START]: (response) => response,
    [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const USER_LOG_OUT = createAction('USER_LOG_OUT', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const CHANGE_TOS = createAction('CHANGE_TOS', {
  [ACTIONS_SUBTYPES.START]: () => true,
  [ACTIONS_SUBTYPES.SUCCESS]: (response) => response,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})
