import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_ACTIVE_ALERTS } from 'modules/alerts/actions'

export default function* getAlertsSaga({ payload }) {
  const { response, error } = yield api.alerts.getAlerts(payload)

  if (error) {
    console.log('getAlertsSaga error', error)

    yield put(GET_ACTIVE_ALERTS.FAILED.create(error.message))
  } else {
    console.log('getAlertsSaga response', response)

    yield put(GET_ACTIVE_ALERTS.SUCCESS.create(response))
  }
}
