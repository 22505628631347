import { styled, withStyles } from '@material-ui/styles'
import { TextField, Container } from '@material-ui/core'

export const CssTextField = withStyles({
  root: {
    // Error Colors
    '& label.Mui-error': {
      color: 'red',
    },
    '& .Mui-error': {
      color: 'red',
      borderBottomColor: 'red',
    },
    // default colors
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#eaeaea',
      },
      '&:hover fieldset': {
        borderColor: '#989898',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0052a5',
      },
    },
  },
})(TextField)

export const CssContainer = styled(Container)({
  marginTop: '1rem',
  display: 'flex',
  maxWidth: '50em',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'flex-center',
  margin: '0 auto 0 auto',
})
