import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DialogMessages, ProgressStage } from '../../../constants'

export default function FormDialog(props) {
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogText, setDialogText] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [required, setRequired] = useState(false)

  function getStatusString(status) {
    switch (status) {
      case ProgressStage.RECEIVED:
        return 'Received'
      case ProgressStage.IN_REVIEW:
        return 'Reviewing'
      case ProgressStage.IN_PROGRESS:
        return 'In Progress'
      case ProgressStage.COMPLETED:
        return 'Complete'
      default:
        return 'None'
    }
  }

  useEffect(() => {
    switch (props.status) {
      case ProgressStage.NEW:
      case ProgressStage.RECEIVED:
      case ProgressStage.IN_REVIEW:
      case ProgressStage.IN_PROGRESS:
        setDialogTitle(`Changing Status to: ${getStatusString(props.status)}`)
        setDialogText(DialogMessages.ABORT_TO_CHANGE_STATUS_NEW)
        setRequired(false)
        break
      case ProgressStage.COMPLETED:
        setDialogTitle('Complete Flare')
        setDialogText(DialogMessages.ABORT_TO_CHANGE_STATUS_CLOSED)
        setRequired(true)
        break
      case ProgressStage.CLOSED:
        setDialogTitle('Move to History')
        setDialogText(DialogMessages.ABORT_TO_CHANGE_STATUS_RECEIVED)
        setRequired(true)
        break
      default:
        // No status, so they are adding a message
        setDialogTitle('Send Message')
        setDialogText(
          'You are about to add a message to the current status. Please enter the message below:',
        )
        setRequired(true)
        break
    }
  }, [props.status]) // Only re-run the effect if status changes

  const handleClose = () => {
    setMessage('')
    setError(false)
    props.handleClose()
  }

  const handleSend = () => {
    if (required && message === '') {
      setError(true)
      return
    }
    props.handleSend(message, props.status)
    console.log(`submit button clicked with message : ${message}`)
    handleClose()
  }

  const handleOnChange = (event) => {
    setMessage(event.target.value)
    if (error) {
      setError(false)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        <TextField
          autoFocus
          error={error}
          fullWidth
          variant="standard"
          helperText={error ? 'Message field is required' : ''}
          id="name"
          label="Message"
          margin="dense"
          multiline
          onChange={handleOnChange}
          required={required}
          type="email"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSend} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
