import { useEffect, useRef } from 'react'

export const useOnClickOutside = (handler) => {
  const ref = useRef(null)
  useEffect(() => {
    const listener = (event) => {
      if (handler) {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
  return ref
}
