/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react'

import {
  Dialog,
  DialogContent,
  Paper,
  Grid,
  Typography,
  Checkbox,
  Container,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { styles } from '../Group/Group'

export const ColorButton = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    color: theme.palette.getContrastText('#eaffea'),
    backgroundColor: '#eaffea',
    '&:hover': {
      backgroundColor: '#d8ead8',
    },
  },
}))(Button)

const cssStyles = {
  dialogContainer: {
    marginTop: '150px',
    marginBottom: '80px',
    paddingTop: 0,
    minWidth: '600px',
  },
  container: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'white',
    padding: '24px 20px 10px 0',
    zIndex: 10,
    marginRight: '48px',
    width: '100%',
    height: '120px',
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    marginLeft: '12px',
  },
  input: {
    marginLeft: '11px',
    paddingRight: '12px',
  },
  buttons: {
    position: 'absolute',
    bottom: 0,
    padding: '16px 48px 16px 0',
    width: '100%',
    height: '80px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    width: '140px',
  },
}

const UsersDialog = ({
  isOpen,
  isUpdateGroupLoading,
  isGetGroupByIdLoading,
  handleClose,
  classes,
  editGroup,
  getGroupByIdRequest,
  setInviteUser,
  handleUpdateGroup,
}) => {
  const [groupName, setGroupName] = useState('')
  const [groupNameError, setGroupError] = useState(undefined)
  const [selectedUsers, setSelectedUsers] = useState([])

  useEffect(() => {
    if (editGroup.isEdit) {
      if (editGroup.users) {
        if (editGroup.users.length > 0) {
          setSelectedUsers(editGroup.users)
        }
      } else {
        getGroupByIdRequest(editGroup.groupId)
      }
      setGroupName(editGroup.name)
    }
  }, [editGroup.users])

  const handleOnEdit = async () => {
    const { groupId } = editGroup
    handleUpdateGroup(groupId, {
      name: groupName,
      users: selectedUsers.map((user) => user.id),
    })
  }

  const onChangeCheckboxValue = (userMofied) => {
    if (selectedUsers.find((user) => user.id === userMofied.id) !== undefined) {
      setSelectedUsers(
        selectedUsers.filter((user) => user.id !== userMofied.id),
      )
    } else {
      setSelectedUsers([...selectedUsers, userMofied])
    }
  }

  const clearFields = () => {
    setGroupName('')
    setGroupError('')
    setSelectedUsers([])
  }

  return (
    <Dialog open={isOpen}>
      <DialogContent style={cssStyles.dialogContainer} id="dialog-container">
        <Container style={cssStyles.container}>
          <Typography style={cssStyles.title}>
            {`Group: ${editGroup.name}`}
          </Typography>
          <Grid item xs={3}>
            <ColorButton
              style={cssStyles.button}
              onClick={() => {
                setInviteUser({
                  isOpen: true,
                  groupId: editGroup.groupId,
                  isLoading: false,
                })
              }}
            >
              Invite
            </ColorButton>
          </Grid>
        </Container>

        <Grid container style={{ padding: '0 0 0 32px' }}>
          <Grid item className={classes.editColumn}>
            <Typography className={classes.heading}>Name</Typography>
          </Grid>
          <Grid
            item
            className={classes.editColumn}
            style={{ textAlign: 'center' }}
          >
            <Typography className={classes.heading}>Email</Typography>
          </Grid>
          <Grid
            item
            className={classes.editColumn}
            style={{ textAlign: 'center' }}
          />
          <Grid
            item
            className={classes.editColumn}
            style={{ textAlign: 'center' }}
          >
            <Typography className={classes.heading} />
          </Grid>
        </Grid>

        {isGetGroupByIdLoading ? (
          <h5 style={{ paddingLeft: '32px', paddingTop: '20px' }}>
            Loading....
          </h5>
        ) : editGroup.users ? (
          <InfiniteScroll
            dataLength={editGroup.users.length}
            scrollableTarget="dialog-container"
            loader={<h4>Loading...</h4>}
          >
            {editGroup.users.map((user) => {
              if (!user.id) return null
              return (
                <Paper
                  key={`user-${user.id}`}
                  className={classes.editContainer}
                  style={{ minWidth: '425px' }}
                >
                  <Grid container>
                    <Grid container style={{ flex: 1 }}>
                      <Grid
                        item
                        className={classes.editColumn}
                        style={{ textAlign: 'left' }}
                      >
                        <Typography style={cssStyles.secondaryHeading}>
                          {user.name}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        className={classes.editColumn}
                        style={{ textAlign: 'left', marginLeft: '1rem' }}
                      >
                        <Typography className={classes.secondaryHeading}>
                          {user.email}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Checkbox
                      disabled={isUpdateGroupLoading}
                      checked={
                        !!selectedUsers.find(
                          (selectedUser) => selectedUser.id === user.id,
                        )
                      }
                      color="primary"
                      onClick={() => onChangeCheckboxValue(user)}
                    />
                  </Grid>
                </Paper>
              )
            })}
          </InfiniteScroll>
        ) : (
          <div
            style={{
              textAlign: 'center',
              margin: '48px 0',
            }}
          >
            <h4 style={{ color: '#989898' }}>No Users</h4>
          </div>
        )}
        <Container style={cssStyles.buttons}>
          <ColorButton
            disabled={isUpdateGroupLoading || !!groupNameError}
            style={cssStyles.button}
            onClick={handleOnEdit}
          >
            Update
          </ColorButton>
          <Button
            style={cssStyles.button}
            onClick={() => {
              handleClose()
              clearFields()
            }}
          >
            Close
          </Button>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(UsersDialog)
