import { createAction, ACTIONS_SUBTYPES } from 'modules/utils/actionCreator'

export const SIGN_UP_VERIFIRED = createAction('SIGN_UP_VERIFIRED', {
  [ACTIONS_SUBTYPES.START]: (params) => params,
  [ACTIONS_SUBTYPES.SUCCESS]: () => true,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const AUTH_FORGOT_PASSWORD = createAction('AUTH_FORGOT_PASSWORD', {
  [ACTIONS_SUBTYPES.START]: (params) => params,
  [ACTIONS_SUBTYPES.SUCCESS]: () => true,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const RESEND_SING_UP_VERIFY_CODE = createAction(
  'RESEND_SING_UP_VERIFY_CODE',
  {
    [ACTIONS_SUBTYPES.START]: (params) => params,
    [ACTIONS_SUBTYPES.SUCCESS]: () => true,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)
export const AUTH_FORGOT_PASSWORD_SUBMIT = createAction(
  'AUTH_FORGOT_PASSWORD_SUBMIT',
  {
    [ACTIONS_SUBTYPES.START]: (params) => params,
    [ACTIONS_SUBTYPES.SUCCESS]: () => true,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const AUTH_FORGOT_PASSWORD_RESEND_CODE = createAction(
  'AUTH_FORGOT_PASSWORD_RESEND_CODE',
  {
    [ACTIONS_SUBTYPES.START]: (params) => params,
    [ACTIONS_SUBTYPES.SUCCESS]: () => true,
    [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
      reason,
    }),
  },
)

export const AUTH_FORGOT_USERNAME = createAction('AUTH_FORGOT_USERNAME', {
  [ACTIONS_SUBTYPES.START]: (email) => ({ email }),
  [ACTIONS_SUBTYPES.SUCCESS]: () => true,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})

export const CHANGE_PASSWORD = createAction('CHANGE_PASSWORD', {
  [ACTIONS_SUBTYPES.START]: (params) => params,
  [ACTIONS_SUBTYPES.SUCCESS]: () => true,
  [ACTIONS_SUBTYPES.FAILED]: (reason) => ({
    reason,
  }),
})
