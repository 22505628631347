/* eslint-disable camelcase */
import React from 'react'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import { Link } from 'react-router-dom'

import { Button, CssBaseline, Typography } from '@material-ui/core'
import {
  CssContainer,
  CssTextField,
  Logo,
  CssPasswordInput,
} from '../components/common'

import * as styles from './styles'
import * as sharedStyles from '../../shared/styles'

import { validatePasswordInput } from '../../../utils'

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: props.routerState?.username || '',
      new_password: '',
      confirm_new_password: '',
      session: props.routerState?.session || null,
      isNotPasswordCompareError: true,
      newPasswordErrors: {
        isNotHaveUppercase: false,
        isNotHaveLowercase: false,
        isNotHaveNumber: false,
        isNotHaveSpecialSymbol: false,
        isShort: false,
      },
      isNewPasswordError: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.changePasswordErrorMessage !==
        this.props.changePasswordErrorMessage &&
      this.props.changePasswordErrorMessage
    ) {
      this.showError(this.props.changePasswordErrorMessage)
    }
  }

  showError = (error) => {
    let message = ''
    if (error === 'mismatch') {
      message = "Passwords don't match"
    } else {
      message = error
    }
    toast.dismiss()
    this.toastId = toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    })
  }

  validateNewPasswordConfirm = () => {
    const { new_password, confirm_new_password } = this.state
    this.setState({
      isNotPasswordCompareError: new_password !== confirm_new_password,
    })
  }

  validateNewPassword = () => {
    const { new_password } = this.state
    const { newPasswordErrors } = this.state
    const errors = validatePasswordInput(new_password)

    this.setState({
      newPasswordErrors: { newPasswordErrors, ...errors },
      isNewPasswordError: Object.values(errors).includes(true),
    })

    if (!Object.values(errors).includes(true)) {
      this.validateNewPasswordConfirm()
    }

    return Object.values(errors).includes(true)
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  onChangePassword = () => {
    const { username, new_password, session } = this.state
    this.props.changePassword({ username, new_password, session })
  }

  navigateToLogin = () => {}

  render() {
    const {
      username,
      newPasswordErrors,
      isNewPasswordError,
      isNotPasswordCompareError,
    } = this.state
    const { isChangePasswordFetching } = this.props
    const { isNotHaveLowercase } = newPasswordErrors
    const { isNotHaveNumber } = newPasswordErrors
    const { isNotHaveSpecialSymbol } = newPasswordErrors
    const { isNotHaveUppercase } = newPasswordErrors
    const { isShort } = newPasswordErrors
    return (
      <>
        <ToastContainer transition={Zoom} />
        <CssContainer component="main">
          <CssBaseline />
          <div className="paper" style={sharedStyles.form}>
            <Logo />
            <Typography
              style={{ marginTop: '1rem' }}
              component="h1"
              variant="h4"
            >
              Create New Password
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
              noValidate
            >
              <CssTextField
                style={{ marginTop: '1.43rem', marginBottom: '1rem' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                type="text"
                value={username}
                onChange={(evt) => this.onChange('username', evt.target.value)}
                autoFocus
                disabled
              />
              <Typography style={styles.input_description}>
                Password requirements: Must be at{' '}
                <span style={isShort ? styles.error : {}}>
                  least 8 characters
                </span>{' '}
                and contain a{' '}
                <span style={isNotHaveUppercase ? styles.error : {}}>
                  capital letter
                </span>
                ,{' '}
                <span style={isNotHaveLowercase ? styles.error : {}}>
                  lowercase letter
                </span>
                ,{' '}
                <span style={isNotHaveNumber ? styles.error : {}}>number</span>,
                and{' '}
                <span style={isNotHaveSpecialSymbol ? styles.error : {}}>
                  special character of the following (!@#$%^&*).{' '}
                </span>
                <span style={isNotPasswordCompareError ? styles.error : {}}>
                  Passwords must match
                </span>
              </Typography>

              <CssPasswordInput
                error={isNewPasswordError || isNotPasswordCompareError}
                id="new_password"
                label="New Password"
                name="new_password"
                onChange={this.onChange}
                onBlur={this.validateNewPassword}
              />
              <CssPasswordInput
                error={isNotPasswordCompareError}
                id="confirm_new_password"
                label="Confirm New Password"
                name="confirm_new_password"
                onChange={this.onChange}
                onBlur={this.validateNewPassword}
              />
              <Button
                style={styles.button}
                disabled={isChangePasswordFetching || isNotPasswordCompareError}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                onClick={this.onChangePassword}
              >
                Submit
              </Button>
            </form>
            <div style={styles.buttons_container}>
              <Link to="/login" style={styles.navigationLink}>
                <Button style={styles.secondary_button} color="primary">
                  Return to login
                </Button>
              </Link>
            </div>
          </div>
        </CssContainer>
      </>
    )
  }
}
