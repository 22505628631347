import { put } from 'redux-saga/effects'
import api from 'api'
import { GET_ALERT_LOGS } from 'modules/alerts/actions'

export default function* getAlertLogsSaga(action) {
  const { alertId, lastId = null } = action.payload

  const { response, error } = yield api.logs.getLogs({
    itemId: alertId,
    lastId,
  })

  if (error) {
    console.log('getAlertLogsSaga error', error)

    yield put(GET_ALERT_LOGS.FAILED.create(error.message))
  } else {
    console.log('getAlertLogsSaga response', response)

    yield put(
      GET_ALERT_LOGS.SUCCESS.create({
        ...response,
        alertId,
        isPagination: !!lastId,
      }),
    )
  }
}
