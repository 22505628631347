import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'

// actions
import {
  ADD_FLARE_TYPE,
  GET_FLARE_TYPES,
  UPDATE_STATION_SETTINGS,
} from 'modules/flares/actions'
import {
  ADD_STATION_ALERT_TYPE,
  GET_STATION_ALERT_TYPES,
} from 'modules/alerts/actions'

// selectors
import { flareTypesSelector } from 'modules/flares/selectors'
import { getStationAlertTypesSelector } from 'modules/alerts/selectors'
import {
  stationSelector,
  isAlertOptionAvailableSelector,
  isFlareOptionAvailableSelector,
} from 'modules/geofence/selectors'
import {
  getAlertAvailableSelector,
  getFlareAvailableSelector,
} from 'modules/generalSettings/selectors'

// ui
import AddFlareTypesModal from 'components/AddFlareTypeDialog/AddFlareTypesModal'
import { TypeSettingsList } from 'components/TypeSettingsList'
import AddTypeDialog from 'components/AlertTypesDialogs/AddTypeDialog'

// hooks
import { useStatusesSelector } from 'hooks/useStatusesSelector'

const useSettingsPageClasses = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 20,
  },
  container: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    margin: '10px 0',
  },
  buttonsSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
    padding: 5,
    marginBottom: 8,
  },
})

const StyledButton = withStyles({
  root: {
    width: '100%',
    margin: '10px 0',
  },
})(Button)

const StyledCircularProgress = withStyles({
  root: {
    marginLeft: '8px',
  },
  colorPrimary: {
    color: '#ffffff',
  },
})(CircularProgress)

export const StationSettings = () => {
  const classes = useSettingsPageClasses()
  const dispatch = useDispatch()
  const stationFlareTypes = useSelector(flareTypesSelector)
  const station = useSelector(stationSelector)
  const alertTypes = useSelector(getStationAlertTypesSelector)

  const isAlertAvailable = useSelector(
    (state) =>
      isAlertOptionAvailableSelector(state) && getAlertAvailableSelector(state),
  )
  const isFlareAvailable = useSelector(
    (state) =>
      isFlareOptionAvailableSelector(state) && getFlareAvailableSelector(state),
  )

  const [selectedFlareTypes, setSelectedFlareTypes] = useState([])
  const [selectedAlertTypes, setSelectedAlertTypes] = useState([])

  const [isFloorsAvailable, setIsFloorsAvailable] = useState(false)
  const [isAddFlareTypesModalVisible, setIsAddFlareTypesModalVisible] =
    useState(false)
  const [isAlertEnterableSubject, setAlertEnterableSubject] = useState(false)
  const [isAlertEnterableSubjectMandatory, setAlertEnterableSubjectMandatory] =
    useState(false)
  const [isAlertModalVisible, setAlertModalVisible] = useState(false)
  const [, isUpdateStationSettingsLoading] = useStatusesSelector(
    UPDATE_STATION_SETTINGS,
  )
  // console.log('!----------------isAddFlareTypesModalVisible', isAddFlareTypesModalVisible);

  useEffect(() => {
    if (stationFlareTypes.length) {
      setSelectedFlareTypes(stationFlareTypes.filter((item) => item.enabled))
    }
  }, [stationFlareTypes])

  useEffect(() => {
    if (alertTypes.length) {
      setSelectedAlertTypes(alertTypes.filter((item) => item.enabled))
    }
  }, [alertTypes])

  useEffect(() => {
    dispatch(GET_STATION_ALERT_TYPES.START.create())
    dispatch(GET_FLARE_TYPES.START.create())
  }, [dispatch])

  useEffect(() => {
    if (station) {
      setAlertEnterableSubject(station.editableSubject?.enabled)
      setAlertEnterableSubjectMandatory(station.editableSubject?.alwaysNotify)
      setIsFloorsAvailable(station.floorsEnabled)
    }
  }, [station])

  const handleIsFloorsAvailableChange = (event) => {
    setIsFloorsAvailable(event.target.checked)
  }

  const handleEnableSubjectAvailableChange = (event) => {
    setAlertEnterableSubject(event.target.checked)
  }

  const handleEnableSubjectMandatoryAvailableChange = (event) => {
    setAlertEnterableSubjectMandatory(event.target.checked)
  }

  const handleCloseAddFlareTypeModal = () => {
    console.log('handleCloseAddFlareTypeModal')
    setIsAddFlareTypesModalVisible(false)
  }

  const handleSubmitAddFlareType = (typeName, description) => {
    dispatch(ADD_FLARE_TYPE.START.create({ typeName, description }))
  }

  const handleSubmit = () => {
    const flareTypeIds = isFlareAvailable
      ? selectedFlareTypes.map((type) => type.id)
      : []

    const alertTypeIds = isAlertAvailable
      ? selectedAlertTypes.map((type) => type.id)
      : []

    if (isAlertAvailable || isFlareAvailable) {
      dispatch(
        UPDATE_STATION_SETTINGS.START.create({
          stationId: station.id,
          flareTypeIds,
          alertTypeIds,
          stationData: {
            floorsEnabled: isFloorsAvailable,
            editableSubject: {
              enabled: isAlertEnterableSubject,
              alwaysNotify: isAlertEnterableSubjectMandatory,
            },
          },
        }),
      )
    }
  }

  const addNewAlertType = (typeName, description, mandatoryNotified) => {
    dispatch(
      ADD_STATION_ALERT_TYPE.START.create({
        typeName,
        description,
        mandatoryNotified,
      }),
    )
  }

  const handleResetForm = () => {
    setAlertEnterableSubject(station.editableSubject?.enabled)
    setAlertEnterableSubjectMandatory(station.editableSubject?.alwaysNotify)
    setIsFloorsAvailable(station.floorsEnabled)
    setSelectedFlareTypes(stationFlareTypes.filter((item) => item.enabled))
    setSelectedAlertTypes(alertTypes.filter((item) => item.enabled))
  }

  return (
    <div className={classes.root}>
      <h3>Station settings</h3>
      <Paper className={classes.container}>
        {isFlareAvailable ? (
          <>
            <Typography
              style={{
                textAlign: 'left',
                marginBottom: '0.5rem',
                fontSize: '1.5rem',
              }}
              variant="body2"
            >
              Flare Settings
            </Typography>
            <TypeSettingsList
              data={stationFlareTypes}
              selectedTypes={selectedFlareTypes}
              setSelectedTypes={setSelectedFlareTypes}
              placeholder="Search flare types"
            />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => setIsAddFlareTypesModalVisible(true)}
            >
              Add New Flare Type
            </StyledButton>
            <FormGroup>
              <h5 style={{ marginTop: '1rem' }}>Flare Options</h5>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFloorsAvailable}
                    onChange={handleIsFloorsAvailableChange}
                  />
                }
                label="Floors"
                disableTypography
              />
            </FormGroup>
          </>
        ) : null}

        {isAlertAvailable ? (
          <>
            <Typography
              style={{
                textAlign: 'left',
                marginBottom: '0.5rem',
                fontSize: '1.5rem',
              }}
              variant="body2"
            >
              Alert Settings
            </Typography>
            <TypeSettingsList
              type="alert"
              data={alertTypes}
              usedAlertTypes={selectedAlertTypes}
              selectedTypes={selectedAlertTypes}
              setSelectedTypes={setSelectedAlertTypes}
              placeholder="Search alert types"
            />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => setAlertModalVisible(true)}
            >
              Add New Alert Type
            </StyledButton>
            <FormGroup>
              <h5 style={{ marginTop: '1rem' }}>Alert Options</h5>
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={isAlertEnterableSubject}
                    onChange={handleEnableSubjectAvailableChange}
                  />
                }
                label="Allow Custom Alert Subject"
                disableTypography
              />
              {isAlertEnterableSubject ? (
                <FormControlLabel
                  style={{ marginLeft: 5 }}
                  control={
                    <Checkbox
                      checked={isAlertEnterableSubjectMandatory}
                      onChange={handleEnableSubjectMandatoryAvailableChange}
                    />
                  }
                  label="Always Notify Subscribers"
                  disableTypography
                />
              ) : null}
            </FormGroup>
          </>
        ) : null}

        <div className={classes.buttonsSection}>
          <Button variant="contained" color="primary" onClick={handleResetForm}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isUpdateStationSettingsLoading}
            onClick={handleSubmit}
          >
            Save
            {isUpdateStationSettingsLoading && (
              <StyledCircularProgress size={15} />
            )}
          </Button>
        </div>
      </Paper>
      <AddFlareTypesModal
        isVisible={isAddFlareTypesModalVisible}
        onSubmitModal={handleSubmitAddFlareType}
        handleCloseDialog={handleCloseAddFlareTypeModal}
      />
      <AddTypeDialog
        title="Add New Alert Type"
        type="alert"
        isVisible={isAlertModalVisible}
        onSubmitModal={addNewAlertType}
        handleCloseDialog={() => setAlertModalVisible(false)}
      />
    </div>
  )
}
