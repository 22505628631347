import { put } from 'redux-saga/effects'

import api from 'api'
import { getStationId } from '../../../components/StationDetect'
import { GET_GEOFENCE } from '../actions'

export default function* getGeofenceSaga() {
  try {
    const station = yield api.station.getStation({
      subdomain: getStationId().toLowerCase(),
    })
    yield put(GET_GEOFENCE.SUCCESS.create(station))
  } catch (err) {
    yield put(GET_GEOFENCE.FAILED.create(err))
  }
}
