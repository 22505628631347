/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { BarLoader } from 'react-spinners'
import { css } from '@emotion/core'
import { NavLink } from 'react-router-dom'

import Fade from '@material-ui/core/Fade'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

export default class LoadingWrapper extends React.PureComponent {
  render() {
    const override = css`
      display: block;
      margin: 1rem auto;
      width: 100%;
    `
    return (
      <div className="LoadingWrapper">
        {this.props.loading ? (
          <Dialog open={this.props.loading} fullScreen>
            <div
              style={{
                margin: 'auto',
              }}
            >
              <h2>{this.props.label}</h2>
              <BarLoader
                css={override}
                sizeUnit="%"
                size={100}
                color="#0052a5"
              />
            </div>
          </Dialog>
        ) : (
          <Fade in={this.props.display}>
            <div>{this.props.children}</div>
          </Fade>
        )}
      </div>
    )
  }
}

export class MainMapLoader extends Component {
  _mounted = false // Store lifecycle state to prevent memory leak

  state = { fallbackLink: false }

  componentDidMount() {
    this._mounted = true

    setTimeout(() => {
      if (this._mounted) this.setState({ fallbackLink: true }) // Show refresh button if loading gets stuck
    }, 5000)
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render() {
    const override = css`
      display: block;
      margin: 1rem auto;
    `
    return (
      <div className="MapLoader">
        {this.props.loading ? (
          <Fade in={this.props.loading}>
            <div
              style={{
                marginTop: '10rem',
                textAlign: 'center',
              }}
            >
              <h2>Loading Map</h2>
              <BarLoader
                css={override}
                sizeUnit="rem"
                size={3}
                color="#0052a5"
              />
              <Fade in={this.state.fallbackLink}>
                <div>
                  <p>Load taking too long?</p>
                  <NavLink
                    to="/dashboard/map"
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                      '&:hover': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Button
                      style={{ primaryColor: '#0052a5' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Click to Reload
                    </Button>
                  </NavLink>
                </div>
              </Fade>
            </div>
          </Fade>
        ) : null}
        <Fade in={this.props.display}>
          <div>{this.props.children}</div>
        </Fade>
      </div>
    )
  }
}

export class DataLoadWrapper extends Component {
  _mounted = false // Store lifecycle state to prevent memory leak

  state = { fallbackLink: false }

  componentDidMount() {
    this._mounted = true

    setTimeout(() => {
      if (this._mounted) this.setState({ fallbackLink: true }) // Show refresh button if loading gets stuck
    }, 5000)
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render() {
    const override = css`
      display: block;
      margin: 1rem auto;
    `
    return (
      <div className="MapLoader">
        {this.props.loading ? (
          <Fade in={this.props.loading}>
            <div
              style={{
                marginTop: '1rem',
                textAlign: 'center',
              }}
            >
              <h2>Loading Data</h2>
              <BarLoader
                css={override}
                sizeUnit="rem"
                size={3}
                color="#0052a5"
              />
              <Fade in={this.state.fallbackLink}>
                <div>
                  <p>Load taking too long?</p>
                  <NavLink
                    to={this.props.path}
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                      '&:hover': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Button
                      style={{ primaryColor: '#0052a5' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Click to Reload
                    </Button>
                  </NavLink>
                </div>
              </Fade>
            </div>
          </Fade>
        ) : null}
        <Fade in={!this.props.loading}>
          <div>{this.props.children}</div>
        </Fade>
      </div>
    )
  }
}
