import { put } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'

import { RESEND_SING_UP_VERIFY_CODE } from '../actions'

export default function* resendSignUpVerifyCode(action) {
  try {
    yield Auth.resendSignUp(action.payload)
    yield put(RESEND_SING_UP_VERIFY_CODE.SUCCESS.create())
  } catch (error) {
    yield put(RESEND_SING_UP_VERIFY_CODE.FAILED.create(error.message))
  }
}
