import React from 'react'
import Button from '@material-ui/core/Button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

const ConfirmDialog = ({ open, title, detail, username, close }) => (
  <Dialog open={open} maxWidth="sm">
    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
      {title} {username}?
    </DialogTitle>
    {detail && <DialogContent>{detail}</DialogContent>}
    <DialogActions>
      <Button onClick={() => close(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={() => close(true)} color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmDialog
