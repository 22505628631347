/* eslint-disable camelcase */
export const stationSelector = (state) => state.station.station

export const isFloorsOptionAvailable = (state) => {
  const station = stationSelector(state)
  return station.floorsEnabled
}

export const isFlareOptionAvailableSelector = (state) => {
  const station = stationSelector(state)
  return station.flaresEnabled
}

export const isSMSAlertsOptionAvailable = (state) => {
  const station = stationSelector(state)
  return station.alertsSMSEnabled
}

export const isSMSFlaresOptionAvailable = (state) => {
  const station = stationSelector(state)
  return station.flaresSMSEnabled
}

export const licensesSelector = (state) => state.station.licenses

export const getActiveUsersCountSelector = (state) =>
  state.station.totalActiveAccounts

export const isFlareReportAvailableSelector = (state) => {
  const station = stationSelector(state)
  return station.reportsEnabled
}

export const isAlertOptionAvailableSelector = (state) =>
  stationSelector(state)?.alertsEnabled

export const isAlertEditableSubjectOptionAvailableSelector = (state) =>
  stationSelector(state).editableSubject.enabled
