import { connect } from 'react-redux'
import { rootUserSelector } from 'modules/user/selectors'
import FlaresListItem from './FlaresListItem'
import FlaresListItemGroup from './FlaresListItemGroup'
import FlaresListItemMin from './FlaresListItemMin'

const mapStateToProps = (state) => ({
  currentUser: rootUserSelector(state),
})

export const FlareItemMin = connect(mapStateToProps, null)(FlaresListItemMin)
export const FlareItem = connect(mapStateToProps, null)(FlaresListItem)
export const FlareItemGroup = connect(
  mapStateToProps,
  null,
)(FlaresListItemGroup)
