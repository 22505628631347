import { all, takeLatest } from 'redux-saga/effects'

import currentUserInfoSaga from './currentUserInfoSaga'
import logOutSaga from './logOutSaga'
import changeToSSaga from './changeToSSaga'
import changeCurrentUserPasswordSaga from './changeCurrentUserPasswordSaga'
import editCurrentUserSaga from './editCurrentUserSaga'
import requestVerificationCodeSaga from './requestVerificationCodeSaga'
import submitVerificationSaga from './submitVerificationSaga'

import {
  USER_GET_INFO,
  USER_LOG_OUT,
  CHANGE_TOS,
  CURRENT_USER_CHANGE_PASSWORD,
  USER_EDIT,
  USER_REQUEST_VERIFICATION_CODE,
  USER_SUBMIT_VERIFICATION,
} from '../actions'

function* rootUserSaga() {
  yield all([
    takeLatest(USER_GET_INFO.START.type, currentUserInfoSaga),
    takeLatest(USER_LOG_OUT.START.type, logOutSaga),
    takeLatest(CHANGE_TOS.START.type, changeToSSaga),
    takeLatest(
      CURRENT_USER_CHANGE_PASSWORD.START.type,
      changeCurrentUserPasswordSaga,
    ),
    takeLatest(USER_EDIT.START.type, editCurrentUserSaga),
    takeLatest(
      USER_REQUEST_VERIFICATION_CODE.START.type,
      requestVerificationCodeSaga,
    ),
    takeLatest(USER_SUBMIT_VERIFICATION.START.type, submitVerificationSaga),
  ])
}

export default rootUserSaga
