import { API } from 'aws-amplify'
import { AGENT_API_NAME, AGENTS_PATH } from 'api/agents'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'
import { getAuthToken } from '../utils/getAuthToken'

export default (sub) =>
  new Promise(async (resolve) => {
    const result = {
      error: undefined,
      response: undefined,
    }
    const path = `${AGENTS_PATH.latest}/${sub}`

    try {
      const token = await getAuthToken()

      const params = {
        body: {},
        headers: {
          Authorization: token,
        },
      }
      const response = await API.del(AGENT_API_NAME, path, params)
      result.response = response
    } catch (error) {
      const parsedError = parseError(error)

      reportAPIError({
        endpoint: AGENT_API_NAME + path,
        payload: {
          sub,
        },
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
