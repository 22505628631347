/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'
import { sortByDate } from 'utils/Date'
import { flareWMHKeyFunc } from 'components/QueueOutput/queueItems'
import { filterByActiveStatus } from '../../utils/filter'
import { ProgressStage } from '../../constants'

export const rootWMHSelector = (state) => state.wmh

export const getWMHIdsSelector = createSelector(rootWMHSelector, (wmh) =>
  Object.keys(wmh).sort(sortByDate),
)

export const getWMHSelector = (state, id) => rootWMHSelector(state)[id]

export const getWMHMarkerSelector = (state, id) => {
  const wmh = getWMHSelector(state, id)

  return {
    name: 'Watch My Home',
    url: `${process.env.REACT_APP_S3}/wmhMarker.svg`,
    position: wmh.position,
    label: {
      userRealName: wmh.userRealName,
      creationTime: wmh.creationTime, //
      address: wmh.address, //
      startDate: wmh.startDate, //
      endDate: wmh.endDate, //
    },
    key: flareWMHKeyFunc(wmh),
  }
}

export const getWMHByStatusIdsSelector = (state, historyMode) => {
  const whmData = getWMHIdsSelector(state)
  return whmData.filter((flareId) =>
    historyMode
      ? !filterByActiveStatus(getWMHSelector(state, flareId))
      : filterByActiveStatus(getWMHSelector(state, flareId)),
  )
}

export const getWMHFilteredByActive = (state, activeStatus) => {
  const whmData = getWMHByStatusIdsSelector(state)
  return whmData.filter((wmhItem) =>
    activeStatus
      ? wmhItem.currentstatus === 'Active'
      : wmhItem.currentstatus !== 'Active',
  )
}

export const getUnreadWMHCount = (state) => {
  const wmhData = getWMHByStatusIdsSelector(state)
  return wmhData.filter(
    (wmhId) =>
      getWMHSelector(state, wmhId).currentstatus.toUpperCase() ===
      ProgressStage.NEW.toUpperCase(),
  ).length
}
