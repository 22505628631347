import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Delete'

import Button from 'components/Button/Button'

import { GET_FLARE_TYPES } from 'modules/flares/actions'

import { flareTypesSelector } from 'modules/flares/selectors'
import { isLoadingSelector } from 'modules/requestsStatuses/selectors'

const styles = {
  container: {
    textAlign: 'right',
    marginBottom: '24px',
    maxWidth: 'auto',
  },
  dialogContent: {
    minWidth: '480px',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  loading: {
    margin: '8xp auto',
  },
  buttonsContainer: {
    padding: '8px 24px',
  },
}

export const FlareAdditionalFilters = ({
  selectedFlareTypes,
  updateSelectedFlareTypes,
}) => {
  const dispatch = useDispatch()
  const flareTypes = useSelector(flareTypesSelector)
  const [isOpen, setOpen] = useState(false)
  const isLoading = useSelector((state) =>
    isLoadingSelector(state, GET_FLARE_TYPES),
  )
  const [checkboxSelectedFlareTypes, setCheckboxSelectedFlareTypes] =
    useState(selectedFlareTypes)

  const getFlareTypesRequest = () => {
    dispatch(GET_FLARE_TYPES.START.create())
  }

  useEffect(() => {
    if (isOpen) {
      getFlareTypesRequest()
      setCheckboxSelectedFlareTypes(selectedFlareTypes)
    }
  }, [isOpen])

  const toggleModal = () => {
    setOpen(!isOpen)
  }

  const onChangeCheckbox = (flareType) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!checkboxSelectedFlareTypes.find((type) => type.id === flareType.id)) {
      setCheckboxSelectedFlareTypes(
        checkboxSelectedFlareTypes.filter((type) => type.id !== flareType.id),
      )
    } else {
      setCheckboxSelectedFlareTypes([...checkboxSelectedFlareTypes, flareType])
    }
  }

  const checkboxValue = (flareTypeId) =>
    !!checkboxSelectedFlareTypes.find((type) => type.id === flareTypeId)

  const onApplyFilter = () => {
    updateSelectedFlareTypes(checkboxSelectedFlareTypes)
    toggleModal()
  }

  const handleClearFilters = () => {
    updateSelectedFlareTypes([])
  }

  const _renderClearFiltersButton = selectedFlareTypes?.length ? (
    <IconButton
      aria-label="remove"
      color="grey"
      size="small"
      onClick={handleClearFilters}
      // eslint-disable-next-line react/no-children-prop
      children={<ClearIcon />}
    />
  ) : null

  const buttonTitle = selectedFlareTypes?.length
    ? selectedFlareTypes.reduce((acc, selectType, index) => {
        const currentType = flareTypes.find(
          (typeObj) => typeObj.id === selectType.id,
        )

        if (acc.substring(acc.length - 3, acc.length) === '...') {
          return acc
        }

        if (acc.length > 30 || index === 2) {
          return `${acc} ...`
        }

        // eslint-disable-next-line no-nested-ternary
        return currentType
          ? index === 0
            ? `${currentType.name}`
            : `${acc}, ${currentType.name}`
          : ''
      }, '')
    : 'Filter by Type'

  return (
    <Container style={styles.container} maxWidth={false}>
      <Button onClick={toggleModal} color="primary">
        {buttonTitle}
      </Button>
      {_renderClearFiltersButton}
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Select flare types for filters
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          {isLoading ? (
            <Container style={styles.loadingContainer}>
              <CircularProgress
                color="primary"
                size={40}
                style={styles.loading}
              />
            </Container>
          ) : (
            flareTypes.map((flareType) => (
              <Grid
                key={`flare-type-${flareType.id}`}
                id={flareType.id}
                direction="row"
                container
              >
                <Grid container item xs={2}>
                  {flareType.id}
                </Grid>
                <Grid container item xs={8}>
                  {flareType.name}
                </Grid>
                <Grid container xs={2} item justify="flex-end">
                  <Checkbox
                    key={`flareType-checkbox-${flareType.id}`}
                    id={`flareType-checkbox-${flareType.id}`}
                    checked={checkboxValue(flareType.id)}
                    color="primary"
                    onClick={() => onChangeCheckbox(flareType)}
                  />
                </Grid>
              </Grid>
            ))
          )}
        </DialogContent>
        <DialogActions style={styles.buttonsContainer}>
          <Button onClick={onApplyFilter} color="primary">
            apply
          </Button>
          <Button onClick={toggleModal} color="primary">
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
