/*  eslint-disable import/no-cycle */
import { put } from 'redux-saga/effects'
import api from 'api'

import { toast } from 'react-toastify'
import { GROUP_UPDATE } from '../actions'

export default function* updateGroupSaga({ payload }) {
  try {
    const { response, error } = yield api.group.updateGroup(payload)

    if (error) {
      yield put(GROUP_UPDATE.FAILED.create(error.message))
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    } else {
      yield put(GROUP_UPDATE.SUCCESS.create(response))
    }
  } catch (error) {
    yield put(GROUP_UPDATE.FAILED.create(error.message))
  }
}
