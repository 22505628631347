/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { Component } from 'reactn'
import Amplify, { Hub } from 'aws-amplify'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getAuthToken } from 'api/utils/getAuthToken'
import { ToastContainer, Zoom } from 'react-toastify'
import awsconfig from './aws-config'
import { STATE_REHYDRATION } from './modules/utils/actions'
import { GET_GENERAL_SETTINGS } from './modules/generalSettings/actions'
import { history } from './configStore'
import Router from './components/Router'
import LoadingWrapper from './components/LoadingOverlay'
import 'react-toastify/dist/ReactToastify.min.css'
import { GET_GEOFENCE } from './modules/geofence/actions'
import {
  isLoadingSelector,
  isSuccessSelector,
} from './modules/requestsStatuses/selectors'

if (process.env.REACT_APP_REMOVE_CONSOLE_OUTPUT === 'true') {
  for (const key in console) {
    console[key] = () => {}
  }
}

console.log(awsconfig, 'awsconfig')
Amplify.configure(awsconfig)

// sentry error logger
Sentry.init({
  dsn: process.env.REACT_APP_STATION_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

class App extends Component {
  async componentDidMount() {
    const { getGeneralSettings, rehydrateState, getGeofence } = this.props
    getGeneralSettings()
    rehydrateState()

    Hub.listen('auth', (data) => {
      console.log('Hub.listen data', data)
      switch (data.payload.event) {
        case 'signIn':
          console.log('user signed in')
          getGeofence()
          break
        case 'signUp':
          console.log('user signed up')
          getGeofence()
          break
        default:
          break
      }
    })

    getAuthToken().then(() => {
      getGeofence()
    })

    if (window.location.href.includes('index.html')) {
      history.replace('/')
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-cond-assign
    Hub.remove('auth')
  }

  render() {
    const { isGetGeneralSettingsSuccess, isGetGeneralSettingsLoading } =
      this.props
    return (
      <Sentry.ErrorBoundary>
        <ToastContainer transition={Zoom} />
        <div className="App">
          <LoadingWrapper
            label="Loading Station Data"
            loading={isGetGeneralSettingsLoading}
            display={isGetGeneralSettingsSuccess}
          >
            <Router />
          </LoadingWrapper>
        </div>
      </Sentry.ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => ({
  isGetGeneralSettingsSuccess: isSuccessSelector(state, GET_GENERAL_SETTINGS),
  isGetGeneralSettingsLoading: isLoadingSelector(state, GET_GENERAL_SETTINGS),
})

const mapDispatchToProps = {
  rehydrateState: STATE_REHYDRATION.DONE.create,
  getGeneralSettings: GET_GENERAL_SETTINGS.START.create,
  getGeofence: GET_GEOFENCE.START.create,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
