import { API } from 'aws-amplify'
// eslint-disable-next-line import/no-cycle
import { AGENT_API_NAME, AGENTS_PATH } from 'api/agents'
import { getAuthToken } from 'api/utils/getAuthToken'
import { parseError } from '../../utils/parseError'
import { reportAPIError } from '../../utils/errorReports'
import { getStationId } from '../../components/StationDetect'

export default (payload = {}) =>
  new Promise(async (resolve) => {
    const { perPage = 20, page = 1 } = payload
    const path = `${AGENTS_PATH.latest}/from/station/${getStationId()}`
    const result = {
      error: undefined,
      response: undefined,
    }

    try {
      const token = await getAuthToken()

      const params = {
        body: {},
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          page,
          perPage,
        },
      }
      const response = await API.get(AGENT_API_NAME, path, params)
      result.response = response
    } catch (error) {
      const parsedError = parseError(error)
      reportAPIError({
        endpoint: AGENT_API_NAME + path,
        error: parsedError,
      })
      result.error = parsedError
    }

    resolve(result)
  })
