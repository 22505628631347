import { put } from 'redux-saga/effects'

import api from 'api'

import { GET_ACTIVE_ALERTS_NEXT_PAGE } from 'modules/alerts/actions'

export default function* getAlertsNextPageSaga(action) {
  const { page } = action.payload
  const { response, error } = yield api.alerts.getAlerts({ page })

  if (error) {
    console.log('getAlertsSaga error', error)

    yield put(GET_ACTIVE_ALERTS_NEXT_PAGE.FAILED.create(error.message))
  } else {
    console.log('getAlertsSaga response', response)

    yield put(GET_ACTIVE_ALERTS_NEXT_PAGE.SUCCESS.create(response))
  }
}
