import head from 'lodash/head'
import last from 'lodash/last'

import { ACTION_DIVIDER, ACTIONS_SUBTYPES } from '../utils/actionCreator'
import { PROGRESS } from './actions'

const INTIAL_STATE = {}

function reduceAction(state, id, status, payload) {
  return id === undefined
    ? {
        status,
        payload,
        timestamp: new Date().getTime(),
      }
    : {
        ...state,
        [id]: {
          status,
          payload,
          timestamp: new Date().getTime(),
        },
      }
}

function requestsStatusesReducer(state = INTIAL_STATE, action) {
  const actionParts = action.type.split(ACTION_DIVIDER)

  const status = last(actionParts)
  const majorType = head(actionParts)

  switch (status) {
    case ACTIONS_SUBTYPES.PRE_START:
    case ACTIONS_SUBTYPES.START:
    case ACTIONS_SUBTYPES.SUCCESS:
    case ACTIONS_SUBTYPES.FAILED:
      return {
        ...state,
        [majorType]: reduceAction(
          state[majorType],
          action.id,
          status,
          action.payload,
        ),
      }
    default:
      break
  }

  switch (action.type) {
    case PROGRESS(null).STATE.type:
      return {
        ...state,
        [majorType]: reduceAction(
          state[majorType],
          action.id,
          status,
          action.payload,
        ),
      }
    default:
      break
  }

  return state
}

export default requestsStatusesReducer
