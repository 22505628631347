import React from 'reactn'
import { connect } from 'react-redux'
import { ConnectedRouter as Router } from 'connected-react-router'
import { bindActionCreators } from 'redux'

import '../App.css'
import { getCurrentUserInfo } from 'api/utils/getCurrentUserInfo'
import { setContext } from '@sentry/react'
import Nav from '../dashboard/nav/Nav'
import RoutePaths from '../dashboard/nav/RoutePaths'
import { USER_GET_INFO } from '../modules/user/actions'

import { history } from '../configStore'
import { UTILS_GET_ALERTS_WMH_FLARES_DATA } from '../modules/utils/actions'
import { isSuccessSelector } from '../modules/requestsStatuses/selectors'
import { GET_USER_GENERAL_SETTINGS } from '../modules/generalSettings/actions'

class MainPage extends React.Component {
  async componentDidMount() {
    this.props.getCurrentUserInfo()
    this.props.loadAlertsFlaresWMH()

    const userData = await getCurrentUserInfo()
    setContext('User', userData)
  }

  render() {
    return (
      <div className="MainPage">
        <Router history={history} id="mainView">
          {this.props.isUserGeneralSettingsSuccess && (
            <>
              <Nav />
              <div id="wrapper">
                <RoutePaths />
              </div>
            </>
          )}
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isUserGeneralSettingsSuccess: isSuccessSelector(
    state,
    GET_USER_GENERAL_SETTINGS,
  ),
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUserInfo: USER_GET_INFO.START.create,
      loadAlertsFlaresWMH: UTILS_GET_ALERTS_WMH_FLARES_DATA.START.create,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
