import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Button,
  TextField,
} from '@material-ui/core'

export default function AddLicenseModal({
  isVisible,
  isLoading,
  onSubmitModal,
  handleCloseDialog,
}) {
  const [licenseNumber, setLicenseNumber] = useState('')
  const [isLicenseNumberError, setLicenseNumberError] = useState(false)

  const checkError = (e) => {
    switch (e.target.id) {
      case 'licenses':
        if (!e.target.value.length) {
          setLicenseNumberError(true)
        } else {
          setLicenseNumberError(false)
        }
        break
      default:
        break
    }
  }
  return (
    <Dialog open={isVisible} fullWidth aria-labelledby="form-dialog-title">
      {isLoading ? (
        <LinearProgress variant="indeterminate" value style={styles.progress} />
      ) : (
        <div style={styles.progress} />
      )}
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        Add license
      </DialogTitle>
      <DialogContent>
        <span>
          A positive number adds license while a negative number removes
        </span>
        <TextField
          disabled={isLoading}
          autoFocus
          margin="dense"
          id="licenses"
          label="License count"
          variant="standard"
          type="number"
          min="0"
          required
          value={licenseNumber}
          onChange={(e) => setLicenseNumber(e.target.value)}
          fullWidth
          error={isLicenseNumberError}
          onBlur={checkError}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleCloseDialog}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading || !licenseNumber.length}
          color="primary"
          onClick={() => onSubmitModal(+licenseNumber)}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  progress: {
    width: '100%',
    height: '4px',
  },
}
